import React, { useCallback, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import Card from "../components/Card";
import MainLayout from '../components/MainLayout';
import Background from "../assets/background_3_0.png"

const TermConditions = () => {
    const client = useSwagger();
    const { login } = useAuth();
    const { doSetLogin } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [terms, setTerms] = useState(false);
    const [pA, setPA] = useState(false);
    const [pp, setPP] = useState(false);
    const { addToast } = useToast();

    const userObj = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.authorization;
                return req;
            };

            const response = await client.apis["User"].getUserObjects();

            if (response.status >= 200 && response.status < 300) {
                if (!response.obj.hasOwnProperty("language_code")) response.obj["language_code"] = "en";
                doSetLogin(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.authorization, doSetLogin])

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();

        if (!terms || !pA || !pp) {
            addToast(t("error"), t("organisationValidation.terms"), "error");
            return
        }

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.authorization;
                req.body = JSON.stringify({terms_conditions: true});
                return req;
            };

            const response = await client.apis["terms_conditions"].terms_conditions_create();

            if (response.status >= 200 && response.status < 300) {
                if (response.obj?.TwoFA) {
                    doSetLogin(response.obj);
                    navigate("/2fa");
                } else {
                    await userObj()
                    navigate("/");
                }
            } 

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [terms, pA, pp, client, addToast, t, login.authorization, userObj, navigate])

   

    return (
        <MainLayout {...{ background: Background }}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                <Card heading={t('conditions')} className="w-100">
                    <p className='mb-3'>{t('termsnConditions')}</p>
                    <Form onSubmit={(e) => onSubmit(e)}>
                        <Form.Check
                            className="mb-3"
                            type="checkbox"
                            id="termsandconditions"
                            checked={terms}
                            onChange={(e) => setTerms(e.target.checked)}
                            label={
                                <Trans i18nKey='registrationTermsnConditions'>
                                    <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                                </Trans>
                            }
                        />

                        <Form.Check
                            className="mb-3"
                            type="checkbox"
                            id="processingAgreement"
                            checked={pA}
                            onChange={(e) => setPA(e.target.checked)}
                            label={
                                <Trans i18nKey='registrationAgg'>
                                    <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                                </Trans>
                            }
                        />

                        <Form.Check
                            className="mb-3"
                            type="checkbox"

                            id="privacyPolicy"
                            checked={pp}
                            onChange={(e) => setPP(e.target.checked)}
                            label={
                                <Trans i18nKey='registrationPP'>
                                    <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                                </Trans>
                            }
                        />
                        <div className={'d-flex justify-content-between'}>
                            <Button variant='outline-primary' onClick={() => doSetLogin(null)}>{t('logout')}</Button>
                            <Button variant='outline-primary' type='submit'>{t('confirm')}</Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </MainLayout> )
}

export default TermConditions