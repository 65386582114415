import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next";
import { useToast } from "../../NotificationsContent";
import { Price } from "./Prices";
import { Nav, Tab } from "react-bootstrap";
import cloneDeep from 'lodash/cloneDeep';
import "./style.css"

export const PricingTable = ({ plans, setPlans, select }) => {
    const { addToast } = useToast();
    const { t } = useTranslation();

    const handleInputChange = useCallback((id, e) => {
        const { value } = e.target;
        const numericRegex = /[0-9\/]+/;
        if (numericRegex.test(value)) {
            setPlans(prev => {
                let tmpArray = [...prev];
                let index = tmpArray.findIndex(p => p.id === id);
                tmpArray[index].quantity = value;
                return tmpArray;
            })
        }
    }, [plans]);

    const onClick = useCallback((obj, plan) => {
        // if (plan.present) addToast(t("error"), t("swInUse"), "error")
        // if (!plan.present) select(obj)
        select(obj)
    }, [select])

    return <div className="pricing__table__wrapper">
        {plans?.map((plan, i) => {
            const priceProMonth = `${plan.price * plan.quantity}€ / ${plan.contract_duration_months === 1 ? '' : plan.contract_duration_months} ${t("month")}`;
            return <div className="price__column" key={`plan-column-${plan.id}`}>
                <h4>{plan.title}</h4>
                <ul className="price__benefits">
                    {Object.values(plan.benefits).map((benefit, i) => <li key={`plan-benefit-${plan.id}-${i}`}>{benefit}</li>)}
                </ul>
                <Price {...{ price: plan.price, recurring: plan.contract_duration_months }} />
                <div className="price__interval">
                    <span>{plan.price === 0 ? '' : t("from")}</span>
                    <p>{plan.price === 0 ? t("free") : priceProMonth}</p>
                </div>
                <input type="number" className="price__quantity" value={plan.quantity} min={1} pattern="[0-9\.\-]*" onChange={(e) => handleInputChange(plan.id, e)} />
                <button className={plan.present ? "price__button__disable" : "price__button"} disabled={plan.present} onClick={() => onClick({ title: plan.title, quantity: plan.quantity, software_id: plan.id, price: plan.price, type: plan.type}, plan)}>{plan.present ? t("alreadyBuyed") : t("choose")}</button>
            </div>
        })}
    </div>
}

export const PricingTableWrapper = ({ plans, setPlans, setPlanID, postOrgHasProduct, setWizard, redirect, setSelectedLicense, wizard }) => {
    const { t } = useTranslation();
    const [uil, setUil] = useState([]);
    const [cul, setCul] = useState([]);

    const select = useCallback((plan) => {
        if (plan.type === 2) {
            redirect()
            return
        }

        // postOrgHasProduct(plan)
        setPlanID(plan)
        setSelectedLicense(plan)
        setWizard(prev => prev + 1)
    }, [setPlanID, setWizard])

    useEffect(() => {
        let uilArray = cloneDeep(plans)?.filter(p => p.type !== 1);
        let culArray = cloneDeep(plans)?.filter(p => p.type !== 0);

        setUil(uilArray);
        setCul(culArray);
    }, [plans])

    return <Tab.Container defaultActiveKey="useridlicence">
        <Nav variant="pills" className="mb-3" justify>
            <Nav.Item>
                <Nav.Link eventKey="useridlicence">{t("userIdentifiedLicence")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="concurrentidlicence">{t("concurrentUserLicence")}</Nav.Link>
            </Nav.Item>
        </Nav>
        <Tab.Content>
            <Tab.Pane eventKey="useridlicence">
                {Boolean(uil?.length) && <PricingTable {...{ plans: uil, setPlans, setWizard, select }} />}
            </Tab.Pane>
            <Tab.Pane eventKey="concurrentidlicence">
                {Boolean(cul?.length) && <PricingTable {...{ plans: cul, setPlans, setWizard, select }} />}
            </Tab.Pane>
        </Tab.Content>
    </Tab.Container>
}