import { useState } from 'react'
import { useAuth } from "../Context"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Card from "../components/Card"
import { Button, Form } from "react-bootstrap";
import { useToast } from '../NotificationsContent';
import { useSwagger } from "../context/SwaggerContext";

function Forget() {
    const client = useSwagger();
    const { deafultTheme } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const { addToast } = useToast();

    const toggleForm = (boolean) => {
        const form = document.getElementById('forget-form');
        const formElements = form.elements;
        for (let i = 0; i < formElements.length; i++) {
            formElements[i].disabled = boolean;
        }
    }

    const doReset = async(e) => {
        e.preventDefault();

        toggleForm(true);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === '' || !emailRegex.test(email)) {
            addToast(t("resetPassword"), t('resetAlerts.associatedEmail'), "notification");
            toggleForm(false);
            return;
        }

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.body = JSON.stringify({ mail: email });
                return req;
            };

            const response = await client.apis["User"].postResetPassword();

            if (response.status >= 200 && response.status < 300) {
                addToast(t("resetPassword"), t('resetAlerts.instructionsEmail'), "success");
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            // console.error(error);
            // addToast(t("resetPassword"), t("resetAlerts.validEmail"), "error");
            // soll immer eine success Meldung kommen
            addToast(t("resetPassword"), t('resetAlerts.instructionsEmail'), "success");

            setTimeout(() => {
                navigate("/login");
            }, 3000);

            toggleForm(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        }

        // let data = {
        //     'mail': email
        // }

        // fetch('https://tech.sigmaheat.de/passwordreset/', {
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     method: 'POST',
        //     body: JSON.stringify(data)
        // }).then(response => {
        //     if (!response.ok) throw new Error(t("resetAlerts.validEmail"));
        //     return {};
        // }).then(() => {
        //     addToast(t("resetPassword"), t('resetAlerts.instructionsEmail'), "success");
        //     setTimeout(() => {
        //         navigate('/login');
        //     }, 3000)
        // }).catch((error) => {
        //     addToast(t("resetPassword"), error.message, "error");
        //     setTimeout(() => console.clear());
        //     toggleForm(false);
        // });
    }

    return (
        <div style={{ width: '100%', maxWidth: '512px' }}>
            <Card {...{ heading: t("resetPassword"), className: 'w-100' }}>
                <Form id='forget-form' onSubmit={(e) => doReset(e)}>
                    <p className={deafultTheme === undefined && localStorage.getItem('SH_Theme') === undefined ? "text-black" : deafultTheme === undefined ? localStorage.getItem('SH_Theme') === 'light' ? "text-black" : "text-light" : deafultTheme === 'light' ? 'text-black' : 'text-light'}>{t("resetEmail")}</p>

                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>{t("emailAddress")}</Form.Label>
                        <Form.Control type="email" placeholder="beispiel@sigmaheat.de" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>

                    <Button variant="outline-primary" className='w-100' type='submit'>{t("reset")}</Button>
                </Form>
            </Card>
        </div>
    )
}

export default Forget