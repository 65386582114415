import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import Collapse from "../components/Collapse";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCalculator,
  faCopy,
  faEdit,
  faTable,
  faToggleOn,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AddEditRoom from "./AddEditRoom";
// import AddEditBuildingPart from "./AddEditBuildingPart";
import { BuildingPartRoomTable, HeatingPartRoomTable } from "./BuildingRoom";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";
import { AddUnit } from "./AddEditUnit";
import { Calculate } from "./CalculateDIN12831";
import { RoomTable } from "./BuildingRoom";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import useBuildingHook from "../hooks/useBuildingHook";
import { usePermissions } from "../context/PermissionsContext";

export default function BuildingUnits() {
  const _buildingGuid = useParams()["buildingGuid"];
  const { login } = useAuth();
  const client = useSwagger();
  const [building, setBuilding] = useState(undefined);
  const { t } = useTranslation();
  const { addToast } = useToast();
  useBuildingHook()

  const loadbuilding = useCallback(async() => {
    // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/`, {
    //   headers: {
    //     Authorization: login.Authorization,
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     if (!response.ok) throw new Error(t("networkError"));
    //     if (response.status === 204) return {};
    //     return response.json();
    //   })
    //   .then((data) => {
    //     setBuilding(data);
    //   });

    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_retrieve({building_uuid: _buildingGuid})
    
      if (response.status === 204) {
        setBuilding({});
        return;
      }
  
      if (response.status >= 200 && response.status < 300) {
        setBuilding(response.obj);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, _buildingGuid, login.Authorization]);

  useEffect(() => {
    loadbuilding();
  }, [loadbuilding]);

  return (
    <MainLayout {...{ background: Background }}>
      <div className="sidebar__padding">
        <UtilizationUnitTable {...{ building }} />
      </div>
    </MainLayout>
  );
}

const UtilizationUnitTable = ({ building }) => {
  const { login } = useAuth();
  const _buildingGuid = useParams()["buildingGuid"];
  const { t } = useTranslation();
  const [show, setShow] = useState(undefined);
  const [units, setUnits] = useState(undefined);
  const [unit, setUnit] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [room, setRoom] = useState(undefined);
  const { addToast } = useToast();
  const client = useSwagger();
  const { allowEdit } = usePermissions();


  const loadUtilizationUnits = useCallback(async() => {
    // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/unit/`, {
    //   headers: {
    //     Authorization: login.Authorization,
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     if (!response.ok) throw new Error(t("networkError"));
    //     if (response.status === 204) return [];
    //     return response.json();
    //   })
    //   .then((data) => {
    //     setUnits(data);
    //   });
    
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].getUnit({building_uuid: _buildingGuid})

      if (response.status === 204) {
          setUnits([]);
          return;
      }
    
      if (response.status >= 200 && response.status < 300) {
        const sortedUnits = response.obj.sort((a, b) => a.entrance_level.level - b.entrance_level.level);
        setUnits(sortedUnits);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, _buildingGuid, t]);

  const copyUnit = useCallback(async(unit) => {
      // fetch(
      //   `https://tech.sigmaheat.de/building/${_buildingGuid}/unit/${unit?.id}/copy`,
      //   {
      //     headers: {
      //       Authorization: login.Authorization,
      //       "Content-Type": "application/json",
      //     },
      //     method: "POST",
      //   }
      // )
      //   .then((response) => {
      //     if (!response.ok) throw new Error(t("networkError"));
      //     if (response.status === 204) return [];
      //     return response.json();
      //   })
      //   .then((data) => {
      //     loadUtilizationUnits();
      //   });

      if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_unit_copy_create({ building_uuid: _buildingGuid, unit_uuid: unit?.id })
    
      if (response.status >= 200 && response.status < 300) loadUtilizationUnits();

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }

  }, [_buildingGuid, client, login.Authorization, _buildingGuid, t]);

  const deleteUnit = useCallback(async() => {
    // fetch(
    //   `https://tech.sigmaheat.de/building/${_buildingGuid}/unit/${unit?.id}/`,
    //   {
    //     headers: {
    //       Authorization: login.Authorization,
    //       "Content-Type": "application/json",
    //     },
    //     method: "DELETE",
    //   }
    // ).then((response) => {
    //   console.log(response.status);
    //   addToast(t("success"), t("deletedUnit"), "success");
    //   loadUtilizationUnits();
    // });

    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].building_unit_destroy({building_uuid: _buildingGuid, unit_uuid: unit?.id})

      if (response.status >= 200 && response.status < 300) {
        addToast(t("success"), t("deletedUnit"), "success");
        loadUtilizationUnits();
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, addToast, t, loadUtilizationUnits, unit]);

  const onClose = () => {
    loadUtilizationUnits();
    setShow(false);
    setUnit(undefined);
    setRoom(undefined);
  };

  const mainFunctions = [
    {
      label: t("addUnit"),
      onClick: () => setShow("Add Unit"),
      key: "add_unit",
      icon: faAdd,
    },
    {
      label: t("calculate"),
      onClick: () => setShow("Calculate"),
      key: "calculate",
      icon: faCalculator,
    },
  ];

  const tableStructure = [
    {
      col: (
        <FontAwesomeIcon
          icon={faToggleOn}
          size="sm"
          className="flex-shrink-0"
        />
      ),
      type: "icon",
    },
    {
      col: t("name"),
      type: "label",
    },
    {
      col: t("entranceLevel"),
      type: "label",
    },
    {
      col: t("area"),
      type: "label",
    },
    {
      col: t("calculatedArea"),
      type: "label",
    },
    {
      col: t("heatingLoad"),
      type: "label",
    },
    {
      col: t("thermalPower"),
      type: "label",
    },
    {
      col: t("actions"),
      type: "buttons",
    },
  ];

  useEffect(() => {
    loadUtilizationUnits();
  }, [loadUtilizationUnits]);

  return (
    <>
      <Card {...{ heading: `${t('utilizationUnits')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}>
        {units === undefined ? (
          <TablePlaceholder {...{ structure: tableStructure }} />
        ) : !Boolean(units.length) ? (
          <p className="m-0">{t("noUnits")}</p>
        ) : (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex"
                    style={{ width: "16px", height: "16px" }}
                  >
                    <FontAwesomeIcon
                      icon={faToggleOn}
                      size="sm"
                      className="flex-shrink-0"
                    />
                  </div>
                </th>
                <th>
                  <span>{t("name")}</span>
                </th>
                <th>
                  <span>{t("entranceLevel")}</span>
                </th>
                <th>
                  <span>{t("area")}</span>
                </th>
                <th>
                  <span>{t("calculatedArea")}</span>
                </th>
                <th>
                  <span>{t("heatingLoad")}</span>
                </th>
                <th>
                  <span>{t("thermalPower")}</span>
                </th>
                <th>
                  <span>{t("actions")}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {units.map((unit) => (
                <UnitRow
                  key={`floor-${unit.id}`}
                  {...{
                    building,
                    unit,
                    setUnit,
                    setShow: (key) => setShow(key),
                    reload: () => loadUtilizationUnits(),
                    copyUnit,
                    deleteUnit
                  }}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Card>
      <ContentModal
        {...{
          show: show === "Calculate",
          onHide: onClose,
          title: t("calculateDIN12831"),
          content: <Calculate {...{ onClose }} />,
        }}
      />
      <ContentModal
        {...{
          show: show === "Add Unit",
          onHide: onClose,
          title: t("unitAddEdit"),
          content: <AddUnit {...{ unit, onClose, loadUtilizationUnits }} />,
        }}
      />
      <ContentModal
        {...{
          show: show === "Room",
          onHide: onClose,
          title: `${t("unit")} ${unit?.name}`,
          content: <AddEditRoom {...{ unit, onClose, loadUtilizationUnits }} />,
        }}
      />
       <SecurityActionModal
            {...{
              show: show === "Delete_Unit",
              onHide: () => setShow(false),
              title: t("deleteUnit"),
              question: t("deleteUnitQuestion"),
              action: () => deleteUnit(),
            }}
          />
    </>
  );
};

export const UnitRow = ({
  building,
  unit,
  setUnit,
  setShow,
  reload,
  copyUnit,
  deleteUnit
}) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const { allowEdit } = usePermissions();

  return (
    <>
      <tr>
        <td>
          <Collapse
            {...{ boolean: display, onClick: () => setDisplay(!display) }}
          />
        </td>
        <td>
          <span>{unit.name}</span>
        </td>
        <td>
          <span>{unit.entrance_level?.name || t("NA")}</span>
        </td>
        <td>
          <span>
            {unit.area.toLocaleString(undefined, { maximumFractionDigits: 0 })}{" "}
            m²
          </span>
        </td>
        <td>
          <span>
            {unit.calculated_area.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            m²
          </span>
        </td>
        <td>
          <span>
            {(unit.heating_load / 1000).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            kW
          </span>
        </td>
        <td>
          <span>
            {(unit.thermal_power / 1000).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
            kW
          </span>
        </td>
        <td>
          <div className="d-flex gap-3">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("addRoom")}</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  setUnit(unit);
                  setShow("Room");
                }}
              >
                <div className='d-flex' style={{width: '16px', height: '16px'}}>
                  <FontAwesomeIcon icon={faAdd} size='sm' className='flex-shrink-0'/>
                </div>
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("edit")}</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  setUnit(unit);
                  setShow("Add Unit");
                }}
              >
                <div className='d-flex' style={{width: '16px', height: '16px'}}>
                  <FontAwesomeIcon icon={faEdit} size='sm' className='flex-shrink-0' />
                </div>
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("copyUnit")}</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                  copyUnit(unit);
                  setShow("copy_unit");
                }}
              >
                <div className='d-flex' style={{width: '16px', height: '16px'}}>
                  <FontAwesomeIcon icon={faCopy} size='sm' className='flex-shrink-0' />
                </div>
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{t("delete")}</Tooltip>}
            >
              <Button
                disabled={!allowEdit}
                variant="outline-primary"
                className="p-10"
                onClick={() => {
                    // deleteUnit(unit);
                    setUnit(unit)
                    setShow("Delete_Unit");
                  }}
              >
                <div className='d-flex' style={{width: '16px', height: '16px'}}>
                  <FontAwesomeIcon icon={faTrash} size='sm' className='flex-shrink-0'/>
                </div>
              </Button>
            </OverlayTrigger>
          </div>
        </td>
      </tr>
      {display && (
        <tr>
          <td colSpan="100%">
            <Card {...{ heading: t('unit') + ": " + unit.name, active: true, className: "height-fit" }}>
              <RoomTable
                {...{
                  building,
                  floor: unit.entrance_level,
                  rooms: unit.unit_rooms,
                  unit,
                  reload
                }}
              />
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

// const RoomTable = ({ building, rooms, unit, reload }) => {
//     const { t } = useTranslation();
//     const [show, setShow] = useState(false);
//     const [room, setRoom] = useState(undefined);
//     const showPowerCol = false;

//     const onClose = () => {
//         reload();
//         setShow(false);
//         setRoom(undefined);
//     }

//     if (!Boolean(rooms?.length)) return <span>{t('noRooms')}</span>

//     return <>
//         <Table responsive>
//             <thead>
//                 <tr>
//                     <th>
//                         <div className='d-flex' style={{ width: '16px', height: '16px' }}>
//                             <FontAwesomeIcon icon={faToggleOn} size='sm' className='flex-shrink-0' />
//                         </div>
//                     </th>
//                     <th>
//                         <span>{t("roomName")}</span>
//                     </th>
//                     <th>
//                         <span>{t("heatingLossSum")}</span>
//                     </th>
//                     <th>
//                         <span>{t("heatedArea")}</span>
//                     </th>
//                     <th>
//                         <span>{t("heatingLoadPerM2")}</span>
//                     </th>
//                     <th>
//                         <span>{t("roomTemperature")}</span>
//                     </th>
//                     <th>
//                         <span>{t("airExchangeRate")}</span>
//                     </th>
//                     {showPowerCol && <th>
//                         <span>{t("thermalPower")}</span>
//                     </th>}
//                     <th>
//                         <span>{t("actions")}</span>
//                     </th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {rooms.map((r) => <RoomRow key={`room-${r.id}`} {...{ building, room: r, showPowerCol, setShow: (bool) => setShow(bool), setRoom: (obj) => setRoom(obj) }} />)}
//             </tbody>
//         </Table>
//         <ContentModal {...{show: show === 'Edit', onHide: onClose, title: `${t('unit')} ${unit?.name}`, content: <AddEditRoom {...{ unit, room, onClose }} />}} />
//         <ContentModal {...{show: show === 'add_buildingpart_table', onHide: () => setShow(false), title: t('partTable'), content: <AddEditBuildingPart {...{ onClose: () => setShow(false), room, building }} />}} />
//     </>
// }

// export const RoomRow = ({ building, room, showPowerCol, setShow, setRoom }) => {
//     const { t } = useTranslation();
//     const [display, setDisplay] = useState(false);

//     return (
//         <>
//             <tr>
//                 <td>
//                     <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
//                 </td>
//                 <td>
//                     <span>{room.name}</span>
//                 </td>
//                 <td>
//                     <span>{(room.heating_load / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
//                 </td>
//                 <td>
//                     <span>{(room.area).toLocaleString(undefined, { maximumFractionDigits: 2 })} m²</span>
//                 </td>
//                 <td>
//                     <span>{(room.heating_load / room.area).toLocaleString(undefined, { maximumFractionDigits: 2 })} W/m²</span>
//                 </td>
//                 <td>
//                     <span>{(room.room_temperature).toLocaleString(undefined, { maximumFractionDigits: 2 })} °C</span>
//                 </td>
//                 <td>
//                     <span>{(room.air_exchange_rate).toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
//                 </td>
//                 {showPowerCol && ((room.thermal_power > 1000) ?
//                     <td>
//                         <span>{(room.thermal_power / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
//                     </td> :
//                     <td>
//                         <span>{(room.thermal_power).toLocaleString(undefined, { maximumFractionDigits: 2 })} W</span>
//                     </td>
//                 )}
//                 <td>
//                     <div className='d-flex gap-3'>
//                         <OverlayTrigger
//                             trigger={['hover', 'focus']}
//                             overlay={
//                                 <Tooltip>{t("addBuildingpartTable")}</Tooltip>
//                             }>
//                             <Button variant="outline-primary" className="p-10" onClick={() => { setRoom(room); setShow('add_buildingpart_table') }}>
//                                 <FontAwesomeIcon icon={faTable} />
//                             </Button>
//                         </OverlayTrigger>
//                         <OverlayTrigger
//                             trigger={['hover', 'focus']}
//                             overlay={
//                                 <Tooltip>{t("edit")}</Tooltip>
//                             }>
//                             <Button variant="outline-primary" className="p-10" onClick={() => { setRoom(room); setShow('Edit') }} >
//                                 <FontAwesomeIcon icon={faEdit} />
//                             </Button>
//                         </OverlayTrigger>
//                         <OverlayTrigger
//                             trigger={['hover', 'focus']}
//                             overlay={
//                                 <Tooltip>{t("delete")}</Tooltip>
//                             }>
//                             <Button variant="outline-primary" className="p-10" onClick={() => { }}>
//                                 <FontAwesomeIcon icon={faTrash} />
//                             </Button>
//                         </OverlayTrigger>

//                         <OverlayTrigger
//                             trigger={['hover', 'focus']}
//                             overlay={
//                                 <Tooltip>{t('copyRoom')}</Tooltip>
//                             }>
//                             <Button variant="outline-primary" className="p-10" onClick={() => setShow('copy_room')}>
//                                 <FontAwesomeIcon icon={faCopy} />
//                             </Button>
//                         </OverlayTrigger>
//                     </div>
//                 </td>
//             </tr>

//             {display && <>
//                 <tr>
//                     <td colSpan="100%">
//                         <BuildingPartRoomTable {...{ building, room}} />
//                     </td>
//                 </tr>
//                 <tr>
//                     <td colSpan="100%">
//                         <HeatingPartRoomTable {...{ building, room}} />
//                     </td>
//                 </tr>
//             </>}
//         </>
//     );
// };
