import React from 'react'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const InfoRoom = ({ room, floor, ...props }) => {
    const { t } = useTranslation();

    const excludedKeys = ['id'];
    const unitExcludedKeys = ['id', 'building', 'entrance_level'];

    const roomEntries = Object.entries(room).filter(([key, value]) => !excludedKeys.includes(key));

    const getDisplayKey = (key) => {
        switch (key) {
            case 'floor':
                return t('level');
            case 'wide':
                return t('width') + ' (m)';
            case 'length':
                return t('length') + ' (m)';
            case 'height':
                return t('height') + ' (m)';
            case 'area':
                return t('area') + ' (m²)';
            case 'room_temperature':
                return t('room_temperature') + ' (°C)';
            case 'air_exchange_rate':
                return t('airExchangeRate') + ' (1/h)';
            case 'transmission_losses':
                return t('transmissionLoss') + ' (W)';
            case 'ventilation_losses':
                return t('din276Main.long.ventilationLoss') + ' (W)';
            case 'thermal_power':
                return t('thermalPower') + ' (kW)';
            case 'heating_load':
                return t('heatingLoad') + ' (W)';
            case 'created_at':
                return 'created';
            case 'updated_at':
                return 'lastUpdate';
            case 'utilization_unit':
                return 'unit';
            case 'calculated_area':
                return 'calculatedArea';
            default:
                return key;
        }
    };

    const renderValue = (key, value) => {
        if (value === null || value === undefined) {
            return t('noValues');
        }

        if (typeof value === 'number') {
            value = parseFloat(value.toFixed(2));
        }

        if (key === 'thermal_power') {
            if (value) {
                const newValue = value / 1000;
                return newValue; 
            } else {
                return value
            }
        }

        if (key === 'updated_at' || key === 'created_at') {
            const date = new Date(value);
            return date.toLocaleString(); 
        }

        if (key === 'floor' && typeof value === 'object' && value !== null) {
            return  value.level + ' - ' + value.name;
        } else if (key === 'floor' && typeof value !== 'object' && value !== null ) {
            return floor.level + ' - ' + floor.name;
        }

        if (key === 'utilization_unit' && typeof value === 'object' && value !== null) {
            return Object.entries(value).filter(([subKey]) => !unitExcludedKeys.includes(subKey)).map(([subKey, subValue]) => {
                const formattedValue = (subKey === 'updated_at' || subKey === 'created_at') ? new Date(subValue).toLocaleString() : renderValue(subKey, subValue);
                return (
                    <div key={subKey} className='mb-2'>
                        <span>{t(getDisplayKey(subKey))}:</span> {formattedValue}
                    </div>
                )
            });
        } 

        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value);
        }
        return value;
    };

    return (
        <div>
            <Form>
                {roomEntries.map(([key, value]) => {
                    const displayKey = getDisplayKey(key);
                    return (
                        <Form.Group className='mb-2' key={key}>
                            {key !== 'utilization_unit' && (
                                <>
                                    <Form.Label style={{ fontWeight: "bold" }}>{t(getDisplayKey(key, t))}</Form.Label>
                                    <Form.Control type='text' readOnly disabled value={renderValue(key, value)} />
                                </>
                            )}
                           {key === 'utilization_unit' && value !== null && typeof value === 'object' && (
                                <>
                                    <Form.Label style={{ fontWeight: "bold" }}>{t(displayKey)}</Form.Label>
                                    {renderValue(key, value)}
                                </>
                            )}
                        </Form.Group>
                    )
                })}
            </Form>
        </div>
    )
}

export default InfoRoom