import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from "./Context";
import Loading from './components/Loading';

export const PrivateRoute = () => {
    const { login, isLoading } = useAuth();
    const location = useLocation();
    const currentRoute = location.pathname;

    // if (isLoading) return <div>Loading...</div>;
    if (isLoading) return <Loading/>;

    if (login?.["TwoFA"]) return currentRoute === "/2fa" ? <Outlet /> : <Navigate to='/2fa' replace />
    if (login?.accepted === false) {
        return currentRoute === "/terms" ? <Outlet /> : <Navigate to='/terms' replace />;
    }
    if (login === null) return <Navigate to='/login' replace />
    // return currentRoute === "/2fa" ? <Navigate to={`/`} replace /> : <Outlet />;

    if (currentRoute === "/2fa") {
        return <Navigate to={`/`} replace />;
    }

    if (currentRoute === "/terms") {
        return <Navigate to={`/`} replace />;
    }

    return <Outlet />;

}

export const PublicRoute = () => {
    const { login, isLoading } = useAuth();
    const location = useLocation();
    const currentRoute = location.pathname;

    // if (isLoading) return <div>Loading...</div>;
    if (isLoading) return <Loading/>;

    if (login?.["TwoFA"]) return currentRoute === "/2fa" ? <Outlet /> : <Navigate to='/2fa' replace />
    if (login?.accepted === false) {
        return currentRoute === "/terms" ? <Outlet /> : <Navigate to='/terms' replace />;
    }
    if (login !== null) return <Navigate to={'currentOrganisation' in login ? `/${login.currentOrganisation.id}/dashboard` : '/'} replace />
    return <Outlet />
}