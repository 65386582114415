import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCheck, faTrash, faEdit, faDrawPolygon, faTriangleExclamation, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { timeReadableFormat } from "../util/helpers";
import Card from "../components/Card";
import { ColumnLineMix } from "../components/AmCharts";
import Collapse from "../components/Collapse";
import { ListExchangeHeatpart } from "./ListExchangeHeatpart";
import SecurityActionModal from "../components/SecurityActionModal";
import ContentModal from "../components/ContentModal";
import AddEditSchema from "./AddEditSchema";
import { useSwagger } from "../context/SwaggerContext";
import { AddEditBuildingPartVariant } from "./AddEditBuildingPartVariant";
import { usePermissions } from "../context/PermissionsContext";

export const StandardReferenceTable = ({ variant, setShow, reloadBP, getAllVariants, ...props }) => {
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const [standardRef, setStandardRef] = useState(undefined);
    const [firstCH, setFirstCH] = useState(undefined)
    const [secndCH, setSecndCH] = useState(undefined)
    const [thirdCH, setThirdCH] = useState(undefined)
    const [temp_data, setTemp_Data] = useState(undefined);
    const [schema, setSchema] = useState(undefined);
    const [schemas, setSchemas] = useState(undefined);
    const [showSchema, setShowSchema] = useState(false);
    const [editBP, setEditBP] = useState(false);
    const [lastCalculationOlder, setLastCalculationOlder] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [strandReferences, setStrandReferences] = useState(undefined);
    const [selectedStrandReference, setselectedStrandReference] = useState(undefined);

    const client = useSwagger();
    const { allowEdit } = usePermissions();

    const localStorageDataRef = useRef(JSON.parse(localStorage.getItem('schema')));
    if (!localStorageDataRef.current) {
        localStorageDataRef.current = [];
    }

    const tableStructure = [
        {
            col: t('#'),
            type: 'label'
        }, {
            col: t('type'),
            type: 'label'
        }, {
            col: t('uValue'),
            type: 'label'
        }, {
            col: t('from_catalog'),
            type: 'label'
        }, {
            col: t('thermalBridgeSurcharge'),
            type: 'label'
        },

        // {
        //     col: t('createdAt'),
        //     type: 'label'
        // }, {
        //     col: t('updatedAt'),
        //     type: 'label'
        // },

        {
            col: t('actions'),
            type: 'buttons'
        },
    ];

    const schemaTableStructure = [
        {
            col: "Name",
            type: 'label'
        }, {
            col: t('createdAt'),
            type: 'label'
        }, {
            col: t('updatedAt'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        },
    ];

    const mainFunctions = [
        { label: t('add'), onClick: () => setShowSchema("add/edit"), key: 'add', icon: faAdd },
    ]

    function mergeArraysIntoObjects(array1, array2, array3) {
        if (array1.length !== array2.length) return []

        let mergedArray = [];

        for (let i = 0; i < array1.length; i++) {
            let obj = {
                x: array1[i],
                y: array2[i]
            };

            if (Boolean(array3?.length)) obj.y_2 = array3[i];

            mergedArray.push(obj);
        }

        return mergedArray;
    }

    const fetchStrandReferences = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_strandreference_list({building_uuid: _buildingGuid, variant_uuid: variant.id});

            if (response.status >= 200 && response.status < 300) {
                
                setStrandReferences(response.obj)
                setselectedStrandReference(undefined)
                
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            console.log("error", error)
            addToast(t('error'), t('networkError'), "error");
        }
    }, [client, login.Authorization, _buildingGuid, variant, t, addToast])

    const loadStandardRef = useCallback(async () => {
        setStandardRef(undefined);
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variant.id}/buildingparts/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        //     .then((response) => {
        //         if (!response.ok) throw new Error(t('networkError'));
        //         if (response.status === 204) return [];
        //         return response.json();
        //     })
        //     .then((data) => {
        //         setStandardRef(data);
        //         console.log(data);
        //     });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getVariantsbp({
                building_uuid: _buildingGuid,
                variant_uuid: variant.id,
            });

            setEditBP(false);

            if (response.status === 204) {
                setStandardRef([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setStandardRef(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            setEditBP(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, reloadBP]);


    const getVariantDATA = useCallback(async () => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variant.id}/data`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        //     .then((response) => {
        //         if (!response.ok) throw new Error(t('networkError'));
        //         if (response.status === 204) return [];
        //         return response.json().then(data => {
        //             let array = [];

        //             // Add entries from 28 to 53
        //             for (let i = 28; i <= 53; i++) {
        //                 array.push(i);
        //             }

        //             // Add entries from 1 to 27
        //             for (let i = 1; i <= 27; i++) {
        //                 array.push(i);
        //             }

        //             setFirstCH({
        //                 name: "Leistung",
        //                 x: "°C Außentemp.",
        //                 y: "kW",
        //                 data: mergeArraysIntoObjects(data[0].x, data[0].y)
        //             })

        //             setSecndCH({
        //                 name: "Leistung",
        //                 name2: "Anzahl Tage",
        //                 x: "°C Außentemp.",
        //                 y: "kW, Days",
        //                 data: mergeArraysIntoObjects(data[1].x, data[1].y, data[1].histogram_days)
        //             })

        //             setThirdCH({
        //                 name: "Leistung",
        //                 name2: "Außentemperatur",
        //                 x: "Calendar Weeks",
        //                 y: "kW",
        //                 data: mergeArraysIntoObjects(array, data[2].y, data[2].t)
        //             })
        //         });
        //     });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_data_retrieve({
                building_uuid: _buildingGuid,
                variant_uuid: variant.id,
            });

            if (response.status === 204) {
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                let array = [];
                // Add entries from 28 to 53
                for (let i = 28; i <= 53; i++) array.push(i);

                // Add entries from 1 to 27
                for (let i = 1; i <= 27; i++)  array.push(i);

                setFirstCH({
                    name: t('power'),
                    x: "°C Außentemp.",
                    y: "kW",
                    data: mergeArraysIntoObjects(response.obj[0].x, response.obj[0].y),
                });

                setSecndCH({
                    name: t('consumption'),
                    name2: "Anzahl Tage",
                    x: "°C Außentemp.",
                    y: "kW, Days",
                    data: mergeArraysIntoObjects(
                        response.obj[1].x,
                        response.obj[1].y,
                        response.obj[1].histogram_days
                    ),
                });

                setThirdCH({
                    name: t('power'),
                    name2: "Außentemperatur",
                    x: "Calendar Weeks",
                    y: "kW",
                    data: mergeArraysIntoObjects(
                        array,
                        response.obj[2].y,
                        response.obj[2].t
                    ),
                });
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization]);

    const getSchemas = useCallback(async () => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variant.id}/scheme/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     if (response.status === 204) return [];
        //     return response.json();
        // })
        // .then(data => setSchemas(data))
        // .catch(error => addToast(t("error"), error.message, "error"));

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_scheme_list({ building_uuid: _buildingGuid, variant_uuid: variant.id });

            if (response.status >= 200 && response.status < 300) {
                setSchemas([]);
                if (response.status === 204) return
                setSchemas(response.obj)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, variant])

    const toggleSchema = (schema, flag) => {
        setSchema(schema)
        setShowSchema(flag)
    }

    const deleteSchema = useCallback(async () => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variant.id}/scheme/${schema?.id}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "DELETE"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     getSchemas();
        //     toggleSchema(undefined, false)
        //     addToast(t("success"), "Schema Deleted Successfully", "success");
        // })
        // .catch(error => addToast(t("error"), error.message, "error"));

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_scheme_destroy({ building_uuid: _buildingGuid, variant_uuid: variant.id, scheme_uuid: schema?.id });

            if (response.status >= 200 && response.status < 300) {
                if (localStorageDataRef?.current && localStorageDataRef?.current?.length >= 1 ) {
                    // Filter out the item with the matching schemaID
                    localStorageDataRef.current = localStorageDataRef.current.filter(item => item.schemaID !== schema?.id);
              
                    // Update the localStorage with the filtered data
                    localStorage.setItem('schema', JSON.stringify(localStorageDataRef.current));
                }
                getSchemas();
                toggleSchema(undefined, false)
                addToast(t("success"), "Schema Deleted Successfully", "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, schema, getSchemas]);

    const onSetDefault = useCallback(async(e, max_flow_temp) => {
        e.preventDefault();
        if (!client || isSubmitting) return;

        setIsSubmitting(true);

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify({
                    ...variant,
                    fixed_flow_temperature: true,
                    max_flow_temp: max_flow_temp
                });
                return req;
            };
  
            const response = await client.apis["building"].building_variants_partial_update({ building_uuid: _buildingGuid, variant_uuid: variant?.id })
    
            if (response.status >= 200 && response.status < 300) {
                setIsSubmitting(false);
                getAllVariants();
                addToast(t("success"), variant?.id ? t("patchVariant") : t("postVariant"), "success");
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            setIsSubmitting(false);
            addToast(t("error"), t(statusCode === 429 ? "fiveVariant" : "networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }
    }, [client, login.Authorization, addToast, variant]);

    const handleSelectChange = (e) => {
        const selectedId = e.target.value;

        if(selectedId=="main"){
            setselectedStrandReference(undefined)
        }else{
            const selectedObject = strandReferences.find(item => item.id === selectedId);
            setselectedStrandReference(selectedObject);
        }
    };

    useEffect(() => {
        fetchStrandReferences()
        loadStandardRef();
        getVariantDATA();
        getSchemas();
    },[])

    useEffect(()=>{

        try {
            let d = [];
            let yo = 0;
            let configuration;
            if(selectedStrandReference){
                configuration = selectedStrandReference?.more_attributes[variant.id]
            }else {
                configuration = variant?.meta

            }

            configuration?.temperature_configurations?.map(m => {
                let x = Object.keys(m)[0];
                let y = m[x];
                
                if (yo != y && Math.round(10*Number(configuration.flow_temperature))>=Math.round(Number(x)*10)){
                    d.push({ x: x, y: y });
                    yo = y;
                }
            })

            d = d.sort((a, b) => {
                if (a.x < b.x) return 1;
                return -1;
            })

            setTemp_Data([...d]);
        } catch (e) {
            console.log(e);
        }
    }, [selectedStrandReference,strandReferences])

    // useEffect(() => {
    //     console.log("Variant: ", editBP);
    // }, [editBP])

    const addSecondsToDate = (date, seconds) => {
        return new Date(date.getTime() + seconds * 1000);
    };

    const lastCalculation = variant?.last_calculation ? new Date(variant?.last_calculation) : undefined;
    const updatedAt = variant?.updated_at ? new Date(variant?.updated_at) : undefined;
    let formattedDate = undefined;

    if (lastCalculation) {
        formattedDate = lastCalculation.toLocaleDateString("de-DE", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const headingContent = (
        <>
            {t('calculation_result')} {formattedDate}
            {lastCalculationOlder && (
                <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('notActual')}</Tooltip>}>
                    <span>
                        <FontAwesomeIcon icon={faTriangleExclamation} color="#FFBF00" style={{ paddingLeft: "5px" }} />
                    </span>
                </OverlayTrigger>
            )}
        </>
    );

    useEffect(() => {
        if (lastCalculation && updatedAt) {
            const lastCalculationWithSecondsAdded = addSecondsToDate(lastCalculation, 5); // 5 Sekunden hinzufügen
            const isLastCalculationOlder = lastCalculationWithSecondsAdded < new Date(updatedAt);
            setLastCalculationOlder(isLastCalculationOlder)
        }
    }, [])

    return <>
        <tr>
            <td colSpan='100%'>
                <Card {...{ heading: headingContent, className: 'mb-3' }}>
                    <Form.Group className='mb-3'>
                        <Form.Label>{t("strand")}</Form.Label>
                        <Form.Select id="strandreference" onChange={handleSelectChange}>
                                <option key={`strandReference-main`} value={"main"}>
                                    {t('summary')}
                                </option>
                            {strandReferences?.map(strandReference => (
                                <option key={`strandReference-${strandReference.id}`} value={strandReference.id}>
                                    {strandReference.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th key={`tableCol-flow`}>
                                    <span>{t('flow_temperature')}</span>
                                </th>
                                <th key={`tableCol-return`}>
                                    <span>{t('return_temperature')}</span>
                                </th>
                                {!selectedStrandReference && <th key={`tableCol-cons`}>
                                    <span>{t('year_consumption')}</span>
                                </th>}
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td>
                                    {variant?.meta?.flow_temperature?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} °C
                                </td>
                                <td>
                                    {variant?.meta?.return_temperature?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} °C
                                </td>
                                <td>
                                    {variant?.avg_consumption?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} kWh
                                </td>
                            </tr> */}

                            <tr>
                                <td>
                                    {(selectedStrandReference?.more_attributes[variant.id]?.flow_temperature || '-')?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} °C
                                </td>
                                <td>
                                    {(selectedStrandReference?.more_attributes[variant.id]?.return_temperature || '-')?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} °C
                                </td>
                                {!selectedStrandReference && <td>
                                    {variant?.avg_consumption?.toLocaleString(undefined, { maximumFractionDigits: 2 }) +" kWh"}
                                </td>}
                            </tr>

                        </tbody>
                    </Table>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th key={`tableCol-mass-flow`}>
                                    <span>{t('target_mass_flow')}</span>
                                </th>
                                <th key={`tableCol-pressure-mbar`}>
                                    <span>{selectedStrandReference && t('pumpTotalHead')}</span>
                                </th>
                                <th key={`tableCol-return`}>
                                    <span>{t('heatpart_count')}</span>
                                </th>
                                {/* <th key={`tableCol-cons`}>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td>
                                    {variant?.meta?.mass_flow?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} kg/h
                                </td>
                                <td>
                                    {variant?.strand?.target_pressure_mbar} mbar
                                </td>
                                <td>
                                    {variant?.meta?.heatpart_count?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
                                </td> */}

                                <td>
                                    {(selectedStrandReference?.more_attributes[variant.id]?.mass_flow || 0)?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} kg/h
                                </td>
                                <td>
                                    {selectedStrandReference && (selectedStrandReference?.target_pressure_mbar || 0) + " mBar"}
                                </td>
                                <td>
                                    {(selectedStrandReference?.more_attributes[variant.id]?.heatpart_count || 0)?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
                                </td>

                                {/* <td>
                                </td> */}
                            </tr>
                        </tbody>
                    </Table>

                    <ListExchangeHeatpart {...{ variant }} />

                    <h5 style={{ paddingTop: "20px" }}>{t("exchange")}</h5>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th key={`tableCol-flow`}>
                                    <span>{t('flow_min')}</span>
                                </th>
                                <th key={`tableCol-count`}>
                                    <span>{t('resulting_count_heatparts')}</span>
                                </th>
                                <th key={`tableCol-set`}>
                                    {selectedStrandReference && <span>{t('set_flow_temperature')}</span>}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {temp_data?.map(t =>
                                <tr>
                                    <td>
                                        {t.x?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} °C
                                    </td>
                                    <td>
                                        {t.y?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}/{(selectedStrandReference?.more_attributes[variant.id]?.heatpart_count|| variant?.meta?.heatpart_count)?.toLocaleString(undefined, { maximumFractionDigits: 0 })} ({(t.y / (selectedStrandReference?.more_attributes[variant.id]?.heatpart_count|| variant?.meta?.heatpart_count)*100).toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0} %)
                                    </td>
                                    <td>
                                       {selectedStrandReference && <Button variant="outline-primary" className="p-10" onClick={(e) => onSetDefault(e, t.x?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0)} disabled={isSubmitting}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>}
                                    </td>
                                </tr>)}
                        </tbody>
                    </Table>
                </Card>
                <Card {...{ heading: t('standardBuildingParts'), className: 'mb-3' }}>
                    {(standardRef === undefined) ? (
                        <TablePlaceholder {...{ structure: tableStructure }} />
                    ) : (!Boolean(standardRef?.length)) ? (
                        <p className="m-0">{t("noStandardReferences")}</p>
                    ) : (
                        <>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                            <span>{ts.col}</span>
                                        </th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {standardRef?.map((reference) => <StandardRefRow key={`ref-key-${reference.id}`} {...{ variant, reference, setShow, loadStandardRef, setEditBP }} />)}
                                </tbody>
                            </Table>

                            <ContentModal {...{ show: editBP, onHide: () => setEditBP(false), title: t('editBPV'), content: <AddEditBuildingPartVariant {...{ buildingPartV: editBP, variantID: variant?.id, onClose: () => loadStandardRef() }} /> }} />
                        </>
                    )}
                </Card>
                {variant?.last_calculation && (
                    <>
                        <h3 className="mb-3">{t("heatingLoad")}</h3>
                        <div style={{ 'height': '500px' }} className="mb-3">
                            {firstCH && <ColumnLineMix {...{ chartID: 'firstCH-' + variant.id, data: firstCH, x_axis_title: "Außentemperatur in °C" }} />}
                        </div>

                        <h3 style={{ paddingTop: "20px" }}>{t('statisticallyRequiredHeatingPower')}</h3>
                        <div style={{ 'height': '500px' }}>
                            {thirdCH && <ColumnLineMix {...{ chartID: 'thirdCH-' + variant.id, data: thirdCH, y_label: "kW", y2_label: "°C Außentemp.", x_label: "In KW {categoryX}: {valueY}", x_axis_title: "Kalenderwoche" }} />}
                        </div>

                        <h3 style={{ paddingTop: "20px" }}>{t('statisticalConsumption')}</h3>
                        <div style={{ 'height': '500px' }} className="mb-3">
                            {secndCH && <ColumnLineMix {...{ chartID: 'secndCH-' + variant.id, data: secndCH, y_label: "kWh", x_label: "Bei {categoryX} °C : {valueY}{categoryY}", x_axis_title: "Außentemperatur in °C" }} />}
                        </div>
                    </>
                )}

                <Card {...{ heading: "Schema", className: 'mb-3 hide-schema', ...(allowEdit && { mainFunctions }) }}>
                    {(schemas === undefined) ? (
                        <TablePlaceholder {...{ structure: schemaTableStructure }} />
                    ) : (!Boolean(schemas?.length)) ? (
                        <p className="m-0">{t("noStandardReferences")}</p>
                    ) : (
                        <Table responsive>
                            <thead>
                                <tr>
                                    {schemaTableStructure.map(ts => <th key={`schemastableCol-${ts.col}`}>
                                        <span>{ts.col}</span>
                                    </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {schemas?.map(schema => <SchemaRow key={`schema-key-${schema.id}`} {...{ variant, schema, toggleSchema }} />)}
                            </tbody>
                        </Table>
                    )}
                </Card>

                <ContentModal {...{ show: showSchema === "add/edit", onHide: () => toggleSchema(undefined, false), title: "Add Edit Schema", content: <AddEditSchema {...{ schema, onClose: () => { toggleSchema(undefined, false); getSchemas() }, variant: variant.id }} /> }} />
                <SecurityActionModal {...{ show: showSchema === "delete", onHide: () => toggleSchema(undefined, false), title: "Delete Schema", question: "Are you sure that you want to delete this Schema?", action: () => deleteSchema() }} />
            </td>
        </tr>
    </>
};

const StandardRefRow = ({ variant, reference, setShow, loadStandardRef, setEditBP }) => {
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { addToast } = useToast();
    const [display, setDisplay] = useState(false);
    const [security, setSecurity] = useState(false);
    const client = useSwagger();

    const { allowEdit } = usePermissions();

    const [type, setType] = useState(undefined)

    const getTypes = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["buildingparttypes"].buildingparttypes_list();

            if (response.status === 204) {
                setType(undefined)
                return
            }

            if (response.status >= 200 && response.status < 300) {
                const matchedItem = response.obj.find(item => item.value === reference.type);
                if (matchedItem) {
                    const labelMap = {
                        Floor: "floor",
                        OuterWall: "outerwall",
                        Ceiling: "ceiling",
                        Window: "window",
                        OuterDoor: "outerdoor",
                        Roof: "roof",
                        InnerWall: "innerwall",
                        InnerDoor: "innerdoor",
                        InnerCeiling: "innerceiling",
                        Shutterbox: "rollershutterbox",
                        "Virtual Group": "virtualGroup",
                        "Ground Soil" : "groundSoil",
                        "OuterWall Soil": "outerWallSoil",
                    };

                    matchedItem.label = labelMap[matchedItem.label] || matchedItem.label;
                    setType(matchedItem.label);
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error:", error)

            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, reference])

    useEffect(() => {
        getTypes()
    }, [reference])

    const deleteReference = useCallback(async () => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${variant.id}/buildingparts/${reference.id}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "DELETE"
        // })
        //     .then((response) => {
        //         if (!response.ok) throw new Error(t('networkError'));
        //         loadStandardRef();
        //         if (response.status === 202) addToast(t("success"), t('deletedBuildingPartVariant'), "success");
        //     })
        //     .catch(error => addToast(t("error"), error.message, "error"));

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_buildingparts_destroy({
                building_uuid: _buildingGuid,
                variant_uuid: variant.id,
                variant_bp_uuid: reference.id,
            });

            if (response.status >= 200 && response.status < 300) {
                loadStandardRef();
                if (response.status === 202) addToast(t("success"), t('deletedBuildingPartVariant'), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, addToast]);

    return <>
        <tr>
            <td>
                <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
            </td>
            <td>
                {/* <span>{t(reference.type)}</span> */}
                <span>{t(`${type}`)}</span>
                {(reference?.comment !== "") &&
                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{reference?.comment}</Tooltip>}>
                    <span>
                        <FontAwesomeIcon icon={faCircleInfo} color="yellow" style={{ paddingLeft: "5px" }} />
                    </span>
                    </OverlayTrigger>
                }
            </td>
            <td>
                <span>{(reference?.standard_reference?.u_value_m2 || reference?.U_typical)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </td>
            <td>
                <span>{reference.standard_reference ? <FontAwesomeIcon icon={faCheck} /> : t("individual")}</span>
            </td>
            <td>
                <span>{(reference?.U_thermal_bridge)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </td>
            {/* <td>
                <span>{timeReadableFormat(reference.created_at)}</span>
            </td>
            <td>
                <span>{timeReadableFormat(reference.updated_at)}</span>
            </td> */}
            <td>
                <div className='actions__buttonbar grid__two'>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={<Tooltip>{t("edit")}</Tooltip>}>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => setEditBP(reference)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={<Tooltip>{t("delete")}</Tooltip>}>
                        {/* <Button variant="outline-primary" className="p-10" onClick={() => deleteReference()}> */}
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => setSecurity("deleteRef")}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>

        {display && <>
            <tr>
                <td colSpan='100%'>
                    <Card {...{ heading: t('catalog'), className: 'mb-3' }}>
                        {reference.standard_reference && <>
                            <p>{t('createdAt')}:<br />{new Date(reference?.created_at).toLocaleDateString("de-DE", {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            })}
                            </p>
                            <p>{t('updatedAt')}:<br />{new Date(reference?.updated_at).toLocaleDateString("de-DE", {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            })}
                            </p>
                        </>}
                        {reference.standard_reference && <>
                            <p>{t('name')}:<br />{reference.standard_reference.name}</p>
                            <p>{t('uValue')}:<br />{reference.standard_reference.u_value_m2}</p>

                            {reference.standard_reference.material && <p>{t('material')}:<br />{reference.standard_reference.material}</p>}
                            {reference.standard_reference.structure && <p>{t('structure')}:<br />{reference.standard_reference.structure}</p>}
                            {reference.standard_reference.valid_from && <p>{t('valid_from')}:<br />{reference.standard_reference.valid_from}</p>}
                            {reference.standard_reference.valid_to && <p>{t('valid_to')}:<br />{reference.standard_reference.valid_to}</p>}
                        </>}
                        {!reference.standard_reference && <>
                            <p>{t('uValue')}:<br />{reference.U_typical}</p>
                            <p>{t('thermalBridge')}:<br />{reference.U_thermal_bridge}</p>
                        </>}
                    </Card>
                </td>
            </tr>
        </>}
        {/* <SecurityActionModal {...{ show: show === "delete", onHide: () => {setShow(false) }, title: t('deleteVariant'), question: t('deleteVariantQuestion'), action: () => deleteReference(variant.id) }} /> */}
        <SecurityActionModal {...{ show: security === "deleteRef", onHide: () => { setSecurity(false) }, title: t('deleteBuildingPartVariant'), question: t('deleteBuildingPartVariantQuestion'), action: () => deleteReference() }} />
    </>;
};


const SchemaRow = ({ variant, schema, toggleSchema }) => {
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];
    const { allowEdit } = usePermissions();

    return <tr>
        <td>
            <span>{schema.name}</span>
        </td>
        <td>
            <span>{timeReadableFormat(schema.created_at)}</span>
        </td>
        <td>
            <span>{timeReadableFormat(schema.updated_at)}</span>
        </td>
        <td>
            <div className='actions__buttonbar grid__three'>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip>{t("design")}</Tooltip>}>
                    {/* <Button variant="outline-primary" className="p-10" onClick={() => window.open(`${window.location.origin}/building/${_buildingGuid}/variant/${variant?.id}/schema/${schema.id}`, "_blank").focus()}>
                        <FontAwesomeIcon icon={faDrawPolygon} />
                    </Button> */}
                    <Button
                        variant="outline-primary"
                        className="p-10"
                        onClick={() => window.location.href = `${window.location.origin}/building/${_buildingGuid}/variant/${variant?.id}/schema/${schema.id}`}>
                        <FontAwesomeIcon icon={faDrawPolygon} />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip>{t("edit")}</Tooltip>}>
                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => toggleSchema(schema, "add/edit")}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={<Tooltip>{t("delete")}</Tooltip>}>
                    <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => toggleSchema(schema, "delete")}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </OverlayTrigger>
            </div>
        </td>
    </tr>
};