import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSwagger } from '../context/SwaggerContext';
import { Button, Form } from "react-bootstrap";
import { getChangedFields, isEmpty } from '../util/helpers';

const AddEditSubsidy = ({onClose, ...props}) => {
    const edit = props?.subsidy
    const client = useSwagger();
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();

    const editSubsidy = props.subsidy;
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    const initialSubsidy = edit ? 
        {
            action: edit?.action,
            variant: edit?.variant,
            subsidy: edit?.subsidy,
            requested_funding: edit?.requested_funding,
            costs_for_the_customer: edit?.costs_for_the_customer,
            approved_subsidies: edit?.approved_subsidies,
            status: edit?.status,
            subsidy_cash_state: edit?.subsidy_cash_state,
            planned_start_date: edit?.planned_start_date,
            planned_end_date: edit?.planned_end_date,      
        } : {
            action: "",
            variant: undefined,
            subsidy: undefined,
            requested_funding: undefined,
            costs_for_the_customer: undefined,
            approved_subsidies: undefined,
            status: undefined,
            subsidy_cash_state: undefined,
            planned_start_date: undefined,
            planned_end_date: undefined,      
        }
    
    // const [subsidy, setSubsidy] = useState({
    //     action: edit?.action || "",
    //     variant: edit?.variant || undefined,
    //     subsidy: edit?.subsidy || undefined,
    //     requested_funding: edit?.requested_funding || undefined,
    //     costs_for_the_customer: edit?.costs_for_the_customer || undefined,
    //     approved_subsidies: edit?.approved_subsidies || undefined,
    //     status: edit?.status || undefined,
    //     subsidy_cash_state: edit?.subsidy_cash_state || undefined,
    //     planned_start_date: edit?.planned_start_date || undefined,
    //     planned_end_date: edit?.planned_end_date || undefined,       
    //     // ...props?.subsidy
    // });
    
    const [subsidy, setSubsidy] = useState(initialSubsidy);

    const [subsidyStatus, setSubsidyStatus] = useState([]);
    const [subsidyCashState, setSubsidyCashState] = useState([]);
    const [subsidyCatalog, setSubsidyCatalog] = useState([]);

    const [variants, setVariants] = useState([]);
    // const [subsidies, setSubsidies] = useState([]);
    // const [selectedFile, setSelectedFile] = useState(undefined);
    // const [info, setInfo] = useState({
    //     title: "",
    //     type: ""
    // })

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setSubsidy(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    // const handleFileChange = (e) => {
    //     setSelectedFile(e.target.files[0]);
    // }

    
    const getAllSubsidies = useCallback(async () => {
        setSubsidyCatalog([]);
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["subsidy"].subsidy_list({
            });

            if (response.status === 204) {
                setSubsidyCatalog([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setSubsidyCatalog(response.obj);
                // setSubsidy(prevState => ({
                //     ...prevState,
                //     [id]: value
                // }));
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, t])

    useEffect(() => {
        getAllSubsidies()
    }, [getAllSubsidies])

    const getVariants = useCallback(async () => {
        setVariants([]);
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getVariants({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                setVariants([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setVariants(response.obj);
                setSubsidy(prevState => ({
                    ...prevState,
                    variant: subsidy?.variant || response.obj[0].id
                }));
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();
        if (!client || isSubmitting) return;
  
        setIsSubmitting(true);

        const changedFields = getChangedFields(subsidy, initialSubsidy);
        if (edit !== undefined && isEmpty(changedFields)) {
            onClose();
            return;
        }

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                // req.body = JSON.stringify(subsidy);
                req.body = JSON.stringify(edit ? changedFields : subsidy);
                return req;
            };
  
            const response = editSubsidy ? await client.apis["building"].building_action_partial_update({ building_uuid: _buildingGuid, action_uuid: editSubsidy?.id }) : await client.apis["building"].building_action_create({ building_uuid: _buildingGuid });
    
            if (response.status >= 200 && response.status < 300) {
                // selectedFile && await postFiles(response.obj.id)
                onClose();
                addToast(t("success"), editSubsidy? t("patchSubsidy") : t("postSubsidy"), "success");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }
    }, [login.Authorization, onClose, client, subsidy]);

    let funding_ratio = Math.max(0,1/(1+(subsidy['costs_for_the_customer']/subsidy['requested_funding'])))
    let new_customer_costs = subsidy['approved_subsidies']/funding_ratio-subsidy['approved_subsidies']


    const getSubsidyStatus = useCallback(async () => {
        setSubsidyStatus([]);
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["subsidy"].subsidy_status_retrieve();
            
            if (response.status === 204) {
                setSubsidyStatus([]);
                return;
            }
            if (response.status >= 200 && response.status < 300) {
                // setSubsidyStatus(Object.values(response.obj));
                setSubsidyStatus(response.obj);
                setSubsidy(prevState => ({
                    ...prevState,
                    status: edit ? edit?.status : (response.obj.length > 0 ? response.obj[0].value : undefined)
                }));

            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    
    const getSubsidyCashState = useCallback(async () => {
        setSubsidyCashState([]);
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["subsidy"].subsidy_cashstate_retrieve();
            
            if (response.status === 204) {
                setSubsidyCashState([]);
                return;
            }
            if (response.status >= 200 && response.status < 300) {
                // setSubsidyCashState(Object.values(response.obj));
                // setSubsidy(prevState => ({
                //     ...prevState,
                //     subsidy_cash_state: edit ? edit?.subsidy_cash_state : (Object.values(response.obj).length > 0 ? Object.values(response.obj)[0][1] : undefined)
                // }));
                setSubsidyCashState(response.obj);
                setSubsidy(prevState => ({
                    ...prevState,
                    subsidy_cash_state: edit ? edit?.subsidy_cash_state : (response.obj.length > 0 ? response.obj[0].value : undefined)
                }));
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    useEffect(() => {
        getSubsidyStatus();
        getSubsidyCashState();
        }, [getSubsidyStatus,getSubsidyCashState])

    // const postFiles = useCallback(async(subsidyID) => {
    //     if (!client || editSubsidy || !subsidyID) return;

    //     const originalRequestInterceptor = client.http.requestInterceptor;

    //     const formData = new FormData();
    //     // formData.append('file', selectedFile || []);
    //     selectedFile.forEach((file, index) => {
    //         formData.append(`file_${index}`, file); // Füge jede Datei einzeln hinzu
    //     });
    //     formData.append('title', info.title);
    //     formData.append('type', info.type);

    //     try {
    //         client.requestInterceptor = (req) => {
    //             req.headers["Content-Type"] = "multipart/form-data";
    //             req.headers["Authorization"] = login.Authorization;
    //             req.body = formData;
    //             return req;
    //         };
  
    //         const response = await client.apis["building"].building_subsidy_file_create({ building_uuid: _buildingGuid, subsidy_uuid: subsidyID});
      
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } catch (error) {
    //         addToast(t("error"), t("failedFileUpload"), "error");
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } 
    // }, [client, editSubsidy, selectedFile, _buildingGuid, login.Authorization, addToast, t]);

    useEffect(() => {
        getVariants()
        // getAllSubsidies()
    }, [])

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='mb-3'>
            <Form.Label>{t('purpose')}</Form.Label>
            <Form.Control id='action' required type='text' value={subsidy['action'] || ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('variants')}</Form.Label>
            <Form.Select id="variant" value={subsidy['variant'] || ''} onChange={(e) => changeHandler(e)}>
                {variants.length > 0 ? (
                    variants?.map(v => <option key={`variant-${v.id}`} value={v.id}>{`${v.name}${v.actual ? ` (${t('actual')})` : (v.realization === null || v.realization === undefined?'':' (Realisierung geplant am '+(new Date(v.realization)).toLocaleDateString()+')')}`}</option>)
                ) : (
                    <option key={`variant-key-none`} value="">
                        {t("noVariant")}
                    </option>
                )}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('subsidy_from_catalog')}</Form.Label>
            <Form.Select id="subsidy" value={subsidy["subsidy"] || ''} onChange={(e) => changeHandler(e)}>
            <option key={`subsidies-None}`} value={undefined}>{t('none')}</option>)
                {subsidyCatalog?.map(s => <option key={`subsidies-${s.id}`} value={s.id}>{s.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('requested_funding')}</Form.Label>
            <Form.Control id='requested_funding' min="0" required type='number' value={subsidy['requested_funding'] || 0} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('costs_for_the_customer')}</Form.Label>
            <Form.Control id='costs_for_the_customer' min="0" required type='number' maximumFractionDigits="2" value={subsidy['costs_for_the_customer'] || 0} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('funding_sum')}</Form.Label>
            <Form.Control id='funding_sum' required type='text' disabled value={(isNaN(subsidy['costs_for_the_customer'])?0:Number(subsidy['costs_for_the_customer']))+(isNaN(subsidy['requested_funding'])?0:Number(subsidy['requested_funding']))} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('funding_ratio')}</Form.Label>
            <Form.Control id='funding_ratio' required type='text' disabled value={isNaN(subsidy['costs_for_the_customer']+subsidy['requested_funding'])?'0 %':(funding_ratio*100).toLocaleString(undefined, { maximumFractionDigits: 2 })+" %" || "0 %"} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('approved_subsidies')}</Form.Label>
            <Form.Control id='approved_subsidies' required min="0" type='number' value={isNaN(subsidy['approved_subsidies'])?0:Math.max(0,subsidy['approved_subsidies'])} onChange={(e) => changeHandler(e)} />
        </Form.Group>
        
        <Form.Group className='mb-3'>
            <Form.Label>{t('new_costs_for_the_customer')}</Form.Label>
            <Form.Control id='new_costs_for_the_customer' required type='text' min={0} disabled value={isNaN(new_customer_costs)?'0':(Math.max(0,new_customer_costs)).toLocaleString(undefined, { maximumFractionDigits: 2 }) || "0"} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('status')}</Form.Label>
            <Form.Select id="status" value={subsidy['status'] || ''} onChange={(e) => changeHandler(e)}>
                {/* {subsidyStatus?.map(s => <option key={`subsidies-${s[0]}`} value={s[1]}>{t(s[1])}</option>)} */}
                {subsidyStatus?.map(s => <option key={`subsidies-${s.value}`} value={s.value}>{t(s.label.toLowerCase())}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('cash_state')}</Form.Label>
            <Form.Select id="subsidy_cash_state" value={subsidy['subsidy_cash_state'] || '0'} onChange={(e) => changeHandler(e)}>
                {/* {subsidyCashState?.map(s => <option key={`subsidies-${s[0]}`} value={s[1]}>{t(s[1])}</option>)} */}
                {subsidyCashState?.map(s => <option key={`subsidies-${s.value}`} value={s.value}>{t(s.label.toLowerCase())}</option>)}
            </Form.Select>
        </Form.Group>
        
        <Form.Group className='mb-3'>
            <Form.Label>{t('planned_start_date')}</Form.Label>
            <Form.Control id='planned_start_date' className="displayDate" type='date' value={subsidy['planned_start_date'] || ""} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('planned_end_date')}</Form.Label>
            <Form.Control id='planned_end_date' className="displayDate" type='date' value={subsidy['planned_end_date'] || ""} onChange={(e) => changeHandler(e)} />
        </Form.Group>

         {/* {!editSubsidy &&
            <Document {...{ setInfo, info, setSelectedFile, selectedFile, required:false }}/>
        } */}

        <Button variant="outline-primary" disabled={isSubmitting} className='w-100' type='submit'>{t(editSubsidy ? 'edit' : 'add')}</Button>
    </Form>;
}

export default AddEditSubsidy