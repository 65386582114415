import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faAdd, faEdit, faTrash, faCheck, faCalculator, faCancel, faA, faF, faB, faC, faD, faE, faG, faH, faDna, faXmark, faCopy, faInfo, faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Card from "../components/Card";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import { Button, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import ContentModal from "../components/ContentModal";
import { timeReadableFormat } from "../util/helpers";
import SecurityActionModal from "../components/SecurityActionModal";
import Collapse from "../components/Collapse";
import { Calculate } from "./CalculateDIN12831";
import { AddEditBuildingPartVariant } from "./AddEditBuildingPartVariant";
import { AddEditVariant } from "./AddEditVariant";
import { StandardReferenceTable } from "./StandardReferenceTable";
import { ListExchangeHeatpart } from "./ListExchangeHeatpart";
import StrandReference from "./StrandReference";
import { useSwagger } from "../context/SwaggerContext";
import {IndividualBuildingParts} from "./IndividualBuildingParts";
import useBuildingHook from "../hooks/useBuildingHook";
import { usePermissions } from "../context/PermissionsContext";
import WebSocketWorker from "../websocket/WebsocketWorker";

export const BuildingVariants = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();
    const [variants, setVariants] = useState(undefined);
    const [variant, setVariant] = useState(undefined);
    const [buildingPartV, setBuildingPartV] = useState(undefined);
    const [show, setShow] = useState(false);
    const [reloadBP, setReloadBP] = useState(Math.random());
    const { allowEdit } = usePermissions();
    useBuildingHook()

    const mainFunctions = [
        { label: t('add'), onClick: () => setShow('variant'), key: 'add', icon: faAdd },
        { label: t('calculate'), onClick: () => setShow("Calculate"), key: 'calculate', icon: faCalculator }
    ]

    const tableStructure = [
        {
            col: <div className='d-flex' style={{ width: '16px', height: '16px' }}><FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' /></div>,
            type: 'icon'
        }, {
            col: "Date",
            type: 'label'
        }, {
            col: "Name",
            type: 'label'
        }, {
            col: "lastCalculation",
            type: 'label'
        }, {
            col: "Consumption_demand",
            type: 'label'
        }, {
            col: "Reduction",
            type: 'label'
        }, {
            col: "co2_emissions_gas",
            type: 'label'
        },
        //  {
        //     col: "Max_Flow_Temperature",
        //     type: 'label'
        // }, {
        //     col: "Max_Return_Temperature",
        //     type: 'label'
        // }, {
        //     col: "exchange_individual_bp_short",
        //     type: 'label'
        // }, {
        //     col: "heatingLoad",
        //     type: 'label'
        // }, {
        //     col: "Created",
        //     type: 'label'
        // },
        {
            col: "landlordsharesco2",
            type: 'label'
        },
        {
            col: "actual",
            type: 'label'
        },
        {
            col: t('actions'),
            type: 'buttons'
        },
    ]

    const getAllVariants = useCallback(async () => {
        setVariants([]);
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "GET"
        // })
        //     .then((response) => {
        //         if (!response.ok) throw new Error(t('networkError'));
        //         if (response.status === 204) return [];
        //         return response.json();
        //     })
        //     .then((data) => {

        //         let a = [...data];
        //         a.sort((a, b) => {
        //             // Wenn a true ist, aber b nicht, a soll vorne stehen (-1)
        //             if (a.actual && !b.actual) {
        //                 return -1;
        //             }
        //             // Wenn b true ist, aber a nicht, b soll vorne stehen (1)
        //             if (b.actual && !a.actual) {
        //                 return 1;
        //             }
        //             // Wenn beide den gleichen Status haben, Reihenfolge nicht ändern (0)
        //             return b.specific_consumption - a.specific_consumption;
        //         });



        //         setVariants(a);

        //     })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getVariants({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                setVariants([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                let a = [...response.obj];
                a.sort((a, b) => {
                    // Wenn a true ist, aber b nicht, a soll vorne stehen (-1)
                    if (a.actual && !b.actual) {
                        return -1;
                    }
                    // Wenn b true ist, aber a nicht, b soll vorne stehen (1)
                    if (b.actual && !a.actual) {
                        return 1;
                    }
                    // Wenn beide den gleichen Status haben, Reihenfolge nicht ändern (0)
                    return b.specific_consumption - a.specific_consumption;
                });

                setVariants(a);

                console.log("variants: ", a)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const deleteVariant = useCallback(async (id) => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/variants/${id}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "DELETE"
        // })
        // .then((response) => {
        //     if (response.status === 406) throw new Error(t("deleteVariantError"));
        //     if (!response.ok) throw new Error(t('networkError'));
        //     getAllVariants();
        //     if (response.status === 202) addToast(t("success"), t("deletedVariant"), "success")
        // })
        // .catch(error => addToast(t("error"), error.message, "error"))

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis[
                "building"
            ].building_variants_destroy({
                building_uuid: _buildingGuid,
                variant_uuid: id,
            });

            if (response.status >= 200 && response.status < 300) {
                getAllVariants();
                if (response.status === 202) {
                    addToast(t("success"), t('deletedVariantpart'), "success");
                }

            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            if (statusCode === 406) {
                console.log(error.response?.obj)
                addToast(t("error"), t("delete_variant." + error.response?.obj?.i18next_message?.toLowerCase()), "error");
            }
            else {
                addToast("error", t('networkError'), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, addToast, getAllVariants])

    const copyVariant = useCallback(async (variantID) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_copy_create({
                building_uuid: _buildingGuid, variant_uuid: variantID,
            });

            if (response.status >= 200 && response.status < 300) {
                getAllVariants();
                addToast(t("success"), t("variantCopiedSuccessfully"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("variantCopiedFailed"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, addToast, getAllVariants])

    const onHide = () => {
        setShow(false);
        setVariant(undefined);
        setBuildingPartV(undefined);
    }

    const onClose = () => {
        setShow(false);
        setVariant(undefined);
        setBuildingPartV(undefined);
        getAllVariants();
    }

    useEffect(() => {
        getAllVariants()
    }, [getAllVariants])

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: `${t('variants')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`, ...(allowEdit && { mainFunctions }) }}>
                {(variants === undefined) ? (
                    <TablePlaceholder {...{ structure: tableStructure }} />
                ) : (!Boolean(variants?.length)) ? (
                    <p className="m-0">{t("noVariants")}</p>
                ) : (
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                    <span>{(ts.type == "label") ? t(ts.col.toLowerCase()) : ts.col}</span>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {variants.map((variant) => <VariantRow key={`variant-key-${variant.id}`} {...{ variant, setVariant, setShow, setBuildingPartV, reloadBP, copyVariant, getAllVariants }} />)}
                        </tbody>
                    </Table>
                )}
            </Card>

            <ContentModal {...{ show: show === 'Calculate', onHide: onClose, title: t('calculateDIN12831'), content: <Calculate {...{ onClose }} /> }} />
            <ContentModal {...{ show: show === 'variant', onHide: onHide, title: t('addVariant'), content: <AddEditVariant {...{ variant, onClose }} /> }} />
            <ContentModal {...{ show: show === 'exchange_list', onHide: onClose, size: "fullscreen", title: t('exchange_list'), content: <ListExchangeHeatpart {...{ variant, onClose }} /> }} />
            <ContentModal {...{ show: show === 'bpv', onHide: onClose, title: t('addBPV'), size: 'lg', content: <AddEditBuildingPartVariant {...{ buildingPartV, variantID: variant?.id, onClose: () => onClose() }} /> }} />
            <ContentModal {...{ show: show === 'strand', onHide: () => { setVariant(undefined); setShow(false) }, title:  t('strands'), content: <StrandReference {...{ variantID: variant?.id }} />, size: 'xl' }} />
            <ContentModal {...{ show: show === 'individual_bp', size: 'xl', onHide: onClose, title: t('individual_bp'), content: <IndividualBuildingParts {...{ onClose, variant }} /> }} />
            <SecurityActionModal {...{ show: show === "delete", onHide: () => { setVariant(undefined); setShow(false) }, title: t('deleteVariant'), question: t('deleteVariantQuestion'), action: () => deleteVariant(variant.id) }} />
        </div>
    </MainLayout>
}

const VariantRow = ({ variant, setVariant, setShow, setBuildingPartV, reloadBP, copyVariant, getAllVariants }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);
    const [bpv, setBPV] = useState(undefined);
    const [lastCalculationOlder, setLastCalculationOlder] = useState(false);
    const { allowEdit } = usePermissions();

    const colors = {
        "A": "#008000", // Grün
        "B": "#ADFF2F", // Hellgrün
        "C": "#FFFF00", // Gelb
        "D": "#FFD700", // Gold
        "E": "#FFA500", // Orange
        "F": "#D2691E", // Schokoladenbraun
        "G": "#A52A2A", // Braun
        "H": "#8B4513", // Sattelbraun
    };

    const icons = {
        "A": faA,
        "B": faB,
        "C": faC,
        "D": faD,
        "E": faE,
        "F": faF,
        "G": faG,
        "H": faH,
    }

    const lastCalculation = variant?.last_calculation ? new Date(variant?.last_calculation) : undefined;
    const updatedAt = variant?.updated_at ? new Date(variant?.updated_at) : undefined;
    let formattedDate = undefined;

    if (lastCalculation) {
        formattedDate = lastCalculation.toLocaleDateString("de-DE", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const addSecondsToDate = (date, seconds) => {
        return new Date(date.getTime() + seconds * 1000);
    };

    useEffect(() => {
        if (lastCalculation && updatedAt) {
            const lastCalculationWithSecondsAdded = addSecondsToDate(lastCalculation, 5); // 5 Sekunden hinzufügen
            const isLastCalculationOlder = lastCalculationWithSecondsAdded < new Date(updatedAt);
            setLastCalculationOlder(isLastCalculationOlder)
        }
    }, [])

    
    return <>
        <tr>
            <td>
                <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
            </td>
            <td>
                <span>{variant.realization || t('planning')}</span>
            </td>
            <td>
                <span>{variant.name}</span>
            </td>
            <td>
                <span>
                    {variant?.last_calculation === undefined && (<span>Berechnung Fehlt</span>)}

                    {lastCalculationOlder && (
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{t('notActual')}</Tooltip>}>
                            <FontAwesomeIcon icon={faTriangleExclamation} color="#FFBF00" style={{ paddingLeft: "5px" }} />
                        </OverlayTrigger>
                    )}

                    {(formattedDate && !lastCalculationOlder) && (<span>{formattedDate}</span>)}
                </span>
            </td>
            <td>
                {
                    variant.last_calculation === null ? t("missingCalculation") : (
                    <Row>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '26px', // Breite des Containers
                            height: '26px', // Höhe des Containers
                            backgroundColor: colors[variant.ee_class], // Hintergrundfarbe des Containers
                            borderRadius: '20%', // Macht den Container rund
                            padding: '10px', // Innenabstand
                            marginRight: '10px',
                            boxSizing: 'border-box', // Stellt sicher, dass Padding zur Breite/Höhe dazugezählt wird
                            boxShadow: '0px 0px 5px rgba(0,0,0,0.2)' // Optional: fügt einen Schatten hinzu
                        }}>
                            <FontAwesomeIcon icon={icons[variant.ee_class] || faA} style={{ color: 'black' }} />
                        </div>
                        {variant.specific_consumption?.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("kwh_year")}
                    </Row>
                    )
                }
            </td>
            <td>
                <span>{variant.last_calculation === null ? t("missingCalculation") : `${(variant.reduction_to_actual * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`}</span>
            </td>
            <td>
                <span>{variant.last_calculation === null ? t("missingCalculation") : `${variant.co2_emissions_m2?.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${t("kg_year")}`}</span>
            </td>
            <td>
                {
                    variant.last_calculation === null ? t("missingCalculation") : (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>
                                <>
                                    <p>{t('landlordCosts')}</p>
                                    {/* {variant.co2_costs?.map(k => <p>{k.year} : {(k.costs_eur * variant.landlord_co2_share)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € </p>)} */}
                                    {variant.co2_costs?.map(k => {
                                        let label;
                                        if (k.year === 2030) {
                                            label = `${t("from_ab")} 2027 (100 EUR/${t('ton')}): `;
                                        } else if (k.year === 2050) {
                                            label = `${t("from_ab")} 2027 (500 EUR/${t('ton')}): `;
                                        } else {
                                            label = `${k.year}: `;
                                        }

                                        return (
                                            <p key={k.year}>
                                                {label}
                                                {(k.costs_eur * variant.landlord_co2_share)?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })} €
                                            </p>
                                        );
                                    })}
                                </>
                            </Tooltip>
                        }>
                        <span>
                            {(variant.landlord_co2_share * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} %
                            {(variant.reduction_co2_costs_landlord_annually > 0) ?
                                <>
                                    (Ersparnis 20y: {(20 * variant.reduction_co2_costs_landlord_annually)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €)
                                </>
                                :
                                <></>
                            }
                            <FontAwesomeIcon icon={faInfoCircle} style={{ paddingLeft: "10px" }} />
                        </span>
                        {/* <span>{(variant.landlord_co2_share * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} % {(variant.reduction_co2_costs_landlord_annually > 0) ? <>(Ersparnis 20y: {(20 * variant.reduction_co2_costs_landlord_annually)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €)</> : <></>}</span> */}
                    </OverlayTrigger>
                    )
                }
            </td>
            <td>
                <FontAwesomeIcon icon={variant.actual ? faCheck : faXmark} />
            </td>
            {/* <td>
                <span>{variant.max_flow_temp} °C</span>
            </td>
            <td>
                <span>{(variant.max_flow_temp || 50).toLocaleString(undefined, { maximumFractionDigits: 2 })} °C</span>
            </td>
            <td>
                <span>{variant.exchange_individual_bp ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCancel}/>}</span>
            </td>
            <td>
                <span>{((variant.transmission_losses + variant.ventilation_losses) / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} kW</span>
            </td>
            <td>
                <span>{timeReadableFormat(variant.created_at)}</span>
            </td> */}
            {/* <td>
                <span>{timeReadableFormat(variant.updated_at)}</span>
            </td> */}
            <td>
                <div className='actions__buttonbar grid__six'>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("addStandardBP")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("bpv") }}>
                            <FontAwesomeIcon icon={faAdd} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("edit")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("variant") }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("copy")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => copyVariant(variant.id)}>
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{ t('strands')}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("strand") }}>
                            <FontAwesomeIcon icon={faDna} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t('individual_bp')}</Tooltip>
                        }>
                        {/* <Button variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("individual_bp") }} disabled={!allowEdit || variant?.actual ? true : false}> */}
                        <Button variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("individual_bp") }} disabled>

                            <FontAwesomeIcon icon={faList} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip>{t("delete")}</Tooltip>
                        }>
                        <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setVariant(variant); setShow("delete") }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>

        {display && <StandardReferenceTable {...{ variant, setShow, reloadBP, getAllVariants }} />}
    </>
};

export default BuildingVariants;