import { useState, useCallback, useRef } from "react";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import Card from "../components/Card";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSwagger } from "../context/SwaggerContext";

export const Signup = () => {
    const { defaultTheme } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const [mail, setMail] = useState("");
    const client = useSwagger();

    const signUPBTN = useRef();

    const navigate = useNavigate()

    const errorHandler = useCallback((message, elem) => {
        addToast(t("createAccount"), message, "error")
        elem.focus()
        signUPBTN.current.disabled = false;
    }, [addToast, t, signUPBTN.current])

    const signup = useCallback(async(e) => {
        e.preventDefault();

        signUPBTN.current.disabled = true;

        if (mail === "" || mail === null || mail === undefined) {
            errorHandler(t("registration.mail"), mail)
            return
        }

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.body = JSON.stringify({ "email": mail });
                return req;
            };

            const response = await client.apis["User"].postSignUp();

            if (!response.ok) throw new Error(t("registrationNotPossible"));

            if (response.status >= 200 && response.status < 300) {
                addToast(t("createAccount"), t("mailOTP"), "success");
                localStorage.setItem('sh-jwttoken', response.obj.token);
                const token = localStorage.getItem('sh-jwttoken')
                while (token === null);
                navigate(`/register`)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("createAccount"), error.message, "error");
            signUPBTN.current.disabled = false;
            client.http.requestInterceptor = originalRequestInterceptor;
        }

        // fetch("https://tech.sigmaheat.de/signup", {
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     method: "POST",
        //     body: JSON.stringify({ "email": mail })
        // })
        // .then(response => {
        //     if (!response.ok) throw new Error(t("registrationNotPossible"));
        //     return response.json()
        // })
        // .then(data => {
        //     addToast(t("createAccount"), t("mailOTP"), "success");
        //     localStorage.setItem('sh-jwttoken', data.token);
        //     const token = localStorage.getItem('sh-jwttoken')
        //     while (token === null);
        //     navigate(`/register`)
        // })
        // .catch((error) => {
        //     addToast(t("createAccount"), error.message, "error");
        //     signUPBTN.current.disabled = false;
        // });
    }, [addToast, mail])

    return <div style={{ width: '100%', maxWidth: '512px' }}>
        <Card {...{ heading: t("signup"), className: 'w-100' }}>
            <Form className='d-block' id='login-form' onSubmit={(e) => signup(e)}>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>{t("emailAddress")}</Form.Label>
                    <Form.Control type="email" placeholder="beispiel@sigmaheat.de" value={mail} onChange={(e) => setMail(e.target.value)} />
                </Form.Group>

                <Button variant="outline-primary" className='w-100 mb-3' type='submit' ref={signUPBTN}>{t('createAccount')}</Button>
                <span className='d-inline-block'>{t('alreadyMember')} <Link to='/login' className={`d-inline-block text-decoration-underline ${defaultTheme === undefined && localStorage.getItem('SH_Theme') === undefined ? "text-black" : defaultTheme === undefined ? localStorage.getItem('SH_Theme') === 'light' ? "text-black" : "text-light" : defaultTheme === 'light' ? 'text-black' : 'text-light'}`}>{t('login')}</Link></span>
            </Form>
        </Card>
    </div>
};

export default Signup;