import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CanvasClass from "./CanvasClass";

import Canvas from "./canvas/Canvas";

import MainLayout from '../components/MainLayout';
import { Button, Form, Modal, Tabs, Tab } from "react-bootstrap";
import Card from "../components/Card";
import "./style.css";
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { useToast } from "../NotificationsContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faDownload, faDrawPolygon, faMouse, faTable, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Background from "../assets/background_3_0.png"
import ContentModal from "../components/ContentModal";
import EditHP from "./EditHP"
import ReactRouterPrompt from "react-router-prompt";
import { useSwagger } from "../context/SwaggerContext";
import SchemaGenerator from "./SchemaGenerator";
import Loading from "../components/Loading";
import { usePermissions } from "../context/PermissionsContext";

const useResizeObserver = (ref) => {
    const [boundaries, setBoundaries] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setBoundaries(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return boundaries;
};

export const SchemaNew = () => {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const canvasClassRef = useRef(null);
    const canvasRef = useRef(null);
    const boundaries = useResizeObserver(canvasRef);
    const [scheme, setScheme] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const [HPCs, setHPCs] = useState(undefined);
    const [currentCatalog, setCurrentCatalog] = useState(undefined);
    const [hpcc, setHPCC] = useState(undefined);
    const [change, setChange] = useState(false);

    const [hpt, setHPT] = useState(undefined);
    const [selectedType, setSelectedType] = useState(1);
    const [hp, setHP] = useState(undefined);
    const [usedHPinScheme, setUsedHPinScheme] = useState([])
    const [notUsedHPinScheme, setNotUsedHPinScheme] = useState(hp)

    const defaultSVG = "<?xml version=\"1.0\" encoding=\"UTF-8\"?><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 100 100\" width=\"100\" height=\"100\"><g transform=\"translate(50,50)\"><circle r=\"20\" fill=\"#ccc\"/><g id=\"gear-teeth\"><rect x=\"-5\" y=\"-35\" width=\"10\" height=\"20\" fill=\"#333\"/></g><use href=\"#gear-teeth\" transform=\"rotate(30)\"/><use href=\"#gear-teeth\" transform=\"rotate(60)\"/><use href=\"#gear-teeth\" transform=\"rotate(90)\"/><use href=\"#gear-teeth\" transform=\"rotate(120)\"/><use href=\"#gear-teeth\" transform=\"rotate(150)\"/><use href=\"#gear-teeth\" transform=\"rotate(180)\"/><use href=\"#gear-teeth\" transform=\"rotate(210)\"/><use href=\"#gear-teeth\" transform=\"rotate(240)\"/><use href=\"#gear-teeth\" transform=\"rotate(270)\"/><use href=\"#gear-teeth\" transform=\"rotate(300)\"/><use href=\"#gear-teeth\" transform=\"rotate(330)\"/><use href=\"#gear-teeth\" transform=\"rotate(360)\"/></g></svg>"

    const [show, setShow] = useState(false);

    const [showSchema, setShowSchema] = useState(false);

    const _buildingGuid = useParams()['buildingGuid'];
    const _variantUUID = useParams()['variantID'];
    const _schemaUUID = useParams()['schemaID'];
    const client = useSwagger();
    const navigate = useNavigate();

    const { allowEdit } = usePermissions();
    let styleAllowEdit;
    
    if (!allowEdit) {
        styleAllowEdit = { pointerEvents: 'none' }
    }

    const mainFunctions = [
        {label: t('schema_generator'), onClick: () => setShowSchema(true), key: 'schema_generator', icon: faAdd}
    ]

    // Schema aus dem localStorage
    const localStorageDataRef = useRef(JSON.parse(localStorage.getItem('schema')));
    if (!localStorageDataRef.current) {
        localStorageDataRef.current = [];
    }

    const matchingItem = localStorageDataRef?.current?.find(item => item.schemaID === _schemaUUID);

    const [showPromt, setShowPromt] = useState(matchingItem)
    const [useLocalStorageData, setuseLocalStorageData] = useState(false)
    const [loadedLibrary, setLoadedLibrary] = useState(false)

    const getSchema = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_scheme_retrieve({building_uuid: _buildingGuid, variant_uuid: _variantUUID, scheme_uuid: _schemaUUID});

            if (response.status >= 200 && response.status < 300) {
                setScheme([]);
                if (response.status === 204) return
                setScheme(response.obj);
                let affineTransformation = response.obj.affine_transformation
                if (matchingItem && useLocalStorageData) {
                    affineTransformation = matchingItem?.affine_transformation;
                }
                canvasClassRef.current?.setTransform(affineTransformation)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;  
            addToast(t("error"), t(statusCode === 401 ? "noAuthorization" : "responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, useLocalStorageData])

    const loadComponent = component => {
        return new Promise((resolve, reject) => {
            const encodedData = encodeURIComponent(component.svg);
            const imgSrc = `data:image/svg+xml;charset=utf-8,${encodedData}`;

            const imageElement = new Image();
            imageElement.src = imgSrc;
            imageElement.alt = '';

            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = decodeURIComponent(encodedData);
            const svgElement = tempDiv.querySelector('svg');

            document.body.appendChild(tempDiv);
            const bBox = svgElement.getBoundingClientRect();
            document.body.removeChild(tempDiv);

            imageElement.onload = function() {
                imageElement.width = bBox.width
                imageElement.height = bBox.height

                resolve({
                    id: component.id,
                    name: component.name,
                    part: component.part,
                    comment: component.comment,
                    x: component.position_x,
                    y: component.position_y,
                    connection_ports: component.connections_ports,
                    type: component.part === "98458027-606b-4ed3-a739-e939d952c985" ? 5 : component.type,
                    rect: {
                        top: component.position_y,
                        right: component.position_x + bBox.width,
                        bottom: component.position_y + bBox.height,
                        left: component.position_x
                    },
                    rotate: component.rotation,
                    img: imageElement
                });
            };

            imageElement.onerror = reject;
        });
    };

    const getSchemaParts = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_scheme_all_retrieve({building_uuid: _buildingGuid, variant_uuid: _variantUUID, scheme_uuid: _schemaUUID});

            if (response.status >= 200 && response.status < 300) {
                canvasClassRef.current.setComponents([])
                if (response.status === 204) return

                let components = response.obj.components
                let connections = response.obj.connections
          
                if (matchingItem && useLocalStorageData) {
                    components = [...matchingItem?.components];
                    connections = [...matchingItem?.connections]
                }
                components = await Promise.all(components.map(loadComponent));
                canvasClassRef.current.setComponents(components, connections, matchingItem?.deleted)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            if (statusCode === 401) {
                addToast(t("error"), t("noAuthorization"), "error");
            } else {
                addToast(t("error"), t("responseError"), "error");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, useLocalStorageData])

    const loadLibraries = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_list({org_uuid: login.currentOrganisation.id});

            if (response.status === 204) {
                setHPCs([])
                setCurrentCatalog(undefined)
                return
            }

            if (response.status >= 200 && response.status < 300) {
                // setHPCs(response.obj.results)
                // setCurrentCatalog(response.obj.results[0]?.id)
                setHPCs(response.obj)
                setCurrentCatalog(response.obj[0]?.id)
                setLoadedLibrary(true)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 401) {
                addToast(t("error"), t("noAuthorization"), "error");
            } else {
                addToast(t("error"), t("responseError"), "error");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const getComponents = useCallback(async(type = null) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const params = {
                org_uuid: login.currentOrganisation.id,
                heatpart_catalog_uuid: currentCatalog,
            };

            if (type) {
                params.type = selectedType;
            }

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_typology_list(params);

            if (response.status === 204) {
                setHPCC([])
            }

            if (response.status >= 200 && response.status < 300) {
                const result = response.obj?.map((hp) => hp?.svg_reference?.trim().startsWith('<?xml') ? {...hp, hasSVG: true} : {...hp, hasSVG: false, svg_reference: defaultSVG})
                setHPCC(result)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 401) {
                addToast(t("error"), t("noAuthorization"), "error");
            } else {
                addToast(t("error"), t("responseError"), "error");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, currentCatalog, selectedType])

    const getHPTypes = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatingparttypes"].heatingparttypes_list();

            if (response.status === 204) {
                setHPT([])
            }

            if (response.status >= 200 && response.status < 300) {
                const transformedHpt = response.obj?.map(h => ({
                    ...h, 
                    label: h.label.toLowerCase().replace(/[\s-]+/g, '_')
                }));

                setHPT(transformedHpt);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {

            addToast(t("error"), t('networkError'), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, t, addToast]);

    const getHP = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_heatpart_list({building_uuid: _buildingGuid});

            if (response.status === 204) {
                setHP([])
            }

            if (response.status >= 200 && response.status < 300) {
                setHP(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), t('networkError'), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t, addToast]);

    const filterHP = useCallback(() => {
        // heating parts des Gebäudes werden mit den Elementen aus dem Schema verglichen
        // wenn die "id" mit "part" des Elements aus dem Schema übereinstimmt, dann wird das in ein array "usedHPinScheme" gepackt
        // wenn die "id" mit "part" nicht übereinstimmt, dann wird das in ein array "notUsedHPinScheme" array gepackt

        // components, welche im scheme gezeichnet sind
        let components = canvasClassRef.current?.getComponents()
        let copyHP = Array.from(hp) || []

        const { usedHPinScheme, notUsedHPinScheme } = copyHP.reduce((acc, hpItem) => {
            if (components.some(component => component.part === hpItem.id)) {
                acc.usedHPinScheme.push(hpItem);
            } else {
                acc.notUsedHPinScheme.push(hpItem);
            }
            return acc;
        }, { usedHPinScheme: [], notUsedHPinScheme: [] });

        const notUsedHPinSchemeSVG = notUsedHPinScheme?.map((hp) => hp?.svg_reference?.trim().startsWith('<?xml') ? {...hp, hasSVG: true} : {...hp, hasSVG: false, svg_reference: defaultSVG})

        setUsedHPinScheme(usedHPinScheme)
        setNotUsedHPinScheme(notUsedHPinSchemeSVG)

    }, [hp, canvasClassRef])

    const handleSelectChange = (event) => {
        setSelectedType(event.target.value);
    };

    const postSchema = useCallback(async() => {
        let data = canvasClassRef.current?.getComponentsPayload()

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(data);
                return req;
            };

            const response = await client.apis["building"].building_variants_scheme_blueprint_partial_update({building_uuid: _buildingGuid, variant_uuid: _variantUUID, scheme_uuid: _schemaUUID});

            if (response.status >= 200 && response.status < 300) {
                if (localStorageDataRef?.current && localStorageDataRef?.current?.length >= 1 ) {
                    // Filter out the item with the matching schemaID
                    localStorageDataRef.current = localStorageDataRef.current.filter(item => item.schemaID !== _schemaUUID);
              
                    // Update the localStorage with the filtered data
                    localStorage.setItem('schema', JSON.stringify(localStorageDataRef.current));
                }
                // canvasClassRef.current?.clearData()
                const components = await Promise.all(response.obj.components.map(loadComponent));
                canvasClassRef.current.clearData()
                canvasClassRef.current.setComponents(components, response.obj.connections)
                // canvasClassRef.current.setConnections(response.obj.connections)
                addToast(t("success"), t("dataSuccess"), "success");
                getHP();
                getSchemaParts()
                setChange(false);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log(error)
            addToast(t("error"), t('networkError'), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization, canvasClassRef.current, _buildingGuid, _variantUUID, _schemaUUID])

    const handleComponentClick = (e, obj) => {
        e.preventDefault();
        // if (isTouchDevice()) canvasClassRef.current.addComponent(obj);
    }

    useEffect(() => {
        loadLibraries();
        getHP();
    }, [])

    useEffect(() => {
        if (currentCatalog === undefined) return;
        // wir können die components nach type filtern
        getComponents(selectedType);
    }, [currentCatalog, selectedType])

    useEffect(() => {
        if (canvasRef.current === null || boundaries === null || loaded || hp === undefined) return

        if(!showPromt) {
            canvasClassRef.current = new Canvas(canvasRef.current, boundaries, setChange, setShow, filterHP);
            getSchema();
            getSchemaParts();
            setLoaded(true)
        }
    }, [canvasRef.current, loaded, boundaries, hp, showPromt])

    useEffect(() => {
        getHPTypes();
    }, [getHPTypes, hpcc])

    useEffect(() => {
        if (!hp) return
        if(!showPromt) {
            filterHP();
        }
    }, [hp, filterHP, showPromt]);

    // Schema im localStorage speichern
    const handleSave = () => {
        if (!canvasClassRef?.current) {
            return;
        }    
        function normalizeSvgStructure(svgUri) {
            // Schritt 1: Dekodiere den SVG-URI
            const svgDecoded = decodeURIComponent(svgUri.split(",")[1]);
        
            // Schritt 2: Erstelle ein neues DOM-Parser-Objekt
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(svgDecoded, "image/svg+xml");
        
            // Schritt 3: Strukturelle Anpassungen vornehmen
            const svgElement = xmlDoc.documentElement;
        
            // Schritt 4: Serialisiere das SVG-Dokument zurück in eine Zeichenkette
            const serializer = new XMLSerializer();
            const newSvgString = serializer.serializeToString(svgElement);
        
            return newSvgString;
        }
        
        const simplifiedComponents = canvasClassRef.current.getComponents()?.map(component => {
            const { 
                connectors, 
                canvas, 
                ctx, 
                canvasDraw, 
                updateConnections, 
                deleteComponent,
                height, 
                img,
                x,
                y,
                rotate, 
                ...rest 
            } = component;
        
            const svg = normalizeSvgStructure(img?.currentSrc);
            const position_x = x
            const position_y = y
            const rotation = rotate       
        
            return { 
                ...rest,
                connections_ports: connectors ? connectors.map(connector => ({ x: connector.x, y: connector.y, type: connector.type })) : [],
                svg,
                position_x,
                position_y,
                rotation,
                // connections
            };
        });
       
        const dataComponentsPayload = canvasClassRef.current?.getComponentsPayload()

        const { components, connections, deleted, affine_transformation } = dataComponentsPayload;

        const connectionsWithoutUUID = connections.map(connection => ({
            start: {
                coordinates: connection.start.coordinates
            },
            end: {
                coordinates: connection.end.coordinates
            },
            lineWidth: connection.lineWidth
        }));

        const formattedConnections = connectionsWithoutUUID.map(connection => ({
            start: {
                x: connection.start.coordinates.x,
                y: connection.start.coordinates.y
            },
            end: {
                x: connection.end.coordinates.x,
                y: connection.end.coordinates.y
            },
            lineWidth: connection.lineWidth
        }));

        // const affineTransformation = canvasClassRef.current?.getTransform()

        const dataToStore = {
            schemaID: _schemaUUID,
            components: simplifiedComponents,
            connections: formattedConnections,
            affine_transformation,
            deleted
        }

        const index = localStorageDataRef.current.findIndex(item => item.schemaID === _schemaUUID);

        if (index !== -1) {
            // Überschreibe das bestehende Objekt
            localStorageDataRef.current[index] = dataToStore;
        } else {
            // Füge das neue Objekt hinzu
            localStorageDataRef.current?.push(dataToStore);
        }

        localStorage.setItem('schema', JSON.stringify(localStorageDataRef.current));
    }

    useEffect(() => {
        if (change) {
            const handleBeforeUnload = (event) => {
                handleSave(); // handleSave wird ausgeführt, bevor die Seite neu geladen wird
                event.preventDefault();
                event.returnValue = ''; // Einige Browser verlangen, dass returnValue gesetzt wird
            };
        
            window.addEventListener('beforeunload', handleBeforeUnload);
        
            // Cleanup-Funktion, um das Event zu entfernen, wenn die Komponente unmounted wird
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [change]);

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: `Scheme: ${scheme?.name}`, ...(allowEdit && { mainFunctions }) }}>
                <main className="mb-3">
                    <div className="canvas__wrapper">
                        <canvas ref={canvasRef} className="digimeta__canvas" style={styleAllowEdit}/>
                        {!loaded && (
                            <Loading/>
                        )}
                        <div className='canvas-menu'>
                            <div className='dropdown dropend'>
                                <button disabled={!allowEdit} className='canvas-menu-item' data-bs-toggle="dropdown">
                                    <FontAwesomeIcon icon={faTable} />
                                </button>
                                <ul className="dropdown-menu">
                                    <li><button className="dropdown-item" onClick={() => canvasClassRef.current.setGrid(false, false)}>Off</button></li>
                                    <li><button className="dropdown-item" onClick={() => canvasClassRef.current.setGrid(true, false)}>Grid no Snap</button></li>
                                    <li><button className="dropdown-item" onClick={() => canvasClassRef.current.setGrid(true, true)}>Grid with Snap</button></li>
                                </ul>
                            </div>
                            
                            <button disabled={!allowEdit} className='canvas-menu-item' onClick={() => canvasClassRef.current.downloadCanvas()}>
                                <FontAwesomeIcon icon={faDownload} />
                            </button>
                        </div>

                    </div>
                    <div className="tabs__wrapper__schema">
                        <Tabs defaultActiveKey="library" className="mb-3">
                            <Tab eventKey="library" title={t("library")}>
                                <Form.Select className="mb-3" value={currentCatalog || ''} onChange={(e) => setCurrentCatalog(e.target.value)}>
                                    {HPCs?.map(catalog => <option key={`catalog-id-${catalog.id}`} value={catalog.id}>{catalog.name}</option>)}
                                </Form.Select>

                                <Form.Select className="mb-3" id="type" required type="number" value={selectedType} onChange={handleSelectChange}>
                                    {hpt?.map(h => <option value={h.value} key={`hp-type-${h.value}`}>{t(h.label)}</option>)}
                                </Form.Select>

                                <div className="list-wrapper"> 
                                    <div id='library-wrapper' className='line-list'>
                                        {hpcc?.length === 0 && <div className="line-item component">{t("noValuesAvailable")}</div>}
                                        {hpcc?.map((c, i) => {

                                            var dragMe = document?.getElementsByClassName("component")[i],
                                            img = new Image();
                                            img.onload = function () {
                                                dragMe?.addEventListener("dragstart", function(e) {
                                                    e.dataTransfer.setDragImage(img, 0, 0);
                                                }, false);
                                            }
                                            img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

                                            const encodedData = encodeURIComponent(c.svg_reference);
                                            const imgSrc = `data:image/svg+xml;charset=utf-8,${encodedData}`;

                                            const imageElement = new Image();
                                            imageElement.src = imgSrc;
                                            imageElement.alt = '';

                                            const tempDiv = document.createElement('div');
                                            tempDiv.innerHTML = decodeURIComponent(encodedData);
                                            const svgElement = tempDiv.querySelector('svg');

                                            document.body.appendChild(tempDiv);
                                            const bBox = svgElement?.getBoundingClientRect();
                                            document.body.removeChild(tempDiv);

                                            imageElement.width = bBox?.width;
                                            imageElement.height = bBox?.height;

                                            return <div key={`hpcomponent-key-${i}`} className="component-item component">
                                                <img
                                                    src={imgSrc}
                                                    width='64px'
                                                    height='64px'
                                                    draggable={c?.hasSVG && c?.attributes?.connection_ports && allowEdit}
                                                    id={c.id}
                                                    onDragStart={(e) => allowEdit && canvasClassRef.current.handleDragStart(e, {
                                                        name: c.name,
                                                        part: c.id,
                                                        img: imageElement,
                                                        rotate: 0,
                                                        connection_ports: c.attributes.connection_ports,
                                                        type: c.type,
                                                        hpcc: true
                                                    })}
                                                    onDrag={(e) => canvasClassRef.current.handleDrag(e)}
                                                    onDragEnd={(e) => canvasClassRef.current.handleDragEnd(e)}
                                                    onClick={(e) => {
                                                        if (c?.hasSVG && c?.attributes?.connection_ports) {
                                                            handleComponentClick(e, {
                                                                name: c.name,
                                                                part: c.id,
                                                                img: imageElement,
                                                                rotate: 0,
                                                                connection_ports: c.attributes.connection_ports,
                                                                type: c.type,
                                                                hpcc: true
                                                            })}
                                                        }
                                                    } 
                                                    alt=''
                                                />
                                                <span>{c.name}</span>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey="heatingparts" title={t(`HeatingParts`)}>
                                <div className="list-wrapper">
                                    <div id='connections-wrapper' className='line-list'>
                                        <Tabs defaultActiveKey="unusedHP">
                                            <Tab eventKey="unusedHP" title={t("unusedHP")}>
                                                <div>
                                                    {t("notUsedHeatingParts")}
                                                    {notUsedHPinScheme?.map((c, i) => {

                                                        var dragMe = document?.getElementsByClassName("notUsedHeatpart")[i],
                                                        img = new Image();
                                                        img.onload = function () {
                                                            dragMe?.addEventListener("dragstart", function(e) {
                                                                e.dataTransfer.setDragImage(img, 0, 0);
                                                            }, false);
                                                        }
                                                        img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

                                                        const encodedData = encodeURIComponent(c.svg_reference);
                                                        const imgSrc = `data:image/svg+xml;charset=utf-8,${encodedData}`;

                                                        const imageElement = new Image();
                                                        imageElement.src = imgSrc;
                                                        imageElement.alt = '';

                                                        const tempDiv = document.createElement('div');
                                                        tempDiv.innerHTML = decodeURIComponent(encodedData);
                                                        const svgElement = tempDiv.querySelector('svg');

                                                        document.body.appendChild(tempDiv);
                                                        const bBox = svgElement?.getBoundingClientRect();
                                                        document.body.removeChild(tempDiv);

                                                        imageElement.width = bBox?.width;
                                                        imageElement.height = bBox?.height;

                                                        return <div key={`notUsedHP-key-${i}`} className="line-item notUsedHeatpart mt-3">
                                                            <img
                                                                src={imgSrc}
                                                                width='64px'
                                                                height='64px'
                                                                draggable={c?.hasSVG && c?.attributes?.connection_ports && allowEdit}
                                                                onDragStart={(e) => allowEdit && canvasClassRef.current.handleDragStart(e, {
                                                                    name: c.name,
                                                                    part: c.id,
                                                                    img: imageElement,
                                                                    rotate: 0,
                                                                    connection_ports: c.attributes.connection_ports,
                                                                    type: c.type,
                                                                    hpcc: false
                                                                })}
                                                                onDrag={(e) => canvasClassRef.current.handleDrag(e)}
                                                                onDragEnd={(e) => {
                                                                    canvasClassRef.current.handleDragEnd(e)
                                                                    filterHP()
                                                                }}
                                                                alt=''
                                                            />
                                                            <span>{c.name}</span>
                                                        </div>
                                                        }
                                                    )}
                                                </div>
                                            </Tab>
                                            
                                            <Tab eventKey="usedHP" title={t("usedHP")}>
                                                <div>
                                                    <p>{t("usedHeatingParts")}</p>
                                                    {usedHPinScheme?.map((c, i) => {

                                                        var dragMe = document?.getElementsByClassName("usedHeatpart")[i],
                                                        img = new Image();
                                                        img.onload = function () {
                                                            dragMe?.addEventListener("dragstart", function(e) {
                                                                e.dataTransfer.setDragImage(img, 0, 0);
                                                            }, false);
                                                        }
                                                        img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

                                                        const encodedData = encodeURIComponent(c.svg_reference);
                                                        const imgSrc = `data:image/svg+xml;charset=utf-8,${encodedData}`;

                                                        const imageElement = new Image();
                                                        imageElement.src = imgSrc;
                                                        imageElement.alt = '';

                                                        const tempDiv = document.createElement('div');
                                                        tempDiv.innerHTML = decodeURIComponent(encodedData);
                                                        const svgElement = tempDiv.querySelector('svg');

                                                        document.body.appendChild(tempDiv);
                                                        const bBox = svgElement?.getBoundingClientRect();
                                                        document.body.removeChild(tempDiv);

                                                        imageElement.width = bBox?.width;
                                                        imageElement.height = bBox?.height;

                                                        return <div key={`usedHP-key-${i}`} className="line-item usedHeatpart mt-3">
                                                            <img
                                                                src={imgSrc}
                                                                width='64px'
                                                                height='64px'
                                                                alt=''
                                                            />
                                                            <span>{c.name}</span>
                                                        </div>
                                                        }
                                                    )}
                                                </div>                                  
                                            </Tab>
                                        </Tabs> 
                                    </div>   
                                </div>                                  
                            </Tab>
                        </Tabs>
                    </div>
                </main>
                <div className="d-flex justify-content-between">
                    <Button variant="outline-primary" style={{marginRight: "10px"}} onClick={() => navigate(-1)}>{t("back")}</Button>
                    <Button disabled={!allowEdit} variant="outline-primary" onClick={() => postSchema()}>{t("save")}</Button>
                </div>
            </Card>
        </div>

        <ContentModal {...{ show: show, onHide: () => setShow(false), title: `${t('edit')} ${show?.name}`, content: <EditHP {...{ onClose: () => setShow(false), selectedComponent: show, canvasClassRef }} /> }} />
        <ContentModal {...{ show: showSchema, onHide: () => setShowSchema(false), title: t('schema_generator'), content: <SchemaGenerator {...{ canvasClassRef, onClose: () => setShowSchema(false) }} /> }} />
        <ContentModal {...{ show: showPromt && loadedLibrary, onHide: () => setShowPromt(false), title: t('scheme'), content: <div><p className="mb-3">{t("continueWorkingQuestion")}</p> <Button disabled={!allowEdit} onClick={()=> {setuseLocalStorageData(true); setShowPromt(false)}} variant="outline-primary">{t("confirm")}</Button></div>}} />
      
        <ReactRouterPrompt when={change}>
            {({ isActive, onConfirm, onCancel }) => (
                <Modal
                    show={isActive}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Body>
                        <p className="mb-3">{t("promptLeavePageQuestion")}</p>

                        <div className="d-flex justify-content-between">
                            <Button variant="outline-primary" onClick={onCancel}>{t("cancel")}</Button>
                            <Button
                                disabled={!allowEdit}
                                variant="outline-primary"
                                onClick={() => {
                                    handleSave(); // Speichern Sie die Daten im localStorage
                                    onConfirm(); // Bestätigen Sie das Verlassen der Seite
                                }}
                            >
                                {t("confirm")}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </ReactRouterPrompt>
    </MainLayout>
}

export default SchemaNew;