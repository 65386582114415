import React, { useCallback, useEffect, useState } from 'react'
import { usePermissions } from '../context/PermissionsContext';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../Context';
import { useSwagger } from '../context/SwaggerContext';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import LineChart from '../charts/LineChart';

const BuildingLineChart = ({counter, variantID, reload, setReload, buildingID}) => {
   
    const _buildingGuid = useParams()['buildingGuid'] || buildingID;
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();

    let counterType = counter?.counter_datasource_type 
    const [preparedData, setPreparedData] = useState()
    const [year, setYear] = useState()
    const [disableNextButton, setDisableNextButton] = useState(preparedData && ((year + 1) in preparedData) ? false : true )
    const [disablePrevButton, setDisablePrevButton] = useState(false)
    const [lineChartData, setLineChartData] = useState(false)

    // Funktion zum Gruppieren nach `related_date`
    function groupByRelatedDate(data) {
        return data.reduce((acc, item) => {
            const year = new Date(item.to_datetime).getFullYear();

            if (!acc[year]) {
                acc[year] = [];
            }

            acc[year].push(item);

            return acc;
        }, {});
    }

    // Funktion zum Sortieren nach related_date
    const sortByRelatedDate = (array) => {
        return array.sort((a, b) => new Date(a.to_datetime) - new Date(b.to_datetime));
    };

    const getCounterReadingsOrConsumptions = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_variants_datasource_consumptions_list({building_uuid: _buildingGuid, variant_uuid: variantID, datasource_uuid: counter.id});

            if (response.status >= 200 && response.status < 300) {

                // nach Jahren sortieren
                const groupedData = groupByRelatedDate(response.obj)
                setPreparedData(groupedData)

                // wenn nur ein Eintrag vorhanden ist, sollen die Buttons "<" und ">" disabled sein
                const numberOfYears = Object.keys(groupedData).length;
                if(numberOfYears === 1) {
                    setDisableNextButton(true)
                    setDisablePrevButton(true)
                } 

                // das letze Jahr filtern und als default ausgeben
                const years = Object.keys(groupedData);
                const latestYear = Math.max(...years.map(year => parseInt(year)));
                if (latestYear === -Infinity) {
                    setYear(false);
                } else {
                    setYear(latestYear);
                }

                const filtered = groupedData[latestYear] || [];

                // sortieren
                const sortedData = sortByRelatedDate(filtered);

                const convertedData = sortedData.map(item => ({
                    date: new Date(item.to_datetime).getTime(), 
                    value: counterType === 1000 ? item.counter_value : item.value_raw
                }));

                if(convertedData.length === 0) {
                    setLineChartData(false)
                } else {
                    setLineChartData(convertedData)
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, variantID, counter, login.Authorization, counterType]);

    const handleIncreaseOrDecrease = (type) => {
        let increase = type === "next" ? true : false

        let newYear = increase ? year + 1 : year - 1

        increase ? setDisableNextButton(false) : setDisablePrevButton(false)
        increase ? setDisablePrevButton(false) : setDisableNextButton(false)

        setYear(newYear)
        // Filtern nach Jahr
        const filtered = preparedData[newYear] || [];

        // sortieren
        const sortedData = sortByRelatedDate(filtered);

        const convertedData = sortedData.map(item => ({
            date: new Date(item.to_datetime).getTime(), 
            value: counterType === 1000 ? item.counter_value : item.value_raw
        }));
    
        setLineChartData(convertedData)
    }

    // Event-Handler für den ">" Button
    const nextYear = () => {
        if ((year + 1) in preparedData) {
            handleIncreaseOrDecrease("next")
        } else {
            setDisableNextButton(true)
            setDisablePrevButton(false)
        }

        if (!(year + 2 in preparedData)) {
            setDisableNextButton(true);
        }
        
    }

    // Event-Handler für den "<" Button
    const prevYear = () => {
        if ((year - 1) in preparedData) {
            handleIncreaseOrDecrease("prev")
        } else {
            setDisablePrevButton(true)
            setDisableNextButton(false)
        }

        if (!(year - 2 in preparedData)) {
            setDisablePrevButton(true);
        }
    }

    useEffect(() => {
        getCounterReadingsOrConsumptions()
    }, [])

    useEffect(() => {
        if (reload) {
            getCounterReadingsOrConsumptions()
            setReload(false)
        }
    }, [getCounterReadingsOrConsumptions, reload, setReload])

    return (
        <> 
            {preparedData && (
                <>
                {year && (
                    <div className="button-container">
                        <Button id="prevButton" disabled={disablePrevButton} variant='outline-primary' className='p-10  bigger-button' onClick={() => prevYear()} style={{ border: "none" }}>
                            {"<"}
                        </Button>
                        <span className="year-display" id="currentYearDisplay">{year}</span>
                        <Button id="nextButton" disabled={disableNextButton} variant='outline-primary' className='p-10  bigger-button' onClick={() => nextYear()} style={{ border: "none" }}>
                            {">"}
                        </Button>
                    </div>
                )}
                    {lineChartData && <LineChart data={lineChartData} counterID={counter.id} />}
                </>
            )}           
        </>
    )
}

export default BuildingLineChart