import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../Context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faSave, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { ScatterPlotChart } from '../components/AmCharts'
import ScatterPlotSVG from '../style/ScatterPlotSVG'
import { formatDateWithoutTime, utcToLocal, calculateLLS } from '../util/helpers'
import DatePicker from "../components/DatePicker";
import { useSwagger } from "../context/SwaggerContext";

const SelectionPage = ({ measurements, axes, handleAxes, xMeasurement, yMeasurement, setStep, loadMeasurements }) => {
    const { t } = useTranslation();

    return <Row>
        <Col className='mb-3'>
            <Row>
                <Col md={1}>X</Col>
                <Col md={1}>Y</Col>
                <Col>{t("measurementPoint")}</Col>
            </Row>
            {measurements?.map((m) =>
                <Row key={`row-${m.id}`}>
                    <Col md={1}>
                        <Form.Check key={`x-${m.id}`} checked={axes.x === m.id} onChange={() => handleAxes('x', m)} />
                    </Col>
                    <Col md={1}>
                        <Form.Check key={`y-${m.id}`} checked={axes.y === m.id} onChange={() => handleAxes('y', m)} />
                    </Col>
                    <Col>{m.name}</Col>
                </Row>
            )}
        </Col>
        <Col xl={6} md={6} sm={12} className='mb-3'>
            <label>{t("preview")}:</label>
            <ScatterPlotSVG xAxis={xMeasurement?.name || "X"} yAxis={yMeasurement?.name || "Y"} style={{ width: '100%', maxWidth: '384px' }} />
        </Col>
        <div className="d-flex justify-content-end">
            <Button variant='outline-primary' onClick={() => {if (axes.y !== undefined) {setStep(2); loadMeasurements()}}} disabled={axes.y === undefined}>{t('plot')}</Button>
        </div>
    </Row>
}

const PlotPage = ({ from, to, setFrom, setTo, currentAggregation, setCurrentAggregation, aggregations, currentInterval, setCurrentInterval, intervals, loadMeasurements, swapAxes, measurementsData, load, firstLoad, dataToShow }) => {
    const { defaultTheme } = useAuth();
    const { t } = useTranslation();
    
    return <Row>
        <Col xl={12} md={12} sm={12}>
            <label>{t("period")}:</label>
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <DatePicker onChange={(e) => {setFrom(e.detail.valueAsDate)}} value={`${String(from.getFullYear())}-${String(from.getMonth() + 1).padStart(2, '0')}-${String(from.getDate()).padStart(2, '0')}`} />
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <DatePicker onChange={(e) => {setTo(e.detail.valueAsDate)}} value={`${String(to.getFullYear())}-${String(to.getMonth() + 1).padStart(2, '0')}-${String(to.getDate()).padStart(2, '0')}`} />
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <label>{t("aggregation")}:</label>
            <Form.Select size="md" value={currentAggregation} onChange={(e) => setCurrentAggregation(e.target.value)}>
                {Object.entries(aggregations).map(([key, value]) => <option key={`aggregation-${key}`} value={key}>{value}</option>)}
            </Form.Select>
        </Col>
        <Col xl={6} md={6} sm={12} className="mb-3">
            <label>{t("interval")}:</label>
            <Form.Select size="md" value={currentInterval} onChange={(e) => setCurrentInterval(e.target.value)}>
                {Object.entries(intervals).map(([key, value]) => <option key={`interval-${key}`} value={key}>{value}</option>)}
            </Form.Select>
        </Col>
        <Col xl={12} md={12} sm={12} className="mb-3">
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip className='position-fixed'>{t("reload")}</Tooltip>
                        }>
                        <Button variant='outline-primary' className='p-10 me-3' onClick={() => loadMeasurements()}>
                            <FontAwesomeIcon icon={faRedo} size="sm" />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip className='position-fixed'>{t("downloadAsCSV")}</Tooltip>
                        }>
                        <Button variant='outline-primary' className='p-10' onClick={() => console.log('download')}>
                            <FontAwesomeIcon icon={faSave} size="sm" />
                        </Button>
                    </OverlayTrigger>
                </div>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                        <Tooltip className='position-fixed'>{t("switch")}</Tooltip>
                    }>
                    <Button variant='outline-primary' className='p-10' onClick={() => swapAxes()}>
                        <FontAwesomeIcon icon={faExchangeAlt} size="sm" />
                    </Button>
                </OverlayTrigger>
            </div>
        </Col>

        <Col xl={12} md={12} sm={12}>
            <div style={{ width: "100%", height: "500px" }}>
                <ScatterPlotChart {...{theme: defaultTheme === 'light' ? 'dark' : 'light', visibleSB: false, data: measurementsData, load, firstLoad, dataToShow}} />
            </div>
        </Col>
    </Row>
}

export const ScatterPlotContent = ({ devices }) => {
    const {login} = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [measurementsData, setMeasurementsData] = useState(undefined);
    const [from, setFrom] = useState(utcToLocal(new Date(new Date().setDate(new Date().getDate() - 30))));
    // eslint-disable-next-line no-unused-vars
    const [to, setTo] = useState(utcToLocal(new Date()));
    // eslint-disable-next-line no-unused-vars
    const [dataToShow, setDataToShow] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [load, setLoad] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [firstLoad, setFirstLoad] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [currentAggregation, setCurrentAggregation] = useState('avg');
    // eslint-disable-next-line no-unused-vars
    const [currentInterval, setCurrentInterval] = useState('daily');

    const client = useSwagger();
    
    // eslint-disable-next-line no-unused-vars
    const aggregations = {
        min: t("minimalValue"),
        avg: t("average"),
        max: t("maximalValue")
    };

    // eslint-disable-next-line no-unused-vars
    const intervals = {
        minutely: t("minutely"),
        quarterhourly: t("quarterhourly"),
        hourly: t("hourly"),
        daily: t("daily")
    };

    const measurements = [].concat(...devices.map(d => d.measurements));
    const [axes, setAxes] = useState({
        x: measurements[0]?.id,
        y: measurements[1]?.id
    })
    const [swapOccurred, setSwapOccurred] = useState(false);

    const handleAxes = useCallback((key, measurement) => {
        let tmpObj = {...axes};
        const guid = measurement.id;
        if (axes.y === guid) tmpObj.y = tmpObj.x;
        if (axes.x === guid) tmpObj.x = tmpObj.y;
        tmpObj[key] = guid;
        setAxes(tmpObj);
    }, [axes])

    const swapAxes = useCallback(() => {
        setAxes({
            x: axes.y,
            y: axes.x
        });
        setSwapOccurred(true);
    }, [axes])

    const xMeasurement = measurements?.find((m) => m.id === axes.x);
    const yMeasurement = measurements?.find((m) => m.id === axes.y);

    const measurementPromise = useCallback((measurement) => {
        return new Promise(async(resolve, reject) => {
            // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/data/${measurement}?from=${formatDate(from)}&to=${formatDate(to)}&aggregation=${currentInterval}`, {
            //     headers: {
            //         'Authorization': login.Authorization,
            //         "Content-Type": "application/json"
            //     }
            // })
            // .then((response) => {
            //     if (response.status === 200) return response.json();
            //     return [];
            // })
            // .then((data) => {
            //     resolve(data);
            // })
            // .catch((error) => {
            //     reject(error);
            // });

            if (!client) return;

            const originalRequestInterceptor = client.http.requestInterceptor;

            try {
                client.requestInterceptor = (req) => {
                    req.headers["Content-Type"] = "application/json";
                    req.headers["Authorization"] = login.Authorization;
                    return req;
                };

                const response = await client.apis["building"].building_data_retrieve({
                    building_uuid: _buildingGuid,
                    measurement_uuid: measurement,
                    aggregation: currentInterval,
                    from: formatDateWithoutTime(from),
                    to: formatDateWithoutTime(to)
                });

                if (response.status === 200)
                    resolve(response.obj);

                client.http.requestInterceptor = originalRequestInterceptor;
            } catch (error) {
                reject(error);
                client.http.requestInterceptor = originalRequestInterceptor;
            }
        });
    }, [client, from, to, currentInterval, _buildingGuid, login.Authorization]);

    const loadMeasurements = useCallback(() => {
        setLoad(false);
        setDataToShow(false);
        setMeasurementsData({
            axes: [xMeasurement.name, yMeasurement.name],
            data: [],
            line: {
                data: [],
                func: []
            }
        });

        const promises = [axes.x, axes.y].map(
            measurement => measurementPromise(measurement)
        );

        Promise.all(promises).then((values) => {
            const nameData = values.map((data, i) => {
                const name = i === 0 ? xMeasurement.name : yMeasurement.name;
                const keyPoint = name.replace(/\s+/g, '').toLowerCase();

                const dataFormatter = (json) => {
                    var retArr = [];
                    for (var i = json.length - 1; i >= 0; i--) {
                        retArr.push({
                            date: Date.parse(utcToLocal(json[i].timestamp)),
                            [keyPoint]: parseFloat((Math.round(json[i][currentAggregation] * 100) / 100).toFixed(2)),
                        });
                    }
                    return retArr;
                }

                return {
                    name: name,
                    data: dataFormatter(data).sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    })
                }
            });

            const data = calculateLLS(nameData.map(nd => nd));

            setMeasurementsData(data);
            setLoad(true);
            setFirstLoad(true);
            setDataToShow(Boolean(data.data.length));
        });
    }, [axes, measurementPromise, currentAggregation, xMeasurement?.name, yMeasurement?.name])

    useEffect(() => {
        if (swapOccurred) {
            loadMeasurements();
            setSwapOccurred(false);
        }
    }, [swapOccurred, axes, loadMeasurements])

    if (step === 1) return <SelectionPage {...{ measurements, axes, handleAxes, xMeasurement, yMeasurement, setStep, loadMeasurements }} />
    if (step === 2) return <PlotPage {...{ from, to, setFrom, setTo, currentAggregation, setCurrentAggregation, aggregations, currentInterval, setCurrentInterval, intervals, loadMeasurements, swapAxes, measurementsData, load, firstLoad, dataToShow }} />
}

export default ScatterPlotContent;