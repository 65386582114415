import styled from "styled-components";
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Offline from '../assets/Offline_Icon.svg'

export const SHButton = styled(Button)`
    background-color: #ffd981;
    border-color: #ffd981;
    color: #000;
    flex-shrink: 0;
    padding: .75rem;
    & > svg {
        display: block!important;
        width: 16px;
        height: 16px;
    }
    &:hover {
        color: #000;
        border-color: #fff0ce;
        background-color: #fff0ce;
    }
    &:active,
    &:focus {
        border-color: #fff0ce;
    }
    &::active {
        background-color: #fff0ce;
    }
    &:focus {
        box-shadow: 0 0 0 0.25rem rgb(227 111 25 / 50%)!important;
    }
    &:disabled {
        background-color: #fff0ce;
        border-color: #fff0ce;
    }
    &.active,
    &:active,
    &:focus-visible {
        color: #fff!important;
        border-color: #f5bc39!important;
        background-color: #f5bc39!important;
    }
    &.secondary {
        border-color: #c9c3c3;
        background-color: #c9c3c3;
    }
    &.secondary:hover {
        border-color: #9e9999;
        background-color: #9e9999;
    }
    &.outline {
        color: #ffd981;
        background-color: transparent;
    }
    &.outline:hover {
        color: #ffffff;
        background-color: #fff0ce;
    }
`;

export const SHCheck = styled(Form.Check)`
    .form-check-input:checked {
        background-color: #ffd981;
        border-color: #ffd981;
    }
    & > label {
        font-size: 1rem;
        font-weight: 500;
        color: #ffffff;
    }
    &.darkfont > label {
        color: #212529;
        font-weight: 400;
        font-size: 14px;
    }
    .form-check-input:focus {
        border-color: #ffd981;
        box-shadow: 0 0 0 0.25rem rgb(227 111 25 / 50%)!important;
    }
`;

export const SHStatus = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-position: center;
    &.online {
        background-color: #1aeb36;
    }  
    &.warning {
        background-color: #fa9d39;
    }
    &.offline {
        width: 20px;
        height: 20px;
        background-image: url(${Offline});
    }
`;

export const SHFormLabel = styled(Form.Label)`
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
`;

export const SHFormInput = styled(Form.Control).attrs({
    type: 'email'
})`
    outline: none!important;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    background-image: linear-gradient(0deg, #fccc86, #faa05c);
    border-radius: 8px;
    padding: 12px;
    box-shadow: inset 2px 2px 10px rgb(0 0 0 / 10%);
    &::placeholder {
        color: #ffffff;
    }
    &:focus::placeholder {
        color: transparent;
    }
    &:focus {
        box-shadow: inset 2px 2px 10px rgb(0 0 0 / 10%);
        border-color: none;
        color: #ffffff;
    }
`;

export const SHFormText = styled(SHFormInput).attrs({
    type: 'text'
})``;

export const SHFormEmail = styled(SHFormInput).attrs({
    type: 'email'
})``;

export const SHFormPassword = styled(SHFormInput).attrs({
    type: 'password'
})``;

export const SHFormSelectWrapper = styled.div`
    position: relative;
    background-image: linear-gradient(0deg, #fccc86, #faa05c);
    border-radius: 8px;
`;

export const SHFormSelect = styled(Form.Select)`
    position: relative;
    font-weight: 500;
    color: #ffffff;
    padding: 12px 2.25rem 12px 12px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
    box-shadow: inset 2px 2px 10px rgb(0 0 0 / 10%);
    option {
        font-weight: 500;
        background-color: #FFBF61;
    }
    &:focus {
        border-color: transparent;
        color: #ffffff;
        box-shadow: inset 2px 2px 10px rgb(0 0 0 / 10%);
    }
`;

export const SHFormButton = styled.button`
    width: 100%;
    outline: none;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #FFFFFF;
    background-image: linear-gradient(180deg, rgba(255, 191, 97, 1), rgba(237, 143, 49, 1));
    cursor: pointer;
    transition: background-color .2s ease-in-out;

    &:hover {
        background-image: linear-gradient(180deg, rgba(255, 191, 97, .75), rgba(237, 143, 49, .75));
    }
    &:active {
        background-image: linear-gradient(180deg, rgba(255, 191, 97, 1), rgba(237, 143, 49, 1));
        box-shadow: inset 2px 2px 10px rgb(0 0 0 / 10%);
    }
`;

export const SHLink = styled(Link)`
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
    text-decoration: none;
    &:hover {
        color: #ffffff;
        text-decoration: underline;
    }
`;

export const SHProgress = styled(ProgressBar)`
    & .progress-bar {
        background-color: #ffd981;
    }
`;