import React, { useState, useEffect, useCallback } from 'react';
import Card from "../components/Card"
import { Link } from 'react-router-dom';
import { Button, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useSwagger } from "../context/SwaggerContext";
import Loading from '../components/Loading';

const SignInWithMicrosoft = ({setLoading}) => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(false);
        }
    }, [isAuthenticated]);

    const handleLogin = (e, loginType) => {
        e.preventDefault();

        sessionStorage.removeItem('microsoft-account-id');

        if (loginType === "popup") {
            instance.loginPopup({
                scopes: ["User.Read"] // Specify the scopes your application needs
            }).then(response => {
                console.log("Login was successful", response);
                // Handle successful login
            }).catch(e => {
                // Handle errors such as user cancelling the popup
                console.error(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect({
                scopes: ["User.Read"]
            });
            // Note: You don't need to handle the response here for redirect; it will be handled by the code in AuthResponseHandler
        }
    };

    return <button className="bsk-btn bsk-btn-default mb-3" onClick={(e) => handleLogin(e, "redirect")}>
        <object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" className="x-icon" />
        <span> Sign In with Microsoft</span>
    </button>
};

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [pw, setPW] = useState('');
    const [stayLoggedIn, setStayLoggedIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { doSetLogin, defaultTheme, login } = useAuth();
    const { addToast } = useToast();
    const client = useSwagger();
    const { instance, accounts } = useMsal();

    const [loading, setLoading] = useState(true)

    const microsoftAuth = useCallback(async(data) => {
        let login = sessionStorage.getItem("microsoft-account-id")

        if (!client || login) return;
        sessionStorage.setItem('microsoft-account-id', true);
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.body = JSON.stringify(data);
                return req;
            };

            const response = await client.apis["microsoftauth"].microsoftauth_create();

            if (response.status >= 200 && response.status < 300) {
                sessionStorage.removeItem("microsoft-login")
                doSetLogin(response.obj);
                navigate(response.obj?.["TwoFA"] ? "/2fa" : "/");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("login"), "Coudn't validate the loggin session!", "error");
            toggleForm(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [addToast, client, doSetLogin, navigate, t])

    const handleResponse = useCallback(async() => {
        let logout = localStorage.getItem("logout")
        if (logout) {
            setLoading(false)
            localStorage.removeItem("logout")
            return
        }

        let login = sessionStorage.getItem("microsoft-login")

        if (login === null) {
            const response = await instance.handleRedirectPromise();
            if (!response) setLoading(false)
            if (!response) return
            const { id, idToken } = response;
            let value = sessionStorage.getItem('microsoft-account-id')
            if (idToken && !value) sessionStorage.setItem("microsoft-login", true)
            if (!value) await microsoftAuth({ token: idToken })
        } else {
            setLoading(false)
        }
    }, [instance, accounts, microsoftAuth]);

    useEffect(() => {
        handleResponse();
    }, []);

    const toggleForm = (boolean) => {
        const form = document.getElementById('login-form');
        const formElements = form.elements;
        for (let i = 0; i < formElements.length; i++) {
            formElements[i].disabled = boolean;
        }
    }

    const submit = useCallback(async(e) => {
        e.preventDefault();

        toggleForm(true);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === '' || !emailRegex.test(email)) {
            addToast(t("login"), t('loginAlerts.validEmailAddress'), "notification");
            toggleForm(false);
            return;
        } else if (pw === '') {
            addToast(t("login"), t('loginAlerts.validPassword'), "notification");
            toggleForm(false);
            return;
        }

        let data = {
            mail: email,
            password: pw,
            stay_logged_in: stayLoggedIn
        }

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.body = JSON.stringify(data);
                return req;
            };

            const response = await client.apis["User"].postLogin();

            if (response.status === 201 || response.status === 200) {
                doSetLogin(response.obj);
                navigate(response.obj?.["TwoFA"] ? "/2fa" : "/");
                navigate(response.obj.accepted === false ? "/terms" : "/");
            } else if (response.status >= 200 && response.status < 300) {
                navigate(response.obj?.["TwoFA"] ? "/2fa" : "/");
                navigate(response.obj.accepted === false ? "/terms" : "/");
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.error(error);
            addToast(t("login"), t("loginAlerts.validCredentials"), "error");
            toggleForm(false);
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, addToast, email, t, pw, stayLoggedIn, doSetLogin, navigate])

    return (
        <>
            {loading && <Loading/>}
            <div style={{ width: '100%', maxWidth: '512px' }}> 
                {!loading && (
                    <Card {...{ heading: t("login"), className: 'w-100' }}>
                        <Form className='d-block' id='login-form' onSubmit={(e) => submit(e)}>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>{t("emailAddress")}</Form.Label>
                                <Form.Control type="email" placeholder="beispiel@sigmaheat.de" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label>{t('password')}</Form.Label>
                                <div className='d-flex justify-content-between'>
                                    <Form.Control type={showPassword ? 'text' : 'password'} placeholder={t('password')} value={pw} onChange={(e) => setPW(e.target.value)} />

                                    <Button variant="outline-primary" className={`ms-3${showPassword ? ' active' : ''}`} onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={faEye} size='sm' />
                                    </Button>
                                </div>
                            </Form.Group>
                            <Form.Check
                                type="checkbox"
                                id="formStayLoggedIn"
                                className="mb-3"
                                label={t('stayLoggedIn')}
                                value="stayLoggedIn"
                                onChange={(e) => setStayLoggedIn(e.target.checked)}
                            />

                            <Link to='/forget' className={`d-block mb-3 ${defaultTheme === undefined && localStorage.getItem('SH_Theme') === undefined ? "text-black" : defaultTheme === undefined ? localStorage.getItem('SH_Theme') === 'light' ? "text-black" : "text-light" : defaultTheme === 'light' ? 'text-black' : 'text-light'}`}>{t('forgotPassword')}</Link>
                            <Button variant="outline-primary" className='w-100 mb-3' type='submit'>{t('login')}</Button>
                            <SignInWithMicrosoft { ...{ setLoading }}/>
                            <span className='d-inline-block'>{t('notMember')} <Link to='/signup' className={`d-inline-block text-decoration-underline ${defaultTheme === undefined && localStorage.getItem('SH_Theme') === undefined ? "text-black" : defaultTheme === undefined ? localStorage.getItem('SH_Theme') === 'light' ? "text-black" : "text-light" : defaultTheme === 'light' ? 'text-black' : 'text-light'}`}>{t('createAccount')}</Link></span>
                        </Form>
                    </Card>
                )}
            </div>
    </>)
};