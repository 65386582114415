import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function NotificationModal({ show, onHide, content, title }) {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className="top__most__modal"
            backdropClassName="top__most__backdrop"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-primary' className="w-100" onClick={onHide}>{t('close')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NotificationModal