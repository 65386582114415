import React, { useCallback, useEffect, useState } from 'react'
import MainLayout from '../components/MainLayout';
import Background from "../assets/background_3_3.png";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import Card from "../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faDownload, faEdit, faList, faSearch, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { useToast } from '../NotificationsContent';
import { Button, Collapse, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import BuildingDocuments from './BuildingDocuments';
import Pagination from '../components/Pagination';

const BuildingSubsidiesCatalog = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();

    const [subsidies, setSubsidies] = useState([]);

    // pagination
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 15;
    const [pageCount, setPageCount] = useState(Math.ceil((subsidies?.length || 0) / pageSize));
    useEffect(() => {
        setPageCount(Math.ceil((subsidies?.length || 0) / pageSize));
    }, [subsidies, pageSize]);
    const indexOfLastItem = (currentPage + 1) * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = subsidies?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const tableStructure = [
        {
            col: <div className='d-flex' style={{ width: '16px', height: '16px' }}><FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' /></div>,
            type: 'icon'
        },
        {
            col: t('name'),
            type: 'label'
        },
        {
            col: t('description'),
            type: 'label'
        },
        {
            col: t('link'),
            type: 'label'
        },
        {
            col: t('valid'),
            type: 'label'
        },       
    ]

    const getAllSubsidies = useCallback(async () => {
        setSubsidies([]);
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["subsidy"].subsidy_list({
            });

            if (response.status === 204) {
                setSubsidies([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setSubsidies(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, login.Authorization, t])

    useEffect(() => {
        getAllSubsidies()
    }, [getAllSubsidies])

    console.log(subsidies)

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: `${t('subsidiesCatalog')}` }}>
                {(subsidies === undefined) ? (
                    <TablePlaceholder {...{ structure: tableStructure }} />
                ) : (!Boolean(subsidies.length)) ? (
                    <p className="m-0">{t("noSubsidies")}</p>
                ) : (
                    <>
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                    <span>{ts.col}</span>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {subsidies.map((subsidy) => <SubsidyRow key={`subsidy-key-${subsidy.id}`} {...{ subsidy }} />)}
                        </tbody>
                    </Table>
                    <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                    pageCount={pageCount}
                    />
                    </>
                )}
            </Card>
        </div>
    </MainLayout>
}

const SubsidyRow = ({ subsidy }) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);

    return (
        <>
            <tr>
                <td>
                    {/* <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} /> */}
                </td>
                <td>
                    <span>{subsidy.name}</span>
                </td>
                <td>
                    <span>{subsidy.description}</span>
                </td>
                <td>
                <span><a href={subsidy.source_link}  target="_blank">Link</a></span>
                </td>
                <td>
                    <span>{new Date(subsidy.valid_from).toLocaleDateString('de-DE')} {t('till')} {new Date(subsidy.valid_to).toLocaleDateString('de-DE')}</span>
                </td>
            </tr>
            {display && (
                <tr>
                    <td colSpan="100%">
                        <Card {...{ heading: t("documents"), active: true, className: "height-fit" }}>
                            <BuildingDocuments {...{ subsidy }}/>
                        </Card>
                    </td>
                </tr>
            )}
        </>
    )
}

export default BuildingSubsidiesCatalog