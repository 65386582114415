import { useState, useContext, createContext, useEffect, useRef, useCallback } from "react";
import { checkProfile, getSavedProfile } from "./util/helpers";
import i18n from "i18next";
import { useSwagger } from "./context/SwaggerContext";
import { setCookie } from "./util/helpers";
import WebSocketWorker from "./websocket/WebsocketWorker";

const LoginContext = createContext();

export function useAuth() {
  return useContext(LoginContext);
}

export function LoginProvider({ children }) {
  // const [login, setLogin] = useState(getSavedProfile());
  const [login, setLogin] = useState(null);
  // const theme = useRef(login?.settings?.theme_code || "light");
  const theme = useRef("light");
  const [reload, setReload] = useState(1);
  const [image48, setImage48] = useState(false);
  const [image128, setImage128] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isThemeSet, setIsThemeSet] = useState(false);

  const [activeUsers, setActiveUsers] = useState([]);
  const wsWorkerRef = useRef(null);

  const client = useSwagger();

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     const profile = await getSavedProfile();
  //     setLogin(profile);
  //     setIsLoading(false);
  //   };

  //   fetchProfile();

  // }, []); 

  useEffect(() => {
    const fetchProfile = async () => {
      if (!client) return; // Sicherstellen, dass client definiert ist

      try {
        const profile = await getSavedProfile(client);
        setLogin(profile);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [client]);

  // const doSetLogin = (login) => {
  //   // Do not save an invalid or expired token
  //   console.log("checkProfile", checkProfile(login))
  //   if (!checkProfile(login)) {
  //     localStorage.setItem("Profile", null);
  //     setLogin(null);
  //     return;
  //   }

  //   localStorage.setItem("Profile", JSON.stringify(login));
  //   i18n.changeLanguage(
  //     login?.settings?.language_code || localStorage.getItem("language") || "en"
  //   );
  //   localStorage.setItem(
  //     "language",
  //     login?.settings?.language_code || localStorage.getItem("language") || "en"
  //   );
  //   changeTheme(login?.settings?.theme_code || "light");
  //   setLogin(login);
  // };

  const doSetLogin = useCallback((login) => {
    checkProfile(login, client).then(profileResult => {
      // console.log("checkProfile", profileResult);
  
      // Überprüfe das Ergebnis von checkProfile
      if (!profileResult) {
        localStorage.setItem("Profile", null);
        setLogin(null);
        return;
      }
  
      // Speichere das Profil und andere Einstellungen
      localStorage.setItem("Profile", JSON.stringify(login));
      const languageCode = login?.settings?.language_code || localStorage.getItem("language") || "en";
      i18n.changeLanguage(languageCode);
      localStorage.setItem("language", languageCode);
      changeTheme(login?.settings?.theme_code || "light");
      setLogin(login);

      // set cookie
      const days = login?.stay_logged_in ? 365 : 1;
      setCookie('sigmaheat_user', { login: login?.id, api: process.env.REACT_APP_API_URL }, days, '.sigmaheat.de');
      // setCookie('sigmaheat_user', login.id, days);

    }).catch(error => {
      console.error("Error during login setup:", error);
      setLogin(null);
    });
  }, [client]);

  const changeTheme = (skin) => {
    theme.current = skin;
    let htmlTag = document.getElementsByTagName("html")[0];
    skin === "dark"
      ? htmlTag.classList.add("dark")
      : htmlTag.classList.remove("dark");
  };

  const updateProfileImage = (size, content) => {
    if (size === 48) setImage48(content);
    if (size === 128) setImage128(content);
  };

  // useEffect(() => {
  //   changeTheme(theme.current);
  //   if (login) {
  //     theme.current = login.settings?.theme_code || "light";
  //   }
  //   i18n.changeLanguage(
  //     login?.settings?.language_code || localStorage.getItem("language") || "en"
  //   );
  //   localStorage.setItem(
  //     "language",
  //     login?.settings?.language_code || localStorage.getItem("language") || "en"
  //   );
  // }, [login]);

  useEffect(() => {
    // Initialisiere das Theme aus localStorage, falls vorhanden
    const savedTheme = localStorage.getItem('theme') || 'light';
    theme.current = savedTheme;
    changeTheme(savedTheme);
    setIsThemeSet(true);
  }, [theme, changeTheme]);

  useEffect(() => {
    if (isThemeSet && login && login.settings) {
      const userTheme = login.settings.theme_code || 'light';
      theme.current = userTheme;
      changeTheme(userTheme);
      localStorage.setItem('theme', userTheme);
      // console.log("theme.current", theme.current);

      const language = login.settings.language_code || localStorage.getItem('language') || 'en';
      i18n.changeLanguage(language);
      localStorage.setItem('language', language);
    }
  }, [isThemeSet, login, theme, changeTheme, i18n]);

  // Active Users
  const updateActiveUsers = useCallback((activeUsersData) => {
    setActiveUsers(prevActiveUsers => {
      // Zustand nur aktualisieren, wenn sich die Benutzerliste ändert
      if (JSON.stringify(prevActiveUsers) !== JSON.stringify(activeUsersData)) {
        return activeUsersData;
      }
      return prevActiveUsers;
    });
  }, [setActiveUsers]);
  
  useEffect(() => {
    if (!login || !login.currentBuilding?.id) return

    // if (!wsWorkerRef.current) {

    wsWorkerRef.current = new WebSocketWorker(
      login, 
      login.currentBuilding.id, 
      (title,msg,type)=> {}, 
      updateActiveUsers
    )
    // }

    return () => {
      wsWorkerRef.current?.disconnect();
    };
  }, [login]);

  const value = {
    login,
    doSetLogin,
    reload,
    setReload,
    defaultTheme: theme.current,
    setDefaultTheme: (theme) => changeTheme(theme),
    image48,
    image128,
    updateProfileImage: (size, content) => updateProfileImage(size, content),
    isLoading,
    activeUsers
  };

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
}
