import { useState, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

// const Price = ({ name, id, price, checked, setChecked }) => {
const Price = ({ id, price}) => {

    const { t } = useTranslation();

    return <li>
        {/* <input type="radio" id={id} name={name} className="item__radio" checked={id === checked} onChange={() => setChecked(id)} /> */}
        {/* <label className="item__label" htmlFor={id}>
            <span>{price.hasOwnProperty('tiers') ? t("tier") : t("price")}</span>
            {!price.hasOwnProperty('tiers') && <span>{price?.price}€ / {price?.recurring?.interval_count} {t(price?.recurring?.interval)}</span>}
            {price.hasOwnProperty('tiers') && <span>{price?.tiers[0]?.flat_amount}€ / {price?.recurring?.interval_count} {t(price?.recurring?.interval)}</span>}
        </label> */}
        <label className="item__label" htmlFor={id}>
            <span>{t("price")}</span>
            <span>{price}€</span>
        </label>
    </li>
}

export const Prices = ({ prices, category, pricesArray, setPrices, chk }) => {
    const [checked, setChecked] = useState(chk);

    const changeChecked = useCallback((id) => {
        const tmpChecked = checked;
        const i = pricesArray.findIndex(p => p === tmpChecked);
        setChecked(id)
        setPrices(prev => {
            const updatedPrices = [...prev];
            updatedPrices[i] = id;
            return updatedPrices;
        })
    }, [pricesArray, checked])

    return <ul className="horizontal__radio__wrapper">
        {/* {prices.map(price => <Price key={`tier-${price.price_id}`} {...{ id: price.price_id, name: category, price, checked, setChecked: changeChecked}} />)} */}
        <Price key={`price-${prices}`} {...{ id: prices, price: prices }} />
    </ul>
}