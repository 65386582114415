import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from '../components/Collapse'
import { icon } from "leaflet";

export default function Sidebar({ sidebarArray }) {
    const [collapsed, setCollapsed] = useState({});
    const location = useLocation();
    const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);

    const SidebarElement = ({ icon, label, to }) => (
        <NavLink key={`sidebar-nav-key-${to}`} className='sidebar__item' to={to} state={true}>
            <FontAwesomeIcon icon={icon} />
            <span>{label}</span>
        </NavLink>
    );

    const toggleCollapse = (boolean, index) => {
        setCollapsed(prevState => ({
            ...prevState,
            [index]: boolean
        }));
    };

    const scrollEffect = useCallback(() => {
        const sidebar = document.querySelector('.sidebar__wrapper');
        var currentScrollPos = window.pageYOffset;
        sidebar.style.maxHeight = `${prevScrollpos > currentScrollPos || !Boolean(currentScrollPos) ? 'calc(100% - 60px)' : '100%'}`;
        sidebar.style.top = `${prevScrollpos > currentScrollPos || !Boolean(currentScrollPos) ? '60' : '0'}px`;
        setPrevScrollpos(currentScrollPos);
    }, [prevScrollpos])

    useEffect(() => {
        if (!Boolean(sidebarArray.length)) return;
        const navbar = document.querySelector('.navigation');
        const sidebar = document.querySelector('.sidebar__wrapper');

        sidebar.addEventListener('pointerenter', () => {
            navbar.classList.add('hovered');
        });
      
        sidebar.addEventListener('pointerleave', () => {
            if (window.scrollY < 60)
                navbar.classList.remove('hovered');
        });

        window.addEventListener('scroll', scrollEffect)
        return () => window.removeEventListener('scroll', scrollEffect)
    }, [scrollEffect, sidebarArray.length])

    useEffect(() => {
        if (!Boolean(sidebarArray.length)) return;
        const sidebar = document?.querySelector('.sidebar__wrapper');
        sidebar.style.maxHeight = 'calc(100% - 60px)';
        sidebar.style.top = '60px';
    }, [location, sidebarArray.length])

    if (!Boolean(sidebarArray.length)) return <></>

    return <div className='sidebar__wrapper'>
        <div className="sidebar__overflow">
            <div className='sidebar__container'>
                {
                    // eslint-disable-next-line array-callback-return
                    sidebarArray.map((elem, index) => {
                        if (elem.type === 'Link') return SidebarElement(elem)
                        if (elem.type === 'Divider') return <div key={`subnav-key-${index}`} className={`sidebar__divider${elem?.hidden ? ' d-none' : ''}`} />
                        if (elem.type === 'Category') return <React.Fragment key={`subnav-category-${index}`}><div className='sidebar__category__item'>
                                <div className='sidebar__item'>
                                    <FontAwesomeIcon icon={elem.icon} />
                                    <span>{elem.label}</span>
                                </div>
                                {elem.button}
                            </div>
                            {elem.array.map(el => SidebarElement(el))}
                        </React.Fragment>
                        if (elem.type === 'Array') return <React.Fragment key={`subnav-array-${index}`}>
                            {elem.array.map(el => SidebarElement(el))}
                        </React.Fragment>
                        if (elem.type === 'Collapsable' && Boolean(elem.array.length)) return <React.Fragment key={`subnav-collaspable-${index}`}>
                            <div className='sidebar__item'>
                                <Collapse {...{boolean: collapsed[index], onClick: () => toggleCollapse(!collapsed[index], index), index}} />
                                {(elem.icon!=undefined)?<FontAwesomeIcon icon={elem.icon} />:<></>}
                                <span>{elem.label}</span>
                            </div>
                            <div className={`${collapsed[index] ? 'd-block' : 'd-none'}`}>
                                {elem.array.map(el => SidebarElement(el))}
                            </div>
                        </React.Fragment>
                    })
                }
            </div>
        </div>
    </div>;
}