import { useState, useCallback } from 'react'
import { useParams } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import { useSwagger } from '../context/SwaggerContext';
import { getChangedFields, isEmpty } from "../util/helpers";

function AddEditLevel({ onClose, ...props }) {
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];
    let initialData = props.floor || {}
    const [floor, setFloor] = useState(initialData);
    const client = useSwagger();

    const onSubmit = useCallback(async(e) => {
        e.preventDefault();

        const changedFields = getChangedFields(floor, initialData);
        if (isEmpty(changedFields)) onClose()

        // if (!client || isEmpty(changedFields)) return;
        if (!client || (props.floor !== undefined && isEmpty(changedFields))) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(props.floor ? changedFields : floor);
                return req;
            };

            const response = floor.id !== undefined ? await client.apis["building"].building_floor_partial_update({building_uuid: _buildingGuid, floor_uuid: floor.id,}) : await client.apis["building"].building_floor_create({building_uuid: _buildingGuid});
            
            if (response.status >= 200 && response.status < 300) {
                addToast(t("floor"), floor.id ? t("floorPatch") : t("floorPost"), "success");
                onClose();
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;
            addToast(t('floor'), t(statusCode === 409 ? 'floorSNConflict' : 'networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client ,_buildingGuid, login.Authorization, floor, onClose, addToast, t])

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setFloor(prevState => ({
            ...prevState,
            [id]: value
        }))
    }, [])

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='mb-3'>
            <Form.Label>{t('floorNumber')}</Form.Label>
            <Form.Control id='level' required type='number' pattern='[0-9]*' value={floor.hasOwnProperty('level') ? (floor['level']) : ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>
        <Form.Group className='mb-3'>
            <Form.Label>{t('name')}</Form.Label>
            <Form.Control id='name' required type='text' value={floor['name'] || ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>{t("height")} (m)</Form.Label>
            <Form.Control id="height" required step="0.05" type="number" value={floor['height'] || ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>
        <Button variant="outline-primary" className='w-100' type='submit'>{t('id' in floor ? 'edit' : 'add')}</Button>
    </Form>
}

export default AddEditLevel