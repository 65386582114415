import PieChart from "../charts/Piechart"
import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../Context';
import { useSwagger } from '../context/SwaggerContext';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from "../components/Card";

const BuildingPiechart = ({types}) => {
    const { login } = useAuth();
    const client = useSwagger();
    const _buildingGuid = useParams()["buildingGuid"];
    const { t } = useTranslation();

    const [sunburstData, setSunburstData] = useState(undefined)
    const [piechartData, setPiechartData] = useState(undefined)
    const [calculation, setCalculation] = useState(undefined)
    const [isTypesLoaded, setIsTypesLoaded] = useState(false);

    // Funktion zum Gruppieren und Summieren der transmission_losses
    const groupAndSumByTypeWithLabels = (array) => {
        const grouped = array.reduce((acc, obj) => {
            const { type, transmission_losses } = obj;
            // Überprüfen, ob der Typ bereits existiert
            if (!acc[type]) {
                acc[type] = { type: type, transmission_losses: 0 };
            }
            // Summieren der transmission_losses
            acc[type].transmission_losses += transmission_losses;
            return acc;
        }, {});

        // Zu einem Array umwandeln und Labels hinzufügen
        return Object.values(grouped).map(item => {
            const labelObj = types?.find(label => label.value === item.type);
            return {
                ...item,
                label: labelObj ? labelObj.label : t('unknown')
            };
        });
    };

    const createDataStructure = (data) => {
        if (!data) return
        const dataStructureSunburst = [{
            name: "root", 
            children: data?.floor?.map(floor => ({
                name: floor.name,
                heating_load: Number((floor.heating_load / 1000).toFixed(3)),
                children: floor?.room?.map(room => ({
                    name: room.name,
                    heating_load: Number((room.heating_load / 1000).toFixed(3)),
                    children: [
                        {
                        name: t('din276Main.long.ventilationLoss'), 
                        value: Number((room?.ventilation_losses / 1000).toFixed(3)),
                        },
                        {
                            name: t('transmissionLoss'), 
                            transmission_losses: Number((room?.transmission_losses / 1000).toFixed(3)),
                            children: room?.buildingparts?.map(bp => ({
                                name: bp?.name,
                                value: Number((bp?.transmission_losses / 1000).toFixed(3)),
                            }))
                        }
                    ],
                }))
            }))
        }];

        setSunburstData(dataStructureSunburst)
    }

    const createBuildingPartDataStructure = (data) => {
        if (!data) return
        const groupedArrayWithLabels = groupAndSumByTypeWithLabels(data)

        const dataStructurePiechart = [{
            name: "root", 
            children: groupedArrayWithLabels?.map(bp => ({
                name: `${t(bp?.label)}`,
                value: Number((bp?.transmission_losses / 1000).toFixed(3)),
            }))
        }];
        setPiechartData(dataStructurePiechart)
    }
    
    const getBuildingparts = useCallback(async() => {
        if (!client) return;
    
        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["building"].building_buildingpart_piechart_retrieve({building_uuid: _buildingGuid})
    
            if (response.status === 204) {
                createBuildingPartDataStructure([])
                return;
            }
    
            if (response.status >= 200 && response.status < 300) {
                createBuildingPartDataStructure(response.obj?.buildingparts);
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, types, login.Authorization]);

    const getAllData = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_piechart_retrieve({building_uuid: _buildingGuid})

            if (response.status === 204) {
                createDataStructure(undefined)
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                response?.obj?.floor?.map((f) => f?.heating_load === 0 ? setCalculation(false) : setCalculation(true))
                createDataStructure(response.obj)
            }
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization]);

    useEffect(() => {
        if (isTypesLoaded) {
            getAllData()
            getBuildingparts()
        }
    }, [getAllData, getBuildingparts, isTypesLoaded])

    useEffect(() => {
        if (types && types.length > 0) {
            setIsTypesLoaded(true);
        }
    }, [types]);

    return (
        <>
            {calculation ? (
                <div className="chart-container">
                    <div className="chart-item">
                        <PieChart {...{data: sunburstData, pills: true, element: "sunburstChart", heading: <span>{t('overview')}:<br/>{t('heatingLoadPerFloor')}</span>}} />
                    </div>

                    {piechartData &&
                        <div className="chart-item">
                            <PieChart {...{data: piechartData, pills: false, element: "pieChart", heading: <span>{t('overview')}<br/>{t('transmissionLoss')}</span>}} /> 
                        </div>
                    }
                </div>
            ) : (
                <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                    <div style={{ width: "100%", height: "500px" }}>
                        <p>{t('noDataAvailable')}</p>
                    </div>
                </Card>
            )}
        </>
    )
}

export default BuildingPiechart