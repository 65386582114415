import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useToast } from "../NotificationsContent";
import { useSwagger } from "../context/SwaggerContext";
import { getChangedFields, isEmpty } from "../util/helpers";

export const AddUnit = ({ unit, onClose, loadUtilizationUnits }) => {
    let initialData = {
        name: unit?.name || '',
        area: unit?.area || 65,
        entrance_level: unit?.entrance_level?.id || ''
    }

    // const [name, setName] = useState(unit?.name || '');
    // const [entranceLevel, setEntrenceLevel] = useState(unit?.entrance_level?.id || '');
    // const [area, setArea] = useState(unit?.area || 65);
    const [unitState, setUnit] = useState(initialData);
    const [floor, setFloor] = useState([]);
    const { login } = useAuth();
    const _buildingGuid = useParams()["buildingGuid"];
    const { t } = useTranslation();
    const { addToast } = useToast();
    const client = useSwagger();

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setUnit(prevState => ({
            ...prevState,
            [id]: value
        }))
    }, [])

    const loadFloors = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/floor/`, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t('networkError'));
        //     if (response.status === 204) return [];
        //     return response.json();
        // })
        // .then((data) => {
        //     setFloor(data);
        //     if (entranceLevel === '') data && data[0] && setEntrenceLevel(data[0].id);
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].getFloors({building_uuid: _buildingGuid});

            if (response.status >= 200 && response.status < 300) {
                setFloor([])
                if (response.status === 204) return
                setFloor(response.obj);
                if (unitState.entrance_level === "") response.obj && response.obj[0] && setUnit(prev => ({...prev, entrance_level: response.obj[0].id}));
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, unitState]);

    const submit = useCallback(async(e) => {
        e.preventDefault();

        const changedFields = getChangedFields(unitState, initialData);
        if (isEmpty(changedFields)) onClose()
        if (!client || isEmpty(changedFields)) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(unit ? changedFields : unitState);
                return req;
            };
    
            const response = unit ? await client.apis["building"].building_unit_partial_update({building_uuid: _buildingGuid, unit_uuid: unit.id}) : await client.apis["building"].building_unit_create({building_uuid: _buildingGuid});

            if (response.status === 204) return
    
            if (response.status >= 200 && response.status < 300) {
                addToast(t('usageUnits'), t('unitPost'), "success");
                loadUtilizationUnits();
                onClose();
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, unit, unitState, onClose, addToast, loadUtilizationUnits]);

    useEffect(() => {
        loadFloors();
    }, [loadFloors]);

    return (<>
        {floor && floor.length > 0 ? <Form onSubmit={(e) => submit(e)}>
            <Form.Group className="mb-3" controlId="name">
                <Form.Label>{t('name')}</Form.Label>
                <Form.Control type="text" value={unitState.name} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="entrance_level">
                <Form.Label>{t('entranceLevel')}</Form.Label>
                    <Form.Select value={unitState.entrance_level} onChange={(e) => changeHandler(e)}>
                    {floor?.map(f => <option key={`key-${f.id}`} value={f.id}>{f.name}</option>)}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="area">
                <Form.Label>{t('area')}</Form.Label>
                <Form.Control type="number" value={unitState.area} onChange={(e) => changeHandler(e)} />
            </Form.Group>

            <div className="d-flex justify-content-end">
                <Button variant="outline-primary" disabled={floor && floor.length==0} type="submit">{unit ? t('edit') : t('add')}</Button>
            </div>
        </Form>: <><p/><p>{t('unitMessage')}</p></>}</>
    );
};
