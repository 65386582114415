import React, { useCallback, useEffect, useState } from 'react'
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import Document from '../components/Document';
import { getChangedFields, isEmpty } from '../util/helpers';

const AddEditDocument = ({subsidy, onClose, file, getAllFiles, setFoundItem, show}) => {
    const edit = show === "addDocument" ? undefined : file 
    const task = show === "addTask" || edit?.supplier

    const subsidyID = subsidy?.id

    const client = useSwagger();
    const { login } = useAuth();
    const { addToast } = useToast();
    const { t } = useTranslation();
    const _buildingGuid = useParams()['buildingGuid'];

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(edit ? true : undefined);

    const initialInfo = edit ? 
        {
            title: edit?.title,
            type: "",
            description: edit?.description,
            required: edit?.required,
            notify: edit?.notify_supplier,
            supplier: edit?.supplier 
 
        } : {
            title: "",
            type: "",
            description: "",
            required: false,
            notify: false,
            supplier: ""
        }

    // const [info, setInfo] = useState({
    //     title: edit?.title || "",
    //     type: "",
    //     description: edit?.description || "",
    //     required: edit?.required || false,
    //     notify: edit?.notify_supplier || false
    // })

    const [info, setInfo] = useState(initialInfo)

    const [members, setMembers] = useState([])

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setInfo(prevState => ({
            ...prevState,
            [id]: value
        }));
    }, []);

    const changeChecked = useCallback((e) => {
        const { id, checked } = e.target;
        setInfo(prevState => ({
            ...prevState,
            [id]: checked
        }));
    }, []);

    const getOrgMembers = useCallback(async() => {
        if (!client || !task) return;
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "multipart/form-data";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
  
            // const response = await client.apis["org"].getOrgUsers({ org_uuid: login.currentOrganisation.id })
            const response = await client.apis["org"].org_user_list({ org_uuid: login.currentOrganisation.id })

            if (response.status >= 200 && response.status < 300) {
                // setMembers(response.obj.users)
                setMembers(response.obj)

                // if (response.obj.users.length > 0) {
                if (response.obj.length > 0) {
                    setInfo(prevState => ({
                        ...prevState,
                        // supplier: edit ? edit.supplier : response.obj.users[0].id
                        supplier: edit ? edit.supplier : response.obj[0].id
                    }));
                }
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        } 
    }, [client, login.Authorization, login.currentOrganisation.id, task]);

    const onSubmit = useCallback(async(e) => {
        e && e.preventDefault();

        if (!client || isSubmitting) return;

        setIsSubmitting(true);

        const originalRequestInterceptor = client.http.requestInterceptor;

        const formData = new FormData();
        (!edit && !task) && selectedFile.forEach((file, index) => {
            formData.append(`file_${index}`, file); // Füge jede Datei einzeln hinzu
        });

        // let taskInfo;
        const changedFields = getChangedFields(info, initialInfo);

        if (!task) {
            edit ? (changedFields.title && formData.append('title', changedFields.title)) : formData.append('title', info.title);
            edit ? (changedFields.type && formData.append('type', changedFields.type)) : formData.append('type', info.type);
            edit ? ((changedFields.description || changedFields.description === "") && formData.append('description', changedFields.description)) : formData.append('description', info.description);

            // formData.append('title', info.title);
            // formData.append('type', info.type);
            // formData.append('description', info.description);
        } else {
            edit ? (changedFields.supplier && formData.append('supplier', changedFields.supplier)) : formData.append('supplier', info.supplier);
            edit ? (changedFields.hasOwnProperty('notify') && formData.append('notify_supplier', changedFields.notify)) : formData.append('notify_supplier', info.notify);
            edit ? (changedFields.hasOwnProperty('required') && formData.append('required', changedFields.required)) : formData.append('required', info.required);

            // formData.append('supplier', info.supplier);
            // formData.append('notify_supplier', info.notify);
            // formData.append('required', info.required);

            // taskInfo = { title: null, supplier: info.supplier, notify: info.notify, required: info.required }
        }
        if (edit !== undefined && isEmpty(changedFields)) {
            onClose() 
            return
        }

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "multipart/form-data";
                req.headers["Authorization"] = login.Authorization;
                // req.body =  task ? JSON.stringify(taskInfo) : formData;
                req.body = formData;
                return req;
            };
  
            const response = subsidyID ? ( edit === undefined ? await client.apis["building"].building_action_file_create({ building_uuid: _buildingGuid, action_uuid: subsidyID }) : await client.apis["building"].building_action_file_edit_partial_update({ building_uuid: _buildingGuid, action_uuid: subsidyID, file_uuid: edit.id }) ) : (edit === undefined ? await client.apis["building"].building_file_create({ building_uuid: _buildingGuid }) :  await client.apis["building"].building_file_edit_partial_update({ building_uuid: _buildingGuid, file_uuid: edit.id }));

            if (response.status >= 200 && response.status < 300) {
                onClose();
                addToast(t("success"), edit ? t("editedDocument") : ( task ? t("fileRequestSuccess") : t("postDocument")), "success");
                setSelectedFile(undefined)
                setFoundItem && setFoundItem(undefined)
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), task ? t("networkError") : t("failedFileUpload"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            edit && getAllFiles()
            setIsSubmitting(false);
        }
    }, [client, isSubmitting, edit, selectedFile, info, subsidyID, _buildingGuid, login.Authorization, onClose, addToast, t, getAllFiles, task]);

    useEffect(() => {
        getOrgMembers()
    }, [])

    const get_task_menu = () => {
        return (
            <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className="mb-3">
                    <Form.Label>{t('supplier')}</Form.Label>
                    <Form.Select
                    id="supplier"
                    value={info["supplier"] || ""}
                    onChange={(e) => changeHandler(e)} 
                    >
                    {members.map((member) => (
                        <option key={`member-key-${member.id}`} value={member.id}>
                        {member.name} {member.prename}
                        </option>
                    ))}

                    {members?.length === 0 && 
                        <option key={`member-key-none`} value="">
                            {t("noSelection")}
                        </option>
                    }
                    </Form.Select>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>{t('notify')}?</Form.Label>
                    <Form.Check
                        type="switch"
                        id="notify"
                        checked={info['notify'] || false}
                        onChange={(e) => changeChecked(e)} />
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>{t('required')}?</Form.Label>
                    <Form.Check
                        type="switch"
                        id="required"
                        checked={info['required'] || false}
                        onChange={(e) => changeChecked(e)} />
                </Form.Group>

                <Button variant="outline-primary" className="w-100" type="submit">
                    {t('confirm')}
                </Button>
            </Form>
        );
    };

    return (
        <div>
            {task ? (
                get_task_menu()
            ) : (
                <>
                    <Document {...{ setInfo, info, setSelectedFile, selectedFile, edit }}/>
                    <div>
                        <Button variant="outline-primary" disabled={isSubmitting || !selectedFile} style={{ display: 'block' }} onClick={() => onSubmit()}>{t('submit')}</Button>
                    </div>
                </>
            )}
        </div>
    )
}

export default AddEditDocument