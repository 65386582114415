import React, { useCallback, useEffect, useState } from 'react'
import MainLayout from '../components/MainLayout';
import Background from "../assets/background_3_3.png";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import Card from "../components/Card";
import ContentModal from "../components/ContentModal";
import SecurityActionModal from "../components/SecurityActionModal";
import AddEditSubsidy from './AddEditAction';
import Collapse from "../components/Collapse";
import AddEditDocument from './AddEditDocument';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faDownload, faEdit, faList, faListCheck, faSearch, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { useToast } from '../NotificationsContent';
import { Button, Form, OverlayTrigger, Table, Tooltip, Placeholder } from "react-bootstrap";
import BuildingDocuments from './BuildingDocuments';
import SearchFile from '../components/SearchFile';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';
import BuildingGanttchart from './BuildingGanttchart';
import { usePermissions } from '../context/PermissionsContext';

export const BuildingActions = () => {
    const _buildingGuid = useParams()['buildingGuid'];
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();

    const [subsidies, setSubsidies] = useState(undefined);
    const [subsidy, setSubsidy] = useState(undefined);
    const [show, setShow] = useState(false);
    const fromSubsidies = true
    const [foundItem, setFoundItem] = useState(undefined)
    const [loading, setLoading] = useState(false)
    let showSearchbarOnBigDevice = true

    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 15;
    const [pageCount, setPageCount] = useState(Math.ceil((subsidies?.length || 0) / pageSize));
    const [subsidyStatus, setSubsidyStatus] = useState([]);
    const [subsidyCashState, setSubsidyCashState] = useState([]);
    const { allowEdit } = usePermissions();

    useEffect(() => {
        setPageCount(Math.ceil((subsidies?.length || 0) / pageSize));
    }, [subsidies, pageSize]);

    const [search, setSearch] = useState(false)

    const mainFunctions = [
        { label: t('add'), onClick: () => { setShow('addSubsidy'); setSubsidy(undefined); }, key: 'add', icon: faAdd, disabled: !allowEdit},
        // wird bei kleinen Bildschirmen ausgeführt
        // { label: t('search'), onClick: () => setShow('searchBar'), key: 'searchFileSmallDevice', icon: faSearch },

        // wird bei größeren Bildschirmen ausgeführt
        { component: <SearchFile {...{ fromSubsidies, setSubsidies, setShow, setFoundItem, setLoading, showSearchbarOnBigDevice, setSearch }} />, key: 'searchFile' }
    ]

    const tableStructure = [
        {
            col: <div className='d-flex' style={{ width: '16px', height: '16px' }}><FontAwesomeIcon icon={faList} size='sm' className='flex-shrink-0' /></div>,
            type: 'icon'
        },
        {
            col: t('purpose'),
            type: 'label'
        },
        {
            col: t('variants'),
            type: 'label'
        },
        {
            col: t('status'),
            type: 'label'
        },
        {
            col: t('funding_sum'),
            type: 'label'
        },
        {
            col: t('created'),
            type: 'label'
        },       
        {
            col: t('actions'),
            type: 'buttons'
        },
    ]

    const getAllActions = useCallback(async () => {
        setSubsidies([]);
        if (!client) return;
        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_action_list({
                building_uuid: _buildingGuid,
            });
            
            if (response.status === 204) {
                setSubsidies([]);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setSubsidies(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const deleteAction = useCallback(async (subsidyId) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_action_destroy({
                building_uuid: _buildingGuid,
                action_uuid: subsidyId
            });

            if (response.status >= 200 && response.status < 300) {
                setSubsidy(undefined);
                setShow(false)
                getAllActions();
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            setSubsidy(undefined);
            setShow(false)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [_buildingGuid, addToast, client, getAllActions, login.Authorization, t])

    const getSubsidyStatus = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["subsidy"].subsidy_status_retrieve();
            
            if (response.status === 204) {
                setSubsidyStatus([]);
                return;
            }
            if (response.status >= 200 && response.status < 300) {
                setSubsidyStatus(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    
    const getSubsidyCashState = useCallback(async () => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["subsidy"].subsidy_cashstate_retrieve();
            
            if (response.status === 204) {
                setSubsidyCashState([]);
                return;
            }
            if (response.status >= 200 && response.status < 300) {
                setSubsidyCashState(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization])

    const onClose = () => {
        setShow(false);
        setSubsidy(undefined);
        getAllActions();
    }

    useEffect(() => {
        getAllActions();
        getSubsidyStatus();
        getSubsidyCashState()
    }, [])


    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">

            {/* <Card {...{ heading: `${t('building_actions')} ${t('for_the')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions, search: true }}>
                <SearchFile {...{ fromSubsidies, setSubsidies, setShow, setFoundItem, setLoading, setSearch }} /> */}
                {(subsidies === undefined) ? (
                    <>
                        <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                            <div style={{ width: "100%", height: "500px" }}>
                                <Placeholder as="div" animation="glow" style={{ width: "100%", height: "100%" }}>
                                    <Placeholder xs={12} style={{ width: "100%", height: "100%" }} />
                                </Placeholder>
                            </div>
                        </Card>
                        <TablePlaceholder {...{ structure: tableStructure }} />
                    </>
                ) : (!Boolean(subsidies.length)) ? (
                    <>
                        <Card {...{ heading: t('overview'), active: true, className: "height-fit mb-3" }}>
                            <div style={{ width: "100%", height: "500px" }}>
                                <p>{t('noDataAvailable')}</p>
                            </div>
                        </Card>
                        <Card {...{ heading: `${t('building_actions')} ${t('for_the')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions, search: true }}>
                            <p className="m-0">{t("noBuildingActions")}</p>
                        </Card>
                    </>
                ) : (<>
                    <BuildingGanttchart {...{ subsidies }}/>
                    <Card {...{ heading: `${t('building_actions')} ${t('for_the')} ${t('building')}: ${login?.currentBuilding?.name}`, mainFunctions, search: true }}>
                        <SearchFile {...{ fromSubsidies, setSubsidies, setShow, setFoundItem, setLoading, setSearch }} />
                        <Table responsive>
                            <thead>
                                <tr>
                                    {tableStructure.map(ts => <th key={`tableCol-${ts.col}`}>
                                        <span>{ts.col}</span>
                                    </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {subsidies.map((subsidyRow) => <SubsidyRow key={`subsidy-key-${subsidyRow.id}`} {...{ subsidyRow, setSubsidy, setShow, foundItem, search, subsidyStatus, subsidyCashState}} />)}
                            </tbody>
                        </Table>
                        <Pagination
                            currentPage={currentPage}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                            pageCount={pageCount}
                        />
                    </Card>
                </>)}
            {/* </Card> */}

            {loading && (
                <Loading/>
            )}

            <ContentModal {...{ show: show === 'addSubsidy' || show === 'editSubsidy', onHide: () => { setShow(false)}, title: (show === 'editSubsidy' ? t('editSubsidy') : t('addSubsidy')), content: <AddEditSubsidy {...{ subsidy, onClose }} /> }} />
            
            {/* only Add Document in subsidy */}
            <ContentModal {...{ show: (show === 'addDocument' || show === 'addTask'), onHide: () => { setShow(false)}, title: (show === 'addDocument' ? t('addDocument') : t('fileRequest')) , content: <AddEditDocument {...{ subsidy, onClose, setFoundItem, show }} /> }} />

            {/* <ContentModal {...{ show: show === 'searchBar', onHide: () => { setShow(false)}, title: t('search'), content: <SearchFile {...{ fromSubsidies, setSubsidies, setShow, setFoundItem, setLoading, showSearchbarOnBigDevice }} /> }} /> */}
            <SecurityActionModal {...{ show: show === "deleteSubsidy", onHide: () => { setSubsidy(undefined); setShow(false) }, title: t('deleteSubsidy'), question: t('deleteSubsidyQuestion'), action: () => deleteAction(subsidy.id) }} />
        </div>
    </MainLayout>
}

const SubsidyRow = ({ subsidyRow, setSubsidy, setShow, foundItem, search, subsidyStatus, subsidyCashState}) => {
    const { t } = useTranslation();
    const [display, setDisplay] = useState(false);
    // const [subsidyRow, setSubsidyRow] = useState(subsidyRowArg)

    const isoString = subsidyRow.created_at

    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('de-DE');
    const formattedTime = date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    const status = subsidyStatus?.length > 0 && subsidyStatus?.filter(item => item.value === subsidyRow.status)[0].label.replace(/ /g, "_").toLowerCase();
    const cashState = subsidyCashState?.length > 0 && subsidyCashState?.filter(item => item.value === subsidyRow.subsidy_cash_state)[0].label.replace(/ /g, "_").toLowerCase();

    const { allowEdit } = usePermissions();

    // subsidyRow.status = subsidyRow.status.replace(/ /g, "_").toLowerCase();
    // subsidyRow.subsidy_cash_state = subsidyRow.subsidy_cash_state.replace(/ /g, "_").toLowerCase();

    return (
        <>
            <tr>
                <td>
                    <Collapse {...{ boolean: display, onClick: () => setDisplay(!display) }} />
                </td>
                <td>
                    <span>{subsidyRow.action}</span>
                </td>
                <td>
                    <span>{subsidyRow.variant_details?.name}</span>
                </td>
                <td>
                    <span>{t(status)}, {t(cashState)}</span>
                </td>
                <td>
                    <span>{Math.max(0,(isNaN(subsidyRow['costs_for_the_customer'])?0:Number(subsidyRow['costs_for_the_customer']))+(isNaN(subsidyRow['requested_funding'])?0:Number(subsidyRow['requested_funding']))).toLocaleString(undefined, { maximumFractionDigits: 2 })} €</span>
                </td>
                <td>
                    <span>{formattedDateTime}</span>
                </td>
                <td>
                    <div className='actions__buttonbar grid__four'>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("addDocument")}</Tooltip>
                            }>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setSubsidy(subsidyRow); setShow("addDocument") }}>
                                <FontAwesomeIcon icon={faAdd} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("addTask")}</Tooltip>
                            }>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setSubsidy(subsidyRow); setShow("addTask") }}>
                                <FontAwesomeIcon icon={faListCheck} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("edit")}</Tooltip>
                            }>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setSubsidy(subsidyRow); setShow("editSubsidy") }}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("delete")}</Tooltip>
                            }>
                            <Button disabled={!allowEdit} variant="outline-primary" className="p-10" onClick={() => { setSubsidy(subsidyRow); setShow("deleteSubsidy") }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>
            {display && (
                <tr>
                    <td colSpan="100%">
                        <Card {...{ heading: t('documents'), active: true, className: "height-fit" }}>
                            <BuildingDocuments {...{ subsidyRow, foundItem, search}}/>
                        </Card>
                    </td>
                </tr>
            )}
        </>
    )
}

export default BuildingActions