import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'

function CookieBanner() {
    const [cookie, setCookie] = useState(localStorage.getItem('sigmaheatCookies') === null ? false : true);
    const { t } = useTranslation();
    const cookieFunction = (boolean) => {
        localStorage.setItem('sigmaheatCookies', boolean);
        setCookie(boolean);
    }

    const toggleScrollbar = (enable) => {
        document.documentElement.style.overflow = enable ? 'unset' : 'hidden';
    }

    useEffect(() => {
        toggleScrollbar(cookie);
    }, [cookie])

    return (
        <>
            {!cookie && <>
                <div className='cookie__backdrop'></div>
                <div className='cookie__wrapper'>
                    <div className='cookie__media__query'>
                        <div className='cookie__icon__wrapper'>
                            <FontAwesomeIcon icon={faCookieBite} />
                        </div>
                        <p className='m-0 cookie__message'>
                            <Trans i18nKey='cookie'>{t('cookieMessage')} <Link to='https://sigmaheat.de/privacy-policy' target='_blank'>{t('here')}</Link> {t('findPrivayPolicy')}</Trans>
                        </p>
                    </div>
                    
                    <button className='cookie__button' onClick={() => cookieFunction(true)}>{t('allow')}</button>
                </div>
            </>}
        </>
    )
}

export default CookieBanner