import React, { useState, Suspense, useRef, useEffect } from "react";
import { useAuth } from "../Context";
import { Navigate } from "react-router";
import { CenterRow, LargeCol, StyledCard } from "../style/StyledContainer";
import { useTranslation } from "react-i18next";
import { faMapMarkerAlt, faPlug } from '@fortawesome/free-solid-svg-icons';
import CustomerSelection from "../widgets/CustomerSelection";
import { Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SHButton } from "../style/SigmaHeatTheme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapContainer, TileLayer, Popup, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Loader } from "../style/Loader/index";
import { Fallback } from "../App";


export default function Temperaturemap(props) {
    // eslint-disable-next-line no-unused-vars
    const {login, doSetLogin} = useAuth();
    const [activeTab, setActiveTab] = useState('DesignTemperature');
    const { t, i18n } = useTranslation();
    if (login == null) return <Navigate to="/login"></Navigate>;

    const title = i18n.language === 'de' ? "Bald finden Sie hier weitere Analysen über den erfassten Gebäudebestand" : "You will soon find further analyzes of the recorded building stock here";
    const firstSentence = i18n.language === 'de' ? "Ab dieser Version befinden sich die Geräte in den Gebäuden." : "Starting with this version, the devices are located in the buildings.";
    const secondSentence = i18n.language === 'de' ? "Um zu den Gebäuden zu gelangen, klicken Sie einfach auf den Button im Karten-Marker oder navigieren Sie sich über das Liegenschaftsmenü zum Gebäude." : "To get to the buildings, simply click on the button in the map marker or navigate to the building via the property menu.";

    return (
        <>
            <CenterRow>
                <CustomerSelection open={parseInt(localStorage.getItem('sigmaheat-customer-selection')) === 0} />
                <LargeCol>
                    <StyledCard title={"DIN-Temperaturen"} buttons={<MapButtonBar {...{ activeTab, setActiveTab }} />}>
                        <Suspense fallback={<Fallback />}><TemperatureMap activeTab={activeTab} /></Suspense>
                    </StyledCard>
                </LargeCol>
            </CenterRow>
        </>
    );
}



const MapButtonBar = (props) => {
    const activeTab = props.activeTab;
    const setActiveTab = props.setActiveTab;

    return <>
        <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
                <Tooltip>{"Auslegungstemperatur"}</Tooltip>
            }>
            <SHButton className={`${activeTab === "DesignTemperature" ? '' : ' secondary'}`} value="design" onClick={() => { setActiveTab("DesignTemperature") }}>
                <span className="d-none d-sm-block">{"Auslegungstemperatur"}</span>
                <FontAwesomeIcon className="d-sm-none" icon={faMapMarkerAlt} size="sm" />
            </SHButton>
        </OverlayTrigger>
        <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
                <Tooltip>{"Mittlere Temperatur"}</Tooltip>
            }>
            <SHButton className={`${activeTab === "MeanTemperature" ? '' : ' secondary'}`} value="mean" onClick={() => { setActiveTab("MeanTemperature") }}>
                <span className="d-none d-sm-block">{"Mittlere Temperatur"}</span>
                <FontAwesomeIcon className="d-sm-none" icon={faPlug} size="sm" />
            </SHButton>
        </OverlayTrigger>
    </>;
}



export const TemperatureMap = (props) => {
    const [mapkey, setMapKey] = useState(Math.random())
    // eslint-disable-next-line no-unused-vars
    const {login, doSetLogin} = useAuth();
    const mapRef = useRef(null);
    const [positions, setPositions] = useState([])
    const [points, setPoints] = useState({})
    const [loading, setLoading] = useState(true)


    //Germany
    const center = [51, 9]

    const load_data = () => {
        fetch('https://tech.sigmaheat.de/static/ambienttemperatures',
        {
            headers:{
                "Authorization":login.Authorization,
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data);
                setPositions(data);
                setLoading(false);
                setMapKey(Math.random());
            });
    }

    useEffect(() => {
        load_data();
    }, [])

    function pickHex(weight) {
        const color1 = [0xFF, 0xFF, 0x0F];
        const color2 = [0xFF, 0x00, 0x00];
        const mincolor = (props.activeTab === "DesignTemperature") ? -15 : 11;
        const maxcolor = (props.activeTab === "DesignTemperature") ? -9 : 6;
        weight = weight - maxcolor
        const diff = maxcolor - mincolor;
        const weights = [(color1[0] - color2[0]) / diff, (color1[1] * 1.0 - color2[1]) / diff, (color1[2] - color2[2]) / diff]

        var rgb = "#"

        weights.forEach(function (value, i) {
            rgb += Math.max(Math.min(Math.round(weights[i] * weight + color1[i]), 255), 0).toString(16).padStart(2, '0')
        });

        //console.log(rgb);
        return rgb;
    }

    console.log(positions.length, Object.keys(points).length)
    return <>
        <Row>
            <div className='position-relative' style={{ zIndex: '999' }}>
                {loading && <div className='loading-layer'><Loader /></div>}
                <MapContainer ref={mapRef} key={mapkey} center={center} zoom={5} scrollWheelZoom={true} className="p-3" style={{ width: "100%", height: "500px" }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {positions.map((p, index) => {
                        //console.log("P", p);
                        return <React.Fragment key={`circlemarker-${index}`}>
                            <CircleMarker center={[p.lat, p.long]} pathOptions={
                                {
                                    color: pickHex((props.activeTab === "DesignTemperature") ? p.design_temperature : p.avg_ambient_temperature),
                                    fillColor: pickHex((props.activeTab === "DesignTemperature") ? p.design_temperature : p.avg_ambient_temperature),
                                    fillOpacity:1,
                                }} radius={4}>
                                <Popup>{p.zip + " " + p.name}
                                    <br></br>{"Auslegungstemperatur: " + p.design_temperature + " °C"}
                                    <br></br>{"Mittlere Temperatur: " + p.avg_ambient_temperature + " °C"}
                                </Popup>
                            </CircleMarker>
                        </React.Fragment>
                    }
                    )}
                    {/*positions.map(p => <Rectangle bounds={p.bounds} pathOptions={{ fillColor: 'black' }} />)*/}
                </MapContainer>
            </div>
        </Row>
    </>
}