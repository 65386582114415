import React, { useCallback, useEffect, useState, useRef } from "react"
import { useAuth } from "../Context"
import { useTranslation } from "react-i18next";
import { useToast } from "../NotificationsContent";
import Card from "../components/Card";
import { Accordion, Alert, Button, Form, Tab, Tabs } from "react-bootstrap";
import QRCode from 'qrcode'
import BG from "../assets/background_3_0.png"
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useSwagger } from "../context/SwaggerContext";

const Activate2FA = ({ submit }) => {
    const { login } = useAuth()
    const { t } = useTranslation();

    return <div>
        <p className="m-0">{t("twoFA.activate2FA")}</p>
        <ul className="bullet__list">
            {login?.["Organisations"].map(o => <li key={`${Math.random()}`}>{o}</li>)}
        </ul>
        <Button variant="outline-primary" className="w-100" onClick={() => submit()}>{t("enable")}</Button>
    </div>
}

export const QRCodeScan = ({ otp, setStep }) => {

    useEffect(() => {
        QRCode.toCanvas(document.getElementById('canvas'), otp["URI"])
    }, [otp])

    return <>
        <p className="mb-3">{t("twoFA.authenticator")}</p>
        <div className="d-flex justify-content-center">
            <canvas id="canvas" className="mb-3" />
        </div>

        <Accordion className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header><h6 className="m-0">{t("twoFA.manual")}</h6></Accordion.Header>
                <Accordion.Body>
                    <input type="text" className="form-control" value={otp.secret_code} readOnly />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        
        <p className="mb-3">{t("twoFA.recoveryCodes")}</p>
        <textarea className="form-control mb-3" rows="10" readOnly value={otp.backup_code.join('\n')} />
        <Button variant="outline-primary" className="w-100" onClick={() => setStep(2)}>{t("continue")}</Button>
    </>
}

const TwoFactor = () => {
    const { login, doSetLogin } = useAuth()
    const { t } = useTranslation()
    const [otpAlert, setOtpAlert] = useState("");
    const [codeAlert, setCodeAlert] = useState("");
    const [step, setStep] = useState(2);
    const [otp, setOtp] = useState({
        URI: "",
        backup_code: []
    })
    const [oneTimeCode, setOneTimeCode] = useState(['', '', '', '', '', '']);
    const [recoveryCodes, setRecoveryCodes] = useState(["", "", "", "", "", "", "", "", "", ""]);
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];
    const navigate = useNavigate();
    const client = useSwagger();
    const { addToast } = useToast()

    const submit = async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["User"].post2FA();

            if (response.status >= 200 && response.status < 300) {
                setOtp(response.obj)
                setStep(1)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast("2FA", t("networkError"), "error");
            setTimeout(() => console.clear());
            client.http.requestInterceptor = originalRequestInterceptor;
        }

        // fetch('https://tech.sigmaheat.de/2fa/enable', {
        //     method: 'POST',
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // }).then(response => {
        //     if (!response.ok) throw new Error(t("networkError"));
        //     return response.json();
        // }).then((data) => {
        //     setOtp(data)
        //     setStep(1)
        // }).catch((error) => {
        //     addToast("2FA", error.message, "error");
        //     setTimeout(() => console.clear());
        // });
    };

    const userObj = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["User"].getUserObjects();

            if (response.status >= 200 && response.status < 300) {
                if (!login["Setup"]) addToast("2FA", t("twoFASuccess"), "success");
                if (!response.obj.hasOwnProperty("language_code")) response.obj["language_code"] = "en";
                doSetLogin(response.obj);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast("2FA", error.message, "error");
            setTimeout(() => console.clear());
            client.http.requestInterceptor = originalRequestInterceptor;
        }

        // fetch('https://tech.sigmaheat.de/user/object', {
        //     method: 'GET',
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // }).then(response => {
        //     if (!response.ok) throw new Error(t("networkError"));
        //     return response.json()
        // }).then(data => {
        //     if (!login["Setup"]) addToast("2FA", t("twoFASuccess"), "success");
        //     if (!data.hasOwnProperty('language_code')) data['language_code'] = "en"
        //     doSetLogin(data)
        // }).catch((error) => {
        //     addToast("2FA", error.message, "error");
        //     setTimeout(() => console.clear());
        // });
    }, [login.Authorization, addToast])

    const setAlertsBasedOnMethod = (method, isError) => {
        if (method === "otp") {
            setOtpAlert(oneTimeCode.some((str) => str === "") ? t("fillOTP") : t("incorrectOTP"));
        } else if (method === "recovery") {
            setCodeAlert(recoveryCodes.some((str) => str === "") ? t("fillCode") : t("incorrectCode"));
        }
    };

    const verifyOTP = useCallback(async(e, method) => {
        e.preventDefault()
        let obj = {};

        if (method === "otp") obj = { otp: oneTimeCode.join('') }
        if (method === "recovery") obj = { backup_codes: recoveryCodes }

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(obj);
                return req;
            };

            const response = await client.apis["User"].postVerify2FA();

            if (response.status >= 200 && response.status < 300) userObj();

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            const statusCode = error.response?.status;

            if (statusCode === 401) {
                doSetLogin(null);
                localStorage.setItem("logout", true);
                navigate("/login");
                addToast("Two-Factor Authentication", t("fiveMinLogTime"), "error");
            } else if (statusCode === 406) {
                addToast("Two-Factor Authentication", "ERROR", "error");
            } else if (method === "recovery" && !login["Setup"]) {
                addToast("Two-Factor Authentication", t("firstTimeSetup"), "error");
            } else {
                addToast("Two-Factor Authentication", t("networkError"), "error");
                setAlertsBasedOnMethod(method, false);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        }

        // fetch('https://tech.sigmaheat.de/2fa/verify', {
        //     method: 'POST',
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify(obj)
        // }).then(response => {
        //     if (response.status === 401) {
        //         doSetLogin(null)
        //         navigate('/login')
        //         throw new Error(t('fiveMinLogTime'));
        //     }
        //     if (method === "recovery" && !login["Setup"]) throw new Error(t("firstTimeSetup"));
        //     if (response.status === 406) throw new Error("ERROR");
        //     if (!response.ok) throw new Error(t("networkError"));
        //     userObj();
        // }).catch((error) => {
        //     if (error.message !== "ERROR") addToast("Two-Factor Authentication", error.message, "error");
        //     if (method === "otp") setOtpAlert(oneTimeCode.some(str => str === '') ? t("fillOTP") : t("incorrectOTP"))
        //     if (method === "recovery") setCodeAlert(recoveryCodes.some(str => str === '') ? t("fillCode") : t("incorrectCode"))
        //     setTimeout(() => console.clear());
        // });
    }, [login.Authorization, oneTimeCode, recoveryCodes, addToast, t, client])

    const handleInputChange = useCallback((index, e) => {
        let value = e.target.value;
        if (!value.match(/^\d+$/)) return

        if (value.length === 1) {
            const newCode = [...oneTimeCode];
            newCode[index] = value;
            setOneTimeCode(newCode);
            if (index < inputRefs.length - 1) {
                inputRefs[index + 1].current.focus();
                inputRefs[index + 1].current.select();
            }
        }
    }, [oneTimeCode])

    const handleBackspace = useCallback((index, e) => {
        if (e.keyCode === 8 && index >= 0) {
            const oldValue = oneTimeCode[index]
            const newCode = [...oneTimeCode];
            newCode[index] = '';
            setOneTimeCode(newCode);

            if (index === 0 || oldValue !== "") return
            inputRefs[index - 1].current.focus();
            inputRefs[index - 1].current.select();
        }
    }, [oneTimeCode])

    const handleFocus = (index, e) => {
        e.preventDefault();
        inputRefs[index].current.select();
    };

    const handleRecoveryCodes = (e, index) => {
        let value;
        if (e.clipboardData && e.clipboardData.getData) {
            value = e.clipboardData.getData('text/plain');
        } else if (e.target.value) {
            value = e.target.value;
        }

        const newCode = [...recoveryCodes];
        newCode[index] = value;
        setRecoveryCodes(newCode);
    };

    // const mainFunctions = [
    //     {label: t("logout"), onClick: () => doSetLogin(null), key: 'logout', icon: faRightFromBracket}
    // ]

    const mainFunctions = [{ label: t("logout"), onClick: () => { doSetLogin(null); localStorage.setItem("logout", true); }, key: 'logout', icon: faRightFromBracket }];


    useEffect(() => {
        setStep(login["Setup"] ? 2 : 0)
    }, [login])


    return <div className='main__wrapper'>
        <div className="main__inner align-items-center">
            <Card {...{ heading: "Two-Factor Authentication", className: "w-512 height-fit", mainFunctions: step === 2 ? mainFunctions : undefined }}>
                {step === 0 ? (
                    <Activate2FA {...{ submit: () => submit() }} />
                ) : (step === 1) ? (
                    <QRCodeScan {...{ otp, setStep: (no) => setStep(no) }} />
                ) : (
                    <Tabs defaultActiveKey="otp">
                        <Tab eventKey="otp" title="OTP">
                            {otpAlert && <Alert variant="danger" className="mt-3">{otpAlert}</Alert>}
                            <Form onSubmit={(e) => verifyOTP(e, "otp")}>
                                <Form.Group className="my-3">
                                    <Form.Label>{t("twoFA.sixDigit")}</Form.Label>
                                    <div className="d-flex justify-content-between gap-3">
                                        {inputRefs.map((ref, index) => (
                                            <Form.Control
                                                type='text'
                                                key={index}
                                                ref={ref}
                                                value={oneTimeCode[index]}
                                                onInput={(e) => handleInputChange(index, e)}
                                                onKeyDown={(e) => handleBackspace(index, e)}
                                                onFocus={(e) => handleFocus(index, e)}
                                                onMouseUp={(e) => handleFocus(index, e)}
                                                maxLength="1"
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                                <Button variant="outline-primary" className="w-100" type="submit">{t("verify")}</Button>
                            </Form>
                        </Tab>
                        {login["Setup"] && <Tab eventKey="recovery" title="Recovery Codes">
                            {codeAlert && <Alert variant="danger" className="mt-3">{codeAlert}</Alert>}
                            <Form onSubmit={(e) => verifyOTP(e, "recovery")}>
                                <Form.Group className="my-3">
                                    <Form.Label>{t("twoFA.recoveryUsage")}</Form.Label>
                                    <div className="d-flex justify-content-between gap-3 mb-3">
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[0]}
                                            onChange={(e) => handleRecoveryCodes(e, 0)}
                                            onPaste={(e) => handleRecoveryCodes(e, 0)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[1]}
                                            onChange={(e) => handleRecoveryCodes(e, 1)}
                                            onPaste={(e) => handleRecoveryCodes(e, 1)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[2]}
                                            onChange={(e) => handleRecoveryCodes(e, 2)}
                                            onPaste={(e) => handleRecoveryCodes(e, 2)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[3]}
                                            onChange={(e) => handleRecoveryCodes(e, 3)}
                                            onPaste={(e) => handleRecoveryCodes(e, 3)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[4]}
                                            onChange={(e) => handleRecoveryCodes(e, 4)}
                                            onPaste={(e) => handleRecoveryCodes(e, 4)}
                                            maxLength="10"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between gap-3">
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[5]}
                                            onChange={(e) => handleRecoveryCodes(e, 5)}
                                            onPaste={(e) => handleRecoveryCodes(e, 5)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[6]}
                                            onChange={(e) => handleRecoveryCodes(e, 6)}
                                            onPaste={(e) => handleRecoveryCodes(e, 6)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[7]}
                                            onChange={(e) => handleRecoveryCodes(e, 7)}
                                            onPaste={(e) => handleRecoveryCodes(e, 7)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[8]}
                                            onChange={(e) => handleRecoveryCodes(e, 8)}
                                            onPaste={(e) => handleRecoveryCodes(e, 8)}
                                            maxLength="10"
                                        />
                                        <Form.Control
                                            type='text'
                                            value={recoveryCodes[9]}
                                            onChange={(e) => handleRecoveryCodes(e, 9)}
                                            onPaste={(e) => handleRecoveryCodes(e, 9)}
                                            maxLength="10"
                                        />
                                    </div>
                                </Form.Group>
                                <Button variant="outline-primary" className="w-100" type="submit">{t("verify")}</Button>
                            </Form>
                        </Tab>}
                    </Tabs>
                )}
            </Card>
        </div>
        <div className="main__background" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.4)), url(${BG})` }}></div>
    </div>
}

export default TwoFactor;