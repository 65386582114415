import React, { useEffect, useRef, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import { useAuth } from '../Context';
import Card from "../components/Card"
const LICENCE = process.env.REACT_APP_AMCHART5;


const Ganttchart = ({data, categories, element, heading}) => {
    const { login } = useAuth();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true)
    const rootRef = useRef(null);
    const seriesRef = useRef(null);

    function createChart(div, rootRef, seriesRef, data) {
        am5.addLicense(LICENCE)

        if (document.getElementById(div) === null) return

        // Zerstöre die vorherige Instanz, wenn sie existiert
        if (rootRef.current) {
            rootRef.current.dispose();
        }

        let root = am5.Root.new(div);
        root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX", "openValueX"]
        });

        rootRef.current = root;

        seriesRef.current = [];

        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        // Add legend
        let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
        }))
          
        // var colors = chart.get("colors");

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        
        var yRenderer = am5xy.AxisRendererY.new(root, {
            minorGridEnabled: true
        });
        yRenderer.grid.template.set("location", 1);
        
        var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        // yAxis.data.setAll([
        //     { category: t('subsidies') },
        //     // { category: "Module #2" },
        //     // { category: "Module #3" },
        //     // { category: "Module #4" },
        //     // { category: "Module #5" }
        // ]);

        yAxis.data.setAll(categories);
          
        var xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: "minute", count: 1 },
            renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
                minorGridEnabled: true,
                minGridDistance: 80
            })
        }));

        xAxis.get("renderer").labels.template.adapters.add("fill", () => { 
            return am5.color(0xffd981);
        });

        yAxis.get("renderer").labels.template.adapters.add("fill", () => { 
            return am5.color(0xffd981);
        });
          
        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            openValueXField: "start",
            valueXField: "end",
            categoryYField: "category",
            sequencedInterpolation: true,
        }));
        
        series.columns.template.setAll({
            templateField: "columnSettings",
            strokeOpacity: 0,
            tooltipText: "{task}:\n[bold]{openValueX}[/] - [bold]{valueX}[/]"
        });
        
        series.data.setAll(data);
        
        // Add scrollbars
        chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

        series.appear();
        chart.appear(1000, 100);

    };

    useEffect(() => {
        if (data) {
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        if (!loading) {
            createChart(element, rootRef, seriesRef, data);
        }
    }, [loading, setLoading]);

    // Effect for updating chart data
    useEffect(() => {
        if (!loading && data && seriesRef.current?.length > 0) {
            seriesRef.current[0]?.data.setAll(data);
            seriesRef.current[0]?.selectDataItem(seriesRef.current[0]?.dataItems[0]);
        }
    }, [data, loading]);

    return (
        <>
            <div>
                <Card {...{ heading: heading, active: true, className: "height-fit mb-3" }}>
                    <div id={element} className="chart-div"/>
                </Card>
            </div>
        </>
    )
}

export default Ganttchart