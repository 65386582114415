
class Transform {
    constructor(ctx, s, dx, dy) {
        this.ctx = ctx;
        this.s = s;
        this.dx = dx;
        this.dy = dy;
        this.minScale = 0.1;
        this.maxScale = 5;
    }

    getTransform() {
        return {
            s: this.s,
            dx: this.dx,
            dy: this.dy
        }
    }
    
    scale(s) {
        const newScale = this.s * (1 / s);

        if (newScale < this.minScale) {
            s = 1 / (this.s / this.minScale);
        } else if (newScale > this.maxScale) {
            s = 1 / (this.s / this.maxScale);
        }

        this.ctx.scale(s, s);
        this.s *= 1 / s;
        this.dx *= 1 / s;
        this.dy *= 1 / s;
    }
    
    translate(dx, dy) {
        this.ctx.translate(dx, dy);
        this.dx -= dx;
        this.dy -= dy;
    }
    
    transform({ x, y }) {
        return {
            x: this.s * x + this.dx,
            y: this.s * y + this.dy
        };
    }

    reset() {
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.s = 1;
        this.dx = 0;
        this.dy = 0;
    }

    apply(affine) {
        this.ctx.setTransform(affine.matrix);
        this.s = affine.transform.s;
        this.dx = affine.transform.dx;
        this.dy = affine.transform.dy;
    }
}

export default Transform