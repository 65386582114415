import React, { useState, useEffect, useCallback } from "react";
// import { useParams } from "react-router-dom";
// import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { Loader } from "../style/Loader";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import Card from "../components/Card";



export default function BuildingReports() {
    // const _buildingGuid = useParams()['buildingGuid'];
    // const { login } = useAuth();
    const { t } = useTranslation();
    const [reports, setReports] = useState(undefined);

    const loadReports = useCallback(() => {
        // fetch(`https://tech.sigmaheat.de/building/` + _buildingGuid + `/reports`, {
        //     headers: {
        //         'Authorization': login.Authorization
        //     }
        // })
        //     .then((response) => (response.status >= 200 && response.status < 300) ? response.json() : [])
        //     .then((data) => {
        //         console.log(data)
        //         setReports(data);
        //     })
        setReports([]);
    }, [])

    useEffect(() => {
        loadReports();
    }, [loadReports]);

    return <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card {...{ heading: t('energy_reports') }}>
                {!reports ? <Loader /> :
                    (reports?.length === 0) ? <p>{t('no_energy_report')}</p> :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>{t('domain')}</span>
                                    </th>
                                    <th>
                                        <span>{t('name')}</span>
                                    </th>
                                    <th>
                                        <span>{t('mail')}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {reports?.map(c => {
                                    return <>
                                        <tr>
                                            <td>
                                                {c.topic}
                                            </td>
                                            <td>
                                                {c.contact.prename + " " + c.contact.name}
                                            </td>
                                            <td>
                                                <td><a href={"mailto:" + c.contact.mail}>{c.contact.mail}</a></td>
                                            </td>
                                        </tr>
                                        {c.contact.telephone?.length > 0 ?
                                            <tr style={{ borderBottom: "1px dashed black" }}>
                                                <td colSpan="100%">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>
                                                                    <span>{t('telephone')}</span>
                                                                </th>
                                                                <th>
                                                                    <span>{t('comment')}</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {c.contact.telephone.map(t => <tr>
                                                                <td></td>
                                                                <td><a href={"tel:" + t.number}>{t.number}</a></td>
                                                                <td>{t.comment}</td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table></td></tr>
                                            : <></>}
                                    </>
                                })
                                }
                            </tbody>
                        </table>
                }
            </Card>
        </div>
    </MainLayout>
}
