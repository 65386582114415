import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Nav, Tab, Tabs } from "react-bootstrap";
import { useToast } from '../NotificationsContent';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../Context';
import { useSwagger } from '../context/SwaggerContext';
import { useParams } from 'react-router-dom';
import { getChangedFields, isEmpty } from "../util/helpers";

const AddEditCounter = ({editCounter, variantID, onClose, buildingID}) => {
    const _buildingGuid = useParams()['buildingGuid'] || buildingID;
    const client = useSwagger();
    const { login } = useAuth();
    const { t } = useTranslation();
    const { addToast } = useToast();

    // editCounter.counter_datasource_type === 1000 => Zählerstand, 2000 Verbrauch

    const units = [{value: 1, label: "m³"}, {value: 2, label: "kWh"}, {value: 3, label: "MWh"}];

    const initialCounter = editCounter ? 
        {
            heatpart_name: editCounter.heatpart_name,
            component: editCounter.component,
            is_main_counter: editCounter.is_main_counter,
            counter_datasource_type: editCounter.counter_datasource_type === 1000 ? true : false,
            unit: editCounter?.unit
        } : {
            heatpart_name: '',
            component: '',
            is_main_counter: false,
            counter_datasource_type: true,
            unit: units[0]?.value
        }

    // const [counter, setCounter] = useState({
    //     heatpart_name: editCounter ? editCounter.heatpart_name : '',
    //     component: editCounter ? editCounter.component : '',
    //     is_main_counter: editCounter ? editCounter.is_main_counter : false,
    //     counter_datasource_type: editCounter ? (editCounter.counter_datasource_type === 1000 ? true : false) : true,
    //     unit: editCounter ? editCounter?.unit : units[0]?.value
    // })

    const [counter, setCounter] = useState(initialCounter)

    const [filters, setFilters] = useState({
        cataloges: [],
        heatparts: [],
    });

    const [defaultFiltersValue, setDefaultFiltersValue] = useState({
        catalog: '',
        component: editCounter ? editCounter.component : '',
    })

    const getAllHP = useCallback(async(catalogID) => {
        if (!client || (!catalogID && !defaultFiltersValue?.catalog)) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_typology_list({
                org_uuid: login.currentOrganisation.id,
                heatpart_catalog_uuid: catalogID ? catalogID : defaultFiltersValue.catalog
            });

            if (response.status >= 200 && response.status < 300) {
                let filteredArray = response.obj.filter((item) => item.type === 13)
                // bei edit Counter, da soll die heatpart componente als erste in der Liste stehen
                const sortedHeatparts = filteredArray.sort((a, b) => {
                    if (!editCounter) return 0; // Wenn editCounter false ist, keine Sortierung vornehmen
                    if (a.id === editCounter.component) return -1; // an erster Stelle
                    if (b.id === editCounter.component) return 1;  // nach hinten schieben
                    return 0; // Wenn keine Bedingung zutrifft, keine Änderung in der Reihenfolge
                });

                setFilters(prev => ({ ...prev, heatparts: sortedHeatparts }));
                setDefaultFiltersValue(prev => ({
                    ...prev,
                    component: filteredArray?.[0]?.id,
                }));

                if (!editCounter || editCounter.component_catalog !== catalogID) {
                    setCounter(prev => ({
                        ...prev,
                        component: filteredArray?.[0]?.id,
                    }));
                }
            }

            client.http.requestInterceptor = originalRequestInterceptor;
            
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.currentOrganisation.id, login.Authorization, defaultFiltersValue, addToast, t]);

    const getHPCataloges = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["heatpartcatalog"].heatpartcatalog_list({ org_uuid: login.currentOrganisation.id });

            if (response.status >= 200 && response.status < 300) {
                const sortedData = response.obj.sort((a, b) => {
                    if(!editCounter) return
                    
                    if (a.id === editCounter.component_catalog) {
                        return -1; // a soll an erster Stelle stehen
                    } else if (b.id === editCounter.component_catalog) {
                        return 1; // b soll an erster Stelle stehen
                    }
                    return 0; // Keine Änderung bei nicht passenden IDs
                });


                setFilters(prev => ({ ...prev, cataloges: editCounter ? sortedData : response.obj }));
                await getAllHP(editCounter ? editCounter.component_catalog : response.obj?.[0].id)
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [addToast, client, editCounter, getAllHP, login.Authorization, login.currentOrganisation.id, t]);

    const onSubmit = useCallback(async(e) => {

        e.preventDefault();
        
        if(!variantID) {
            addToast(t("error"), t('noActualVariant'), "error");
            return
        }

        if (!client) return;

        let copyCounter = {...counter}

        if (copyCounter.component === "" ) {
            copyCounter.component = defaultFiltersValue.component 
        }

        if (copyCounter.counter_datasource_type) {
            // Zählerstand
            copyCounter.counter_datasource_type = 1000
        } else {
            // Verbrauch
            copyCounter.counter_datasource_type = 2000
        }

        // if (copyCounter.component === "") {
        //     copyCounter.component = defaultFiltersValue.component 
        // }

        if (copyCounter.component === "" || copyCounter.component === undefined) {
            addToast(t("error"), t('missingHpccomponent'), "error");
            return
        }

        const changedFields = getChangedFields(copyCounter, initialCounter);
        if (editCounter !== undefined && isEmpty(changedFields)) onClose()

        if (!client || (editCounter !== undefined && isEmpty(changedFields))) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                req.body = JSON.stringify(editCounter ? changedFields : copyCounter);
                return req;
            };

            const response = editCounter ? await client.apis["building"].building_variants_datasource_partial_update({building_uuid: _buildingGuid, variant_uuid: variantID, datasource_uuid: editCounter?.id}) : await client.apis["building"].building_variants_datasource_create({building_uuid: _buildingGuid, variant_uuid: variantID});

            if (response.status >= 200 && response.status < 300) {
                onClose();
                addToast(t("success"), t("addCounterSuccess"), "success");
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            console.log("error", error)
            addToast(t("error"), t('networkError'), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [counter, client, defaultFiltersValue, editCounter, _buildingGuid, variantID, onClose, login.Authorization, addToast, t])

    const changeHandler = useCallback((e) => {
        const { id, value } = e.target;
        setCounter(prev => ({ ...prev, [id]: value }));
    }, [counter]);

    const changeFilterHandler = useCallback((e) => {
        const { id, value } = e.target;
        setDefaultFiltersValue(prev => {
            const newState = { ...prev, [id]: value };
            return newState;
        });
    }, [])

    useEffect(() => {
        getHPCataloges();
    }, [])

    useEffect(() => {
        if (defaultFiltersValue.catalog === '') return
        getAllHP();
    }, [defaultFiltersValue.catalog])

    return <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='mb-3'>
            <Form.Label>Name</Form.Label>
            <Form.Control id='heatpart_name' required type='text' value={counter['heatpart_name'] ?? ''} onChange={(e) => changeHandler(e)} />
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('heatingPartCataloges')}</Form.Label>
            <Form.Select id="catalog" value={defaultFiltersValue?.catalog ?? ''} onChange={(e) => changeFilterHandler(e)}>
                {(filters.cataloges ?? [])?.map(h => <option key={`cataloges-${h.id}`} value={h.id}>{h.name}</option>)}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t("reference")}</Form.Label>
            <Form.Select id="component" value={counter['component'] ?? ''} onChange={(e) => changeHandler(e)}>
                {(filters.heatparts?.length === 0 || !filters.heatparts) && (
                    <option value="">{t("noSelection")}</option>
                )}
                {(filters.heatparts ?? []).map(el => (
                    <option key={`filtered-heatpart-${el.id}`} value={el.id}>{el.name}</option>
                ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Label>{t('unit')}</Form.Label>
            <Form.Select id="unit" value={counter['unit'] ?? ''} onChange={(e) => changeHandler(e)}>
                {(units ?? []).map(el => (
                    <option key={`unit-${el.value}`} value={el.value}>{el.label}</option>
                ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
            <Form.Check id='is_main_counter' type='checkbox' checked={counter['is_main_counter'] ?? false} onChange={(e) => setCounter(prev => ({...prev, is_main_counter: e.target.checked}))} label={`${t("mainCounter")}?`}/>
        </Form.Group>

        <Nav variant="pills" justify>
            <Nav.Item className="me-2">
                <Nav.Link
                    className={
                        counter.counter_datasource_type ? "table-nav-link-active" : "table-nav-link" 
                    }
                    onClick={() => {
                        setCounter(prev => ({ ...prev, counter_datasource_type: true }));
                    }}
                >
                    {t("counterReading")}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ms-2">
                <Nav.Link
                    className={
                        !counter.counter_datasource_type ? "table-nav-link-active" : "table-nav-link"
                    }
                    onClick={() => {
                        setCounter(prev => ({ ...prev, counter_datasource_type: false }));
                    }}
                >
                    {t("consumption")}
                </Nav.Link>
            </Nav.Item>
        </Nav>

        <Button variant="outline-primary" className='w-100' type='submit'>{ t('save') }</Button>
    </Form>
}

export default AddEditCounter