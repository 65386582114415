import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

const SortableDataTable = ({headers, data, sort, setSort, currentItems, getSortedArray, heatpartsList}) => {
    const { t } = useTranslation();
    const REACT_ELEMENT_TYPE = Symbol.for('react.element');

    const handleHeaderClick = (header) => {
        setSort(prevSort => {
            let sortDirection = undefined
            if (sort.direction === 'asc') {
                sortDirection = "desc"
            }

            if (sort.direction === 'desc') {
                sortDirection = null
            }

            if (sort.direction === null) {
                sortDirection = 'asc' 
            }

            return {
                keyToSort: header.KEY,
                direction: sortDirection,
                prevDirection: prevSort.direction
            };
        })
    };

    function renderUnitAndFloorRows(row, previousUnitId, previousFloorId) {
        const shouldRenderUnitRow = row?.props?.unit?.id !== previousUnitId;
        const shouldRenderFloorRow = row?.props?.room?.floor?.id !== previousFloorId;

        if (!shouldRenderUnitRow && !shouldRenderFloorRow) {
            return null;
        }

        return (
            <tr className="heatpartlist_tr">
                <td>{shouldRenderUnitRow ? row?.props?.unit?.name || t('belongsToNoAppartment') : ""}</td>
                <td>{shouldRenderFloorRow ? row?.props?.room?.floor?.name || "" : ""}</td>
            </tr>
        );
    }
      
    return (
        <Table responsive>
            <thead>
                <tr>
                    {headers?.map(header => <th key={`tableCol-${header.id}`} onClick={() => {
                        if (header.clickable) {
                            handleHeaderClick(header);
                            currentItems && getSortedArray(data, header)
                        }
                    }}>
                        <div className={header.clickable ? "header_container_clickable header_container" : "header_container"}>
                            <span>{header.col}</span>
                            {header.KEY === sort.keyToSort ? (
                                <p className={sort.prevDirection === null ? "sort_icon_span sort_icon_span_hidden" : "sort_icon_span"}><SortIcon direction={sort.prevDirection} /></p>
                            ) : (
                                <p className="sort_icon_span sort_icon_span_hidden"><SortIcon/></p>
                            )}
                        </div>
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {!currentItems ? (
                    getSortedArray(data).map((row, index) => (
                        <tr key={index}>
                            {headers.map((header, index) => (
                                <td title={row[header.KEY]} key={index}>
                                    {row[header.KEY]}
                                </td>
                            ))}
                         </tr>
                    ))
                ) : (
                    (() => {
                        let previousUnitId = null;
                        let previousFloorId = null;
                        return currentItems.map((row, index) => {
                            if (row.$$typeof === REACT_ELEMENT_TYPE) {
                                const renderedUnitAndFloorRows = renderUnitAndFloorRows(row, previousUnitId, previousFloorId);
                                previousUnitId = row?.props?.unit?.id;
                                previousFloorId = row?.props?.room?.floor?.id;

                                return (
                                    <>
                                        {heatpartsList && renderedUnitAndFloorRows}
                                        {row}
                                    </>
                                );
                            } else {
                                return (
                                    <tr key={index}>
                                        {headers.map((header, index) => (
                                            <td title={row[header.KEY]} key={index}>
                                                {row[header.KEY]}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            }
                        });
                    })()
                )}
            </tbody>
        </Table>
    )
}


const SortIcon = ({direction}) => {

    return (
        <FontAwesomeIcon icon={faSortDown} className={`sort_icon_${direction}`} />
    )
}

export default SortableDataTable