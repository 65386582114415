import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth } from "../Context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Nav, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import MyUploadAdapter from "./MyUploadAdapter";
import DivPlaceholder from "../components/Placeholders/DivPlaceholder";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-classic-plus";
import { useToast } from '../NotificationsContent';
import "./ckeditorskin.css";
import { useSwagger } from "../context/SwaggerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faExclamation, faTrash } from "@fortawesome/free-solid-svg-icons";
import SecurityActionModal from "../components/SecurityActionModal";
import { getChangedFields, isEmpty } from "../util/helpers";

function WYSIWYGEditor({ entry, onlyView, handleEditorChange, MyCustomUploadAdapterPlugin, schemaCustomization }) {

    return <CKEditor
        editor={ClassicEditor}
        data={entry.content}
        disabled={onlyView}
        onChange={handleEditorChange}
        config={(onlyView) ? {
            toolbar: [],
            removePlugins: [],
            isReadOnly: true,
        } : {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|',
                    'heading',
                    '|',
                    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                    '|',
                    'bold', 'italic', 'strikethrough', 'subscript', 'superscript',
                    '|',
                    'link', 'uploadImage', 'blockQuote', 'insertTable',
                    '|',
                    'alignment',
                    '|',
                    'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                ],
                shouldNotGroupWhenFull: true
            },
            extraPlugins: [MyCustomUploadAdapterPlugin, schemaCustomization]
        }}
    />
}

export const AddEditViewLog = ({ onlyView = false, autoSaved = false, close, reload, item, hp_id, log }) => {
    const _buildingGuid = useParams()['buildingGuid'];
    const { addToast } = useToast();
    const { login } = useAuth();
    const client = useSwagger();
    const { t } = useTranslation();
    const [images, setImages] = useState(undefined);
    const [showLoader, setShowLoader] = useState(true);
    const [autoSave, setAutoSave] = useState(autoSaved);
    const [disableButton, setDisableButton] = useState(false);
    const [lastChangeTime, setLastChangeTime] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showMeterReading, setShowMeterReading] = useState(false);
    const [show, setShow] = useState(false)
    const [toggleChecked, setToggleChecked] = useState(false);

    const [meterReading, setMeterReading] = useState(true);

    const [structuredPartData, setStructuredPartData] = useState(() => {
        const initialData = log?.structured_part_data?.attributes ? log?.structured_part_data?.attributes : {"counter": [], "consumption": []};
    
        // Daten nach Datum sortieren
        if (initialData.counter) {
            initialData.counter.sort((a, b) => new Date(a.date) - new Date(b.date));
        }
    
        // Daten nach Datum "from" sortieren
        if (initialData.consumption) {
            initialData.consumption.sort((a, b) => new Date(a.from) - new Date(b.from));
        }
    
        return initialData;
    });

    // const [checkedState, setCheckedState] = useState(() => {
    //     const initialCheckedState = {};
    //     structuredPartData.range.forEach((row, rowIndex) => {
    //         if (row.oldValue) {
    //             initialCheckedState[rowIndex] = true;
    //         }
    //     });
    //     return initialCheckedState;
    // });

    const [minValue, setMinValue] = useState(undefined)
 
    const units = [`m³ ${t('energyTypes.gas_01')}`, "kWh", "MWh"];

    // const [rangeMeterReplacements, setRangeMeterReplacements] = useState([]);
    const [consumeMeterReplacements, setConsumeMeterReplacements] = useState([]);

    const [initialLog, setInitialLog] = useState(undefined)
    const [entry, setEntry] = useState({
        title: log ? log.title : '',
        content: log ? log.content : '',
        id: log ? log.id : undefined
    });

    const timerRef = useRef(null);

    const inputHandler = useCallback((e) => {
        let log = { ...entry };
        log[e.target.id] = e.target.value;
        setEntry({ ...log });
    }, [entry])

    const handleEditorChange = useCallback((event, editor) => {
        clearInterval(timerRef.current);
        const data = editor.getData();
        setEntry({ ...entry, content: data });
        setLastChangeTime(Date.now());
    }, [entry]);

    const updateImages = useCallback((id, file) => {
        const blob = new Blob([file], { type: file.type });
        let im = images === undefined ? [] : [...images];
        im.push({
            id: id,
            url: URL.createObjectURL(blob),
            active: false
        })
        setImages([...im]);
    }, [images])

    const onSubmit = useCallback(async(e, closeStatus = false) => {
        e?.preventDefault();
        if (disableButton) return;

        if (!client || isSubmitting) return;

        if (hp_id) {
            entry.type = 1
            entry.model_uuid = hp_id
        }

        setIsSubmitting(true);

        const changedFields = getChangedFields(entry, initialLog);
        if (log !== undefined && isEmpty(changedFields)) reload()

        if (!client || (log !== undefined && isEmpty(changedFields))) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                // req.body = JSON.stringify(entry);
                req.body = JSON.stringify(item?.id === undefined ? entry : changedFields);

                return req;
            };
  
            const response = entry.id === undefined ? await client.apis["building"].building_log_create({building_uuid: _buildingGuid}) : await client.apis["building"].building_log_partial_update({building_uuid: _buildingGuid, log_uuid: entry.id});
    
            if (response.status >= 200 && response.status < 300) {
                hp_id ? addToast(response.obj.title, entry.id ? t("successulHpLogEditSubmit") : t("successulHpLogPostSubmit"), "success") : addToast(response.obj.title, entry.id ? t("successulLogEditSubmit") : t("successulLogPostSubmit"), "success");
                setEntry((prevEntry) => ({ ...prevEntry, id: response.obj.id }));
                reload();
                if (closeStatus) close();
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        } finally {
            setIsSubmitting(false);
        }

    }, [client, login.Authorization, _buildingGuid, entry, reload, disableButton, close, t, addToast, structuredPartData])


    const fetchImageSource = useCallback(async(id) => {
        // try {
        //     const response = await fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/picture/${id}`, {
        //         headers: {
        //             'Authorization': login.Authorization,
        //             "Content-Type": "application/json"
        //         }
        //     });
        //     if (response.ok) {
        //         const data = await response.blob();
        //         return URL.createObjectURL(data);
        //     } else {
        //         addToast(t('error'), t('fetchingImageError'), "error");
        //     }
        // } catch (error) {
        //     addToast(t('error'), t('fetchingImageError'), "error");
        // }
        // return null;

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
            
            const response = await client.apis["building"].building_picture_retrieve({building_uuid: _buildingGuid, picture_uuid: id});

            if (response.ok) return URL.createObjectURL(response.data);

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("fetchingImageError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
        return null;
    }, [client, _buildingGuid, addToast, login.Authorization, t])

    async function defaultImageSource(htmlString) {
        const regex = /<figure class="image" id="([^"]+)"><img src="([^"]+)"><\/figure>/g;
        let match;
        let images = [];
        while ((match = regex.exec(htmlString)) !== null) {
            const figureTag = match[0];
            const figureId = match[1];
            const oldImageSrc = match[2];
            const newFigureTag = figureTag.replace(oldImageSrc, 'https://media.tenor.com/wpSo-8CrXqUAAAAi/loading-loading-forever.gif');
            htmlString = htmlString.replace(figureTag, newFigureTag);
            images.push({
                id: figureId,
                active: false
            });
        }
        setImages(images);
        return htmlString;
    }

    function replaceImageSourceByID(htmlString, id, newSource) {
        const regex = new RegExp(`<figure class="image" id="${id}"><img src="([^"]+)"></figure>`, 'g');
        const match = regex.exec(htmlString);
    
        if (match) {
            const newFigureTag = `<figure class="image" id="${id}"><img src="${newSource}"></figure>`;
            htmlString = htmlString.replace(match[0], newFigureTag);
        }

        const regexSigmaHeat = new RegExp(`<figure class="image"><img></figure>`, 'g');
        const matchSigma = regexSigmaHeat.exec(htmlString);

        if (matchSigma && match === null) {
            const newFigureTag = `<figure class="image" id="${id}"><img src="${newSource}"></figure>`;
            htmlString = htmlString.replace(matchSigma[0], newFigureTag);
        }

        return htmlString;
    }

    useEffect(() => {
        if (item?.id === undefined) setShowLoader(false);
        if (item?.id === undefined) return;
        
        defaultImageSource(item.content)
        .then(content => {
            setInitialLog({ id: item.id, title: item.title, content });
            setEntry({ id: item.id, title: item.title, content });
            setShowLoader(false);
        })
        .catch(error => {
            addToast(t('error'), t('updatingImageSourceError'), "error");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!Boolean(images?.length)) return;

        const imagesToUpdate = images.filter((img) => !img.active);
        if (!Boolean(imagesToUpdate.length)) return;

        let content = entry.content;

        const updateContent = async () => {
            for (const img of imagesToUpdate) {
                const newSource = img?.url ? img.url : await fetchImageSource(img.id);
                content = replaceImageSourceByID(content, img.id, newSource);
            }

            setEntry((prevEntry) => ({ ...prevEntry, content }));
            setImages((prevData) => prevData.map((obj) => ({ ...obj, active: true })));
        };

        updateContent();
    }, [entry, images, fetchImageSource])

    useEffect(() => {
        clearInterval(timerRef.current);
        if (!autoSave || Date.now() - lastChangeTime > 10000) return;
    
        timerRef.current = setTimeout(() => {
            onSubmit();
        }, 10000);
    
        return () => clearTimeout(timerRef.current);
    }, [onSubmit, autoSave, lastChangeTime]);

    function MyCustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader, login, _buildingGuid, updateImages, setDisableButton, addToast, t);
        };
    }

    function schemaCustomization(editor) {
        editor.model.schema.extend('$blockObject', {
			allowAttributes: 'id'
		});

		editor.conversion.attributeToAttribute({
			model: 'id',
			view: 'id'
		});
    }

    const handleInputChange = useCallback((e, rowIndex, colIndex, part) => {
        const updatedArray = [...structuredPartData[part]];
        updatedArray[rowIndex][colIndex] = e.target.value;

        const newStructuredPartData = {
            ...structuredPartData,
            [part]: updatedArray
        };

        setStructuredPartData(newStructuredPartData);
        setEntry(prevEntry => ({ ...prevEntry, structured_part_data: newStructuredPartData }));

        // Zählertausch durchgeführt?
        let hasMeterReplacement = false;

        let sortedArray = updatedArray.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
        let index = sortedArray.findIndex(obj => obj.date === updatedArray[rowIndex].date);


            const previousRow = sortedArray[index - 1];

            if (previousRow) {

                if (meterReading) {
                    let currentVal = Number(updatedArray[rowIndex].value);
                    let prevVal = Number(previousRow.value);

                    if (currentVal && sortedArray[rowIndex].date > previousRow.date && currentVal < prevVal) {
                        hasMeterReplacement = true;
                        setMinValue({ value: prevVal, row: rowIndex });
                    } else {
                        delete updatedArray[rowIndex].oldValue
                    }
                } 
                
                // else {
                //     let currentVal = Number(sortedArray[rowIndex].value);
                //     let prevVal = Number(previousRow.value);
                //     if (currentVal && sortedArray[rowIndex].from > previousRow.from && currentVal < prevVal) {
                //         hasMeterReplacement = true;
                //     }
                // }
            }

        // const newMeterReplacements = part == "consume" ? [...consumeMeterReplacements] : [...rangeMeterReplacements]

        if (part == "counter") {
            const newMeterReplacements = [...consumeMeterReplacements]
            newMeterReplacements[rowIndex] = hasMeterReplacement;
            setConsumeMeterReplacements(newMeterReplacements)
        }
        // const newMeterReplacements = part == "consume" && [...consumeMeterReplacements]
        // newMeterReplacements[rowIndex] = hasMeterReplacement;
        // part == "consume" ? setConsumeMeterReplacements(newMeterReplacements) : setRangeMeterReplacements(newMeterReplacements)

    }, [structuredPartData, consumeMeterReplacements, meterReading])

    const handleInputBlur = useCallback((e, rowIndex, colIndex, part) => {
        // Überprüfen Datum auf Überlappung
        if(part === "counter") {
            e.target.setCustomValidity("");
            return
        }

        const updatedArray = [...structuredPartData[part]];

        let newFromDate = new Date(updatedArray[rowIndex].from);
        let newToDate = new Date(updatedArray[rowIndex].to);

        updatedArray.some((row, index) => {
            if (index === rowIndex) return false;
            const existingFromDate = new Date(row.from);
            const existingToDate = new Date(row.to);

            if (colIndex == "from") {
                if((newFromDate >= new Date(updatedArray[0].from)) && (newFromDate <= existingToDate)) {
                    const inputElement = e.target;
                    inputElement.setCustomValidity(t("overlappingDates"));
                    inputElement.reportValidity();
                } else {
                    e.target.setCustomValidity("");
                }                
            }

            if (colIndex == "to") {
                if((newToDate >= new Date(updatedArray[0].from) && (newToDate <= existingToDate))) {
                    const inputElement = e.target;
                    inputElement.setCustomValidity(t("overlappingDates"));
                    inputElement.reportValidity();
                } else {
                    e.target.setCustomValidity("");
                }  
            }
        });
    }, [structuredPartData]);

    // const handleCheckboxChange = (rowIndex) => (event) => {
    //     setCheckedState(prevState => ({
    //         ...prevState,
    //         [rowIndex]: event.target.checked,
    //     }));

    //     if (!event.target.checked) {
    //         delete structuredPartData.range[rowIndex].oldValue;
    //     }

    //     setEntry(prevEntry => ({ ...prevEntry, structured_part_data: structuredPartData }));
    // };
    
    const addRow = useCallback(() => {
        const newRow = meterReading ? { date: "", value: "", unit: structuredPartData?.consumption[0]?.unit || structuredPartData?.counter[0]?.unit || units[0] } : { from: "", to: "", value: "", unit: structuredPartData?.consumption[0]?.unit || structuredPartData?.counter[0]?.unit || units[0] };

        setStructuredPartData(prevData => {
            const newData = {
                ...prevData,
                counter: meterReading ? [...prevData.counter, newRow] : prevData.counter,
                consumption: !meterReading ? [...prevData.consumption, newRow] : prevData.consumption
            };
            setEntry(prevEntry => ({ ...prevEntry, structured_part_data: newData }));
            return newData;
        });

        // meterReading ? setConsumeMeterReplacements(prevMeterReplacements => [...prevMeterReplacements, false]) : setRangeMeterReplacements(prevMeterReplacements => [...prevMeterReplacements, false])
        meterReading && setConsumeMeterReplacements(prevMeterReplacements => [...prevMeterReplacements, false])
    }, [structuredPartData, meterReading]);

    const deleteRow = useCallback((rowID, part) => {
        let tmpData = [...structuredPartData[part]]
        tmpData.splice(rowID, 1);

        const newStructuredPartData = {
            ...structuredPartData,
            [part]: tmpData
        };

        setStructuredPartData(newStructuredPartData);
        setEntry(prevEntry => ({ ...prevEntry, structured_part_data: newStructuredPartData }));
    }, [structuredPartData])


    // hier wird geprüft, ob wir einen Zählerwechsel haben beim Laden der Daten
    // Zählerwechsel, wenn Datum fortgeschritten ist aber der Wert kleiner als der vorherige ist
    const checkMeterReplacements = (data, meterReading) => {
        return data.map((row, index) => {
            const previousRow = data[index - 1];
            let hasMeterReplacement = false;
    
            if (previousRow) {
                if (meterReading) {
                    let prevVal = Number(previousRow.value)
                    let currentVal = Number(row.value)
                    if (row.date > previousRow.date && currentVal < prevVal) {
                        hasMeterReplacement = true;
                    }
                } else {
                    let prevVal = Number(previousRow.value)
                    let currentVal = Number(row.value)
                    if (row.from > previousRow.from && currentVal < prevVal) {
                        hasMeterReplacement = true;
                    }
                }
            }
            return hasMeterReplacement;
        });
    };

    const deleteStructuredPartData = () => {
        if (meterReading) {
            const newStructuredPartData = {
                ...structuredPartData,
                counter: []
            };
    
            setStructuredPartData(newStructuredPartData);
            setEntry(prevEntry => ({ ...prevEntry, structured_part_data: newStructuredPartData }));
        } else {
            const newStructuredPartData = {
                ...structuredPartData,
                consumption: []
            };
    
            setStructuredPartData(newStructuredPartData);
            setEntry(prevEntry => ({ ...prevEntry, structured_part_data: newStructuredPartData }));
        }

        setMeterReading(!meterReading);
        setToggleChecked(!toggleChecked);
    }

    useEffect(() => {
        if (structuredPartData.counter.length > 0) {
            const consumeMeterReplacements = checkMeterReplacements(structuredPartData.counter, meterReading);
            setConsumeMeterReplacements(consumeMeterReplacements);
        }

        if (structuredPartData.consumption.length > 0) {
            setMeterReading(false)
        }
        // if (structuredPartData.range.length > 0) {
        //     const rangeMeterReplacements = checkMeterReplacements(structuredPartData.range, meterReading);
        //     setRangeMeterReplacements(rangeMeterReplacements);
        // }
    }, [])

    if (showLoader) return <div className="d-height"><DivPlaceholder /></div>
    if (onlyView) return <WYSIWYGEditor {...{ entry, onlyView, handleEditorChange, MyCustomUploadAdapterPlugin, schemaCustomization }} />

    return <Form onSubmit={(e) => onSubmit(e, true)}>

        <Nav variant="pills">
            <Nav.Item>
                <Nav.Link
                    className={showMeterReading ? "table-nav-link" : "table-nav-link-active"}
                    onClick={() => {
                        setShowMeterReading(false)
                    }}
                    >
                    {t("hpLog")}
                </Nav.Link>
            </Nav.Item>
            {
                hp_id && (
                    <Nav.Item>
                        <Nav.Link
                            className={showMeterReading ? "table-nav-link-active" : "table-nav-link"}
                            onClick={() => {
                                setShowMeterReading(true)
                            }}
                            >
                            {`${t("meterReading")} / ${t("consumption")}`}
                        </Nav.Link>
                    </Nav.Item>
                )   
            }
        </Nav>

        {!showMeterReading ? (
            <>
                <Form.Group id="name" className="mb-3">
                    <Form.Label>{t("title")}</Form.Label>
                    <Form.Control id="title" required type="text" value={entry?.title || ''} onChange={(e) => inputHandler(e)} />
                </Form.Group>
                <Form.Group id="toggle" className="mb-3">
                    <Form.Check
                        type="switch"
                        id="autosave"
                        label={t("autosaveQuery")}
                        className="darkfont"
                        onChange={(e) => setAutoSave(e.target.checked)}
                        checked={autoSave}
                    />
                </Form.Group>

                <Form.Group id="content" className="mb-3">
                    <Form.Label>{t("content")}</Form.Label>
                    <WYSIWYGEditor {...{ entry, onlyView, handleEditorChange, MyCustomUploadAdapterPlugin, schemaCustomization }} />
                </Form.Group>
            </>

        ) : (
            // ZÄHLERSTAND / VERBRAUCH
            <>
                {hp_id && ( 
                    <>
                        <Form.Group id="name" className="mb-1">
                            <Form.Label>{`${meterReading ? t("meterReading") : t("consumption")}`}</Form.Label>
                        </Form.Group>

                        <Form.Group id="toggle" className="mb-3">
                            <Form.Check
                                type="switch"
                                id="change"
                                label={`${t("switchTo")} ${!meterReading ? t("meterReading") : t("consumption")}`}
                                className="darkfont"
                                checked={toggleChecked}
                                onChange={(e) => {setShow("deleteStructuredPartData")}}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="consumption">
                            <Table responsive className="mb-3">
                                <thead>
                                    <tr>
                                        {/* {!meterReading && <th>{t("meterReplacement") + '?'}</th>} */}
                                        {meterReading && <th>{t("date")}</th>}
                                        {!meterReading && (
                                            <>
                                                <th>{t("from_1")}</th>
                                                <th>{t("to_1")}</th>
                                            </>
                                        )}
                                        {meterReading ? <th>{t("meterReading")}</th> : <th>{t("consumption")}</th>}
                                        <th>{t("unit")}</th>
                                        <th><FontAwesomeIcon icon={faTrash} size='sm' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {meterReading ?
                                        structuredPartData.counter?.map((row, rowIndex) => (
                                            <tr key={`selected-row-${rowIndex}`}>
                                                <td>
                                                    <input className="form-control displayDate" type="date" value={row.date} required onBlur={(e) => handleInputBlur(e, rowIndex, "date", "counter")}  onChange={(e) => handleInputChange(e, rowIndex, "date", "counter" )} />
                                                </td>
                                                <td>
                                                    <div style={{display: "flex", gap: "10px"}}>                                       
                                                        {consumeMeterReplacements[rowIndex] && (
                                                            <input className="form-control" placeholder={t("oldValMeterReading")} type="number" min={minValue?.row == rowIndex ? minValue?.value : 0} value={row.oldValue} required step="0.1" onChange={(e) => handleInputChange(e, rowIndex, "oldValue", "counter")} />
                                                        )}
                                                        <input className="form-control" placeholder={ consumeMeterReplacements[rowIndex] ? t("newValMeterReading") : "" } type="number" value={row.value} required step="0.1" onChange={(e) => handleInputChange(e, rowIndex, "value", "counter")} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <select className="form-select" value={row.unit} onChange={(e) => handleInputChange(e, rowIndex, "unit", "counter")}>
                                                        {units.map((unit, index) => (
                                                            <option key={index} value={unit}>
                                                                {unit}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>

                                                <td>
                                                    <div style={{display: "flex", gap: "10px"}}>
                                                        <Button variant="outline-primary" className="p-10" style={{ width: "38px" }} onClick={() => deleteRow(rowIndex, "counter")}>
                                                            <FontAwesomeIcon icon={faTrash} size='sm' />
                                                        </Button>
                                                    
                                                        {consumeMeterReplacements[rowIndex] && (
                                                            <OverlayTrigger
                                                                trigger={['hover']}
                                                                overlay={<Tooltip><p>{`${t('meterReplacement')}`}</p> <p>{`${t('right')}: ${t('newValMeterReading')}`}</p> <p>{`${t('left')}: ${t('alter Zählerstand')}`}</p></Tooltip>}>
                                                                <FontAwesomeIcon icon={faExclamation} size="xl" style={{ color: 'red', alignItems: 'center', alignSelf: 'center', paddingInline: '1rem' }} />
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    :
                                        structuredPartData.consumption.map((row, rowIndex) => (
                                            <tr key={`selected-row-${rowIndex}`}>
                                                {/* <td>
                                                    <input
                                                        style={{width: "100%"}}
                                                        type="checkbox"
                                                        checked={checkedState[rowIndex] || false}
                                                        onChange={handleCheckboxChange(rowIndex)}
                                                    />
                                                </td> */}
                                                <td>
                                                    <input className="form-control displayDate" type="date" max={row.to || undefined} value={row.from} onBlur={(e) => handleInputBlur(e, rowIndex, "from", "consumption")} onChange={(e) => handleInputChange(e, rowIndex, "from", "consumption")} />
                                                </td>
                                                <td>
                                                    <input className="form-control displayDate" type="date" min={row.from || undefined} value={row.to} onBlur={(e) => handleInputBlur(e, rowIndex, "to", "consumption")} onChange={(e) => handleInputChange(e, rowIndex, "to", "consumption")} />
                                                </td>
                                                <td>
                                                    <div style={{display: "flex", gap: "10px"}}>
                                                        {/* {checkedState[rowIndex] && (
                                                            <input style={{width: "auto"}} className="form-control" placeholder={t("oldValMeterReading")} type="number" value={row.oldValue} required onChange={(e) => handleInputChange(e, rowIndex, "oldValue", "range")} />
                                                        )} */}

                                                        {/* <input style={checkedState[rowIndex] ? {width: "auto"} : {}} className="form-control" placeholder={checkedState[rowIndex] ? t("newValMeterReading") : ""} type="number" value={row.value} required onChange={(e) => handleInputChange(e, rowIndex, "value", "range")} /> */}
                                                        <input className="form-control" type="number" value={row.value} required step="0.1" onChange={(e) => handleInputChange(e, rowIndex, "value", "consumption")} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <select className="form-select" value={row.unit} onChange={(e) => handleInputChange(e, rowIndex, "unit", "consumption")}>
                                                        {units.map((unit, index) => (
                                                            <option key={index} value={unit}>
                                                                {unit}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <div style={{display: "flex", gap: "10px"}}>
                                                        <Button variant="outline-primary" className="p-10" style={{ width: "38px" }} onClick={() => deleteRow(rowIndex, "consumption")}>
                                                            <FontAwesomeIcon icon={faTrash} size='sm' />
                                                        </Button>
                                                    
                                                        {/* {rangeMeterReplacements[rowIndex] && (
                                                            <OverlayTrigger
                                                                trigger={['hover']}
                                                                overlay={<Tooltip><p>{`${t('meterReplacement')}`}</p> <p>{`${t('right')}: ${t('newValMeterReading')}`}</p> <p>{`${t('left')}: ${t('alter Zählerstand')}`}</p></Tooltip>}>
                                                                <FontAwesomeIcon icon={faExclamation} size="xl" style={{ color: 'red', alignItems: 'center', alignSelf: 'center', paddingInline: '1rem' }} />
                                                            </OverlayTrigger>
                                                        )} */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <Button className="w-100 btn-icon" variant="outline-primary" onClick={() => addRow()}><FontAwesomeIcon icon={faAdd} size='sm' /></Button>
                        </Form.Group>
                    </>
                )}
            </>
        )}
        <Button variant='outline-primary' className="w-100" type="submit" disabled={disableButton}>{t("save")}</Button>
        <SecurityActionModal {...{ show: show === "deleteStructuredPartData", onHide: () => { setShow(false) }, title: `${t("switchTo")} ${meterReading ? t("consumption") : t("meterReading")} `, question: t("deleteStructuredPartDataQuestion"), action: () => deleteStructuredPartData() }} />
    </Form>
}

export default AddEditViewLog;