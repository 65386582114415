import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context";
import { useToast } from "../NotificationsContent";
import Collapse from "../components/Collapse";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCopy, faEdit, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import { dateToString } from "../util/helpers";
import Card from "../components/Card";
import ContentModal from "../components/ContentModal";
import TablePlaceholder from "../components/Placeholders/TablePlaceholder";
import MainLayout from "../components/MainLayout";
import Background from "../assets/background_3_3.png";
import AddEditViewLog from "./AddEditViewLog";
import SecurityActionModal from "../components/SecurityActionModal";
import { extractInitials, specificColorGenerator } from "../util/helpers";
import { useSwagger } from "../context/SwaggerContext";
import useBuildingHook from "../hooks/useBuildingHook";
import { usePermissions } from "../context/PermissionsContext";

export default function BuildingLog() {
    const { addToast } = useToast();
    const _buildingGuid = useParams()['buildingGuid'];
    const { login } = useAuth();
    const { t } = useTranslation();
    const [entry, setEntry] = useState({ title: "", content: "", id: undefined });
    const [logs, setLogs] = useState(undefined);
    const [show, setShow] = useState(false);
    const client = useSwagger();
    const { allowEdit } = usePermissions();
    const [userCache, setUserCache] = useState({});
    useBuildingHook()

    const getLogs = useCallback(async() => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/log/`, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     }
        // }).then(data => {
        //     if (data.status === 200) return data.json()
        //     return []
        // })
        // .then(data => {
        //     data = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        //     setLogs(data)
        //     setEntry({ title: "", content: "", id: undefined })
        //     console.log("LOGS IN GETLOGS:", logs)
        //     console.log("ENTRY IN GETLOGS:", entry)
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
    
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            // const response = await client.apis["building"].getLog({ building_uuid: _buildingGuid })
            const response = await client.apis["building"].building_log_list({ building_uuid: _buildingGuid })

            if (response.status === 200) {
                const sortedLogs = response.obj?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setLogs(sortedLogs);
                setEntry({ title: "", content: "", id: undefined });

                const uniqueUserIds = new Set();

                response.obj.forEach((entry) => {
                    // if (entry.created_by?.id) uniqueUserIds.add(entry.created_by?.id);
                    // if (entry.updated_by?.id) uniqueUserIds.add(entry.updated_by?.id);
                    if (entry.author_uuid) uniqueUserIds.add(entry.author_uuid);

                });

                for (let userId of uniqueUserIds) {
                    await profileImage(userId);
                }

            } else {
                setLogs([]);
            }
        
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid])

    let deleteEntry = useCallback(async(id) => {

        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/log/${id}`, {
        //     headers: {
        //         "Authorization": login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "DELETE"
        // }).then(_ => {
        //     getLogs();
        //     addToast(t('buildingLogBook'), t('buildingLogDeletedSuccessfully'), "success")
        // })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_log_destroy({ building_uuid: _buildingGuid, log_uuid: id })
    
            if (response.status >= 200 && response.status < 300) {
                getLogs();
                addToast(t("buildingLogBook"), t("buildingLogDeletedSuccessfully"), "success");
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, getLogs, addToast, t])

    const copyLogs = useCallback(async(log) => {
        // fetch(`https://tech.sigmaheat.de/building/${_buildingGuid}/log/${log.id}/copy`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //         "Content-Type": "application/json"
        //     },
        //     method: "POST"
        // })
        // .then((response) => {
        //     if (!response.ok) throw new Error(t("networkError"));
        //     if (response.status === 204) return [];
        //     return response.json();
        // })
        // .then(data => {
        //     getLogs();
        // })

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_log_copy_create({building_uuid: _buildingGuid, log_uuid: log.id})

            if (response.status >= 200 && response.status < 300) {
                getLogs();
            }
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, getLogs, login.Authorization, t])

    const loadProfileImage = useCallback(async(userID) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;
  
        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };
    
            const response = await client.apis["User"].getUserProfilePicture({ user_uuid_path: userID, size: 48 })

            if (response.status >= 200 && response.status < 300 && response.status !== 204)
                setUserCache((prevCache) => ({ ...prevCache, [userID]: URL.createObjectURL(response.data) }));
    
            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            client.http.requestInterceptor = originalRequestInterceptor;
        }

    }, [client, login.Authorization])

    const profileImage = useCallback(async(userID) => {
        if (!userCache[userID])
            await loadProfileImage(userID);

        return userCache[userID];
    }, [userCache])

    const mainFunctions = [
        {label: t('addLogEntry'), onClick: () => { setEntry({ title: "", content: "", id: undefined }); setShow("log") }, key: 'add_log_entry', icon: faAdd},
    ]

    const tableStructure = [
        {
            col: <FontAwesomeIcon icon={faToggleOn} size='sm' className='flex-shrink-0' />,
            type: 'icon'
        }, {
            col: t('title'),
            type: 'label'
        }, {
            col: t('author'),
            type: 'label'
        }, {
            col: t('updatedAt'),
            type: 'label'
        }, {
            col: t('createdAt'),
            type: 'label'
        }, {
            col: t('actions'),
            type: 'buttons'
        }, 
    ]

    useEffect(() => {
        getLogs();
    }, [getLogs]);

    return  <MainLayout {...{ background: Background }}>
        <div className="sidebar__padding">
            <Card heading={`${t('buildingLogBook')} ${t('inThe')} ${t('building')}: ${login?.currentBuilding?.name}`} {...(allowEdit && { mainFunctions })} >
                {(logs === undefined) ? (
                    <TablePlaceholder {...{structure: tableStructure}} />
                ) : (logs.length === 0) ? (
                    <p className="m-0">{t("noBuildingLogs")}</p>
                ) : (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <div
                                        className="d-flex"
                                        style={{ width: "16px", height: "16px" }}
                                    >
                                            <FontAwesomeIcon icon={faToggleOn} size="sm" className='flex-shrink-0' />
                                    </div>
                                </th>

                                <th>
                                    <span>{t("title")}</span>
                                </th>
                                <th>
                                    <span>{t("author")}</span>
                                </th>
                                <th>
                                    <span>{t("updatedAt")}</span>
                                </th>
                                <th>
                                    <span>{t("createdAt")}</span>
                                </th>
                                <th>
                                    <span>{t("actions")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map(log => <BuildingLogRow key={`entry-${log.updated_at}-${log.id}`} {...{ log, setEntry: (log) => setEntry(log), setShow: (key) => setShow(key), copyLogs: (log)=> copyLogs(log), c_b: userCache[log.author_uuid]}}/>)}
                        </tbody>
                    </Table>
                )}
            </Card>
            <ContentModal {...{ show: show === "log", onHide: () => setShow(false), title: t('addLogEntry'), content: <AddEditViewLog {...{ close: () => setShow(false), item: entry, reload: () => getLogs(), autoSaved: true }} />, size: 'lg'}} />
            <SecurityActionModal {...{ show: show === "delete", onHide: () => { setEntry({ title: "", content: "", id: undefined }); setShow(false) }, title: t('deleteLog'), question: t('deleteLogQuestion'), action: () => deleteEntry(entry.id) }} />
        </div>
    </MainLayout>
}


export const BuildingLogRow = ({ log, setEntry, setShow, copyLogs,  c_b}) => {
    const { login, defaultTheme } = useAuth();
    const { t } = useTranslation();
    const client = useSwagger();
    const [display, setDisplay] = useState(false);
    const [image, setImage] = useState(undefined);

    function copyToClip(str) {
        function listener(e) {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    };

    // const loadProfileImage = useCallback(async() => {
    //     // fetch(`https://tech.sigmaheat.de/user/${log.author_uuid}/profilepicture?size=48`, {
    //     //     headers: {
    //     //         'Authorization': login.Authorization,
    //     //         "Content-Type": "application/json"
    //     //     },
    //     //     method: "GET"
    //     // })
    //     // .then((response) => {
    //     //     if (!response.ok) return
    //     //     return response.blob()
    //     // })
    //     // .then(content => {
    //     //     setImage(URL.createObjectURL(content));
    //     // })
    //     // .catch(error => {
    //     //     setImage(false)
    //     // });

    //     if (!client) return;

    //     const originalRequestInterceptor = client.http.requestInterceptor;
  
    //     try {
    //         client.requestInterceptor = (req) => {
    //             req.headers["Content-Type"] = "application/json";
    //             req.headers["Authorization"] = login.Authorization;
    //             return req;
    //         };
    
    //         const response = await client.apis["User"].getUserProfilePicture({ user_uuid_path: log.author_uuid, size: 48 })
    
    //         if (response.status >= 200 && response.status < 300) {
    //             setImage(URL.createObjectURL(response.data))
    //         }
    
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     } catch (error) {
    //         setImage(false);
    //         client.http.requestInterceptor = originalRequestInterceptor;
    //     }

    // }, [client, log.author_uuid, login.Authorization, t])

    // useEffect(() => {
    //     loadProfileImage()
    // }, [loadProfileImage])

    return (
        <>
            <tr>
                <td>
                    <Collapse {...{ boolean: display, onClick: () => {setEntry(log); setDisplay(!display)} }} />
                </td>
                <td>
                    <span>{log.title}</span>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        {/* {image && <img className="author__img" src={image} alt='' />}*/}
                        {/* {image === false && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(log.prename + ' ' + log.name, true) }}> */}

                        {c_b && <img className="author__img" src={c_b} alt='' />}
                        {c_b === false && <div className="log__profile" style={{ backgroundColor: specificColorGenerator(log?.prename + ' ' + log?.name, true) }}>
                            <span className="initials" style={{ color: specificColorGenerator(log.prename + ' ' + log.name, false) }}>{extractInitials(log.prename + ' ' + log.name)}</span>
                        </div>}
                        <span>
                            <a className={defaultTheme === 'light' ? "text-black" : "text-white"} href={("mailto:" + log.mail + "?subject=" + encodeURIComponent("Frage zum Objektlog") + "&body=" + encodeURIComponent("Titel:" + log.title + "\nObjektLog-ID: " + log.id + "\n" , "https://tech.sigmaheat.de" + window.location.pathname))}>{log.prename + " " + log.name}</a>
                        </span>
                    </div>
                </td>
                <td>
                    <span>{dateToString(new Date(Date.parse(log.updated_at)))}</span>
                </td>
                <td>
                    <span>{dateToString(new Date(Date.parse(log.created_at)))}</span>
                </td>
                <td>
                    <div className='actions__buttonbar grid__two'>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("edit")}</Tooltip>
                            }>
                            <Button variant='outline-primary' className="p-10" onClick={() => { setEntry(log); setShow("log") }} disabled={log.author_uuid !== login.id}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </OverlayTrigger>
                        {/* <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("copyContentWithoutPictures")}</Tooltip>
                            }>
                            <Button variant='outline-primary' className="p-10" onClick={() => {copyLogs(log); copyToClip("<h1>" + log.title + "</h1>" + log.content)}}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </OverlayTrigger> */}
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip>{t("delete")}</Tooltip>
                            }>
                            <Button variant='outline-primary' className="p-10" onClick={() => { setEntry(log); setShow("delete") }} disabled={log.author_uuid !== login.id}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>
            {display && <tr>
                <td colSpan="100%">
                    <h3>{log.title}</h3>
                    <AddEditViewLog {...{ onlyView: true, item: log, autoSaved: false }} />
                </td>
            </tr>}
        </>
    );
};