import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Nav, Tab } from "react-bootstrap";
import { RoofSlope } from "./components/RoofSlope";

export const PitchedRoof = ({ pitches, room, type, setFormData }) => {
    const MAX = 4;
    const { t } = useTranslation();
    const [activeSlopeTab, setActiveSlopeTab] = useState('tabs1');
    const [tabSlopeContents, setTabSlopeContents] = useState({
        tabs1: { formValue: { heightOne: 0, heightTwo: 0, distance: 0 }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } },
        tabs2: { formValue: { heightOne: 0, heightTwo: 0, distance: 0 }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } },
        tabs3: { formValue: { heightOne: 0, heightTwo: 0, distance: 0 }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } },
        tabs4: { formValue: { heightOne: 0, heightTwo: 0, distance: 0 }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } }
    });

    const handleSlopeFormChange = (tabKey, field, value, dormerKey = null, dormerField = null) => {
        setTabSlopeContents((prevContents) => {
            const updatedTab = { ...prevContents[tabKey] };
    
            if (dormerKey && dormerField) {
                updatedTab.dormer.dormers[dormerKey].formValue[dormerField] = value;
            } else if (field === 'noOfDoomer' || field === 'sync' || field === 'noofdeductionareas') {
                updatedTab.dormer[field] = field === 'noofdeductionareas' ? Math.min(parseInt(value), 2) : value;
            } else {
                updatedTab.formValue[field] = value;
            }
    
            return {
                ...prevContents,
                [tabKey]: updatedTab,
            };
        });
    };

    const updateDormers = (eventKey) => {
        setTabSlopeContents((prevContents) => {
            const sourceTabDormer = prevContents[eventKey].dormer.dormers.tab1.formValue;
            const noOfDoomer = prevContents[eventKey].dormer.noOfDoomer;
            const newContents = { ...prevContents };
    
            for (let i = 1; i <= noOfDoomer; i++) {
                const dormerKey = `tab${i}`;
                newContents[eventKey].dormer.dormers[dormerKey].formValue = { ...sourceTabDormer };
            }
    
            return newContents;
        });
    };

    const tabSlopeCount = Math.min(pitches, MAX);

    const navSlopeItems = [];
    const tabSlopePanes = [];

    for (let i = 1; i <= tabSlopeCount; i++) {
        const eventKey = `tabs${i}`;
        navSlopeItems.push(
            <Nav.Item key={eventKey}>
                <Nav.Link eventKey={eventKey}>{t("slanting")} {i}</Nav.Link>
            </Nav.Item>
        );
        tabSlopePanes.push(
            <Tab.Pane eventKey={eventKey} key={eventKey}>
                <RoofSlope {...{
                    heightOne: tabSlopeContents[eventKey].formValue.heightOne,
                    heightTwo: tabSlopeContents[eventKey].formValue.heightTwo,
                    distance: tabSlopeContents[eventKey].formValue.distance,
                    dormers: tabSlopeContents[eventKey].dormer,
                    sync: tabSlopeContents[eventKey].dormer.sync,
                    noofdeductionareas: tabSlopeContents[eventKey].dormer.noofdeductionareas,
                    onHeightOneChange: (value) => handleSlopeFormChange(eventKey, 'heightOne', parseFloat(value)),
                    onHeightTwoChange: (value) => handleSlopeFormChange(eventKey, 'heightTwo', parseFloat(value)),
                    onDistanceChange:  (value) => handleSlopeFormChange(eventKey, 'distance',  parseFloat(value)),
                    onNoOfDoomerChange: (value) => handleSlopeFormChange(eventKey, 'noOfDoomer', parseInt(value)),
                    onDormerChange: (dormerKey, dormerField, value) => handleSlopeFormChange(eventKey, null, parseFloat(value), dormerKey, dormerField),
                    onSyncChange: (value) => handleSlopeFormChange(eventKey, 'sync', value),
                    onNoofdeductionareasChange: (value) => handleSlopeFormChange(eventKey, 'noofdeductionareas', parseInt(value)),
                    onSyncAllDormers: () => updateDormers(eventKey)
                    }}
                />
            </Tab.Pane>
        );
    }

    const transformTabSlopeContents = (tabSlopeContents) => {
        const slanting = [];
        
        for (let i = 1; i <= pitches; i++) {
            const tabKey = `tabs${i}`;
            const tabContent = tabSlopeContents[tabKey];
            const { formValue, dormer } = tabContent;
            const noOfDoomer = dormer.noOfDoomer;
            const dormerArray = Object.keys(dormer.dormers).map((dormerKey) => {
                return { ...dormer.dormers[dormerKey].formValue };
            }).slice(0, noOfDoomer);
            slanting.push({
                heightOne: formValue.heightOne,
                heightTwo: formValue.heightTwo,
                distance: formValue.distance,
                dormer: dormerArray,
                noofdeductionareas: dormer.noofdeductionareas
            });
        }

        return {
            room: room,
            slanting: slanting,
            type: type
        };
    };

    useEffect(() => {
        const currentTabIndex = parseInt(activeSlopeTab.replace('tabs', ''), 10);
        if (tabSlopeCount > 0 && (currentTabIndex > tabSlopeCount || currentTabIndex === 0)) setActiveSlopeTab(`tabs${tabSlopeCount}`);
    }, [tabSlopeCount, activeSlopeTab]);

    useEffect(() => {
        setFormData(transformTabSlopeContents(tabSlopeContents))
    }, [tabSlopeContents, pitches, setFormData]);

    return <>
        {pitches > 0 && <Tab.Container key={`tabs-${activeSlopeTab}`} defaultActiveKey={activeSlopeTab} onSelect={(k) => setActiveSlopeTab(k)} className="mb-3">
            <Nav variant="pills" justify className="mb-3">
                {navSlopeItems}
            </Nav>
            <Tab.Content>
                {tabSlopePanes}
            </Tab.Content>
        </Tab.Container>}
    </>
}

export default PitchedRoof;