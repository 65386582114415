

export default class WebSocketWorker {

    constructor(login,building_uuid,callback_function_message,updateActiveUsers,setReceivedMessages) {
        
        this.url = process.env.WEBSOCKET;
        this.socket = null;
        this.token = login?.Authorization?.replace("Bearer ", "");
        this.building_uuid = building_uuid
        this.callback_function_toast = callback_function_message
        this.setActiveUsersCallback = updateActiveUsers
        this.setReceivedMessages = setReceivedMessages;

        if(this.token && this.building_uuid && this.url)this.connect()
    }
  
    connect() {
      this.building_socket = new WebSocket(this.url+'building/'+this.building_uuid+'/?token='+this.token);
      // this.privatesocket = new WebSocket(this.url+'?token='+this.token);
      // Event-Listener: Wenn die Verbindung geöffnet wird
      this.building_socket.onopen = () => {
        console.log('WebSocket connection opened.');
      };
  
      // Event-Listener: Wenn eine Nachricht empfangen wird
      this.building_socket.onmessage = (event) => {
        try {

            let parsedData = JSON.parse(event.data);

            
            if (typeof parsedData === "object" && parsedData !== null) {

              if(Object.keys(parsedData).includes("active_users")){

                if (this.setActiveUsersCallback) {
                  if (JSON.stringify(parsedData['active_users']) !== JSON.stringify(this.currentActiveUsers)) {
                    this.currentActiveUsers = parsedData['active_users'];
                    this.setActiveUsersCallback(parsedData['active_users']);
                  }
                }
              return;
              }
              else if(Object.keys(parsedData).includes("message")){

                if(this.setReceivedMessages){
                  console.log("ABC")
                  console.log(parsedData)
                  this.setReceivedMessages(prevState => {
                    let updatedState = { ...prevState };
                    updatedState[parsedData['id']]={...parsedData,date: new Date().toISOString()}; 
                    return updatedState;
                  })
                }
                this.callback_function_message && this.callback_function_message(parsedData)

              }
              else{
                  console.log("Received a dictionary (object):", parsedData);
              }
              
            }
          } catch (error) {

            console.log("Received non-dict message:", event.data);
          }

      };
  
      // Event-Listener: Wenn ein Fehler auftritt
      this.building_socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      // Event-Listener: Wenn die Verbindung geschlossen wird
      this.building_socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
    }
  
    // Schicke eine Nachricht über den WebSocket
    // sendMessage(message) {
    //     if (this.building_socket && this.building_socket.readyState === WebSocket.OPEN) {
    //     this.building_socket.send({"type":"chat_message","message": message.message});
    //     console.log('Message sent:', message);
    //   } else {
    //     console.warn('WebSocket is not open. Message not sent.');
    //   }
    // }

    async sendMessage(message, onSentCallback) {
      await this.waitForSocketOpen();
      try {
        this.building_socket.send(JSON.stringify({ "type": "chat.message", "message": message.message }));
        console.log('Message sent:', message);

        if (onSentCallback) {
          onSentCallback(); // Nachricht auf "sent: true"setzen
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
    
    waitForSocketOpen() {
      return new Promise((resolve, reject) => {
        if (this.building_socket && this.building_socket.readyState === WebSocket.OPEN) {
          resolve(); 
        } else {
          const onOpenHandler = () => {
            this.building_socket.removeEventListener('open', onOpenHandler);
              resolve(); 
          };
          this.building_socket.addEventListener('open', onOpenHandler);
        }
      });
    }
  
    // Schließe die WebSocket-Verbindung
    disconnect() {
      if (this.socket) {
        this.building_socket.close();
      }
    }
  }
  