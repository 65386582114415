import React from "react";

const Heading = ({ context }) => {
    return <h5 className="mb-3">{context}</h5>
}

const Paragraph = ({ context, margin }) => {
    return <p className={`${margin ? "mb-3" : "m-0" }`}>{context}</p>
}

export const PrivacyPolicy = () => {

    return <>
        <Heading {...{ context: `Datenschutzerklärung` }} />
        <Paragraph {...{ margin: true, context: `Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird. "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.` }} />
    
        <Heading {...{ context: `Server-Logfiles` }} />
        <Paragraph {...{ margin: true, context: `Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet Browser übermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der anfragende Provider.` }} />
        <Paragraph {...{ margin: true, context: `Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung eines störungsfreien Betriebs unserer Website sowie zur Verbesserung unseres Angebotes.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden dabei gegebenenfalls in Drittstaaten außerhalb der Europäischen Union übermittelt, für die ein Angemessenheitsbeschluss der EU-Kommission vorliegt.` }} />
    
        <Heading {...{ context: `Kontakt` }} />
        <Heading {...{ context: `Verantwortlicher` }} />
        <Paragraph {...{ margin: true, context: `Kontaktieren Sie uns auf Wunsch. Verantwortlicher für die Datenverarbeitung ist:` }} />

        <Paragraph {...{ context: `Sigmaheat GmbH` }} />
        <Paragraph {...{ context: `vert. d.d. Geschäftsführer Maik Brinkmann` }} />
        <Paragraph {...{ context: `Lange Laube 2` }} />
        <Paragraph {...{ context: `30159 Hannover` }} />
        <Paragraph {...{ context: `Telefon: <a href="tel:+4951187453680">+49 511 8745 3680</a>` }} />
        <Paragraph {...{ margin: true, context: `E-Mail: <a href="mailto:info@sigmaheat.de">info@sigmaheat.de</a>` }} />

        <Heading {...{ context: `Initiativ-Kontaktaufnahme des Kunden per E-Mail` }} />
        <Paragraph {...{ margin: true, context: `Wenn Sie per E-Mail initiativ mit uns in Geschäftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage.` }} />
        <Paragraph {...{ margin: true, context: `Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden die Systeme „Jira Service Desk“ sowie „Confluence“ der ATLASSIAN CORPORATION PLC (Level 6, 341 George Street, Sydney NSW 2000, Australien). Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung ebenfalls auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.` }} />
        <Paragraph {...{ margin: true, context: `Erfolgt die Kontaktaufnahme aus anderen Gründen, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. <strong>In diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong>` }} />
        <Paragraph {...{ margin: true, context: `Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.` }} />
    
        <Heading {...{ context: `Erhebung und Verarbeitung bei der Verwendung von Boldsign` }} />
        <Paragraph {...{ context: `Wir nutzen die Dienste von Boldsign (2501 Aerial Center Parkway Suite 111 Morrisville, North Carolina 27560).` }} />
        <Paragraph {...{ margin: true, context: `Die Datenverarbeitung dient dem Zweck der Erstellung und Übermittlung digitaler Signaturen zum Vertragsschluss. Mit Nutzung von Boldsign werden die zum Vertragsschluss erforderlichen Daten an Boldsign übermittelt, um den Vertrag mit Ihnen zu schließen. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.` }} />
        <Paragraph {...{ margin: true, context: `Alle Transaktionen via Boldsign unterliegen der Boldsign-Datenschutzerklärung. Diese finden Sie unter: <a href="https://boldsign.com/privacy-policy/" target="_blank">https://boldsign.com/privacy-policy/</a>.` }} />

        <Heading {...{ context: `Erhebung und Verarbeitung bei Bewerbungen per E-Mail` }} />
        <Paragraph {...{ margin: true, context: `Seitenbesucher können sich bei Interesse auf freie Vakanzen, die auf unserer Website ausgeschrieben sind, per E-Mail bewerben. Hierbei erheben wir Ihre personenbezogenen Daten nur in dem von Ihnen zur Verfügung gestellten Umfang. Hierzu gehören Ihre Kontaktdaten (z.B. Name, E-Mail Adresse, Telefonnummer), Angaben zu Ihrer beruflichen Qualifikation und Ausbildung, Angaben zu beruflichen Weiterbildungen sowie leistungsspezifische Nachweise.` }} />
        <Paragraph {...{ margin: true, context: `Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme und der Entscheidung über die Begründung eines Beschäftigungsverhältnisses mit Ihnen. Die Bereitstellung der Daten ist erforderlich, um das Bewerbungsverfahren durchzuführen. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO i.V.m. § 26 Abs. 1 BDSG zur Durchführung vorvertraglicher Maßnahmen (Durchlaufen des Bewerbungsverfahrens als Arbeitsvertragsanbahnung).` }} />
        <Paragraph {...{ margin: true, context: `Soweit Sie uns eine Einwilligung zur Verarbeitung von personenbezogenen Daten für die Aufnahme in unseren Bewerberpool erteilt haben, z.B. durch Abhaken einer Checkbox, erfolgt die Verarbeitung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können Ihre Einwilligung jederzeit uns gegenüber widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ context: `Sofern im Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO bei Bewerbern angefragt werden, wie z.B. Angaben zum Grad der Schwerbehinderung, erfolgt dies auf Grundlage von Art. 9 Abs. 2 lit. b. DSGVO, damit wir die aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit und des Sozialschutzes erwachsenden Rechte ausüben und unseren diesbezüglichen Pflichten nachkommen können.` }} />
        <Paragraph {...{ margin: true, context: `Wir speichern Ihre personenbezogenen Daten solange dies für die Entscheidung über Ihre Bewerbung erforderlich ist. Ihre Daten werden anschließend spätestens nach sechs Monaten gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben. Sofern es im Anschluss an das Bewerbungsverfahren zu einem Beschäftigungsverhältnis kommt, werden die bereitgestellten Daten auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO i.V. m. § 26 Abs. 1 BDSG für die Zwecke der Durchführung des Beschäftigungsverhältnisses weiterverarbeitet und anschließend in die Personalakte überführt.` }} />
    
        <Heading {...{ context: `Erhebung und Verarbeitung bei Verwendung des Bewerbungsformulars` }} />
        <Paragraph {...{ margin: true, context: `Bei der Nutzung des Bewerbungsformulars erheben wir Ihre personenbezogenen Daten nur in dem von Ihnen zur Verfügung gestellten Umfang. Hierzu gehören Ihre Kontaktdaten (z.B. Name, E-Mail Adresse, Telefonnummer), Angaben zu Ihrer beruflichen Qualifikation und Ausbildung, Angaben zu beruflichen Weiterbildungen sowie leistungsspezifische Nachweise.` }} />
        <Paragraph {...{ margin: true, context: `Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme und der Entscheidung über die Begründung eines Beschäftigungsverhältnisses mit Ihnen. Die Bereitstellung der Daten ist erforderlich, um das Bewerbungsverfahren durchzuführen. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO i.V.m. § 26 Abs. 1 BDSG zur Durchführung vorvertraglicher Maßnahmen (Durchlaufen des Bewerbungsverfahrens als Arbeitsvertragsanbahnung).` }} />
        <Paragraph {...{ margin: true, context: `Soweit Sie uns eine Einwilligung zur Verarbeitung von personenbezogenen Daten für die Aufnahme in unseren Bewerberpool erteilt haben, z.B. durch Abhaken einer Checkbox, erfolgt die Verarbeitung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können Ihre Einwilligung jederzeit uns gegenüber widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Sofern im Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO bei Bewerbern angefragt werden, wie z.B. Angaben zum Grad der Schwerbehinderung, erfolgt dies auf Grundlage von Art. 9 Abs. 2 lit. b. DSGVO, damit wir die aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit und des Sozialschutzes erwachsenden Rechte ausüben und unseren diesbezüglichen Pflichten nachkommen können.` }} />
        <Paragraph {...{ margin: true, context: `Wir speichern Ihre personenbezogenen Daten solange dies für die Entscheidung über Ihre Bewerbung erforderlich ist. Ihre Daten werden anschließend spätestens nach sechs Monaten gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben. Sofern es im Anschluss an das Bewerbungsverfahren zu einem Beschäftigungsverhältnis kommt, werden die bereitgestellten Daten auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO i.V. m. § 26 Abs. 1 BDSG für die Zwecke der Durchführung des Beschäftigungsverhältnisses weiterverarbeitet und anschließend in die Personalakte überführt.` }} />
    
        <Heading {...{ context: `Verwendung von Personio` }} />
        <Paragraph {...{ margin: true, context: `Die im Rahmen Ihrer Bewerbung übermittelten Daten werden per TLS-Verschlüsselung übertragen und in einer Datenbank gespeichert. Diese Datenbank wird von der Personio GmbH (Rundfunkplatz 4, 80335 München), welche eine Personalverwaltungs- und Bewerbermanagement-Software anbietet, betrieben. Ihre Bewerberdaten werden an den Dienstleister im Rahmen einer Auftragsverarbeitung weitergegeben. Eine Weitergabe an sonstige Dritte erfolgt nicht.` }} />

        <Heading {...{ context: `Erhebung und Verarbeitung bei Zusendung von Bildern per Upload` }} />
        <Paragraph {...{ margin: true, context: `Wir stellen auf unserer Webseite eine Upload-Funktion für Bilddateien zur Verfügung. Es besteht so die Möglichkeit, Bilder an uns mittels verschlüsselter Datenübertragung zu senden. Mit Übermittlung Ihrer Bilder erheben wir ggf. Ihre personenbezogenen Daten (Abbildung einer identifizierbarer Personen) nur in dem von Ihnen zur Verfügung gestellten Umfang.` }} />
        <Paragraph {...{ margin: true, context: `Die Datenverarbeitung dient dem Zweck personalisierte Produkte zu erstellen. Das übersandte Bild dient hierbei als Vorlage für das Produkt und wird dafür verwendet (bspw. T-Shirt Druck). Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich.` }} />
        <Paragraph {...{ margin: true, context: `Eine Weitergabe Ihrer Daten erfolgt hierbei ggf. an Dienstleister, derer wir uns im Rahmen einer Auftragsverarbeitung bedienen. Eine Weitergabe an sonstige Dritte erfolgt nicht. Das von Ihnen übersandte Bild nutzen wir nur im Rahmen der Leistungserbringung. Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.` }} />
    
        <Heading {...{ context: `Verwendung von HubSpot zur Terminbuchung` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden auf unserer Website die Terminbuchungsfunktion der HubSpot, Inc. (25 First Street Cambridge, MA 02141, USA; “HubSpot” mit einer Niederlassung in Irland HubSpot, 2nd Floor 30 North Wall Quay, Dublin 1, Irland) im Rahmen einer Auftragsverarbeitung. Bei Nutzung dieser Funktion erheben und verarbeiten wir Ihre personenbezogenen Daten (Vor- und Nachname, E-Mail-Adresse und Telefonnummer, Nachrichtentext, ggf. Mitgliedsnummer) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der Terminvergabe sowie der Nutzerfreundlichkeit. HubSpot verwendet Technologien wie Cookies. Dabei können u.a. folgende Informationen erhoben und an HubSpot übermittelt werden: IP-Adresse, Datum und Uhrzeit des Seitenaufrufs, Gerätemodell, Informationen über den von Ihnen verwendeten Browser und das von Ihnen verwendete Betriebssystem sowie den Standort.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden gegebenenfalls in Drittstaaten wie die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). HubSpot hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ context: `Die Verarbeitung Ihrer personenbezogenen Daten zur Terminbuchung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO zur Erfüllung des mit uns geschlossenen Vertrages oder zur Durchführung vorvertraglicher Maßnahmen. Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die durch die Nutzung der Cookies stattfindende Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zum Datenschutz und zur Nutzung von Cookies finden Sie unter <a href="https://legal.hubspot.com/de/privacy-policy" target="_blank">https://legal.hubspot.com/de/privacy-policy</a> und <a href="https://legal.hubspot.com/de/dpa" target="_blank">https://legal.hubspot.com/de/dpa</a>` }} />
    
        <Heading {...{ context: `WhatsApp Business` }} />
        <Paragraph {...{ margin: true, context: `Wenn Sie per WhatsApp mit uns in Geschäftskontakt treten, nutzen wir hierfür die Version WhatsApp Business der WhatsApp Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland; “WhatsApp”). Soweit Sie Ihren Aufenthalt außerhalb des Europäischen Wirtschaftsraumes haben, wird dieser Dienst durch die WhatsApp Inc. (1601 Willow Road, Menlo Park, CA 94025, USA) bereitgestellt.` }} />
        <Paragraph {...{ margin: true, context: `Die Datenverarbeitung dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage. Zu diesem Zweck erheben und verarbeiten wir Ihre bei WhatsApp hinterlegte Mobilfunknummer, falls bereitgestellt Ihren Namen sowie weitere Daten in dem von Ihnen zur Verfügung gestellten Umfang. Wir verwenden für den Dienst ein mobiles Endgerät, in dessen Adressbuch ausschließlich Daten von Nutzern gespeichert sind, die uns über WhatsApp kontaktiert haben. Eine Weitergabe personenbezogener Daten an WhatsApp, ohne dass Sie hierin bereits gegenüber WhatsApp eingewilligt haben, erfolgt damit nicht.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden von WhatsApp an Server der Meta Platforms Inc. in den USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Meta Platforms Inc. hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten. Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.` }} />
        <Paragraph {...{ margin: true, context: `Erfolgt die Kontaktaufnahme aus anderen Gründen, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse am Bereitstellen einer schnellen und einfachen Kontaktaufnahme sowie an der Beantwortung Ihrer Anfrage. <strong>In diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen</strong>.` }} />
        <Paragraph {...{ margin: true, context: `Ihre personenbezogenen Daten nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zu Nutzungsbedingungen und Datenschutz bei Nutzung von WhatsApp finden Sie unter <a href="https://www.whatsapp.com/legal/#terms-of-service" target="_blank">https://www.whatsapp.com/legal/#terms-of-service</a> und <a href="https://www.whatsapp.com/legal/#privacy-policy" target="_blank">https://www.whatsapp.com/legal/#privacy-policy</a>.` }} />
    
        <Heading {...{ context: `Kundenkonto Bestellungen` }} />
        <Heading {...{ context: `Kundenkonto` }} />
        <Paragraph {...{ margin: true, context: `Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegebenen Umfang. Die Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihr Kundenkonto wird anschließend gelöscht.` }} />
    
        <Heading {...{ context: `Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei Bestellungen` }} />
        <Paragraph {...{ context: `Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen Daten nur, soweit dies zur Erfüllung und Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der Daten ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich.` }} />
        <Paragraph {...{ margin: true, context: `Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gewählten Versandunternehmen und Dropshipping Anbieter, Zahlungsdienstleister, Diensteanbieter für die Bestellabwicklung und IT-Dienstleister. In allen Fällen beachten wir strikt die gesetzlichen Vorgaben. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden dabei gegebenenfalls Drittstaaten außerhalb der Europäischen Union übermittelt, für die ein Angemessenheitsbeschluss der EU-Kommission vorliegt.` }} />

        <Heading {...{ context: `Werbung` }} />
        <Heading {...{ context: `Verwendung der E-Mail-Adresse für die Zusendung von Newslettern` }} />
        <Paragraph {...{ margin: true, context: `Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung ausschließlich für eigene Werbezwecke zum Newsletterversand, sofern Sie dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Sie können dazu den Newsletter jederzeit unter Nutzung des entsprechenden Links im Newsletter oder durch Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.` }} />

        <Heading {...{ context: `Verwendung der E-Mail-Adresse für die Zusendung von Direktwerbung` }} />
        <Paragraph {...{ margin: true, context: `Wir nutzen Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung erhalten haben, für die elektronische Übersendung von Werbung für eigene Waren oder Dienstleistungen, die denen ähnlich sind, die Sie bereits bei uns erworben haben, soweit Sie dieser Verwendung nicht widersprochen haben. Die Bereitstellung der E-Mail-Adresse ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an Direktwerbung. <strong><i>Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch Mitteilung an uns widersprechen. Die Kontaktdaten für die Ausübung des Widerspruchs finden Sie im Impressum</i></strong>. Sie können auch den dafür vorgesehenen Link in der Werbe-E-Mail nutzen. Hierfür entstehen keine anderen als die Übermittlungskosten nach den Basistarifen.` }} />
        
        <Heading {...{ context: `Verwendung von HubSpot` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden für den Newsletterversand den Dienst der HubSpot Ireland Ltd. (Ground Floor, Two Dockland Central Guild Street, Dublin 1, Irland; „HubSpot“) im Rahmen einer Auftragsverarbeitung. HubSpot ist ein mit der HubSpot, Inc. (25 First Street, Cambridge, MA 02141 USA) verbundenes Unternehmen.` }} />
        <Paragraph {...{ margin: true, context: `Wir geben die von Ihnen während der Newsletteranmeldung zur Verfügung gestellten Informationen (E-Mail Adresse, ggf. Vor- und Nachname) an HubSpot weiter. Die Datenverarbeitung dient dem Zweck des Newsletterversands und dessen statistischer Auswertung.` }} />
        <Paragraph {...{ margin: true, context: `Um Newsletter-Kampagnen auszuwerten, enthalten die versendeten Newsletter eine 1x1 Pixel Grafik (Tracking Pixel) oder einen Tracking-Link. So können wir feststellen, ob Sie den Newsletter geöffnet haben und ob Sie ggf. integrierte Links angeklickt haben. In dem Zusammenhang erheben wir Ihre personenbezogenen Daten wie bspw. IP-Adresse, Browsertyp- und device sowie den Zeitpunkt. Aus diesen Daten können unter einem Pseudonym Nutzungsprofile erstellt werden. Die erhobenen Daten werden nicht dazu benutzt, Sie persönlich zu identifizieren. Die erhobenen Daten werden lediglich zur statistischen Auswertung zur Verbesserung von Newsletter-Kampagnen genutzt.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden ggf. an Server von HubSpot in den USA übermittelt und dort gespeichert. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). HubSpot hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ margin: true, context: `Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an einem zielgerichteten, werbewirksamen und benutzerfreundlichen Newslettersystem. <strong><i>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen</i></strong>. Nähere Informationen sowie die Datenschutzerklärung von HubSpot finden Sie unter: <a href="https://legal.hubspot.com/de/privacy-policy" target="_blank">https://legal.hubspot.com/de/privacy-policy</a>.` }} />

        <Heading {...{ context: `Zahlungsdienstleister` }} />
        <Heading {...{ context: `Verwendung von PayPal` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden auf unserer Website den Zahlungsdienst PayPal der PayPal (Europe) S.à.r.l. et Cie, S.C.A. (22-24 Boulevard Royal L-2449, Luxemburg; "PayPal"). Die Datenverarbeitung dient dem Zweck, Ihnen die Zahlung über den Zahlungsdienst anbieten zu können. Mit Auswahl und Nutzung von Zahlung via PayPal werden die zur Zahlungsabwicklung erforderlichen Daten an PayPal übermittelt, um den Vertrag mit Ihnen mit der gewählten Zahlart erfüllen zu können. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.` }} />
        <Paragraph {...{ margin: true, context: `Alle PayPal-Transaktionen unterliegen der PayPal-Datenschutzerklärung. Diese finden Sie unter <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full" target="_blank">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>.` }} />
    
        <Heading {...{ context: `Verwendung des Zahlungsdienstleisters Stripe` }} />
        <Paragraph {...{ context: `Wir verwenden auf unserer Website den Zahlungsdienst Stripe der Stripe Payments Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland). Die Datenverarbeitung dient dem Zweck, Ihnen die Zahlung über den Zahlungsdienst anbieten zu können. Mit Auswahl und Nutzung von Stripe werden die zur Zahlungsabwicklung erforderlichen Daten an Stripe übermittelt, um den Vertrag mit Ihnen mit der gewählten Zahlart erfüllen zu können. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Stripe behält sich das Recht vor, ggf. eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren unter Nutzung von Auskunfteien einzuholen. Hierzu übermittelt Stripe die zu einer Bonitätsprüfung benötigten personenbezogenen Daten an eine Auskunftei und verwendet die erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalls für eine abgewogene Entscheidung über die Begründung, Durchführung oder Beendigung des Vertragsverhältnisses. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren berechnet werden und in deren Berechnung unter anderem Anschriftendaten einfließen. Ihre schutzwürdigen Belange werden gemäß den gesetzlichen Bestimmungen berücksichtigt. Die Datenverarbeitung dient dem Zweck der Bonitätsprüfung für eine Vertragsanbahnung. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse am Schutz vor Zahlungsausfall, wenn Stripe in Vorleistung geht.` }} />
        <Paragraph {...{ margin: true, context: `<strong><i>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 Abs. 1 lit. f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten durch Mitteilung an Stripe zu widersprechen</i></strong>. Die Bereitstellung der Daten ist für den Vertragsschluss mit der von Ihnen gewünschten Zahlart erforderlich. Eine Nichtbereitstellung hat zur Folge, dass der Vertrag nicht mit der von Ihnen gewählten Zahlart geschlossen werden kann. Alle Stripe-Transaktionen unterliegen der Stripe-Datenschutzerklärung. Diese finden Sie unter <a href="" target="_blank">https://stripe.com/de/privacy</a>.` }} />
    
        <Heading {...{ context: `Verwendung des Zahlungsdienstleisters Mollie` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden für die Zahlungsabwicklung auf unserer Website den Zahlungsdienstleister Mollie B.V. (Keizersgracht 313, 1016 EE Amsterdam, Niederlande; „Mollie“). Die Datenverarbeitung dient dem Zweck Ihnen verschiedene Zahlungsarten anbieten zu können durch die Zahlungsabwicklung über den Zahlungsdienstleister Mollie. Wenn Sie sich für eine der Zahlungsoptionen des Zahlungsdienstleisters Mollie entschieden haben, werden die zur Zahlungsabwicklung erforderlichen Daten an Mollie übermittelt. Dazu gehören Ihre Bezahldaten (zum Beispiel Bankkontonummer oder Kreditkartennummer), Ihre IP-Adresse, Ihr Internetbrowser und Gerätetyp sowie in manchen Fällen Ihr Vor- und Nachname, Ihre Adressdaten und Informationen über das Produkt oder die Dienstleistung, die Sie bei uns erworben haben. Diese Datenverarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Nähere Informationen zur Datenverarbeitung bei Verwendung des Zahlungsdienstleisters Mollie finden Sie in der dazugehörigen Datenschutzerklärung <a href="https://www.mollie.com/de/privacy" target="_blank">https://www.mollie.com/de/privacy</a>` }} />
    
        <Heading {...{ context: `Cookies` }} />
        <Paragraph {...{ margin: true, context: `Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.` }} />
        <Paragraph {...{ margin: true, context: `Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie vor dem Setzen von Cookies benachrichtigt werden und über die Annahme einzeln entscheiden sowie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können.` }} />
        <Paragraph {...{ margin: true, context: `Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren) können:` }} />
        <Paragraph {...{ context: `Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank">https://support.google.com/accounts/answer/61416?hl=de</a>` }} />
        <Paragraph {...{ context: `Microsoft Edge: <a href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-lB6schen-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-lB6schen-63947406-40ac-c3b8-57b9-2a946a29ae09</a>` }} />
        <Paragraph {...{ context: `Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" target="_blank">https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</a>` }} />
        <Paragraph {...{ margin: true, context: `Safari: <a href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>` }} />

        <Heading {...{ context: `Technisch notwendige Cookies` }} />
        <Paragraph {...{ margin: true, context: `Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben gemacht werden setzen wir nur diese technisch notwendigen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 25 Abs. 2 TTDSG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung der optimalen Funktionalität der Website sowie einer nutzerfreundlichen und effektiven Gestaltung unseres Angebots.` }} />
        <Paragraph {...{ margin: true, context: `<strong>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</strong>` }} />
    
        <Heading {...{ context: `Analyse Werbetracking Kommunikation` }} />
        <Heading {...{ context: `Verwendung von Google Analytics 4` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; „Google“). Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher sowie für Marketing- und Werbezwecke. Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.` }} />
        <Paragraph {...{ margin: true, context: `Dabei können u.a. folgende Informationen erhoben werden: IP-Adresse, Datum und Uhrzeit des Seitenaufrufs, Klickpfad, Informationen über den von Ihnen verwendeten Browser und das von Ihnen verwendete Device (Gerät), besuchte Seiten, Referrer-URL (Webseite, über die Sie unsere Webseite aufgerufen haben), Standortdaten, Kaufaktivitäten. Ihre Daten können von Google mit anderen Daten, wie beispielsweise Ihrem Suchverlauf, Ihren persönlichen Accounts, Ihren Nutzungsdaten anderer Geräte und allen anderen Daten, die Google zu Ihnen vorliegen hat, verknüpft werden.` }} />
        <Paragraph {...{ margin: true, context: `Ihre IP-Adresse wird zuvor durch uns auf eigenen Servern gekürzt. Google erhält so ausschließlich pseudonymisierte Daten.` }} />
        <Paragraph {...{ margin: true, context: `Google verwendet Technologien wie Cookies, Webspeicher im Browser und Zählpixel, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO.` }} />
        <Paragraph {...{ margin: true, context: `Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Die dadurch erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Google hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten. Sowohl Google als auch staatliche US-Behörden haben Zugriff auf Ihre Daten.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://policies.google.com/technologies/partner-sites" target="_blank">https://policies.google.com/technologies/partner-sites</a> und unter <a href="https://policies.google.com/privacy?hl=de&gl=de" target="_blank">https://policies.google.com/privacy?hl=de&gl=de</a>` }} />

        <Heading {...{ context: `Verwendung des Meta Pixel` }} />
        <Paragraph {...{ context: `Wir verwenden auf unserer Website den Meta Pixel der Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland; "Meta"). Meta und wir sind gemeinsam Verantwortliche für die bei Einbindung des Dienstes stattfindende Erhebung Ihrer Daten und Übermittlung dieser Daten an Meta. Grundlage dafür ist eine Vereinbarung zwischen uns und Meta über die gemeinsame Verarbeitung personenbezogener Daten, in der die jeweiligen Verantwortlichkeiten festgelegt werden. Die Vereinbarung ist unter <a href="https://de-de.facebook.com/legal/terms/businesstools aufrufbar" target="_blank">https://de-de.facebook.com/legal/terms/businesstools aufrufbar</a>. Danach sind wir insbesondere verantwortlich für die Erfüllung der Informationspflichten gemäß der Art. 13, 14 DSGVO, für die Einhaltung der Sicherheitsvorgaben des Art. 32 DSGVO im Hinblick auf die korrekte technische Implementierung und Konfiguration des Dienstes sowie zur Einhaltung der Verpflichtungen nach den Art. 33, 34 DSGVO, soweit eine Verletzung des Schutzes personenbezogener Daten unsere Verpflichtungen gemäß der Vereinbarung über die gemeinsame Verarbeitung betrifft. Meta obliegt die Verantwortung, die Betroffenenrechte gemäß den Art. 15 - 20 DSGVO zu ermöglichen, die Sicherheitsvorgaben des Art. 32 DSGVO im Hinblick auf die Sicherheit des Dienstes einzuhalten und die Verpflichtungen nach den Art. 33, 34 DSGVO, soweit eine Verletzung des Schutzes personenbezogener Daten die Verpflichtungen von Meta gemäß der Vereinbarung über die gemeinsame Verarbeitung betrifft.` }} />
        <Paragraph {...{ context: `Die Anwendung dient dem Zweck die Besucher der Website zielgerichtet mit interessenbezogener Werbung in den sozialen Netzwerken Facebook und Instagram anzusprechen. Dazu wurde auf der Website das Remarketing-Tag von Meta implementiert. Über dieses Tag wird beim Besuch der Website eine direkte Verbindung zu den Meta-Servern hergestellt. Hierdurch wird an den Meta-Server übermittelt, welche unserer Seiten Sie besucht haben. Meta ordnet diese Information Ihrem persönlichen Facebook- und/oder Instagram-Benutzerkonto zu. Wenn Sie die sozialen Netzwerke Facebook oder Instagram besuchen, werden Ihnen dann personalisierte, interessenbezogene Ads angezeigt. Die Anwendung dient darüber hinaus dem Zweck, Conversion-Statistiken zu erstellen. Hierbei erfahren wir die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden sowie welche Handlungen nach der Weiterleitung auf diese Website vorgenommen werden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.` }} />
        <Paragraph {...{ context: `Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Meta hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ margin: true, context: `Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Sie können die Remarketing-Funktion „Custom Audiences“ hier deaktivieren. Nähere Informationen zur Erhebung und Nutzung der Daten durch Meta, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Meta unter <a href="https://www.facebook.com/about/privacy/" target="_blank">https://www.facebook.com/about/privacy/</a>.` }} />

        <Heading {...{ context: `Verwendung von Google Ads Conversion-Tracking` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden auf unserer Website das Online-Werbeprogramm „Google Ads“ und in diesem Rahmen Conversion-Tracking (Besuchsaktionsauswertung). Das Google Conversion Tracking ist ein Analysedienst der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; Google).` }} />
        <Paragraph {...{ margin: true, context: `Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies haben eine begrenzte Gültigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen und das Cookie noch nicht abgelaufen ist, können Google und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google Ads-Kunde erhält ein anderes Cookie. Somit besteht keine Möglichkeit, dass Cookies über die Websites von Ads-Kunden nachverfolgt werden können.` }} />
        <Paragraph {...{ context: `Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden, dienen dem Zweck Conversion-Statistiken zu erstellen. Hierbei erfahren wir die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.` }} />
        <Paragraph {...{ context: `Ihre Daten werden gegebenenfalls an die Server der Google LLC in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Google hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen sowie die Datenschutzerklärung von Google finden Sie unter: <a href="https://www.google.de/policies/privacy/" target="_blank">https://www.google.de/policies/privacy/</a>` }} />

        <Heading {...{ context: `Verwendung von Google AdSense` }} />
        <Paragraph {...{ context: `Wir verwenden auf unserer Website die AdSense-Funktion der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; "Google"). Die Datenverarbeitung dient dem Zweck, Werbeflächen auf der Website zu vermieten und auf diesen die Besucher der Website zielgerichtet mit interessenbezogener Werbung anzusprechen. Mittels dieser Funktion werden den Besuchern der Website des Anbieters personalisierte, interessenbezogene Werbung-Anzeigen aus dem Google Display-Netzwerk geschaltet. Dabei verwendet Google Cookies, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Google hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten. Google wird diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen sowie die Datenschutzerklärung von Google finden Sie unter: <a href="https://www.google.com/policies/technologies/ads/" target="_blank">https://www.google.com/policies/technologies/ads/</a> und <a href="https://www.google.de/policies/privacy/" target="_blank">https://www.google.de/policies/privacy/</a>` }} />

        <Heading {...{ context: `Verwendung von HubSpot` }} />
        <Paragraph {...{ context: `Wir verwenden auf unserer Website die Software Marketing Hub der HubSpot, Inc. (25 First Street Cambridge, MA 02141, USA; “HubSpot” mit einer Niederlassung in Irland HubSpot, 2nd Floor 30 North Wall Quay, Dublin 1, Irland) im Rahmen einer Auftragsverarbeitung. Die Datenverarbeitung dient dem Zweck der Analyse unserer Website und ihrer Besucher sowie der Analyse und Auswertung unserer Marketingkampagnen und zur besseren Kundenansprache durch das Schalten gezielter Werbeanzeigen. Dazu werden Cookies eingesetzt, die eine Wiedererkennung des Browsers ermöglichen. Dabei kann u.a. Ihre IP-Adresse erhoben und an HubSpot übermittelt werden. Aus den so erhobenen Daten können unter Verwendung von Pseudonymen Nutzungsprofile erstellt werden.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). HubSpot hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zur Erhebung und Nutzung der Daten durch HubSpot, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Hubspot unter: <a href="https://legal.hubspot.com/de/privacy-policy" target="_blank">https://legal.hubspot.com/de/privacy-policy</a> und <a href="https://legal.hubspot.com/de/dpa" target="_blank">https://legal.hubspot.com/de/dpa</a>.` }} />

        <Heading {...{ context: `Verwendung des Live-Chat-Systems HubSpot` }} />
        <Paragraph {...{ context: `Wir verwenden auf unserer Website das Live-Chat-System der HubSpot, Inc. (25 First Street Cambridge, MA 02141, USA; “HubSpot” mit einer Niederlassung in Irland HubSpot, 2nd Floor 30 North Wall Quay, Dublin 1, Irland) im Rahmen einer Auftragsverarbeitung.` }} />
        <Paragraph {...{ context: `Die Datenverarbeitung dient dem Zweck der unmittelbaren und effizienten Kommunikation zwischen Ihnen und uns als Anbieter. Um das Live-Chat-System zu betreiben, werden zudem Cookies eingesetzt, die die Wiedererkennung des Browsers ermöglichen. Unter anderem können folgende Informationen erhoben und verarbeitet werden: Datum und Uhrzeit des Aufrufs, IP-Adresse sowie weitere, von Ihnen im Chatverlauf zur Verfügung gestellte Informationen.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). HubSpot hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zur Erhebung und Nutzung Ihrer Daten durch HubSpot finden Sie unter <a href="https://legal.hubspot.com/privacy-policy" target="_blank">https://legal.hubspot.com/privacy-policy</a> und <a href="https://www.hubspot.com/security" target="_blank">https://www.hubspot.com/security</a>` }} />

        <Heading {...{ context: `Plug-ins und Sonstiges` }} />
        <Heading {...{ context: `Verwendung von GoogleMaps` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden auf unserer Website die Funktion zur Einbettung von GoogleMaps-Karten der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland, "Google"). Die Funktion ermöglicht die visuelle Darstellung von geographischen Informationen und interaktiven Landkarten. Dabei werden von Google bei Aufrufen der Seiten, in die GoogleMaps-Karten eingebunden sind, auch Daten der Besucher der Websites erhoben, verarbeitet und genutzt.` }} />
        <Paragraph {...{ margin: true, context: `Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Google hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ context: `Nähere Informationen zur Erhebung und Nutzung der Daten durch Google finden Sie in den Datenschutzhinweisen von Google unter <a href="https://www.google.com/privacypolicy.html" target="_blank">https://www.google.com/privacypolicy.html</a>. Dort haben Sie auch im Datenschutzcenter die Möglichkeit Ihre Einstellungen zu verändern, so dass Sie Ihre von Google verarbeiteten Daten verwalten und schützen können.` }} />

        <Heading {...{ context: `Verwendung von OpenStreetmap` }} />
        <Paragraph {...{ context: `Wir verwenden auf unserer Website den Open-Source-Mapping-Dienst der OpenStreetMap Foundation (St John's Innovation Centre, Cowley Road, Cambridge, CB4 0WS, United Kingdom; „OpenStreetmap“). Die Datenverarbeitung dient dem Zweck der visuellen Darstellung von geographischen Informationen und Landkarten um Ihnen unseren Standort darzustellen.` }} />
        <Paragraph {...{ context: `Hierbei können Cookies eingesetzt werden. Unter anderem können folgende Informationen erhoben und verarbeitet werden: Datum und Uhrzeit des Aufrufs, IP-Adresse sowie Informationen zu dem von Ihnen genutzten Browser und Gerät. Diese Informationen werden Ihrem persönlichen Benutzerkonto zugeordnet, falls Sie ein Benutzerkonto bei OpenStreetMap haben und beim Besuch der Webseite dort angemeldet sind. In dem Fall werden u.a. folgende zusätzliche Informationen erhoben und verarbeitet: User ID, E-Mail-Adresse, welche dem Benutzerkonto zugeordnet ist sowie vom Benutzer blockierte Inhalte. Ihre Daten werden dabei gegebenenfalls auch außerhalb der EU in die das Vereinigte Königreich übermittelt. Für das Vereinigte Königreich ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden.` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zur Datenverarbeitung und zum Datenschutz finden Sie unter <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy?tid=331640695983" target="_blank">https://wiki.osmfoundation.org/wiki/Privacy_Policy?tid=331640695983</a>.` }} />

        <Heading {...{ context: `Verwendung von YouTube` }} />
        <Paragraph {...{ context: `Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; „YouTube“).YouTube ist ein mit der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”) verbundenes Unternehmen.` }} />
        <Paragraph {...{ margin: true, context: `Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die Option „Erweiterter Datenschutzmodus“ aktiviert. Dadurch werden von YouTube keine Informationen über die Besucher der Website gespeichert. Erst wenn Sie sich ein Video ansehen, werden Informationen darüber an YouTube übermittelt und dort gespeichert. Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). YouTube hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische Datenschutzgrundsätze einzuhalten.` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von YouTube unter <a href="https://www.youtube.com/t/privacy" target="_blank">https://www.youtube.com/t/privacy</a>.` }} />

        <Heading {...{ context: `Verwendung von Vimeo` }} />
        <Paragraph {...{ margin: true, context: `Wir verwenden auf unserer Website Plug-ins der Vimeo Inc. (555 West 18th Street New York, New York 10011, USA; “Vimeo”) zur Einbindung von Videos des Portals “Vimeo”. Wenn Sie mit einem solchen Plug-in versehene Seiten unserer Website aufrufen, wird eine Verbindung zu den Servern von Vimeo hergestellt und dabei das Plug-in durch Mitteilung an Ihren Browser auf der Seite dargestellt. Hierdurch wird an die Server von Vimeo sowohl Ihre IP-Adresse als auch die Information, welche unserer Seiten Sie besucht haben, übermittelt. Sind Sie dabei bei Vimeo eingeloggt, ordnet Vimeo diese Information Ihrem persönlichen Benutzerkonto zu. Bei der Nutzung der Plug-in-Funktionen (z.B. durch Start eines Videos durch Betätigung des entsprechenden Buttons) werden auch diese Informationen Ihrem Vimeo-Konto zugeordnet.` }} />
        <Paragraph {...{ context: `Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Vimeo ist nicht nach dem TADPF zertifiziert. Die Datenübermittlung erfolgt u.a. auf Grundlage von Standardvertragsklauseln als geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbarunter: <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de" target="_blank">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_de</a>` }} />
        <Paragraph {...{ context: `Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.` }} />
        <Paragraph {...{ margin: true, context: `Nähere Informationen zu Zweck und Umfang der Erhebung sowie zur weiter gehenden Nutzung und Verarbeitung der Daten durch Vimeo sowie über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Vimeo: <a href="https://vimeo.com/privacy" target="_blank">https://vimeo.com/privacy</a>.` }} />

        <Heading {...{ context: `Betroffenenrechte und Speicherdauer` }} />
        <Heading {...{ context: `Dauer der Speicherung` }} />
        <Paragraph {...{ margin: true, context: `Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.` }} />
        
        <Heading {...{ context: `Rechte der betroffenen Person` }} />
        <Paragraph {...{ margin: true, context: `Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.` }} />
        <Paragraph {...{ margin: true, context: `Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.` }} />
        
        <Heading {...{ context: `Beschwerderecht bei der Aufsichtsbehörde` }} />
        <Paragraph {...{ margin: true, context: `Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.` }} />
        <Paragraph {...{ margin: true, context: `Eine Beschwerde können Sie unter anderem bei der für uns zuständigen Aufsichtsbehörde einlegen, die Sie unter folgenden Kontaktdaten erreichen:` }} />
        <Paragraph {...{ context: `Der Landesbeauftragte für den Datenschutz Niedersachsen` }} />
        <Paragraph {...{ context: `Prinzenstraße 5` }} />
        <Paragraph {...{ context: `30159 Hannover` }} />
        <Paragraph {...{ context: `Tel.: +49 511- 120 4500` }} />
        <Paragraph {...{ margin: true, context: `E-Mail: <a href="mailto:poststelle@lfd.niedersachsen.de">poststelle@lfd.niedersachsen.de</a>` }} />

        <Heading {...{ context: `Widerspruchsrecht` }} />
        <Paragraph {...{ margin: true, context: `Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO, haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit Wirkung für die Zukunft zu widersprechen.` }} />
        <Paragraph {...{ margin: true, context: `Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.` }} />
        <Paragraph {...{ context: `Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der Direktwerbung, können Sie dieser Verarbeitung jederzeit durch Mitteilung an uns widersprechen. Nach erfolgtem Widerspruch beenden wir die Verarbeitung der betroffenen Daten zum Zwecke der Direktwerbung.` }} />
    </>
}

export default PrivacyPolicy;