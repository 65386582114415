import { useRef, useEffect, useState, useCallback } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import './style.css'
import { Link } from "react-router-dom";

export const Card = ({ heading, mainFunctions, className, active, children, search, info }) => {
    const cardRef = useRef(null);
    const btnGroupRef = useRef(null);
    const dropdownRef = useRef(null);
    const [functionsArray, setFunctionsArray] = useState(mainFunctions)

    const hasOnlyOneLabel = (arr) => {
        const labelCount = arr?.reduce((count, item) => {
            return count + (item.hasOwnProperty('label') ? 1 : 0);
        }, 0);

        return labelCount === 1;
    }

    const onlyOneLabel = hasOnlyOneLabel(mainFunctions);

    const classToggle = useCallback(() => {
        if (btnGroupRef.current === null || dropdownRef.current === null) return
        const mobileDevice = cardRef.current.clientWidth <= 768

        let filteredFunctions = mainFunctions?.filter((item) => !item.hasOwnProperty('component'));

        if (mobileDevice) {
            setFunctionsArray(filteredFunctions)
        } else {
            setFunctionsArray(mainFunctions)
        }

        if (onlyOneLabel && mobileDevice) {
            // Wenn nur ein Label vorhanden ist und der Bildschirm klein ist, Dropdown ausblenden und Button-Gruppe anzeigen
            btnGroupRef.current.classList.add('d-block');
            btnGroupRef.current.classList.remove('d-none');
            dropdownRef.current.classList.add('d-none');
            dropdownRef.current.classList.remove('d-block');
        } else {
            btnGroupRef.current.classList.add((cardRef.current.clientWidth > 768) ? 'd-flex' : 'd-none')
            dropdownRef.current.classList.add(cardRef.current.clientWidth > 768 ? 'd-none' : 'd-block')
            btnGroupRef.current.classList.remove(cardRef.current.clientWidth > 768  ? 'd-none' : 'd-block')
            dropdownRef.current.classList.remove(cardRef.current.clientWidth > 768 ? 'd-block' : 'd-none')
        }
    }, [mainFunctions, onlyOneLabel])

    useEffect(() => {
        if (cardRef.current === null || btnGroupRef.current === null || dropdownRef.current === null) return

        classToggle()

        window.addEventListener('resize', () => classToggle())
        return () => window.removeEventListener('resize', () => classToggle())
    }, [mainFunctions, classToggle])

    return <div ref={cardRef} className={`card__wrapper w-100 ${className !== undefined ? className : ''}`}>
        <div className="card__heading__wrapper">
            <div className="card__heading">
                <div className="d-flex align-items-center">
                    <h5>{heading}</h5>
                    {info ? <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip>{info}</Tooltip>}>
                        <FontAwesomeIcon icon={faCircleInfo} className="ms-3" />
                    </OverlayTrigger> : <></>}
                </div>

                {/* {Boolean(mainFunctions?.length) && <>
                    <div ref={btnGroupRef} className="btn-group" role="group">
                        {mainFunctions.map(func => <OverlayTrigger
                            key={`main-function-${Math.random()}`}
                            trigger={['hover', 'focus']}
                            overlay={<Tooltip>{func["label"]}</Tooltip>}
                        >
                            <Button variant="outline-primary" onClick={func['onClick']} className={`${active === func["key"] ? ' active' : ''}`} disabled={func['disabled']}>
                                {func?.icon ? (
                                    <FontAwesomeIcon icon={func.icon} size="sm" />
                                ) : (
                                    func["label"]
                                )}
                            </Button>
                        </OverlayTrigger>)}
                    </div>

                    <div ref={dropdownRef} className="dropdown">
                        <Button variant="outline-primary" className="d-flex" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faEllipsisVertical} size="sm" />
                        </Button>

                        <ul className="dropdown-menu dropdown-menu-end">
                            {mainFunctions.map(func => <li key={`main-function-${Math.random()}`}>
                                <Button variant="outline-primary" className={`dropdown-item${active === func["key"] ? ' active' : ''}`} onClick={func['onClick']}>{func["label"]}</Button>
                            </li>)}
                        </ul>
                    </div>
                </>} */}

                {Boolean(mainFunctions?.length) && <>
                    <div ref={btnGroupRef} className="btn-group" role="group">
                        {functionsArray?.map(func => {
                            if (func.key === "searchFileSmallDevice" && btnGroupRef.current) return

                            if (func.link)
                                return (
                                    <Link to={func['link']} key={`main-function-${func.key}`}>
                                        <OverlayTrigger
                                            key={`main-function-${func.key}`}
                                            trigger={['hover', 'focus']}
                                            overlay={<Tooltip>{func["label"]}</Tooltip>}
                                        >
                                            <Button
                                                variant="outline-primary"
                                                className={`${active === func["key"] ? ' active' : ''} p-10 flex-shrink-0`}
                                                disabled={func['disabled']}
                                                style={{ minWidth: "38px" }}
                                            >
                                                {func?.icon ? (
                                                    <FontAwesomeIcon icon={func.icon} size="sm" />
                                                ) : (
                                                    func["label"]
                                                )}
                                            </Button>
                                        </OverlayTrigger>
                                    </Link>
                                );

                            if (func.component)
                                return <div key={func.key}>{func.component}</div>

                            // Andernfalls handelt es sich um Btn
                            return (
                                <OverlayTrigger
                                    key={`main-function-${func.key}`}
                                    trigger={['hover', 'focus']}
                                    overlay={<Tooltip>{func["label"]}</Tooltip>}
                                >
                                    <Button
                                        variant="outline-primary"
                                        onClick={func['onClick']}
                                        className={`${active === func["key"] ? ' active' : ''} p-10 flex-shrink-0`}
                                        style={{ minWidth: "38px" }}
                                        disabled={func['disabled']}
                                    >
                                        {func?.icon ? (
                                            <FontAwesomeIcon icon={func.icon}  />
                                        ) : (
                                            func["label"]
                                        )}
                                    </Button>
                                </OverlayTrigger>
                            );
                        })}
                    </div>

                    <div ref={dropdownRef} className="dropdown">
                        <Button variant="outline-primary" className="d-flex p-10" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: "36px" }}>
                            <FontAwesomeIcon icon={faEllipsisVertical} size="sm" />
                        </Button>

                        <ul className="dropdown-menu dropdown-menu-end">
                            {mainFunctions.map(func => {
                                if (func.component) {
                                    // Falls es eine Komponente ist, ignorieren wir sie im Dropdown
                                    return null;
                                }

                                return (
                                    <li key={`main-function-${func.key}`}>
                                        <Button
                                            variant="outline-primary"
                                            className={`dropdown-item${active === func["key"] ? ' active' : ''}`}
                                            onClick={func['onClick']}
                                        >
                                            {func["label"]}
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>}
            </div>
            <div className="card__divider"></div>
        </div>
        
        <div className="card__body">
            {children}
        </div>
    </div>
}

export default Card;