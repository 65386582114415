import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import { createBrowserRouter, RouterProvider, useLocation, useParams } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './RouteType';
import { useAuth, LoginProvider } from './Context';
import { ToastProvider } from './NotificationsContent';
import { useToast } from './NotificationsContent';
import { Navigate } from "react-router";
import NavBar from './widgets/Navbar';
import Sidebar from './widgets/Sidebar';
import ToastNotification from './components/ToastNotification';
import Canvas from './canvas/Canvas';
import { Wrapper } from './style/StyledContainer';
import CookieBanner from './components/CookieBanner';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from './ErrorBoundary';
import Temperaturemap from './temperature-map/Temperaturemap';
import GrantOverview from './granted/GrantOverview';
import BuildingLog from './BuildingPage/BuildingLog';
import { GetSideBarArray } from './BuildingPage/BuildingDashboard';
import BuildingLevel from './BuildingPage/BuildingLevel';
import { faHouse, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Loader } from './style/Loader';

import CredentialWrapper from './login/CredentialWrapper'
import Login from './login/Login';
import Register from './login/Register';
import Forget from './login/Forget';
import Reset from './login/Reset';
import TwoFactor from './login/TwoFactor';
import Organisation from './login/Organisation';
// import BuildingPlant from './BuildingPage/BuildingPlant';
import BuildingPictures from './BuildingPage/BuildingPictures';
import BuildingContacts from './BuildingPage/BuildingContacts';
import BuildingUnits from './BuildingPage/BuildingUnits';
import BuildingInvoice from './BuildingPage/BuildingInvoice';
import BuildingAddons from './BuildingPage/BuildingProducts';
import BuildingReports from './BuildingPage/BuildingReports';
import BuildingSettings from './BuildingPage/BuildingSettings';
import BuildingDevices from './BuildingPage/BuildingDevices';
import BuildingEnergyFlow from './BuildingPage/BuildingEnergyFlow';
import BuildingVariants from './BuildingPage/BuildingVariants';

import Digimeta from './intern/digimeta/Digimeta';
import i18n from 'i18next';
import Signup from './login/Signup';
import BoldSign from './companyProfile/BoldSign';
import PrivacyPolicy from './legal/PrivacyPolicy';
import TermsAndConditions from './legal/TermsAndConditions';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import SchemaNew from './schema/SchemaNew';
import BuildingHeatparts from './BuildingPage/BuildingHeatparts';
import { PermissionsProvider } from './context/PermissionsContext';
import { SwaggerProvider } from './context/SwaggerContext';
import { deleteCookie, getCookie, setCookie } from './util/helpers';
import BuildingDocuments from './BuildingPage/BuildingDocuments';
import BuildingActions from './BuildingPage/BuildingActions';
import BuildingSubsidiesCatalog from './BuildingPage/BuildingSubsidiesCatalog';
import EnergyReports from './dashboard/EnergyReports';
import BuildingVariantActual from './BuildingPage/BuildingVariantActual';
import TermConditions from './login/TermConditions';
import BuildingCounters from './BuildingPage/BuildingCounters';
import ActiveUsers from './components/ActiveUsers/ActiveUsers';
import BuildingHeatpartsSortableTable from './BuildingPage/BuildingHeatpartsSortableTable';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Settings = lazy(() => import('./settings/Settings'));
const CompanyProfile = lazy(() => import('./companyProfile/CompanyProfile'));
const BuildingPageWidget = lazy(() => import('./BuildingPage/BuildingDashboard'));
const BuildingsList = lazy(() => import('./BuildingListV2/BuildingList'));

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();
await msalInstance.handleRedirectPromise();

export const Fallback = () => {
    return (
        <div className='d-flex vh-100 vw-100 justify-content-center align-items-center'>
            <Loader />
        </div>
    )
}

const NotificationsCenter = () => {
    // eslint-disable-next-line no-unused-vars
    const { toasts, _, removeToast } = useToast();

    return <ToastNotification {...{ toasts, handleCloseToast: removeToast }} />
}

export const NavigationWrapper = ({ children }) => {
    const { t } = useTranslation();
    const _orgGuid = useParams()['orgGuid'];
    const _buildingGuid = useParams()['buildingGuid'];

    const { login } = useAuth();
    const [toggle, setToggle] = useState(false);

    const linksArray = [
        {
            icon: faHouse,
            label: t('homepage'),
            to: `/${login?.currentOrganisation?.id}/dashboard`,
        }, {
            icon: faBuilding,
            label: t('buildings'),
            to: `/${login?.currentOrganisation?.id}/buildings`,
            array: GetSideBarArray()
        }
    ]

    return <>
        <NavBar {...{ toggle, setToggle, linksArray: (_orgGuid || login?.currentOrganisation?.id) ? linksArray : [] }} />
        <Sidebar {...{ sidebarArray: GetSideBarArray() }} />
        {_buildingGuid && <ActiveUsers/>}
        {children}
        <NotificationsCenter />
    </>
}

function App() {

    const usePrevious = (value) => {
        const ref = useRef()
        useEffect(() => { ref.current = value })
      
        return ref.current
    }
      
    const useLocationChange = (action) => {
        const location = useLocation()
        const prevLocation = usePrevious(location)
        useEffect(() => { 
            action(location, prevLocation) 
        }, [location])
    }

    const WithUI = (props) => {
        const { login, doSetLogin, _, __, defaultTheme } = useAuth();
        // Detect refresh or Change of Location
        const location = useLocation();
        useEffect(() => {
            if (new Date(login.valid_until) <= new Date()) doSetLogin(null);
        }, [location, login, doSetLogin]);

        useEffect(() => { 
            let htmlTag = document.getElementsByTagName('html')[0];
            defaultTheme === 'dark' ? htmlTag.classList.add('dark') : htmlTag.classList.remove('dark');
        }, [])

        useLocationChange((location, prevLocation) => {
            if (prevLocation?.pathname === "/settings") {

                let htmlTag = document.getElementsByTagName('html')[0];
                defaultTheme === 'dark' ? htmlTag.classList.add('dark') : htmlTag.classList.remove('dark');

                i18n.changeLanguage(login?.settings?.language_code || "en");
            }
        })

        if (login.currentOrganisation === undefined && location.pathname !== "/settings") return <Navigate to={`/`} replace />;
        if (login.currentOrganisation !== undefined && location.pathname === "/settings" && login.currentOrganisation.in_onboarding !== 3 ) return login.currentOrganisation = undefined

        return <>
            <NavigationWrapper />

            <Wrapper>
                <ErrorBoundary key={Math.random()}>
                    {props.children}
                </ErrorBoundary>
            </Wrapper>

            <CookieBanner />
        </>;
    }

    function DashboardNavigate() {
        const { login } = useAuth();
        const location = useLocation();

        useEffect(() => {
            if (getCookie('sigmaheat_moodle') === null) return
            const days = login?.stay_logged_in ? 365 : 1;
            setCookie('sigmaheat_user', { login: login?.id, api: process.env.REACT_APP_API_URL }, days, '.sigmaheat.de');
            deleteCookie('sigmaheat_moodle', undefined, '.sigmaheat.de');
            window.location.replace("https://academy.sigmaheat.de");
        }, [login])

        if (location.pathname === "/settings") return <WithUI><Suspense fallback={<Fallback />}><Settings /></Suspense></WithUI>;
        // if (login.currentOrganisation !== undefined) return <Navigate to={`/${login.currentOrganisation.id}/dashboard`} replace />;
        if (login.currentOrganisation !== undefined && login.currentOrganisation.in_onboarding === 3) return <Navigate to={`/${login.currentOrganisation.id}/dashboard`} replace />;
        if (login.currentOrganisation !== undefined && login.currentOrganisation.in_onboarding < 3) login.currentOrganisation = undefined 

        return <><CredentialWrapper><Organisation/></CredentialWrapper><NotificationsCenter /></>
    }

    const acceptsInput = (e) => {
        if (!e) return false
        let tag = e.tagName;
        return tag === 'INPUT' || tag === 'SELECT' || tag === 'TEXTAREA' || e.isContentEditable || e.tabIndex >= 0;
    } 

    useEffect(() => {
        document.addEventListener('touchend', (e) => {
            let target = e.target;
            if (!acceptsInput(target)) document.activeElement.blur()
        })
    }, [])

    const router = createBrowserRouter([
        {
            path: "/",
            element: <PrivateRoute />,
            children: [
                {
                    path: "/",
                    element: <DashboardNavigate />
                }, {
                    path: "/settings",
                    element: <WithUI><Suspense fallback={<Fallback />}><Settings /></Suspense></WithUI>
                }, {
                    path: "/2fa",
                    element: <><TwoFactor /><NotificationsCenter /></>
                }, {
                    path: "/terms",
                    element: <><TermConditions /><NotificationsCenter /></>
                }, {
                    path: "/:orgGuid/dashboard",
                    element: <WithUI><Suspense fallback={<Fallback />}><Dashboard /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/dashboard",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingPageWidget /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/devices",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingDevices /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/counters",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingCounters /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/energyflow",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingEnergyFlow /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/heatparts",
                    // element: <WithUI><Suspense fallback={<Fallback />}><BuildingHeatparts /></Suspense></WithUI>
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingHeatpartsSortableTable /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/actions",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingActions /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/subsidiescatalog",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingSubsidiesCatalog /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/actualvariant",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingVariantActual /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/variants",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingVariants /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/log",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingLog /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/units",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingUnits /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/level",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingLevel /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/reports",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingReports /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/pictures",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingPictures /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/contacts",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingContacts /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/documents",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingDocuments /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/invoices",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingInvoice /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/settings",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingSettings /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/addons",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingAddons /></Suspense></WithUI>
                }, {
                    path: "org/:orgGuid/energyreport",
                    element: <WithUI><Suspense fallback={<Fallback />}><EnergyReports /></Suspense></WithUI>
                }, {
                    path: "org/:orgGuid/energyreport/:eReportGuid",
                    element: <WithUI><Suspense fallback={<Fallback />}><EnergyReports /></Suspense></WithUI>
                }, {
                    path: "/:orgGuid/buildings",
                    element: <WithUI><Suspense fallback={<Fallback />}><BuildingsList /></Suspense></WithUI>
                }, {
                    path: "/:orgGuid/orgprofile",
                    element: <WithUI><Suspense fallback={<Fallback />}><CompanyProfile /></Suspense></WithUI>
                }, {
                    path: "/:orgGuid/document/:docuID",
                    element: <WithUI><Suspense fallback={<Fallback />}><BoldSign /></Suspense></WithUI>
                }, {
                    path: "/canvas",
                    element: <WithUI><Suspense fallback={<Fallback />}><Canvas canvasHeight={500} canvasWidth={1024} /></Suspense></WithUI>
                }, {
                    path: "building/:buildingGuid/variant/:variantID/schema/:schemaID",
                    element: <WithUI><Suspense fallback={<Fallback />}><SchemaNew /></Suspense></WithUI>
                }, {
                    path: "/temperaturemap",
                    element: <WithUI><Suspense fallback={<Fallback />}><Temperaturemap /></Suspense></WithUI>
                }, {
                    path: "/granted",
                    element: <WithUI><Suspense fallback={<Fallback />}><GrantOverview /></Suspense></WithUI>
                }, {
                    path: "/intern/digimeta",
                    element: <WithUI><Suspense fallback={<Fallback />}><Digimeta /></Suspense></WithUI>
                },
            ],
        }, {
            path: "/",
            element: <PublicRoute />,
            children: [
                {
                    path: "/login",
                    element: <><CredentialWrapper><Login /></CredentialWrapper><NotificationsCenter /></>
                }, {
                    path: "/forget",
                    element: <><CredentialWrapper><Forget /></CredentialWrapper><NotificationsCenter /></>
                }, {
                    path: "/reset/:token",
                    element: <><CredentialWrapper><Reset /></CredentialWrapper><NotificationsCenter /></>
                }, {
                    path: "/register",
                    element: <><CredentialWrapper><Register /></CredentialWrapper><NotificationsCenter /></>
                }, {
                    path: "/signup",
                    element: <><CredentialWrapper><Signup /></CredentialWrapper><NotificationsCenter /></>
                }, {
                    path: "*",
                    element: <Navigate to={'/login'} replace />
                }
            ],
        }, {
            path: "/privacy-policy",
            element: <PrivacyPolicy />
        }, {
            path: "/termsconditions",
            element: <TermsAndConditions />
        }
    ]);

    return <SwaggerProvider>
        <LoginProvider>
            <ToastProvider>
                <PermissionsProvider>
                    <MsalProvider instance={msalInstance}>
                        <RouterProvider router={router} />
                    </MsalProvider>
                </PermissionsProvider>
            </ToastProvider>
        </LoginProvider>
    </SwaggerProvider>
}

export default App;