class Transform {
    constructor(ctx) {
        this.ctx = ctx;
        this.s = 1;
        this.dx = 0;
        this.dy = 0;
    }
    
    scale(s) {
        this.ctx.scale(s, s);
        this.s *= 1 / s;
        this.dx *= 1 / s;
        this.dy *= 1 / s;
    }
    
    translate(dx, dy) {
        this.ctx.translate(dx, dy);
        this.dx -= dx;
        this.dy -= dy;
    }
    
    transform({ x, y }) {
        return {
            x: this.s * x + this.dx,
            y: this.s * y + this.dy
        };
    }
}

export class CanvasClass {
    constructor(canvas, boundaries, image, setData) {
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.canvas.width = boundaries.width;
        this.ctx.canvas.height = boundaries.height;
        this.transform = new Transform(this.ctx);

        this.dragging = false;
        this.dragTL = false;
        this.dragBL = false;
        this.dragTR = false;
        this.dragBR = false;
        this.startX = null;
        this.startY = null;
        this.isDown = false;
        this.closeEnough = 5;
        this.rectangles = [];
        this.image = image;
        this.selectedRectangle = undefined;
        this.selectedRectangleIndex = -1;
        this.isTyping = false;
        this.degree = 0;
        this.scaledWidth = 0;
        this.scaledHeight = 0;
        this.setData = setData;
        
        this.canvas.addEventListener('wheel', e => this.onWheel(e));
        this.canvas.addEventListener('mousedown', e => this.onMouseDown(e));
        this.canvas.addEventListener('mousemove', e => this.onMouseMove(e));
        this.canvas.addEventListener('mouseup', e => this.onMouseUpOut(e));
        this.canvas.addEventListener('mouseout', e => this.onMouseUpOut(e));
        this.canvas.addEventListener('contextmenu', e => this.onContextMenu(e));
        this.canvas.addEventListener('dblclick', e => this.onDoubleClick(e));

        document.addEventListener("click", () => {
            document.getElementById("context-menu")?.remove();
            if (this.selectedRectangleIndex !== -1) {
                this.selectedRectangle = undefined;
                this.selectedRectangleIndex = -1;
                this.draw();
            }
        });

        document.addEventListener('keydown', (e) => {
            if (this.isTyping && this.selectedRectangleIndex !== -1) {
                if (e.key === 'Enter') {
                    this.isTyping = false;
                    this.selectedRectangleIndex = -1
                    this.selectedRectangle = undefined
                } else if (e.key === 'Backspace') {
                    this.rectangles[this.selectedRectangleIndex].value = this.rectangles[this.selectedRectangleIndex].value.substring(0, this.rectangles[this.selectedRectangleIndex].value.length - 1);
                } else {
                    this.rectangles[this.selectedRectangleIndex].value += e.key;
                }
                this.draw();
            }
        });
        
        this.draw();
    }

    clearCanvas() {
        const width = this.ctx.canvas.width;
        const storedTransform = this.ctx.getTransform();
        this.ctx.canvas.width = width;
        this.ctx.setTransform(storedTransform);
    }

    getWindowToCanvas(e) { 
        e = e || window.event;
        var target = e.target || e.srcElement,
            style = target.currentStyle || window.getComputedStyle(target, null),
            borderLeftWidth = parseInt(style["borderLeftWidth"], 10),
            borderTopWidth = parseInt(style["borderTopWidth"], 10),
            rect = target.getBoundingClientRect(),
            offsetX = e.clientX - borderLeftWidth - rect.left,
            offsetY = e.clientY - borderTopWidth - rect.top;
        let x = (offsetX * target.width) / target.clientWidth;
        let y = (offsetY * target.height) / target.clientHeight;

        var transform = this.ctx.getTransform();
        const invMat = transform.invertSelf();
        return {
            x: x * invMat.a + y * invMat.c + invMat.e,
            y: x * invMat.b + y * invMat.d + invMat.f
        };
    }

    leftClick(e) {
        return e.button === 0 ? 1 : 0;
    }

    drawImage() {
        const scaleX = this.canvas.width / this.image.width;
        const scaleY = this.canvas.height / this.image.height;
        const scaleFactor = Math.min(scaleX, scaleY);

        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;

        this.scaledWidth = this.image.width * scaleFactor;
        this.scaledHeight = this.image.height * scaleFactor;

        this.ctx.save();
        this.ctx.translate(centerX, centerY);
        this.ctx.rotate(this.degree * Math.PI / 180);
        this.ctx.translate(-centerX, -centerY);
        this.ctx.drawImage(this.image, centerX - this.scaledWidth / 2, centerY - this.scaledHeight / 2, this.scaledWidth, this.scaledHeight);
        this.ctx.restore();
    }

    drawCircle(x, y, radius, color) {
        this.ctx.fillStyle = color;
        this.ctx.beginPath();
        this.ctx.arc(x, y, radius, 0, 2 * Math.PI);
        this.ctx.fill();
    }

    draw() {
        this.clearCanvas();
        this.drawImage();
        this.rectangles.forEach((rectangle, index) => {
            // Draw Rectangles
            this.ctx.strokeStyle = index === this.selectedRectangleIndex ? this.isTyping ? 'orange' : 'red' : 'blue';
            this.ctx.strokeRect(rectangle.x, rectangle.y, rectangle.width, rectangle.height);
            
            // Draw Values
            const x = rectangle.x + rectangle.width / 2;
            const y = rectangle.y + rectangle.height / 2;
            this.ctx.font = '20px Arial';
            this.ctx.fillStyle = "#000000";
            this.ctx.textAlign = 'center';
            this.ctx.textBaseline = 'middle';
            this.ctx.fillText(rectangle.value, x, y);

            // Draw Circles
            this.drawCircle(rectangle.x, rectangle.y, this.closeEnough, index === this.selectedRectangleIndex ? this.isTyping ? 'orange' : 'red' : 'blue');
            this.drawCircle(rectangle.x + rectangle.width, rectangle.y, this.closeEnough, index === this.selectedRectangleIndex ? this.isTyping ? 'orange' : 'red' : 'blue');
            this.drawCircle(rectangle.x + rectangle.width, rectangle.y + rectangle.height, this.closeEnough, index === this.selectedRectangleIndex ? this.isTyping ? 'orange' : 'red' : 'blue');
            this.drawCircle(rectangle.x, rectangle.y + rectangle.height, this.closeEnough, index === this.selectedRectangleIndex ? this.isTyping ? 'orange' : 'red' : 'blue');
        });

        this.getInfos();
    }

    insertRectangle(e) {
        if (!this.isDown) return;
        const coordinates = this.getWindowToCanvas(e);
        const mouseX = coordinates.x;
        const mouseY = coordinates.y;
    
        if (this.selectedRectangleIndex !== -1) {
            this.selectedRectangleIndex = -1;
        } else {
            const width = mouseX - this.startX;
            const height = mouseY - this.startY;

            if (width < 0) this.startX = mouseX;
            if (height < 0) this.startY = mouseY;
        
            const newRectangle = {
                x: this.startX,
                y: this.startY,
                width: Math.abs(width),
                height: Math.abs(height),
                value: "",
            };

            this.rectangles = [...this.rectangles, newRectangle]
        }
        this.draw();
    }

    checkCloseEnough(p1, p2) {
        return Math.abs(p1 - p2) < this.closeEnough;
    }
    
    mouseOffset(e) {
        return {
            x: e.clientX - this.canvas.getBoundingClientRect().x,
            y: e.clientY - this.canvas.getBoundingClientRect().y
        };
    }
    
    onMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();

        document.getElementById("context-menu")?.remove();
        this.isDown = true;

        if (this.isTyping) {
            this.isTyping = false;
            this.selectedRectangle = undefined;
            this.selectedRectangleIndex = -1;
        }

        if (!this.leftClick(e)) {
            this.isDown = false;
            this.dragging = false;
            this.dragTL = this.dragTR = this.dragBL = this.dragBR = false;
            this.selectedRectangle = undefined;
            this.selectedRectangleIndex = -1;
            return;
        }

        const coordinates = this.getWindowToCanvas(e);
        this.startX = coordinates.x;
        this.startY = coordinates.y;

        this.rectangles.forEach((rect, index) => {
            // Check if clicked over the edge
            if (this.checkCloseEnough(this.startX, rect.x) && this.checkCloseEnough(this.startY, rect.y)) {
                this.dragTL = true;
                this.selectedRectangle = rect;
                this.selectedRectangleIndex = index;
            } else if (this.checkCloseEnough(this.startX, rect.x + rect.width) && this.checkCloseEnough(this.startY, rect.y)) {
                this.dragTR = true;
                this.selectedRectangle = rect;
                this.selectedRectangleIndex = index;
            } else if (this.checkCloseEnough(this.startX, rect.x) && this.checkCloseEnough(this.startY, rect.y + rect.height)) {
                this.dragBL = true;
                this.selectedRectangle = rect;
                this.selectedRectangleIndex = index;
            } else if (this.checkCloseEnough(this.startX, rect.x + rect.width) && this.checkCloseEnough(this.startY, rect.y + rect.height)) {
                this.dragBR = true;
                this.selectedRectangle = rect;
                this.selectedRectangleIndex = index;
            }
        })

        const clickedRectangleIndex = this.rectangles.findIndex((rectangle) =>
            this.startX >= rectangle.x &&
            this.startX <= rectangle.x + rectangle.width &&
            this.startY >= rectangle.y &&
            this.startY <= rectangle.y + rectangle.height
        );

        if (clickedRectangleIndex !== -1) {
            this.selectedRectangle = this.rectangles[clickedRectangleIndex];
            this.selectedRectangleIndex = clickedRectangleIndex;
            this.draw();
        }

        this.dragStart = this.transform.transform(this.mouseOffset(e));

        if (e.ctrlKey) {
            this.isDown = false;
            this.dragging = true;
            this.dragTL = this.dragTR = this.dragBL = this.dragBR = false;
            this.selectedRectangle = undefined;
            this.selectedRectangleIndex = -1;
        } else {
            this.dragging = false;
        }
    }
    
    onMouseMove(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!e.ctrlKey) this.dragging = false;

        if (this.dragging) {
            const offset = this.mouseOffset(e);
            const dragEnd = this.transform.transform(offset);
            const dx = dragEnd.x - this.dragStart.x;
            const dy = dragEnd.y - this.dragStart.y;

            this.transform.translate(dx, dy);
            this.draw();
            this.dragStart = this.transform.transform(offset);
        }

        if (!this.isDown) return;

        let coordinates = this.getWindowToCanvas(e);
        const mouseX = coordinates.x
        const mouseY = coordinates.y

        if (this.selectedRectangleIndex !== -1) {
            const newRectangles = [...this.rectangles];

            if (this.dragTL === false && this.dragTR === false && this.dragBL === false && this.dragBR === false) {
                const offsetX = mouseX - this.startX;
                const offsetY = mouseY - this.startY;
        
                newRectangles[this.selectedRectangleIndex] = {
                    ...this.selectedRectangle,
                    x: this.selectedRectangle.x + offsetX,
                    y: this.selectedRectangle.y + offsetY
                };
            } else {
                if (this.dragTL) {
                    newRectangles[this.selectedRectangleIndex] = {
                        ...this.selectedRectangle,
                        width: Math.abs(this.selectedRectangle.width + this.selectedRectangle.x - mouseX),
                        height: Math.abs(this.selectedRectangle.height + this.selectedRectangle.y - mouseY),
                        x: this.selectedRectangle.width + this.selectedRectangle.x - mouseX < 0 ? this.selectedRectangle.width + this.selectedRectangle.x : mouseX,
                        y: this.selectedRectangle.height + this.selectedRectangle.y - mouseY < 0 ? this.selectedRectangle.height + this.selectedRectangle.y : mouseY
                    };
                } else if (this.dragTR) {
                    newRectangles[this.selectedRectangleIndex] = {
                        ...this.selectedRectangle,
                        width: Math.abs(this.selectedRectangle.x - mouseX),
                        height: Math.abs(this.selectedRectangle.height + this.selectedRectangle.y - mouseY),
                        x: this.selectedRectangle.x - mouseX < 0 ? this.selectedRectangle.x : mouseX,
                        y: this.selectedRectangle.height + this.selectedRectangle.y - mouseY < 0 ? this.selectedRectangle.height + this.selectedRectangle.y : mouseY
                    };
                } else if (this.dragBL) {
                    newRectangles[this.selectedRectangleIndex] = {
                        ...this.selectedRectangle,
                        width: Math.abs(this.selectedRectangle.width + this.selectedRectangle.x - mouseX),
                        height: Math.abs(this.selectedRectangle.y - mouseY),
                        x: this.selectedRectangle.width + this.selectedRectangle.x - mouseX < 0 ? this.selectedRectangle.width + this.selectedRectangle.x : mouseX,
                        y: this.selectedRectangle.y - mouseY < 0 ? this.selectedRectangle.y : mouseY
                    };
                } else if (this.dragBR) {
                    newRectangles[this.selectedRectangleIndex] = {
                        ...this.selectedRectangle,
                        width: Math.abs(this.selectedRectangle.x - mouseX),
                        height: Math.abs(this.selectedRectangle.y - mouseY),
                        x: this.selectedRectangle.x - mouseX < 0 ? this.selectedRectangle.x : mouseX,
                        y: this.selectedRectangle.y - mouseY < 0 ? this.selectedRectangle.y : mouseY,
                    };
                }
            }

            this.rectangles = newRectangles;
            this.draw()
        } else {
            const width = mouseX - this.startX;
            const height = mouseY - this.startY;
            this.draw()
            this.ctx.strokeRect(this.startX, this.startY, width, height);
        }
    }
    
    onMouseUpOut(e) {
        e.preventDefault();
        e.stopPropagation();

        if (document.getElementById("context-menu")) return;

        if (this.isDown) {
            let coordinates = this.getWindowToCanvas(e);
            const mouseX = coordinates.x
            const mouseY = coordinates.y
            const width = Math.abs(mouseX - this.startX);
            const height = Math.abs(mouseY - this.startY);

            if (this.selectedRectangleIndex !== -1) {
                this.selectedRectangle = undefined;
                this.selectedRectangleIndex = -1;
                this.draw();
            } else if (width < 10 || height < 10) {
                this.draw();
            } else {
                this.insertRectangle(e);
            }
        }

        this.isDown = false;
        this.dragTL = false;
        this.dragTR = false;
        this.dragBL = false;
        this.dragBR = false;
        this.dragging = false;
    }
    
    onWheel(e) {
        e.preventDefault();
        e.stopPropagation();

        if (document.getElementById("context-menu")) {
            document.getElementById("context-menu").remove();
            this.selectedRectangle = undefined;
            this.selectedRectangleIndex = -1;
        }
    
        const offset = this.mouseOffset(e);
        const zoomCenter = this.transform.transform(offset);
        const factor = Math.sign(e.deltaY) > 0 ? 0.9 : 1.1;
    
        this.transform.translate(zoomCenter.x, zoomCenter.y);
        this.transform.scale(factor);
        this.transform.translate(-zoomCenter.x, -zoomCenter.y);
        this.draw();
    }

    onContextMenu(e) {
        e.preventDefault();

        document.getElementById("context-menu")?.remove();

        if (e.ctrlKey) return;

        const coordinates = this.getWindowToCanvas(e);
        const contextMenuPosition = this.mouseOffset(e);

        const clickedRectangleIndex = this.rectangles.findIndex((rectangle) =>
            coordinates.x >= rectangle.x &&
            coordinates.x <= rectangle.x + rectangle.width &&
            coordinates.y >= rectangle.y &&
            coordinates.y <= rectangle.y + rectangle.height
        );

        if (clickedRectangleIndex !== -1) {
            this.selectedRectangle = this.rectangles[clickedRectangleIndex];
            this.selectedRectangleIndex = clickedRectangleIndex;
            this.draw();
        

            const contextMenu = document.createElement("div");
            contextMenu.id = "context-menu";
            contextMenu.style.left = `${contextMenuPosition.x}px`;
            contextMenu.style.top  = `${contextMenuPosition.y}px`;
            
            const ul = document.createElement("ul");
            const li = document.createElement("li");
            li.textContent = "Löschen";

            li.addEventListener("click", () => {
                document.getElementById("context-menu").remove();
                this.rectangles.splice(clickedRectangleIndex, 1);
                this.selectedRectangle = undefined;
                this.selectedRectangleIndex = -1;
                this.draw();
            });

            ul.appendChild(li);
            contextMenu.appendChild(ul);

            this.canvas.parentNode.appendChild(contextMenu);
            contextMenu.style.display = "block";
        }
    }

    onDoubleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        document.getElementById("context-menu")?.remove();

        if (e.ctrlKey) return;

        const coordinates = this.getWindowToCanvas(e);

        const clickedRectangleIndex = this.rectangles.findIndex((rectangle) =>
            coordinates.x >= rectangle.x &&
            coordinates.x <= rectangle.x + rectangle.width &&
            coordinates.y >= rectangle.y &&
            coordinates.y <= rectangle.y + rectangle.height
        );

        if (clickedRectangleIndex !== -1) {
            this.isTyping = true;
            this.selectedRectangle = this.rectangles[clickedRectangleIndex];
            this.selectedRectangleIndex = clickedRectangleIndex;
            this.draw();
        }
    }

    changeDegree(degree) {
        this.degree = degree;
        this.draw();
    }

    getInfos() {
        const scaleX = this.image.width / this.scaledWidth;
        const scaleY = this.image.height / this.scaledHeight;
        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;
        const rotationRad = (this.degree * Math.PI) / 180;

        const transformPoint = (x, y) => {
            // Calculate the position of the image's top-left corner on the canvas
            const imageX = centerX - this.scaledWidth / 2;
            const imageY = centerY - this.scaledHeight / 2;

            // Translate the point back to the original image position
            const translatedX = x - imageX;
            const translatedY = y - imageY;

            // Calculate the original x and y with respect to the image's position
            const originalX = (Math.cos(-rotationRad) * translatedX - Math.sin(-rotationRad) * translatedY) * scaleX;
            const originalY = (Math.sin(-rotationRad) * translatedX + Math.cos(-rotationRad) * translatedY) * scaleY;

            return { x: originalX, y: originalY };
        }

        let tempRectangles = [...this.rectangles];

        const rectanglesOnOriginal = tempRectangles.sort((a, b) => a.x - b.x).map((rect) => {
            const transformedTopLeft = transformPoint(rect.x, rect.y);
            const transformedBottomRight = transformPoint(rect.x + rect.width, rect.y + rect.height);
            const originalX = transformedTopLeft.x;
            const originalY = transformedTopLeft.y;
            const originalWidth = transformedBottomRight.x - originalX;
            const originalHeight = transformedBottomRight.y - originalY;
            return { ...rect, x: originalX, y: originalY, width: originalWidth, height: originalHeight };
        });

        this.setData(rectanglesOnOriginal);
    }

    cleanUp(canvas, boundaries, image) {
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.canvas.width = boundaries.width;
        this.ctx.canvas.height = boundaries.height;
        this.transform = new Transform(this.ctx);
        this.rectangles = [];
        this.image = image;
        this.degree = 0;

        this.draw();
    }
}

export default CanvasClass;