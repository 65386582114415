import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "./components/MainLayout";
import Card from "./components/Card";
import Background from "./assets/background_3_0.png"

export const ErrorBoundary = ({ children }) => {
    const { t } = useTranslation();
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(null);

    const logErrorToMyService = (error, errorInfo) => {
        console.log("log", error, errorInfo);
    };

    useEffect(() => {
        const componentDidCatch = (error, errorInfo) => {
            // You can also log the error to an error reporting service
            logErrorToMyService(error, errorInfo);
            setHasError(true);
            setError(error);
        };

        window.addEventListener("error", componentDidCatch);
        window.addEventListener("unhandledrejection", componentDidCatch);

        return () => {
            window.removeEventListener("error", componentDidCatch);
            window.removeEventListener("unhandledrejection", componentDidCatch);
        };
    }, []);

    if (hasError) return <MainLayout {...{ background: Background }}>
        <div style={{ width: "100%", maxWidth: "768px" }}>
            <Card {...{ heading: t('somethingWentWrong')}}>
                <p className="mb-3">{t('errorBoundry.messageOne')} <a className="text-white text-decoration-underline" href="tel:+4951187453685">0511 8745 3685</a> {t('errorBoundry.messageTwo')} <a className="text-white text-decoration-underline" href={`mailto:info@simgaheat.de?subject=Fehler%20im%20Dashboard%20&body=Fehlermeldung: ${error}`}>info@sigmaheat.de</a>.</p>
                <span>{t('errorBoundry.messageThree')}</span>
            </Card>
        </div>
    </MainLayout>;

    return children;
}

export default ErrorBoundary;