import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Modal, Row } from 'react-bootstrap'

function ContentModal({ show, onHide, title, content, topMost, size, footer, fullscreen, doNotShwCloseBtn }) {
    const { t } = useTranslation();

    useEffect(() => {
        // console.log("Footer", footer);
    }, [footer])

    return (
        <Modal
        fullscreen={fullscreen}
            show={show}
            onHide={onHide}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className={topMost ? 'top__most__modal' : ''}
            backdropClassName={topMost ? 'top__most__backdrop' : ''}
            size={size}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>

            {!doNotShwCloseBtn && (
                (footer === undefined || footer) && <Modal.Footer>
                    <Row className='d-flex w-100 justify-content-end'>
                        <Col sm={6} className='p-0'>
                            <Button variant='outline-primary' className='w-100' onClick={onHide}>{t('close')}</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default ContentModal