import Transform from "./Transform";
import Component from "./Component";
import Connection from "./Connection";
import { connectionsColor } from "./utils";
import SchemaBuilder from "./SchemaBuilder";

class Canvas {
    constructor(canvas, boundaries, setChange, setShow, filterHP) {
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.canvas.width = boundaries.width;
        this.ctx.canvas.height = boundaries.height;
        this.transform = new Transform(this.ctx, 1, 0, 0);
        this.ORIGIN = Object.freeze({ x: 0, y: 0 });

        this.grid = true;
        this.gridSize = 25;

        this.closeEnough = 20;
        this.components = [];
        this.deletedComponents = [];
        this.connections = [];

        this.transformedPoint = this.ORIGIN;
        this.draggingComponent = null
        this.connector = null
        this.componentOffset = {
            x: undefined,
            y: undefined
        }

        this.start = this.ORIGIN;
        this.dragStart = this.ORIGIN;

        this.setChange = setChange;
        this.setShow = setShow;
        this.filterHP = filterHP;

        this.schemaBuilder = undefined;

        this.lastClientX = 0;
        this.lastClientY = 0;

        this.canvas.addEventListener('wheel', e => this.onWheel(e), { passive: false });
        this.canvas.addEventListener('pointerdown', e => this.onPointerDown(e), { passive: false });
        this.canvas.addEventListener('pointermove', e => this.onPointerMove(e), { passive: false });
        this.canvas.addEventListener('pointerup', e => this.onPointerUpOut(e), { passive: false });
        this.canvas.addEventListener('pointerout', e => this.onPointerUpOut(e), { passive: false });
        this.canvas.addEventListener('contextmenu', e => this.onContextMenu(e), { passive: false });

        document.addEventListener('dragover', (e) => {
            this.lastClientX = e.clientX;
            this.lastClientY = e.clientY;
        }, { passive: false });

        this.draw = this.draw.bind(this);
        this.updateConnectors = this.updateConnectors.bind(this);
        this.deleteComponent = this.deleteComponent.bind(this);
        this.deleteConnection = this.deleteConnection.bind(this);
        this.addComponent = this.addComponent.bind(this);
        this.append_elements = this.append_elements.bind(this);
        this.addConnections = this.addConnections.bind(this);
    }

    clearCanvas() {
        const width = this.ctx.canvas.width;
        const storedTransform = this.ctx.getTransform();
        this.ctx.canvas.width = width;
        this.ctx.setTransform(storedTransform);
    }

    drawTempLine(startConnector, endPoint) {
        const endX = endPoint.x;
        const endY = endPoint.y;
        const componentPadding = 20
        const padding = 100

        this.ctx.strokeStyle = connectionsColor(startConnector.type);
        this.ctx.beginPath();
        this.ctx.moveTo(startConnector.aX, startConnector.bY);

        const vector1 = { x: endX - startConnector.aX, y: endY - startConnector.bY };
        const vector2 = { x: startConnector.aX - startConnector.aX, y: endY - startConnector.bY };
        const dotProduct = (vector1.x * vector2.x) + (vector1.y * vector2.y);
        const magnitude1 = Math.sqrt(vector1.x ** 2 + vector1.y ** 2);
        const magnitude2 = Math.sqrt(vector2.x ** 2 + vector2.y ** 2);
        const cosTheta = dotProduct / (magnitude1 * magnitude2);
        const angle = Math.acos(cosTheta) * (180 / Math.PI);

        // const connector = this.getConnectorAt(endX, endY);
        // const sameTypeConnector = connector && connector !== selectedConnector && connector.type === selectedConnector.type && connector.component !== selectedConnector.component;

        if (startConnector.direction === 'top') {
            if (endY > startConnector.bY - componentPadding) {
                this.ctx.lineTo(startConnector.aX, (startConnector.bY - componentPadding));

                let yThreshold = endY >= startConnector.bY - componentPadding && endY <= startConnector.bY + startConnector.component.height + padding;
                let xThresholdRight = endX >= startConnector.aX && endX <= startConnector.aX + startConnector.component.width / 2 + componentPadding
                let xThresholdLeft  = endX < startConnector.aX && endX >= startConnector.aX - startConnector.component.width / 2 - componentPadding
                const midY = ((startConnector.bY + endY + startConnector.component.height + componentPadding * 2) / 2) - (padding / 2);

                if (angle <= 45) {
                    if (!yThreshold && !xThresholdRight && !xThresholdLeft) {
                        this.ctx.lineTo(endX, (startConnector.bY - componentPadding));
                    } else if (!yThreshold && !xThresholdRight) {
                        this.ctx.lineTo(startConnector.aX - startConnector.component.width / 2 - componentPadding, (startConnector.bY - componentPadding));
                        this.ctx.lineTo(startConnector.aX - startConnector.component.width / 2 - componentPadding, midY);
                        this.ctx.lineTo(endX, midY);
                    } else if (!yThreshold && !xThresholdLeft) {
                        this.ctx.lineTo(startConnector.aX + startConnector.component.width / 2 + componentPadding, (startConnector.bY - componentPadding));
                        this.ctx.lineTo(startConnector.aX + startConnector.component.width / 2 + componentPadding, midY);
                        this.ctx.lineTo(endX, midY);
                    } else {
                        this.ctx.lineTo(endX, (startConnector.bY - componentPadding));
                    }
                } else {
                    let halfX = endX < startConnector.aX ? Math.abs(endX - startConnector.aX) / 2 : (startConnector.aX - endX) / 2
                    this.ctx.lineTo(endX + halfX, (startConnector.bY - componentPadding));
                    this.ctx.lineTo(endX + halfX, endY);
                }
                this.ctx.lineTo(endX, endY);

            } else {
                if (angle <= 45) {
                    this.ctx.lineTo(startConnector.aX, (startConnector.bY + endY) / 2);
                    this.ctx.lineTo(endX, (startConnector.bY + endY) / 2);
                } else {
                    this.ctx.lineTo(startConnector.aX, endY);
                }
                this.ctx.lineTo(endX, endY);
            }
        }
        else if (startConnector.direction === 'right') {
            if (endX <= startConnector.aX + componentPadding) {
                this.ctx.lineTo(startConnector.aX + componentPadding, startConnector.bY);

                let xThreshold = endX <= startConnector.aX + componentPadding && endX >= startConnector.aX - startConnector.component.width - padding;
                let yThresholdTop = endY <= startConnector.bY && endY >= startConnector.bY - startConnector.component.height / 2 - componentPadding
                let yThresholdBottom = endY > startConnector.bY && endY <= startConnector.bY + startConnector.component.height / 2 + componentPadding

                const midX = ((startConnector.aX + endX - startConnector.component.width - componentPadding * 2) / 2) + (padding / 2);

                if (angle > 45) {
                    if (!xThreshold && !yThresholdTop && !yThresholdBottom) {
                        this.ctx.lineTo((startConnector.aX + componentPadding), endY);
                    } else if (!xThreshold && !yThresholdTop) {
                        this.ctx.lineTo(startConnector.aX + componentPadding, startConnector.bY + startConnector.component.height / 2 + componentPadding);
                        this.ctx.lineTo(midX, startConnector.bY + startConnector.component.height / 2 + componentPadding);
                        this.ctx.lineTo(midX, endY);
                    } else if (!xThreshold && !yThresholdBottom) {
                        this.ctx.lineTo(startConnector.aX + componentPadding, startConnector.bY - startConnector.component.height / 2 - componentPadding);
                        this.ctx.lineTo(midX, startConnector.bY - startConnector.component.height / 2 - componentPadding);
                        this.ctx.lineTo(midX, endY);
                    } else {
                        this.ctx.lineTo(startConnector.aX + componentPadding, endY);
                    }
                } else {
                    let halfY = endY < startConnector.bY ? Math.abs(endY - startConnector.bY) / 2 : (startConnector.bY - endY) / 2
                    this.ctx.lineTo((startConnector.aX + componentPadding), endY + halfY);
                    this.ctx.lineTo(endX, endY + halfY);
                }
                this.ctx.lineTo(endX, endY);
            } else {
                if (angle > 45) {
                    this.ctx.lineTo((startConnector.aX + endX) / 2, startConnector.bY);
                    this.ctx.lineTo((startConnector.aX + endX) / 2, endY);
                } else {
                    this.ctx.lineTo(endX, startConnector.bY);
                }
                this.ctx.lineTo(endX, endY);
            }
        }
        else if (startConnector.direction === 'bottom') {
            if (endY < startConnector.bY + componentPadding) {
                this.ctx.lineTo(startConnector.aX, (startConnector.bY + componentPadding));

                let yThreshold = endY <= startConnector.bY + componentPadding && endY >= startConnector.bY - startConnector.component.height - padding;
                let xThresholdRight = endX >= startConnector.aX && endX <= startConnector.aX + startConnector.component.width / 2 + componentPadding
                let xThresholdLeft  = endX <= startConnector.aX && endX >= startConnector.aX - startConnector.component.width / 2 - componentPadding
                const midY = ((startConnector.bY + endY - startConnector.component.height - componentPadding * 2) / 2) + (padding / 2);

                if (angle <= 45) {
                    if (!yThreshold && !xThresholdRight && !xThresholdLeft) {
                        this.ctx.lineTo(endX, (startConnector.bY + componentPadding));
                    } else if (!yThreshold && !xThresholdRight) {
                        this.ctx.lineTo(startConnector.aX - startConnector.component.width / 2 - componentPadding, (startConnector.bY + componentPadding));
                        this.ctx.lineTo(startConnector.aX - startConnector.component.width / 2 - componentPadding, midY);
                        this.ctx.lineTo(endX, midY);
                    } else if (!yThreshold && !xThresholdLeft) {
                        this.ctx.lineTo(startConnector.aX + startConnector.component.width / 2 + componentPadding, (startConnector.bY + componentPadding));
                        this.ctx.lineTo(startConnector.aX + startConnector.component.width / 2 + componentPadding, midY);
                        this.ctx.lineTo(endX, midY);
                    } else {
                        this.ctx.lineTo(endX, (startConnector.bY + componentPadding));
                    }
                } else {
                    let halfX = endX < startConnector.aX ? Math.abs(endX - startConnector.aX) / 2 : (startConnector.aX - endX) / 2
                    this.ctx.lineTo(endX + halfX, (startConnector.bY + componentPadding));
                    this.ctx.lineTo(endX + halfX, endY);
                }
                this.ctx.lineTo(endX, endY);

            } else {
                if (angle <= 45) {
                    this.ctx.lineTo(startConnector.aX, (startConnector.bY + endY) / 2);
                    this.ctx.lineTo(endX, (startConnector.bY + endY) / 2);
                } else {
                    this.ctx.lineTo(startConnector.aX, endY);
                }
                this.ctx.lineTo(endX, endY);
            }
        }
        else if (startConnector.direction === 'left') {
            if (endX > startConnector.aX - componentPadding) {
                this.ctx.lineTo(startConnector.aX - componentPadding, startConnector.bY);

                let xThreshold = endX >= startConnector.aX - componentPadding && endX <= startConnector.aX + startConnector.component.width + padding;
                let yThresholdTop = endY >= startConnector.bY && endY <= startConnector.bY + startConnector.component.height / 2 + componentPadding;
                let yThresholdBottom = endY < startConnector.bY && endY >= startConnector.bY - startConnector.component.height / 2 - componentPadding;

                const midX = ((startConnector.aX + endX + startConnector.component.width + componentPadding * 2) / 2) - (padding / 2);

                if (angle > 45) {
                    if (!xThreshold && !yThresholdTop && !yThresholdBottom) {
                        this.ctx.lineTo((startConnector.aX - componentPadding), endY);
                    }
                    else if (!xThreshold && !yThresholdTop) {
                        this.ctx.lineTo(startConnector.aX - componentPadding, startConnector.bY - startConnector.component.height / 2 - componentPadding);
                        this.ctx.lineTo(midX, startConnector.bY - startConnector.component.height / 2 - componentPadding);
                        this.ctx.lineTo(midX, endY);
                    }
                    else if (!xThreshold && !yThresholdBottom) {
                        this.ctx.lineTo(startConnector.aX - componentPadding, startConnector.bY + startConnector.component.height / 2 + componentPadding);
                        this.ctx.lineTo(midX, startConnector.bY + startConnector.component.height / 2 + componentPadding);
                        this.ctx.lineTo(midX, endY);
                    }
                    else {
                        this.ctx.lineTo((startConnector.aX - componentPadding), endY);
                    }
                } else {
                    let halfY = endY < startConnector.bY ? Math.abs(endY - startConnector.bY) / 2 : (startConnector.bY - endY) / 2
                    this.ctx.lineTo((startConnector.aX - componentPadding), endY + halfY);
                    this.ctx.lineTo(endX, endY + halfY);
                }
                this.ctx.lineTo(endX, endY);
            } else {
                if (angle > 45) {
                    this.ctx.lineTo((startConnector.aX + endX) / 2, startConnector.bY);
                    this.ctx.lineTo((startConnector.aX + endX) / 2, endY);
                } else {
                    this.ctx.lineTo(endX, startConnector.bY);
                }
                this.ctx.lineTo(endX, endY);
            }
        }

        this.ctx.stroke();
    }

    updateConnectors() {
        this.connections.forEach(connection => connection.updateConnectors())
    }

    draw() {
        this.clearCanvas();
        this.drawGrid();
        this.components.forEach(component => component.draw())
        this.connections.forEach(connection => connection.draw());

        if (this.connector && this.start)
            this.drawTempLine(this.connector, this.start);
    }

    leftClick(e) {
        return e.button === 0 ? 1 : 0;
    }

    pointerOffset(e) {
        return {
            x: e.clientX - this.canvas.getBoundingClientRect().x,
            y: e.clientY - this.canvas.getBoundingClientRect().y
        };
    }

    getTransformedPoint(x, y) {
        const originalPoint = new DOMPoint(x, y);
        return this.ctx.getTransform().invertSelf().transformPoint(originalPoint);
    }

    getWindowToCanvas(e) { 
        e = e || window.event;
        var target = e.target || e.srcElement,
            style = target.currentStyle || window.getComputedStyle(target, null),
            borderLeftWidth = parseInt(style["borderLeftWidth"], 10),
            borderTopWidth = parseInt(style["borderTopWidth"], 10),
            rect = target.getBoundingClientRect(),
            offsetX = e.clientX - borderLeftWidth - rect.left,
            offsetY = e.clientY - borderTopWidth - rect.top;
        let x = (offsetX * target.width) / target.clientWidth;
        let y = (offsetY * target.height) / target.clientHeight;

        var transform = this.ctx.getTransform();
        const invMat = transform.invertSelf();
        return {
            x: x * invMat.a + y * invMat.c + invMat.e,
            y: x * invMat.b + y * invMat.d + invMat.f
        };
    }

    getComponentAt(x, y) {
        return this.components.find(component => {
            const cx = component.x + component.width / 2;
            const cy = component.y + component.height / 2;
            const translatedX = x - cx;
            const translatedY = y - cy;
            
            const angle = -component.rotate * Math.PI / 180;
            const cos = Math.cos(angle);
            const sin = Math.sin(angle);
            const localX = translatedX * cos - translatedY * sin;
            const localY = translatedX * sin + translatedY * cos;
            
            return (
                localX >= -component.width / 2 && localX <= component.width / 2 &&
                localY >= -component.height / 2 && localY <= component.height / 2
            );
        });
    }

    checkCloseEnough(p1, p2) {
        const distance = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
        return distance <= this.closeEnough;
    }

    getConnectorAt() {
        let connectorObj = null

        this.components.forEach(component => {
            const centerX = component.x + component.img.width / 2;
            const centerY = component.y + component.img.height / 2;

            component.connectors?.forEach(connector => {
                const relativeConnectorX = connector.x - (component.img.width / 2);
                const relativeConnectorY = connector.y - (component.img.height / 2);

                const rotatedConnectorX = relativeConnectorX * Math.cos(component.rotate * Math.PI / 180) - relativeConnectorY * Math.sin(component.rotate * Math.PI / 180);
                const rotatedConnectorY = relativeConnectorX * Math.sin(component.rotate * Math.PI / 180) + relativeConnectorY * Math.cos(component.rotate * Math.PI / 180);

                const point = {
                    x: centerX + rotatedConnectorX,
                    y: centerY + rotatedConnectorY
                }

                if (this.checkCloseEnough(point, this.start)) connectorObj = connector;
            });
        });
        return connectorObj;
    }

    onWheel(e) {
        e.preventDefault();
        e.stopPropagation();

        // Remove Context Menu On Zoom
        document.getElementById("context-menu")?.remove();

        // Zoom Logic
        const offset = this.pointerOffset(e);
        const zoomCenter = this.transform.transform(offset);
        let factor = Math.sign(e.deltaY) > 0 ? 0.9 : 1.1;

        // Enforce zoom limits
        const minScale = 0.1; // Minimum zoom level
        const maxScale = 5;  // Maximum zoom level
        let newScale = this.transform.s * factor;

        // Adjust factor so that it doesn't exceed minScale or maxScale
        if (newScale < minScale) {
            factor = minScale / this.transform.s;
            newScale = minScale;
        } else if (newScale > maxScale) {
            factor = maxScale / this.transform.s;
            newScale = maxScale;
        }

        // Only apply transformations if within limits
        if (newScale >= minScale && newScale <= maxScale) {
            this.transform.translate(zoomCenter.x, zoomCenter.y);
            this.transform.scale(factor);
            this.transform.translate(-zoomCenter.x, -zoomCenter.y);
        }

        // Redraw
        this.draw();
    }

    onPointerDown(e) {
        e.preventDefault();
        e.stopPropagation();

        document.getElementById("context-menu")?.remove();

        if (!this.leftClick(e)) {
            this.dragging = false;
            return;
        }

        this.dragStart = this.transform.transform(this.pointerOffset(e));

        if (e.ctrlKey) this.dragging = true;

        this.start = this.getWindowToCanvas(e);
        const component = this.getComponentAt(this.start.x, this.start.y);
        const connector = this.getConnectorAt();
        this.componentOffset = this.getTransformedPoint(e.offsetX, e.offsetY);

        if (component && !connector) this.draggingComponent = component;
        if (connector)
            this.connector = connector;
    }

    onPointerMove(e) {
        e.preventDefault();
        e.stopPropagation();

        
        this.transformedPoint = this.getTransformedPoint(e.offsetX, e.offsetY);
        this.start = this.getWindowToCanvas(e);

        if (!e.ctrlKey) this.dragging = false;

        // Offset aka Panning Calculation
        const offset = this.pointerOffset(e);
        if (this.dragging) {
            const dragEnd = this.transform.transform(offset);
            const dx = dragEnd.x - this.dragStart.x;
            const dy = dragEnd.y - this.dragStart.y;
            this.transform.translate(dx, dy);
            this.dragStart = this.transform.transform(offset);
            this.setChange(true);
        }

        // Update Components Position
        if (this.draggingComponent || this.draggingComponent && !e.ctrlKey) {
            this.draggingComponent.x = this.transformedPoint.x - this.draggingComponent.img.width / 2;
            this.draggingComponent.y = this.transformedPoint.y - this.draggingComponent.img.height / 2;
            this.draggingComponent.updateConnectors()
            this.setChange(true);
        }
        
        // Redraw
        if (this.dragging || this.draggingComponent || this.draggingComponent && !e.ctrlKey || this.connector)
            this.draw();
    }

    deleteComponent(component) {
        component?.removeEventListeners()
        this.deletedComponents.push(component.id)
        this.components.splice(this.components.findIndex(c => (c.x === component.x && c.y === component.y)), 1);

        this.connections = this.connections.filter(connection => {
            const startMatches = component.connectors.some(connector => 
                connector.aX === connection.startConnector.aX && connector.bY === connection.startConnector.bY
            );
            
            const endMatches = component.connectors.some(connector => 
                connector.aX === connection.endConnector.aX && connector.bY === connection.endConnector.bY
            );
            
            return (!startMatches && !endMatches);
        });

        this.setChange(true);
        this.filterHP();
    }

    deleteConnection(conn) {
        this.connections = this.connections.filter(connection => 
            (connection.endConnector.aX !== conn.endConnector.aX || connection.endConnector.bY !== conn.endConnector.bY) &&
            (connection.startConnector.aX !== conn.startConnector.aX || connection.startConnector.bY !== conn.startConnector.bY)
        );
        this.draw()
        this.setChange(true);
    }

    addConnection(startConnector, endConnector) {
        const connection = new Connection(startConnector, endConnector, this.ctx, this.deleteConnection);
        this.connections.push(connection);
        this.setChange(true);
    }

    checkForSplitConnection() {
        let found = false;
        let alreadyConnected = this.connections.find(connection => connection.startConnector.component.x === this.draggingComponent.x && connection.startConnector.component.y === this.draggingComponent.y ||
                                                                   connection.endConnector.component.x   === this.draggingComponent.x && connection.endConnector.component.y   === this.draggingComponent.y)

        this.draggingComponent.connectors?.forEach(connector => {
            this.connections.forEach((connection, index) => {
                if (connection.containsPoint(connector.aX, connector.bY, 10) && connector.component !== connection.startConnector.component && connector.component !== connection.endConnector.component) {
                    
                    if ((connector.type === connection.type || connector.type === 'universal') && !found && !alreadyConnected) {
                        // Create two new connections
                        const newConnection1 = new Connection(connection.startConnector, connector, this.ctx, this.deleteConnection);
                        const newConnection2 = new Connection(connector, connection.endConnector, this.ctx, this.deleteConnection);

                        // Determine the color and type for the new connections
                        newConnection1.color = connectionsColor(connection.type);
                        newConnection1.type = connection.type;
                        newConnection2.color = newConnection1.color;
                        newConnection2.type = newConnection1.type;

                        this.connections.splice(index, 1);
                        this.connections.push(newConnection1)
                        this.connections.push(newConnection2)
                        found = true
                    }
                }
            });
        });
    }

    onPointerUpOut(e) {
        e.preventDefault();
        e.stopPropagation();

        this.dragging = false;

        if (this.draggingComponent) {
            this.checkForSplitConnection();
            this.draggingComponent = null;
        }

        if (this.connector) {
            const mouseX = e.offsetX;
            const mouseY = e.offsetY;
            let connector = this.getConnectorAt(mouseX, mouseY);
            if (connector && connector !== this.connector && (connector.type === this.connector.type || connector.type === 'universal' || this.connector.type === 'universal') && connector.component !== this.connector.component)
                this.addConnection(this.connector, connector);

            this.connector = null;
        }

        // Redraw
        this.draw();
    }

    onContextMenu(e) {
        e.preventDefault();
        document.getElementById("context-menu")?.remove();

        if (e.ctrlKey) return;
    }

    // GRID
    drawGrid() {
        if (!this.grid) return;
        this.ctx.beginPath();

        const visibleWidth = this.canvas.width / this.ctx.getTransform().a;
        const visibleHeight = this.canvas.height / this.ctx.getTransform().d;
        const visibleX = this.transform.dx > this.ctx.getTransform().e ? -(Math.min(this.transform.dx, this.ctx.getTransform().e)) / this.ctx.getTransform().a : -(Math.max(this.transform.dx, this.ctx.getTransform().e)) / this.ctx.getTransform().a;
        const visibleY = this.transform.dy > this.ctx.getTransform().f ? -(Math.min(this.transform.dy, this.ctx.getTransform().f)) / this.ctx.getTransform().d : -(Math.max(this.transform.dy, this.ctx.getTransform().f)) / this.ctx.getTransform().d;

        let extraOffsetX = visibleX % this.gridSize;
        let extraOffsetY = visibleY % this.gridSize;

        this.ctx.lineWidth = 1 / this.ctx.getTransform().a;

        for (let x = visibleX - extraOffsetX; x <= visibleWidth + visibleX; x += this.gridSize) {
            this.ctx.moveTo(x, visibleY);
            this.ctx.lineTo(x, visibleY + visibleHeight);
        }

        for (let y = visibleY - extraOffsetY; y <= visibleHeight + visibleY; y += this.gridSize) {
            this.ctx.moveTo(visibleX, y);
            this.ctx.lineTo(visibleX + visibleWidth, y);
        }
        
        this.ctx.strokeStyle = '#ccc';
        this.ctx.stroke();
    }

    setGrid(value, flag) {
        this.grid = value;
        this.snap = flag;
        this.draw();
    }

    setTransform(affine) {
        this.transform.reset();
        this.transform.apply(affine);
        this.draw();
        this.grid = affine.grid;
        this.snap = affine.snap;
        this.gridSize = affine.gridSize;
    }

    getTransform() {
        return {
            matrix: {
                a: this.ctx.getTransform().a,
                b: this.ctx.getTransform().b,
                c: this.ctx.getTransform().c,
                d: this.ctx.getTransform().d,
                e: this.ctx.getTransform().e,
                f: this.ctx.getTransform().f
            },
            transform: this.transform.getTransform(),
            grid: this.grid,
            gridSize: this.gridSize,
            snap: this.snap
        }
    }

    setComponents(components = [], connections = [], deleted=[]) {
        this.clearData()
        for (const c of components) {
            const width = Math.abs(c.rect.right - c.rect.left)
            const height = Math.abs(c.rect.bottom - c.rect.top)
            let component = new Component(c.id, c.name, c.img, c.part, c.type, c.x, c.y, width, height, c.rotate, this.canvas, this.ctx, c.connection_ports, this.draw, this.updateConnectors, this.deleteComponent, this.setShow)
            this.components.push(component)
        }

        for (const c of connections) {
            this.start = c.start
            const start = this.getConnectorAt()

            this.start = c.end
            const end = this.getConnectorAt()

            if (start && end) {
                const connection = new Connection(start, end, this.ctx, this.deleteConnection);
                this.connections.push(connection);
            }
        }

        this.deletedComponents = deleted;
        this.draw()
    }

    getComponents() {
        return this.components;
    }

    downloadCanvas() {
        const link = document.createElement('a');
        link.href = this.canvas.toDataURL('image/png');
        link.download = 'Schema.png';
        link.click();
    }

    handleDragStart(e, c) {
        console.log('dragstart')
        let component = new Component(c.id, c.name, c.img, c.part, c.type, c.x, c.y, c.img.width, c.img.height, c.rotate, this.canvas, this.ctx, c.connection_ports, this.draw, this.updateConnectors, this.deleteComponent, this.setShow)
        this.components.push(component)
        this.draggingComponent = component;
        this.dragStart = {
            x: e.clientX,
            y: e.clientY
        }
    }

    handleDrag(e) {
        e.preventDefault();
        // Fallback for Firefox
        const clientX = this.lastClientX;
        const clientY = this.lastClientY;

        if (clientX === 0 && clientY === 0) return;
        
        const pointerMoveEvent = new MouseEvent('pointermove', {
            clientX: clientX,
            clientY: clientY,
            bubbles: true,
            cancelable: true
        });
        document.getElementsByClassName('digimeta__canvas')[0].dispatchEvent(pointerMoveEvent);
    }
      
    handleDragEnd(e) {
        console.log('handleDragEnd')
        this.draggingComponent = undefined;
    }

    clearData() {
        this.components.forEach(c => c.removeEventListeners())
        this.connections.forEach(c => c.removeEventListeners())
        this.components  = []
        this.connections = []
        this.deletedComponents = []
    }

    addComponent(config) {
        this.setChange(true);
        return new Component(config.id, config.name, config.img, config.part, config.type, config.x, config.y, config.width, config.height, config.rotate, this.canvas, this.ctx, config.connection_ports, this.draw, this.updateConnectors, this.deleteComponent, this.setShow);
    }

    addConnections(connections) {
        for (const c of connections) {
            this.start = c.start
            const start = this.getConnectorAt()

            this.start = c.end
            const end = this.getConnectorAt()

            if (start && end) {
                const connection = new Connection(start, end, this.ctx, this.deleteConnection);
                this.connections.push(connection);
            }
        }
        this.draw();
    }

    append_elements(components) {
        this.components.push(...components);
    }

    initSchemaBuilder(elements, extra_hp, elementBlueprints) { 
        this.schemaBuilder = new SchemaBuilder(elements, extra_hp, elementBlueprints, this.canvas, this.transform, this.addComponent, this.append_elements, this.addConnections)
    }

    getComponentsAndConnections() {
        return {
            components: this.components,
            connections: this.connections
        }
    }

    getComponentsPayload() {
        const cp = this.components.map(c => {
            return {
                id: c.id,
                name: c.name,
                part: c.part,
                position_x: c.x,
                position_y: c.y,
                rotation: c.rotate,
                type: c.type
            }
        })

        const cn = this.connections.map(c => {
            console.log(c)
            return {
                start: {
                    uuid: c.startConnector.component.part,
                    coordinates: {
                        x: c.startConnector.aX,
                        y: c.startConnector.bY
                    },
                    input_output: c.startConnector.io
                },
                end: {
                    uuid: c.endConnector.component.part,
                    coordinates: {
                        x: c.endConnector.aX,
                        y: c.endConnector.bY
                    },
                    input_output: c.endConnector.io
                },
                lineWidth: c.lineWidth,
                type: c.startConnector.type !== 'universal' ? c.startConnector.type : c.endConnector.type
            }
        })

        const obj = {
            affine_transformation: this.getTransform(),
            components: cp,
            connections: cn,
            deleted: this.deletedComponents
        }

        return obj
    }
}

export default Canvas