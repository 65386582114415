import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Nav, Tab } from "react-bootstrap";

import { RoofSlopeDefault } from "./components/RoofSlope";

export const GableRoof = ({ pitches, room, type, setFormData, buildingParts, roof }) => {
    const MAX = 4;
    const { t } = useTranslation();

    let data = {
        tabs1: { formValue: { width: 0, kneestock: 0, diagonal: 0, dedOne: buildingParts?.[0]?.id, dedTwo: buildingParts?.[0]?.id }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } },
        tabs2: { formValue: { width: 0, kneestock: 0, diagonal: 0, dedOne: buildingParts?.[0]?.id, dedTwo: buildingParts?.[0]?.id }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } },
        tabs3: { formValue: { width: 0, kneestock: 0, diagonal: 0, dedOne: buildingParts?.[0]?.id, dedTwo: buildingParts?.[0]?.id }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } },
        tabs4: { formValue: { width: 0, kneestock: 0, diagonal: 0, dedOne: buildingParts?.[0]?.id, dedTwo: buildingParts?.[0]?.id }, dormer: {
            noOfDoomer: 0,
            noofdeductionareas: 0,
            sync: false,
            dormers: {
                tab1: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab2: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab3: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } },
                tab4: { formValue: { width: 0, height: 0, ClosedSidesNo: 0 } }
            }
        } }
    }

    if (roof) {
        for (let i = 0; i < roof.slanting.length; i++) {
            data[`tabs${i + 1}`].formValue.width = roof.slanting[i].width
            data[`tabs${i + 1}`].formValue.diagonal = roof.slanting[i].diagonal
            data[`tabs${i + 1}`].formValue.kneestock = roof.slanting[i].kneestock
            data[`tabs${i + 1}`].dormer.noofdeductionareas = roof.slanting[i].noofdeductionareas

            for (let j = 0; j < roof.slanting[i].dormer.length; j++) {
                console.log(data[`tabs${i + 1}`].dormer.dormers[`tab${j + 1}`])
                data[`tabs${i + 1}`].dormer.noOfDoomer = roof.slanting[i].dormer.length
                data[`tabs${i + 1}`].dormer.dormers[`tab${j + 1}`].formValue.width = roof.slanting[i].dormer[j]?.width
                data[`tabs${i + 1}`].dormer.dormers[`tab${j + 1}`].formValue.height = roof.slanting[i].dormer[j]?.height
                data[`tabs${i + 1}`].dormer.dormers[`tab${j + 1}`].formValue.ClosedSidesNo = roof.slanting[i].dormer[j]?.ClosedSidesNo
            }
        }
    }

    const [activeSlopeTab, setActiveSlopeTab] = useState('tabs1');
    const [tabSlopeContents, setTabSlopeContents] = useState(data);

    const handleSlopeFormChange = (tabKey, field, value, dormerKey = null, dormerField = null) => {
        setTabSlopeContents((prevContents) => {
            const updatedTab = { ...prevContents[tabKey] };
    
            if (dormerKey && dormerField) {
                updatedTab.dormer.dormers[dormerKey].formValue[dormerField] = value;
            } else if (field === 'noOfDoomer' || field === 'sync' || field === 'noofdeductionareas') {
                updatedTab.dormer[field] = field === 'noofdeductionareas' ? Math.min(parseInt(value), 2) : value;
            } else {
                updatedTab.formValue[field] = value;
            }
    
            return {
                ...prevContents,
                [tabKey]: updatedTab,
            };
        });
    };

    const updateDormers = (eventKey) => {
        setTabSlopeContents((prevContents) => {
            const sourceTabDormer = prevContents[eventKey].dormer.dormers.tab1.formValue;
            const noOfDoomer = prevContents[eventKey].dormer.noOfDoomer;
            const newContents = { ...prevContents };
    
            for (let i = 1; i <= noOfDoomer; i++) {
                const dormerKey = `tab${i}`;
                newContents[eventKey].dormer.dormers[dormerKey].formValue = { ...sourceTabDormer };
            }
    
            return newContents;
        });
    };

    const tabSlopeCount = Math.min(pitches, MAX);

    const navSlopeItems = [];
    const tabSlopePanes = [];

    for (let i = 1; i <= tabSlopeCount; i++) {
        const eventKey = `tabs${i}`;
        navSlopeItems.push(
            <Nav.Item key={eventKey}>
                <Nav.Link eventKey={eventKey}>{t("slanting")} {i}</Nav.Link>
            </Nav.Item>
        );
        tabSlopePanes.push(
            <Tab.Pane eventKey={eventKey} key={eventKey}>
                <RoofSlopeDefault {...{
                    width: tabSlopeContents[eventKey].formValue.width,
                    kneestock: tabSlopeContents[eventKey].formValue.kneestock,
                    diagonal: tabSlopeContents[eventKey].formValue.diagonal,
                    dedOne: tabSlopeContents[eventKey].formValue.dedOne,
                    dedTwo: tabSlopeContents[eventKey].formValue.dedTwo,
                    dormers: tabSlopeContents[eventKey].dormer,
                    sync: tabSlopeContents[eventKey].dormer.sync,
                    noofdeductionareas: tabSlopeContents[eventKey].dormer.noofdeductionareas,
                    buildingParts: buildingParts,
                    onDeductionAreaChange: (value, key) => handleSlopeFormChange(eventKey, key, value),
                    onWidthChange: (value) => handleSlopeFormChange(eventKey, 'width', parseFloat(value)),
                    onKneestockChange: (value) => handleSlopeFormChange(eventKey, 'kneestock', parseFloat(value)),
                    onDiagonalChange: (value) => handleSlopeFormChange(eventKey, 'diagonal',  parseFloat(value)),
                    onNoOfDoomerChange: (value) => handleSlopeFormChange(eventKey, 'noOfDoomer', parseInt(value)),
                    onDormerChange: (dormerKey, dormerField, value) => handleSlopeFormChange(eventKey, null, parseFloat(value), dormerKey, dormerField),
                    onSyncChange: (value) => handleSlopeFormChange(eventKey, 'sync', value),
                    onNoofdeductionareasChange: (value) => handleSlopeFormChange(eventKey, 'noofdeductionareas', parseInt(value)),
                    onSyncAllDormers: () => updateDormers(eventKey)
                    }}
                />
            </Tab.Pane>
        );
    }

    const transformTabSlopeContents = (tabSlopeContents) => {
        const slanting = [];
        
        for (let i = 1; i <= pitches; i++) {
            const tabKey = `tabs${i}`;
            const tabContent = tabSlopeContents[tabKey];
            const { formValue, dormer } = tabContent;
            const noOfDoomer = dormer.noOfDoomer;
            const dormerArray = Object.keys(dormer.dormers).map((dormerKey) => {
                return { ...dormer.dormers[dormerKey].formValue };
            }).slice(0, noOfDoomer);
            slanting.push({
                width: formValue.width,
                kneestock: formValue.kneestock,
                diagonal: formValue.diagonal,
                dormer: dormerArray,
                noofdeductionareas: dormer.noofdeductionareas,
                dedOne: formValue.dedOne,
                dedTwo: formValue.dedTwo
            });
        }

        return {
            room: room,
            slanting: slanting,
            type: type
        };
    };

    useEffect(() => {
        const currentTabIndex = parseInt(activeSlopeTab.replace('tabs', ''), 10);
        if (tabSlopeCount > 0 && (currentTabIndex > tabSlopeCount || currentTabIndex === 0)) setActiveSlopeTab(`tabs${tabSlopeCount}`);
    }, [tabSlopeCount, activeSlopeTab]);


    useEffect(() => {
        setFormData(transformTabSlopeContents(tabSlopeContents))
    }, [tabSlopeContents, pitches, setFormData]);

    return <>
        {pitches > 0 && <Tab.Container key={`tabs-${activeSlopeTab}`} defaultActiveKey={activeSlopeTab} onSelect={(k) => setActiveSlopeTab(k)} className="mb-3">
            <Nav variant="pills" justify className="mb-3">
                {navSlopeItems}
            </Nav>
            <Tab.Content>
                {tabSlopePanes}
            </Tab.Content>
        </Tab.Container>}
    </>
}

export default GableRoof;