import React, { useCallback, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useSwagger } from '../context/SwaggerContext';
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { useToast } from '../NotificationsContent';
import { useParams } from 'react-router-dom';

const AddEditContacts = ({editContact, show, onClose, ...props}) => {
  const client = useSwagger();
  const _buildingGuid = useParams()['buildingGuid'];
  const { login } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [contact, setContact] = useState(editContact)

  const changeHandler = useCallback((e) => {
    const { id, value } = e.target;

    setContact(prevState => ({
        ...prevState,
        [id]: value
    }));
  }, [contact, setContact]);

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
  
    return date.toLocaleDateString('de-DE', options);
  }

  const onSubmit = useCallback(async(e) => {
    e.preventDefault();

    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;
          req.body = JSON.stringify(contact);
          return req;
      };
      
      const response = await client.apis["building"].building_contact_partial_update({building_uuid: _buildingGuid, contact_uuid: contact.id});

      if (response.status >= 200 && response.status < 300) {
        addToast(t("success"), t('editContactPerson'), "success");
        onClose()
      }

      client.http.requestInterceptor = originalRequestInterceptor;

    } catch (error) {
        addToast(t("error"), t('networkError'), "error");
        client.http.requestInterceptor = originalRequestInterceptor;
    }

  }, [client, _buildingGuid, login.Authorization, t, contact]) 

  return (
    <>
      {show === "edit_contact" ? (
        <Form onSubmit={onSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>{t('topic')}</Form.Label>
            <Form.Control id="topic" value={contact['topic'] || ''} type='text' onChange={changeHandler} />
          </Form.Group>

          <div className='d-flex justify-content-end'>
            <Button variant="outline-primary" type='submit'>
              {t('save')}
            </Button>
          </div>
        </Form>
      ) : (
        <Form>
          <Form.Group className='mb-3'>
            <Form.Label>{t('createdAt')}</Form.Label>
            <Form.Control id="topic" value={contact['created_at'] ? formatDate(contact['created_at']) : ''} readOnly disabled type='text' />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>{t('updatedAt')}</Form.Label>
            <Form.Control id="topic" value={contact['updated_at'] ? formatDate(contact['updated_at']) : ''} readOnly disabled type='text' />
          </Form.Group>
        </Form>
      )}
    </>
  );
}

export default AddEditContacts