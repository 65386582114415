export const connectionsColor = (type) => {
    switch (type) {
        case 1: // Gas
            return '#FF8C00'
        case 2: // VL
            return '#008080'
        case 3: // RL 
            return '#8B4513'
        case 4: // WW
            return '#E97451'
        case 5: // KW
            return '#00008B'
        case 6: // ZIRK
            return '#228B22'
        case 7: // Strom
            return '#191970'
        case 8: // Öl
            return '#556B2F '
        default:
            return '#000000'
    }
}