import React, { useEffect, useRef, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import { useAuth } from '../Context';
import Card from "../components/Card"
const LICENCE = process.env.REACT_APP_AMCHART5;

function Piechart({ data, pills, element, heading }) {
    const { login } = useAuth();
    const { t } = useTranslation();
    const [valuePercentTotal, setValuePercentTotal] = useState(false)
    const [heatRules, setHeatRules] = useState(false)

    const [loading, setLoading] = useState(true)
    const rootRef = useRef(null);
    const seriesRef = useRef(null);

    function createChart(div, rootRef, seriesRef, data) {
        am5.addLicense(LICENCE)

        if (document.getElementById(div) === null) return

        // Zerstöre die vorherige Instanz, wenn sie existiert
        if (rootRef.current) {
            rootRef.current.dispose();
        }

        let root = am5.Root.new(div);
        rootRef.current = root;

        seriesRef.current = [];
        
        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create wrapper container
        let container = root.container.children.push(
            am5.ZoomableContainer.new(root, {
            width: am5.p100,
                height: am5.p100,
                wheelable: true,
                pinchZoom: true
            })
        );

        // Hourglass
        let indicator = root.container.children.push(am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layer: 1000,
            background: am5.Rectangle.new(root, {
                // fill: am5.color(0xffffff),
                fillOpacity: 0.7
            })
        }));

        indicator.children.push(am5.Label.new(root, {
            text: "Loading...",
            fill: login.settings.theme_code === 'dark' ? am5.color(0xffd981) : am5.color(0x072c50),
            fontSize: 25,
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50
        }));

        let hourglass = indicator.children.push(am5.Graphics.new(root, {
            width: 32,
            height: 32,
            fill: login.settings.theme_code === 'dark' ? am5.color(0xffd981) : am5.color(0x072c50),
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            dy: -45,
            svgPath: "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z"
        }));

        let hourglassanimation = hourglass.animate({
            key: "rotation",
            to: 180,
            loops: Infinity,
            duration: 2000,
            easing: am5.ease.inOut(am5.ease.cubic)
        });

        // Zoom
        let zoomTools = container.children.push(am5.ZoomTools.new(root, {
            target: container
        }));

        // Create series
        let series = container.contents.children.push(am5hierarchy.Sunburst.new(root, {
            singleBranchOnly: true,
            downDepth: 10,
            initialDepth: 10,
            topDepth: 1,
            innerRadius:am5.percent(20),
            valueField: "value",
            categoryField: "name",
            childDataField: "children",
            calculateAggregates: true
        }));

        // Template
        series.labels.template.setAll({
            fontSize: element === "sunburstChart" ? 10 : 20,
            text: "{category}",
            fill: "#fafafa",
        });

        // hide template
        // series.labels.template.set("forceHidden", true);

        if (valuePercentTotal) {
            series.nodes.template.set("tooltipText", "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({sum} kW)[/]");
        } else {
            series.nodes.template.set("tooltipText", "{category}: [bold]{valuePercent.formatNumber('0.00')}%[/] ({sum} kW)[/]");
        }

        // Slices
        series.slices.template.setAll({
            stroke: am5.color(0xa0a0a),
            // strokeWidth: 2,
        });
        
        // Colors
        if (heatRules) {
            // series.set("heatRules", [{
            //     target: series.slices.template,
            //     min: am5.color(0xe2e6bd),
            //     max: am5.color(0x8e133b),
            //     minValue: 0,
            //     maxValue: 100,
            //     dataField: "valuePercent",
            //     key: "fill"
            // }]);

            series.set("heatRules", [{
                target: series.slices.template,
                dataField: "valuePercent",
                customFunction: function(sprite, min, max, value) {
                    if (value < 15) {
                    sprite.set("fill", am5.color(0xe2e6bd));
                    } else if (value < 30) {
                    sprite.set("fill", am5.color(0xf1de81));
                    } else if (value < 45) {
                    sprite.set("fill", am5.color(0xf6c971));
                    } else if (value < 60) {
                    sprite.set("fill", am5.color(0xeeab65));
                    } else if (value < 75) {
                    sprite.set("fill", am5.color(0xda8459));
                    } else if (value < 90) {
                    sprite.set("fill", am5.color(0xb9534c));
                    } else if (value <= 100) {
                    sprite.set("fill", am5.color(0x8e133b));
                    } 
                }
            }])
        } else {
            series.get("colors").setAll({
                colors: [
                    am5.color(0xf1c40f),
                    am5.color(0xe67e22), 
                    am5.color(0xc2410c), 
                    am5.color(0xb91c1c),
                    am5.color(0x7f1d1d),  
                ],
                step: 1
            });
        }

        // Loading
        if (loading) {
            hourglassanimation.play();
            indicator.show();
        } else {
            hourglassanimation.pause();
            indicator.hide();  
            series.data.setAll(data);
            series.selectDataItem(series.dataItems[0]);
            seriesRef.current.push(series);

            // Make stuff animate on load
            seriesRef.current[0]?.appear(2500, 100);
        }

        return () => root.dispose();
    }

    useEffect(() => {
        if (data) {
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        if (!loading) {
            createChart(element, rootRef, seriesRef, data);
        }
    }, [loading, setLoading, valuePercentTotal, heatRules]);

    // Effect for updating chart data
    useEffect(() => {
        if (!loading && data && seriesRef.current?.length > 0) {
            seriesRef.current[0]?.data.setAll(data);
            seriesRef.current[0]?.selectDataItem(seriesRef.current[0]?.dataItems[0]);
        }
    }, [data, loading]);


    return (
        <>
            <div>
                <Card {...{ heading: heading, active: true, className: "height-fit mb-3" }}>
                    <div id={element} className="chart-div"/>
                </Card>
            </div>

            {
                pills && (
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link
                                className={valuePercentTotal ? "table-nav-link" : "table-nav-link-active"}
                                onClick={() => {setValuePercentTotal(!valuePercentTotal)}}
                            >
                                {t("percentValueParent")}
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                className={heatRules ? "table-nav-link-active" : "table-nav-link"}
                                onClick={() => {setHeatRules(!heatRules)}}
                            >
                                {`${t("heatmap")}`}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                )    
            }
            
        </>
    );
}

export default Piechart