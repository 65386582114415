import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useAuth } from '../Context'
import { SHButton, SHCheck } from '../style/SigmaHeatTheme'
import { useTranslation } from 'react-i18next'

function CustomerSelection(props) {
    // eslint-disable-next-line no-unused-vars
    const {login, doSetLogin} = useAuth();
    const [visible, setVisibility] = useState(props.open);
    const [selection, setSelection] = useState(false);
    const [window, setWindow] = useState(false);
    const customers = login.customers || [];
    customers.sort((c1, c2) => (c1.customer.name > c2.customer.name ? 1 : c1.customer.name < c2.customer.name ? -1 : 0));
    const { t } = useTranslation();

    const setCustomer = () => {
        const newCustomer = customers.find(c => c.customer.guid === selection);
        login.currentCustomer = newCustomer;
        doSetLogin(login);
        setVisibility(false);
        localStorage.setItem('sigmaheat-customer-selection', window ? 2 : 1);
    };

    return (
        <Modal
            show={visible}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            onHide={() => {setVisibility(false)}}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>{t('clientsAccount')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {customers.map((c) => <SHCheck 
                    type='radio'
                    id={`radioselection-${c.customer.guid}}`}
                    label={c.customer.name}
                    name='customerSelection'
                    onChange={() => setSelection(c.customer.guid)}
                />)}
            </Modal.Body>
            <Modal.Footer>
                <div className='modal-footer-center'>
                    <SHCheck 
                        type='checkbox'
                        id='asknexttime'
                        label={`Dieses Fenster nicht mehr anzeigen`}
                        onChange={() => {setWindow(!window)}}
                    />
                    <SHButton onClick={() => {setCustomer()}} disabled={!selection}>{t('apply')}</SHButton>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default CustomerSelection