import React from "react";

const Heading = ({ context }) => {
    return <h5 className="mb-3">{context}</h5>
}

const Paragraph = ({ context, margin }) => {
    return <p className={`${margin ? "mb-3" : "m-0" }`}>{context}</p>
}

export const TermsAndConditions = () => {

    return <>
        <Heading {...{ context: `Allgemeine Geschäfts- und Nutzungsbedingungen und Kundeninformationen der Softwareanwendung „Sigmaheat-App“` }} />
        <Paragraph {...{ context: `Sigmaheat GmbH` }} />

        <Heading {...{ context: `I. Allgemeine Nutzungsbedingungen` }} />
        <Heading {...{ context: `1. Grundlegende Bestimmungen` }} />
        <Paragraph {...{ context: `Die nachstehenden Allgemeinen Geschäfts- und Nutzungsbedingungen gelten für die Nutzung der von der Sigmaheat GmbH, vertr. d.d. Geschäftsführer Maik Brinkmann, Lange Laube 2, 30159 Hannover (nachfolgend „Anbieter“ genannt) angebotenen Softwareanwendung „Sigmaheat App “ und die hierin geschlossenen Verträge durch die Nutzer (nachfolgend als „Nutzer“ bezeichnet) dieser Software.` }} />
        <Paragraph {...{ context: `Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls vom Nutzer verwendeter eigener Bedingungen widersprochen.` }} />
        <Paragraph {...{ context: `Die Angebote des Anbieters richten sich ausschließlich an Unternehmer. Unternehmer gem. § 14 Abs. 1 BGB ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.` }} />
        <Paragraph {...{ context: `Die Vertragssprache ist deutsch.` }} />

        <Heading {...{ context: `2. Vertragsgegenstand` }} />
        <Paragraph {...{ context: `Gegenstand des Vertrages ist die entgeltliche Bereitstellung der cloudbasierten SaaS-Anwendung „Sigmaheat-App “ (nachfolgend als „Software“ bezeichnet) durch den Anbieter in der jeweils aktuellen Version auf einem Server zur Nutzung über das Medium Internet unter Einräumung der erforderlichen Nutzungsrechte an der Software.` }} />
        <Paragraph {...{ context: `Die Software dient der Optimierung des Betriebs von Heizungsanlagen in Wohn- und Geschäftsgebäuden sowie die damit verwandten Aufgaben, wie Verbrauchsdarstellung und Reporting.` }} />
        <Paragraph {...{ context: `Die Software befindet sich beim Anbieter. Der Anbieter hält auf einer zentralen Datenverarbeitungsanlage oder mehreren Datenverarbeitungsanlagen (im Folgenden „Server“ genannt) die Software in der jeweils aktuellen Version zur Nutzung bereit. Der Anbieter bedient sich zur Bereitstellung der Datenverarbeitungsanlagen der Dienste Dritter. Der Nutzer stimmt dem zu.` }} />
        <Paragraph {...{ context: `Die wesentlichen Leistungsmerkmale finden sich im jeweiligen Angebot und den Angaben auf der Webseite des Anbieters.` }} />
        <Paragraph {...{ context: `Darüber hinausgehende Leistungen, etwa zur Datenmigration oder zur technischen Unterstützung bei der Integration der Schnittstellen bedürfen einer gesonderten Vereinbarung.` }} />

        <Heading {...{ context: `3. Anlegen eines Nutzerkontos/ Zustandekommen des Vertrages` }} />
        <Paragraph {...{ context: `Voraussetzung für die Nutzung der Software ist die Einrichtung eines Nutzer-Accounts. Die Einrichtung eines Nutzer-Accounts ist für den Nutzer kostenfrei.` }} />
        <Paragraph {...{ context: `Über die Checkbox „Registrieren“ kann der Nutzer einen Account anlegen. Das Anlegen eines Benutzerkontos kann auf zwei Wegen erfolgen:` }} />
        <Paragraph {...{ context: `Der Nutzer gibt zunächst seine E-Mail-Adresse ein. Anschließend wird der Nutzer auf eine neue Seite weitergeleitet und erhält parallel auf die hinterlegte E-Mail-Adresse einen Code, den er auf der Weiterleitungsseite eintragen muss. Nach Aufruf der Seite, auf dem der Code eingetragen werden muss und das System die Richtigkeit des Codes verifiziert hat, kann der Nutzer seine Kontaktdaten eingeben sowie Benutzername und Passwort festlegen.` }} />
        <Paragraph {...{ context: `Dem Nutzer steht weiter die Möglichkeit bereit sich über einen Einladungslink ein Nutzerkonto einzurichten. Hierbei erhält der potenzielle Nutzer einen Einladungslink über den er auf die Registrierungsseite weitergeleitet wird. Anschließend kann der Nutzer seine Kontaktdaten eingeben sowie Benutzername und Passwort festlegen.` }} />
        <Paragraph {...{ context: `Der entgeltliche Vertrag kommt innerhalb der Software wie folgt zustande: Der Nutzer legt innerhalb der Software über die Schaltfläche „Organisation anlegen“ eine Organisationseinheit an. Eine Organisationseinheit entspricht einem Immobilienkomplex in der die Leistungen entsprechend Ziff. 2 dieser AGB abgebildet werden. Hierfür gibt der Nutzer den Namen und die Anschrift der Organisationseinheit an. Anschließend wählt der Nutzer die Art und die Anzahl an Nutzerlizenzen aus sowie den Zeitpunkt der Zahlung und bestätigt dies durch Anklicken der entsprechend bezeichneten Schaltfläche.  Nach Bestätigung wird der Nutzer auf eine Übersichtsseite weitergeleitet, auf der eine Buchungsübersicht angezeigt wird und die Zahlungsinformationen eingegeben werden müssen. Vor Absenden der Buchung hat der Nutzer die Möglichkeit sämtliche Angaben nochmals zu überprüfen, zu ändern, bzw. die Buchung abzubrechen. Mit dem Absenden der Buchung über die entsprechende Schaltfläche erklärt der Nutzer rechtsverbindlich die Annahme des Angebots, wodurch der Vertrag zustande kommt.` }} />
        <Paragraph {...{ context: `Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.` }} />

        <Heading {...{ context: `4. Mängelhaftung, Support` }} />
        <Paragraph {...{ context: `Der Anbieter haftet dafür, dass die bereitgestellte Software während der Vertragslaufzeit frei von Mängeln ist und für die Zwecke geeignet ist.  Als Mangel gelten Abweichungen von der Leistungsbeschreibung, soweit diese den Wert oder die Eignung zur üblichen, dort beschriebenen Verwendung nicht nur unerheblich beeinträchtigen oder wenn der Anbieter die für die vertraglich vereinbarte Verwendung erforderlichen Rechte dem Nutzer nicht wirksam einräumen konnte. Ein unerheblicher Mangel lässt Mängelansprüche nicht entstehen.` }} />
        <Paragraph {...{ context: `Die Mängelbehebung erfolgt nach Wahl des Anbieters entweder durch Fehlerbeseitigung, durch Überlassung eines neuen Softwarestandes, durch das Aufzeigen von Möglichkeiten, die Auswirkungen des Fehlers zu vermeiden oder  durch Bereitstellung einer Umgehungslösung, welche die Laufzeit und das Antwortzeitverhalten der Software nicht unangemessen behindert.` }} />
        <Paragraph {...{ context: `Gerät der Anbieter mit der betriebsfähigen Bereitstellung der Software oder der Mängelbeseitigung in Verzug, so richtet sich die Haftung nach § 13. Der Kunde ist zur Minderung der Vergütung oder zum Rücktritt vom Vertrag berechtigt, wenn der Anbieter eine vom Kunden gesetzte zweiwöchige Nachfrist nicht einhält, d.h. innerhalb der Nachfrist nicht die volle vereinbarte Funktionalität der Software zur Verfügung stellt.` }} />
        <Paragraph {...{ context: `Der Anbieter wird während der Supportzeit ordnungsgemäße Störungsmeldungen des Nutzers entgegennehmen, den vereinbarten Störungskategorien zuordnen und anhand dieser Zuordnung die vereinbarten Maßnahmen zur Analyse und Bereinigung von Störungen durchführen.` }} />

        <Heading {...{ context: `5. Softwareüberlassung, Softwarepflege` }} />
        <Paragraph {...{ context: `Der Anbieter entwickelt die Software laufend weiter und wird diese durch laufende Updates (Bündelung mehrere Mängelbehebungen in der Software) und Upgrades (funktionale Verbesserungen und/oder Anpassungen der Software) sowie neue Versionen (Änderungen der Funktionalitäten der Software) verbessern. Die Upgrades und neuen Versionen können vom Anbieter kostenfrei oder kostenpflichtig angeboten werden. Der Anbieter darf dabei den Funktionsumfang der Software für ihren bestimmungsgemäßen Gebrauch nicht einschränken, insbesondere nicht für Funktionen, auf deren Nutzungsmöglichkeit der Nutzer vertrauen darf, es sei denn, zwingende rechtliche Vorgaben würden ihn dazu verpflichten.` }} />
        <Paragraph {...{ context: `Ein Anspruch des Nutzers auf die Erstellung/ Zurverfügungstellung eines bestimmten Updates, Upgrades oder einer bestimmten Version, insbesondere eines mit einem vom Nutzer gewünschten Funktionsinhalts, besteht hingegen nicht. Hiervon ausgenommen sind zwingende notwendige Sicherheitsupdates bzw. Updates und Upgrades die aufgrund von sicherheitsrelevanten Umständen erforderlich werden bzw. sind und unverzüglich vorgenommen werden müssen.` }} />
        <Paragraph {...{ context: `Übergabepunkt für die Software und die Anwendungsdaten ist der Routerausgang des vom Anbieter genutzten Rechenzentrums.` }} />
        <Paragraph {...{ context: `Auf Seiten des Nutzers sind für die Nutzung der Software eine Internetverbindung Voraussetzung. Für die Beschaffenheit der erforderlichen Hard- und Software auf Seiten des Nutzers sowie für die Telekommunikationsverbindung zwischen dem Nutzer und dem Anbieter bis zum Übergabepunkt ist der Anbieter nicht verantwortlich.` }} />

        <Heading {...{ context: `6. Technische Verfügbarkeit, Wartungsarbeiten` }} />
        <Paragraph {...{ context: `Die Überwachung der Grundfunktionen der Leistungen, erfolgt täglich. Die Verfügbarkeit der Software und der Anwendungsdaten am Übergabepunkt beträgt 99,5% im Monatsmittel.` }} />
        <Paragraph {...{ context: `Unter Verfügbarkeit verstehen die Vertragspartner die technische Nutzbarkeit der Software und der Anwendungsdaten am Übergabepunkt zum Gebrauch durch den Nutzer. Zur verfügbaren Nutzung (Verfügbarkeit gegeben) zählen auch die Zeiträume während` }} />
        <Paragraph {...{ context: `Wartungsarbeiten stattfinden;` }} />
        <Paragraph {...{ context: `Störungen in oder aufgrund des Zustands von nicht vom Anbieter oder seinen Erfüllungsgehilfen bereit zu stellenden Teilen der für die Ausführung der Software erforderlichen technischen Infrastruktur;` }} />
        <Paragraph {...{ context: `Störungen oder sonstigen Ereignissen, die nicht vom Anbieter oder einem seiner Erfüllungsgehilfen (mit-)verursacht sind, z. B. die Überschreitung einer vereinbarten zugelassenen Beanspruchung der Software, Flooding oder Spamming durch den Nutzer;` }} />
        <Paragraph {...{ context: `Störungen aufgrund von technischen oder sonstigen Problemen, die nicht im Einflussbereich des Anbieters liegen (höhere Gewalt, Netzwerkstörung außerhalb unseres Netzes, Verschulden Dritter etc.).` }} />
        <Paragraph {...{ context: `unerheblicher Minderung der Tauglichkeit zum vertragsgemäßen Gebrauch.` }} />
        <Paragraph {...{ context: `Der Anbieter ist während der Wartungsarbeiten berechtigt, die Software und/oder Server zu warten, zu pflegen, Datensicherungen oder sonstige Arbeiten vorzunehmen. Der Anbieter wird den Nutzer über anstehende Wartungsarbeiten vorab verständigen und den technischen Bedingungen entsprechend in der möglichst kürzesten Zeit außerhalb der üblichen Geschäftszeiten, also nicht Montag bis Freitag zwischen 06.00 – 19.00 Uhr, durchführen. Wenn und soweit der Nutzer während der Wartungszeiten die Software nutzen kann, so besteht hierauf kein Rechtsanspruch. Kommt es bei der Nutzung der Software während der Wartungsarbeiten zu einer Leistungsreduzierung oder -einstellung, besteht für den Nutzer kein Anspruch auf Mangelhaftung oder Schadensersatz.` }} />

        <Heading {...{ context: `7. Nutzungsrechte an der Software, Ansprüche Dritter` }} />
        <Paragraph {...{ context: `Der Anbieter räumt dem Nutzer nach Maßgabe der nachstehenden Regelungen das nicht ausschließliche, nicht unterlizenzierbare und nicht übertragbare Recht ein, die in diesem Vertrag bezeichnete Software während der Dauer des Vertrages im Rahmen der SaaS-Dienste bestimmungsgemäß zu nutzen.` }} />
        <Paragraph {...{ context: `Eine physische Überlassung der Software an den Nutzer erfolgt nicht. Der Auftraggeber darf die Software nur für seine eigenen geschäftlichen Tätigkeiten nutzen.` }} />
        <Paragraph {...{ context: `Sofern der Anbieter während der Laufzeit neue Versionen, Updates, Upgrades oder andere Neulieferungen im Hinblick auf die Software vornimmt, gelten die vorstehenden Rechte auch für diese.` }} />
        <Paragraph {...{ context: `Rechte, die vorstehend nicht ausdrücklich dem Nutzer eingeräumt werden, stehen dem Nutzer nicht zu. Der Nutzer ist insbesondere nicht berechtigt, die Software über die vereinbarte Nutzung hinaus zu nutzen oder von Dritten nutzen zu lassen oder die Software Dritten zugänglich zu machen. Es ist vor allem nicht gestattet, die Software zu vervielfältigen, zu veräußern oder zeitlich begrenzt zu überlassen, insbesondere nicht zu vermieten oder zu verleihen.` }} />
        <Paragraph {...{ context: `Nach Beendigung des Vertrages endet das dem Nutzer eingeräumte Nutzungsrecht.` }} />
        <Paragraph {...{ context: `Im Falle eines vertragswidrigen Überschreitens des Nutzungsumfangs durch einen Nutzer oder im Falle einer unberechtigten Nutzungsüberlassung hat der Nutzer dem Anbieter auf Verlangen unverzüglich sämtliche ihm verfügbaren Angaben zur Geltendmachung der Ansprüche wegen der vertragswidrigen Nutzung zu machen, insbesondere Name und Anschrift des Nutzers mitzuteilen.` }} />
        <Paragraph {...{ context: `Der Anbieter stellt den Nutzer von sämtlichen Ansprüchen Dritter frei, die diese aufgrund der Verletzung von deren Rechten geltend machen. Die Freistellung setzt voraus, dass der Nutzer den Anbieter unverzüglich unterrichtet, die erforderlichen Informationen bereitstellt und ein Vergleich oder ein Anerkenntnis über Ansprüche Dritter nur mit vorheriger schriftlicher Zustimmung des Anbieters erfolgt.` }} />

        <Heading {...{ context: `8. Rechte des Nutzers an etwa entstehenden Datenbanken/Datenbankwerken` }} />
        <Paragraph {...{ context: `Sofern und soweit während der Laufzeit dieses Vertrages, insb. durch Zusammenstellung von Anwendungsdaten, durch nach diesem Vertrag erlaubte Tätigkeiten des Nutzers auf dem Server eine Datenbank, Datenbanken, ein Datenbankwerk oder Datenbankwerke entstehen, stehen alle Rechte hieran dem Nutzer zu.` }} />
        <Paragraph {...{ context: `Der Nutzer bleibt auch nach Vertragsende Eigentümer dieser Datenbanken bzw. Datenbankwerke.` }} />

        <Heading {...{ context: `9. Pflichten des Nutzers` }} />
        <Paragraph {...{ context: `Der Nutzer ist verpflichtet, den unbefugten Zugriff Dritter auf die geschützten Bereiche der Software durch geeignete Vorkehrungen zu verhindern. Zu diesem Zwecke wird der Nutzer, soweit erforderlich, seine Mitarbeiter schulen und sensibilisieren.` }} />
        <Paragraph {...{ context: `Unbeschadet der Verpflichtung des Anbieters zur Datensicherung ist der Nutzer selbst für die Eingabe, Pflege und Sicherung seiner zur Nutzung der Software erforderlichen Daten und Informationen verantwortlich. Der Nutzer kann seine Daten selbstständig sichern. Eine anlasslose Herausgabe der Daten durch den Anbieter ist kostenpflichtig;` }} />
        <Paragraph {...{ context: `Der Nutzer ist verpflichtet, seine Daten und Informationen vor der Eingabe auf Viren oder sonstige schädliche Komponenten zu prüfen und hierzu dem Stand der Technik entsprechende Virenschutzprogramme einzusetzen.` }} />

        <Heading {...{ context: `10. Vertragswidrige Nutzung, Schadensersatz, Sperrung, Haftungsfreistellung` }} />
        <Paragraph {...{ context: `Für den Fall, dass die vom Anbieter zur Verfügung gestellten SaaS-Dienste von unberechtigten Dritten unter Verwendung der Zugangsdaten des Nutzers in Anspruch genommen werden, haftet der Nutzer für dadurch anfallende Entgelte im Rahmen der zivilrechtlichen Haftung bis zum Eingang seines Auftrages zur Änderung der Zugangsdaten oder der Meldung des Verlustes oder Diebstahls, sofern den Nutzer am Zugriff des unberechtigten Dritten ein Verschulden trifft.` }} />
        <Paragraph {...{ context: `Der Anbieter kann die Zugangsberechtigung des Nutzers widerrufen und / oder den Vertrag kündigen, wenn der Nutzer die ihm gestattete Nutzung erheblich überschreitet oder gegen Regelungen zum Schutz vor unberechtigter Nutzung verstößt. Damit verbunden kann der Anbieter den Zugriff auf die vertraglichen Leistungen unterbrechen bzw. sperren. Der Anbieter hat dem Nutzer vorher grundsätzlich eine angemessene Nachfrist zur Abhilfe zu setzen. Der alleinige Widerruf der Zugangsberechtigung gilt nicht zugleich als Kündigung des Vertrages. Den Widerruf der Zugangsberechtigung ohne Kündigung kann der Anbieter nur für eine angemessene Frist, maximal 3 Monate, aufrechterhalten.` }} />
        <Paragraph {...{ context: `Der Nutzer hat einen Anspruch auf Wiedereinräumung der Zugangsberechtigung und der Zugriffsmöglichkeit, nachdem er nachgewiesen hat, dass er die vertragswidrige Nutzung eingestellt und eine zukünftige vertragswidrige Nutzung unterbunden hat.` }} />
        <Paragraph {...{ context: `Der Nutzer stellt den Anbieter von sämtlichen Ansprüchen Dritter aufgrund von Rechtsverletzungen frei, die auf einer rechtswidrigen Nutzung der SaaS-Dienste durch den Nutzer beruhen oder mit seiner Billigung erfolgen. Erkennt der Nutzer oder muss er erkennen, dass ein solcher Verstoß droht, besteht die Pflicht zur unverzüglichen Unterrichtung des Anbieters. Die Freistellung umfasst neben Schadensersatzforderungen auch den Ersatz der angemessenen Kosten für die Rechtsverteidigung. Die Freistellung setzt voraus, dass ein Vergleich oder ein Anerkenntnis über Ansprüche Dritter nur mit vorheriger schriftlicher Zustimmung des Nutzers erfolgt.` }} />

        <Heading {...{ context: `11. Vergütung, Zahlungsbedingungen` }} />
        <Paragraph {...{ context: `Der Nutzer verpflichtet sich, für die Überlassung der SaaS-Dienste das vereinbarte Entgelt zzgl. gesetzlicher MwSt. zu bezahlen. Sofern nicht anders vereinbart, richtet sich die Vergütung nach der im Zeitpunkt des Vertragsschlusses gültigen im Angebot ausgewiesenen Preise, bzw. auf der Bestellseite bei Anlegen einer Organisation (vgl. Ziff. 3 dieser AGB).` }} />
        <Paragraph {...{ context: `Der Anbieter ist berechtigt, die Preise für die SaaS-Dienste angemessen zu erhöhen. Dazu ist er insbesondere berechtigt, wenn und soweit Dritte, die er zur Erfüllung seiner vertraglichen Pflichten hinzuziehen muss, wiederum ihre Preise dafür anpassen. Eine Preisanpassung wird der Anbieter mindestens 6 Wochen im Voraus schriftlich oder elektronisch ankündigen. Ist der Nutzer mit der Preisanpassung nicht einverstanden, steht ihm ein außerordentliches Sonderkündigungsrecht zu. Auf dieses Sonderkündigungsrecht wird der Anbieter den Nutzer zusammen mit jeder Ankündigung hinweisen. Kündigt der Nutzer hingegen nicht, gelten die dann ausgewiesenen Preise in den Angeboten auf der Website des Anbieters, bzw. gemäß der im Zuge der Preisanpassung übermittelten Preisliste; die dort ausgewiesenen Preise sind dann ab dem jeweiligen Zeitpunkt vom Nutzer zu entrichten.` }} />

        <Heading {...{ context: `12. Besondere Vereinbarungen zu angebotenen Zahlungsarten` }} />
        <Paragraph {...{ context: `Zahlung über „Stripe“` }} />
        <Paragraph {...{ context: `Bei Auswahl einer Zahlungsart, die über "Stripe" angeboten wird, erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister Stripe Payments Europe Limited (1 Grand Canal Street Lower, Grand Canal Dock, Dublin, D02 H210, Ireland; "Stripe"). Die einzelnen Zahlungsarten über "Stripe" werden Ihnen unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz angezeigt. Für die Zahlungsabwicklung kann sich "Stripe" weiterer Zahlungsdienste bedienen; soweit hierfür besondere Zahlungsbedingungen gelten, werden Sie auf diese gesondert hingewiesen. Nähere Informationen zu "Stripe" finden Sie unter <a href="https://stripe.com/de" target="_blank">https://stripe.com/de</a>.` }} />
        <Paragraph {...{ context: `Zahlung über "Mollie"` }} />
        <Paragraph {...{ context: `Bei Auswahl einer Zahlungsart, die über "Mollie" angeboten wird, erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister Mollie B.V. (Keizersgracht 313, 1016 EE Amsterdam, Niederlande; "Mollie"). Die einzelnen Zahlungsarten über "Mollie" werden Ihnen unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz sowie im Online-Bestellvorgang angezeigt. Für die Zahlungsabwicklung kann sich "Mollie" weiterer Zahlungsdienste bedienen; soweit hierfür besondere Zahlungsbedingungen gelten, werden Sie auf diese gesondert hingewiesen. Nähere Informationen zu "Mollie" finden Sie unter <a href="https://www.mollie.com/de" target="_blank">https://www.mollie.com/de</a>.` }} />

        <Paragraph {...{ context: `Gewährleistung, Haftung` }} />
        <Paragraph {...{ context: `Es gelten die gesetzlichen Mängelgewährleistungsrechte.` }} />
        <Paragraph {...{ context: `Die verschuldensunabhängige Haftung für anfängliche Mängel gem. § 536a BGB ist ausgeschlossen. Der Anbieter haftet im Übrigen nach den im Folgenden dargelegten Grundsätzen.` }} />
        <Paragraph {...{ context: `Der Anbieter haftet jeweils uneingeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Weiter haftet der Anbieter ohne Einschränkung in allen Fällen des Vorsatzes und grober Fahrlässigkeit, bei arglistigem Verschweigen eines Mangels, bei Übernahme einer Garantie und in allen anderen gesetzlich geregelten Fällen.` }} />
        <Paragraph {...{ context: `Sofern wesentliche Vertragspflichten sind, ist die Haftung des Anbieters bei leichter Fahrlässigkeit auf den vertragstypischen, vorhersehbaren Schaden beschränkt. Wesentliche Vertragspflichten sind Pflichten, die sich aus der Natur des Vertrages ergeben und deren Verletzung die Erreichung des Vertragszweckes gefährden würde sowie Pflichten, die der Vertrag nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst möglich machen und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf.` }} />
        <Paragraph {...{ context: `Bei der Verletzung unwesentlicher Vertragspflichten ist unsere Haftung bei leicht fahrlässigen Pflichtverletzungen ausgeschlossen.` }} />

        <Heading {...{ context: `13. Laufzeit und Vertragsbeendigung` }} />
        <Paragraph {...{ context: `Dieser Vertrag hat die vereinbarte Laufzeit, welche sich aus dem Angebot ergibt. Wird der Vertrag nicht einen Monat vor Vertragsende von einer der Parteien gekündigt, verlängert er sich jeweils um die im Angebot vereinbarte Zeit. Das verlängerte Vertragsverhältnis kann jederzeit mit einer Frist von einem Monat gekündigt werden.` }} />
        <Paragraph {...{ context: `Die Kündigung muss entweder in Textform (z.B. per E-Mail) oder über die in der Software eingebunden Kündigungsschaltfläche („Vertrag kündigen“ oder ähnliche Bezeichnung) erklärt und übermittelt werden.` }} />
        <Paragraph {...{ context: `Unberührt bleibt das Recht jeder Vertragspartei, den Vertrag aus wichtigem Grunde fristlos zu kündigen.` }} />
        <Paragraph {...{ context: `Der Nutzer wird rechtzeitig vor Beendigung des Vertrages seine Datenbestände eigenverantwortlich sichern (etwa durch Download).` }} />

        <Heading {...{ context: `14. Datenschutz` }} />
        <Paragraph {...{ context: `Soweit der Anbieter auf personenbezogene Daten des Nutzers oder aus dessen Bereich zugreifen kann, wird er ausschließlich als Auftragsverarbeiter tätig und wird diese Daten nur zur Vertragsdurchführung verarbeiten und nutzen. Der Anbieter wird Weisungen des Nutzers für den Umgang mit diesen Daten beachten. Der Nutzer trägt etwaige nachteilige Folgen solcher Weisungen für die Vertragsdurchführung. Die datenschutzrechtlichen Anforderungen werden zwischen dem Nutzer und dem Anbieter im Rahmen eines Auftragsverarbeitungsvertrages (AVV) vereinbart.` }} />
        <Paragraph {...{ context: `Der Nutzer bleibt sowohl allgemein im Auftragsverhältnis als auch im datenschutzrechtlichen Sinne der Verantwortliche. Verarbeitet der Nutzer im Zusammenhang mit dem Vertrag personenbezogene Daten (einschließlich Erhebung und Nutzung), so steht er dafür ein, dass er dazu nach den anwendbaren, insbesondere datenschutzrechtlichen Bestimmungen berechtigt ist und stellt im Falle eines Verstoßes den Anbieter von Ansprüchen Dritter frei. Die Freistellung setzt voraus, dass ein Vergleich oder ein Anerkenntnis über Ansprüche Dritter nur mit vorheriger schriftlicher Zustimmung des Nutzers erfolgt.` }} />
        <Paragraph {...{ context: `Für das Verhältnis zwischen Anbieter und Nutzer gilt: Gegenüber der betroffenen Person trägt die Verantwortung für die Verarbeitung (einschließlich Erhebung und Nutzung) personenbezogener Daten der Nutzer, außer soweit der Anbieter etwaige Ansprüche der betroffenen Person wegen einer ihm zuzurechnenden Pflichtverletzung zu vertreten hat. Der Nutzer wird etwaige Anfragen, Anträge und Ansprüche der betroffenen Person verantwortlich prüfen, bearbeiten und beantworten. Das gilt auch bei einer Inanspruchnahme des Anbieters durch die betroffene Person. Der Anbieter wird den Nutzern im Rahmen seiner Pflichten unterstützen.` }} />

        <Heading {...{ context: `15. Anwendbares Recht, Gerichtsstand` }} />
        <Paragraph {...{ context: `Es gilt deutsches Recht. Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.` }} />
        <Paragraph {...{ context: `Erfüllungsort sowie Gerichtsstand ist der Sitz des Anbieters, soweit Sie Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen sind. Dasselbe gilt, wenn Sie keinen allgemeinen Gerichtsstand in Deutschland oder der EU haben.` }} />

        <Heading {...{ context: `II. Nutzerinformationen` }} />
        <Heading {...{ context: `1. Identität des Anbieters` }} />
        <Paragraph {...{ context: `Sigmaheat GmbH` }} />
        <Paragraph {...{ context: `vert. d.d. Geschäftsführer Maik Brinkmann` }} />
        <Paragraph {...{ context: `Lange Laube 2` }} />
        <Paragraph {...{ context: `30159 Hannover` }} />
        <Paragraph {...{ context: `Telefon: <a href="tel:+4951187453680">+49 511 8745 3680</a>` }} />
        <Paragraph {...{ context: `E-Mail: <a href="mailto:info@sigmaheat.de">info@sigmaheat.de</a>` }} />

        <Heading {...{ context: `Alternative Streitbeilegung:` }} />
        <Paragraph {...{ context: `Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>.` }} />
        <Paragraph {...{ context: `Wir sind bereit, aber nicht verpflichtet, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.` }} />

        <Heading {...{ context: `2. Informationen zum Zustandekommen des Vertrages` }} />
        <Paragraph {...{ context: `Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die Korrekturmöglichkeiten erfolgen nach Maßgabe der Regelungen "Zustandekommen des Vertrages" unserer Nutzungsbedingungen(Teil I.).` }} />

        <Heading {...{ context: `3. Vertragssprache, Vertragstextspeicherung` }} />
        <Paragraph {...{ context: `3.1. Vertragssprache ist deutsch.` }} />
        <Paragraph {...{ context: `3.2. Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Abschluss des Vertrages können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang der Bestellung bei uns werden die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen Nutzungsbedingungen nochmals per E-Mail an Sie übersandt.` }} />
        <Paragraph {...{ context: `3.3. Bei Angebotsanfragen außerhalb der Software erhalten Sie alle Vertragsdaten im Rahmen eines verbindlichen Angebotes in Textform übersandt, z.B. per E-Mail, welche Sie ausdrucken oder elektronisch sichern können.` }} />

        <Heading {...{ context: `4. Wesentliche Merkmale der Software oder Dienstleistung` }} />
        <Paragraph {...{ context: `Die wesentlichen Merkmale der Software und/oder Dienstleistung finden sich im jeweiligen Angebot.` }} />

        <Heading {...{ context: `5. Preise und Zahlungsmodalitäten` }} />
        <Paragraph {...{ context: `5.1. Die in den jeweiligen Angeboten angeführten Preise stellen Gesamtpreise dar. Sie beinhalten alle Preisbestandteile einschließlich aller anfallenden Steuern.` }} />
        <Paragraph {...{ context: `5.2. Es fallen keine Versandkosten an.` }} />
        <Paragraph {...{ context: `5.3. Entstandene Kosten der Geldübermittlung (Überweisungs- oder Wechselkursgebühren der Kreditinstitute) sind von Ihnen in den Fällen zu tragen, in denen die Bereitstellung der Software in einen EU-Mitgliedsstaat erfolgt, die Zahlung aber außerhalb der Europäischen Union veranlasst wurde.` }} />
        <Paragraph {...{ context: `5.4. Die Ihnen zur Verfügung stehenden Zahlungsarten sind unter einer entsprechend bezeichneten Schaltfläche in der Softwareanwendung oder im jeweiligen Angebot ausgewiesen.` }} />
        <Paragraph {...{ context: `5.5. Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort zur Zahlung fällig.` }} />

        <Heading {...{ context: `7. Gesetzliches Mängelhaftungsrecht` }} />
        <Paragraph {...{ context: `Die Mängelhaftung richtet sich nach der Regelung "Gewährleistung" in unseren Allgemeinen Nutzungsbedingungen (Teil I).` }} />

        <Heading {...{ context: `8. Vertragslaufzeit / Kündigung` }} />
        <Paragraph {...{ context: `Informationen zur Laufzeit des Vertrages sowie den Kündigungsbedingungen finden Sie in der Regelung "Laufzeit und Vertragsbeendigung" in unseren Allgemeinen Nutzungsbedingungen (Teil I) sowie im jeweiligen Angebot.` }} />
    </>
}

export default TermsAndConditions;