class SchemaBuilder {
    constructor(elements, extra_hp, elementBlueprints, canvas, transform, addComponent, append_elements, addConnections) {
        this.source = elements.source;
        this.source_no = parseInt(elementBlueprints.source_no);
        this.exchange = elements.exchange;
        this.exchange_no = parseInt(elementBlueprints.exchange_no);
        this.cylinder = elements.cylinder;
        this.cylinder_no = parseInt(elementBlueprints.cylinder_no);
        this.heatpart = elements.heatpart;
        this.extra_hp = extra_hp;
        this.heatpart_no = parseInt(elementBlueprints.heatpart_no) + extra_hp.length;
        this.exchange_before_cylinder = elementBlueprints.exchange_before_cylinder;
        this.connection_type = elementBlueprints.connection_type;
        this.existing_heat_parts = elementBlueprints.existing_heat_parts;
        this.addComponent = addComponent;
        this.components = [];
        this.connections = [];
        this.append_elements = append_elements;
        this.canvas = canvas;
        this.transform = transform;
        this.addConnections = addConnections;

        this.renderElements();
        this.drawConnections();
    }

    renderElements() {
        const viewCenterY = (this.canvas.getContext('2d').canvas.height / 2 - this.transform.dy) / this.transform.s;
        const viewCenterX = (this.canvas.getContext('2d').canvas.width  / 2 - this.transform.dx) / this.transform.s;

        const gap = 100;
        let y = viewCenterY;
        let x = viewCenterX;

        

        // Lay Source Down
        if (this.source_no === 0) return
        let width  = Math.abs(this.source.rect.right - this.source.rect.left)
        let height = Math.abs(this.source.rect.bottom - this.source.rect.top)
        let source_config = { id: this.source.id, name: this.source.name, img: this.source.img, part: this.source.part, x, y: y - height / 2, height, width, rotate: 0, connection_ports: this.source.connection_ports }
        this.components.push(this.addComponent(source_config));
        x = x + width + gap;

        // Lay Cylinder and Exchanger down, based on position and connection type
        //
        // Cylinder 
        let cylinder_width  = Math.abs(this.cylinder.rect.right - this.cylinder.rect.left)
        let cylinder_height = Math.abs(this.cylinder.rect.bottom - this.cylinder.rect.top)
        const totalCylinderHeight = (this.cylinder_no - 1) * gap + this.cylinder_no * cylinder_height;
        let cylinder_y = viewCenterY - totalCylinderHeight / 2;
        let cylinder_y_serial =  viewCenterY - cylinder_height / 2;

        // Exchange
        let exchange_width  = Math.abs(this.exchange.rect.right - this.exchange.rect.left)
        let exchange_height = Math.abs(this.exchange.rect.bottom - this.exchange.rect.top)
        const totalExchangeHeight = (this.exchange_no - 1) * gap + this.exchange_no * exchange_height;
        let exchange_y = viewCenterY - totalExchangeHeight / 2;
        let exchange_y_serial = viewCenterY - exchange_height / 2;
        
        if (this.exchange_before_cylinder) {
            if (this.exchange_no !== 0) {
                for (let i = 0; i < this.exchange_no; i++) {
                    let exchange_config = { id: this.exchange.id, name: this.exchange.name, img: this.exchange.img, part: this.exchange.part, x, y: this.connection_type !== 0 ? exchange_y_serial : exchange_y + i * (gap + exchange_height), height: exchange_height, width: exchange_width, rotate: 0, connection_ports: this.exchange.connection_ports }
                    this.components.push(this.addComponent(exchange_config));
                    if (this.connection_type !== 0) x = x + exchange_width + gap;
                }

                if (this.connection_type === 0) x = x + exchange_width + gap;
            }

            if (this.cylinder_no !== 0) {
                for (let i = 0; i < this.cylinder_no; i++) {
                    let cylinder_config = { id: this.cylinder.id, name: this.cylinder.name, img: this.cylinder.img, part: this.cylinder.part, x, y: (this.connection_type !== 0 && this.exchange_no === 0) ? cylinder_y_serial : cylinder_y + i * (gap + cylinder_height), height: cylinder_height, width: cylinder_width, rotate: 0, connection_ports: this.cylinder.connection_ports }
                    this.components.push(this.addComponent(cylinder_config));
                    if (this.connection_type !== 0 && this.exchange_no === 0) x = x + cylinder_width + gap;
                }
            }
        } else {
            if (this.cylinder_no !== 0) {
                for (let i = 0; i < this.cylinder_no; i++) {
                    let cylinder_config = { id: this.cylinder.id, name: this.cylinder.name, img: this.cylinder.img, part: this.cylinder.part, x, y: this.connection_type !== 0 ? cylinder_y_serial : cylinder_y + i * (gap + cylinder_height), height: cylinder_height, width: cylinder_width, rotate: 0, connection_ports: this.cylinder.connection_ports }
                    this.components.push(this.addComponent(cylinder_config));
                    if (this.connection_type !== 0) x = x + cylinder_width + gap;
                }
                x = x + cylinder_width + gap;
            }

            if (this.exchange_no !== 0) {
                for (let i = 0; i < this.exchange_no; i++) {
                    let exchange_config = { id: this.exchange.id, name: this.exchange.name, img: this.exchange.img, part: this.exchange.part, x, y: (this.connection_type !== 0 && this.cylinder_no === 0) ? exchange_y_serial : exchange_y + i * (gap + exchange_height), height: exchange_height, width: exchange_width, rotate: 0, connection_ports: this.exchange.connection_ports }
                    this.components.push(this.addComponent(exchange_config));
                    if (this.connection_type !== 0 && this.cylinder_no === 0) x = x + exchange_width + gap;
                }
            }
        }

        x = x + cylinder_width + gap;

        // Lay Heatparts down
        if (this.heatpart_no !== 0) {
            let heatpart_width   = Math.abs(this.heatpart.rect.right - this.heatpart.rect.left)
            let heatpart_height  = Math.abs(this.heatpart.rect.bottom - this.heatpart.rect.top)
            const totalHeatpartHeight = (this.heatpart_no - 1) * gap + this.heatpart_no * heatpart_height;
            let heatpart_y = viewCenterY - totalHeatpartHeight / 2;

            for (let i = 0; i < this.heatpart_no - this.extra_hp.length; i++) {
                let heatpart_config = { id: this.heatpart.id, name: this.heatpart.name, img: this.heatpart.img, part: this.heatpart.part, x, y: heatpart_y + i * (gap + heatpart_height), height: heatpart_height, width: heatpart_width, rotate: 0, connection_ports: this.heatpart.connection_ports }
                this.components.push(this.addComponent(heatpart_config));
            }

            for (let i = this.heatpart_no - this.extra_hp.length; i < this.heatpart_no; i++) {
                this.extra_hp[i].x      = x;
                this.extra_hp[i].y      = heatpart_y + i * (gap + heatpart_height);
                this.extra_hp[i].rotate = 0;
                this.extra_hp[i].updateConnectors();
            }
        }

        this.append_elements(this.components)
    }

    connectParallel = (fromComponents, toComponents) => {
        fromComponents.forEach((fromComponent, i) => {
            const fromO1Ports = fromComponent.connectors.find(c => c.io === "o" && c.type === 2);
            const fromO2Ports = fromComponent.connectors.find(c => c.io === "o" && c.type === 3);

            toComponents.forEach((toComponent, j) => {
                const to01Ports = toComponent.connectors.find(c => c.io === "i" && c.type === 2);
                const to02Ports = toComponent.connectors.find(c => c.io === "i" && c.type === 3);

                this.connections.push({
                    start: {
                        x: fromO1Ports.aX,
                        y: fromO1Ports.bY
                    },
                    end: {
                        x: to01Ports.aX,
                        y: to01Ports.bY
                    },
                    lineWidth: 2
                }, {
                    start: {
                        x: fromO2Ports.aX,
                        y: fromO2Ports.bY
                    },
                    end: {
                        x: to02Ports.aX,
                        y: to02Ports.bY
                    },
                    lineWidth: 2
                })

            });
        });
    };

    connectSerial = (fromComponent, toComponents) => {
        let fromO1Ports = fromComponent.connectors.find(c => c.io === "o" && c.type === 2);
        let fromO2Ports = fromComponent.connectors.find(c => c.io === "o" && c.type === 3);

        toComponents.forEach(component => {
            const to01Ports = component.connectors.find(c => c.io === "i" && c.type === 2);
            const to02Ports = component.connectors.find(c => c.io === "i" && c.type === 3);

            this.connections.push({
                start: {
                    x: fromO1Ports.aX,
                    y: fromO1Ports.bY
                },
                end: {
                    x: to01Ports.aX,
                    y: to01Ports.bY
                },
                lineWidth: 2
            }, {
                start: {
                    x: fromO2Ports.aX,
                    y: fromO2Ports.bY
                },
                end: {
                    x: to02Ports.aX,
                    y: to02Ports.bY
                },
                lineWidth: 2
            })


            fromO1Ports = component.connectors.find(c => c.io === "o" && c.type === 2);
            fromO2Ports = component.connectors.find(c => c.io === "o" && c.type === 3);
        });
    };

    drawConnections() {
        if (this.components.length > 1) {
            const source_no = this.source_no;
            const secondLevel = this.source_no + (this.exchange_before_cylinder ? this.exchange_no : this.cylinder_no);
            const thirdLevel = this.source_no + this.cylinder_no + this.exchange_no;
            const lowLevel = this.source_no + this.cylinder_no + this.exchange_no + this.heatpart_no;

            const firstComponents = this.components.slice(0, source_no)
            const secondComponents = this.components.slice(source_no, secondLevel)
            const thirdComponents = this.components.slice(secondLevel, thirdLevel)
            const lowComponents = this.components.slice(thirdLevel, lowLevel)

            if (this.source_no && this.cylinder_no && this.exchange_no) {
                this.connection_type === 0 ? this.connectParallel(firstComponents, secondComponents) : this.connectSerial(firstComponents[0], secondComponents)
                this.connectParallel(this.connection_type === 0 ? secondComponents : [secondComponents[secondComponents.length - 1]], thirdComponents);
                if (this.heatpart_no) 
                    this.connectParallel(thirdComponents, [...lowComponents, ...this.extra_hp]);
            } else if (this.mainCount && (this.bufferCount || this.subCount)) {
                this.connection_type === 0 ? this.connectParallel(firstComponents, secondComponents.length ? secondComponents : thirdComponents) : this.connectSerial(firstComponents[0], secondComponents.length ? secondComponents : thirdComponents)
                if (this.heatpart_no)
                    this.connectParallel(secondComponents.length ? this.connection_type === 0 ? secondComponents : [secondComponents[secondComponents.length - 1]] : this.connection_type === 0 ?  thirdComponents : [thirdComponents[thirdComponents.length - 1]], lowComponents);
            } else {
                this.connectParallel(firstComponents, [...lowComponents, ...this.extra_hp]);
            }
        }
        this.addConnections(this.connections)
    }

}

export default SchemaBuilder;