import { useState, useMemo, useEffect, useCallback } from "react";
import { useAuth } from "../../Context";
import { useParams } from "react-router-dom";
import Card from "../../components/Card";
import { ColumnChart } from "../../components/AmCharts";
import { getMinMax } from "../../util/helpers";
import { useTranslation } from "react-i18next";
import SelectYear from "../../components/SelectYear";
import { Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useSwagger } from "../../context/SwaggerContext";
import { useToast } from "../../NotificationsContent";

export const Consumption = ({orgDashbord}) => {
    const { login, defaultTheme } = useAuth();
    const client = useSwagger();
    const { addToast } = useToast();
    const _buildingGuid = useParams()['buildingGuid'];
    const _orgGuid = useParams()['orgGuid'];
    const { t } = useTranslation();
    const [year, setYear] = useState(2024)
    const [actualType, setActualType] = useState('MWh')
    const [type, setType] = useState('MWh')
    const [data, setData] = useState([{
        unit: 'MWh',
        dimension: 'Mega Watt',
        name: [t('heating'), t('current')],
        data: [[], []],
        type: ['Column', 'Column'],
        min: 0,
        max: 1
    }, {
        unit: 'm³',
        dimension: 'm³ Wasser',
        name: [t('water')],
        data: [[]],
        type: ['Column'],
        min: 0,
        max: 1
    }]);
    const [dataRaw, setDataRaw] = useState([]);

    const fetchConsumptionData = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = _orgGuid ? await client.apis["consumptionchart"].consumptionchart_retrieve({org_uuid: _orgGuid, type: type, year: year}) : await client.apis["building"].building_consumptionchart_retrieve({building_uuid: _buildingGuid, type: type, year: year});

            if (response.status >= 200 && response.status < 300) {
                let heating = [...response.obj.heating].map(({ heating, ...rest }) => ({ ...rest, [t('heating').toLocaleLowerCase()]: heating }));
                let current = [...response.obj.current].map(({ current, ...rest }) => ({ ...rest, [t('current').toLocaleLowerCase()]: current }));
                let water = [...response.obj.water].map(({ water, ...rest }) => ({ ...rest, [t('water').toLocaleLowerCase()]: water }));

                if (type === 'MWh') {
                    const { min, max } = getMinMax([response.obj.heating, response.obj.current]);
                    const { minW, maxW } = getMinMax([response.obj.water]);

                    setData([{
                        unit: 'MWh',
                        dimension: 'Mega Watt',
                        name: [t('heating'), t('current')],
                        data: [heating, current],
                        type: ['Column', 'Column'],
                        min: min,
                        max: max
                    }, {
                        unit: 'Liter',
                        dimension: 'Liter',
                        name: [t('water')],
                        data: [water],
                        type: ['Column'],
                        min: minW,
                        max: maxW
                    }])
                } else if (type === 'price') {
                    const { min, max } = getMinMax([response.obj.heating, response.obj.current, response.obj.water]);

                    setData([{
                        unit: '€',
                        dimension: 'Euro',
                        name: [t('heating'), t('current'), t('water')],
                        data: [heating, current, water],
                        type: ['Column', 'Column', 'Column'],
                        min: min,
                        max: max
                    }])
                } else {
                    const { min, max } = getMinMax([response.obj.heating, response.obj.current, response.obj.water]);

                    setData([{
                        unit: 'kg',
                        dimension: 'kilogram',
                        name: [t('heating'), t('current'), t('water')],
                        data: [heating, current, water],
                        type: ['Column', 'Column', 'Column'],
                        min: min,
                        max: max
                    }])
                }
                
                setDataRaw(response.obj)
                setActualType(type)
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            // temporary disabled
            // addToast(t("error"), t("responseError"), "error");
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, login.Authorization, _buildingGuid, year, type]);

    // const timeRange = {
    //     from: new Date(new Date().setDate(new Date().getDate() - 14)),
    //     to: new Date()
    // }

    // const generateStaticConsumption = (timeRange, key, buildingSize = 1000) => {
    //     const consumption = {
    //         heizung: (120 + Math.random() * 50) * buildingSize,  // Heizbedarf in kWh/Jahr
    //         wasser: (30 + Math.random() * 5) * buildingSize,    // Warmwasser in kWh/Jahr
    //         strom: (50 + Math.random() * 10) * buildingSize      // Stromverbrauch in kWh/Jahr
    //     };
    
    //     const distributions = {
    //         heizung: [0.2, 0.15, 0.1, 0.05, 0.02, 0, 0, 0, 0.05, 0.1, 0.15, 0.18],
    //         wasser: Array.from(new Array(12),(val,index)=> (Math.random() + 0.5)/12 ), // Gleichmäßig
    //         strom: Array.from(new Array(12),(val,index)=> (Math.random() + 0.5)/12 )   // Gleichmäßig
    //     };
    
    //     // const divider = { heizung: 32.5, wasser: 32.5 * 4, strom: 32.5 * 3 };
    
    //     const startDate = new Date(timeRange.from);
    //     return distributions[key].map((factor, month) => {
    //         const date = new Date(startDate);
    //         date.setMonth(startDate.getMonth() + month);
    //         return { date: date.getTime(), [key]: Math.round((consumption[key] * factor / 1000)) };
    //     });
    // };

    // let dataOne = useMemo(() => [], []); // 1 Sum Monthly Consumption value_mwh
    // let dataTwo = useMemo(() => [], []); // 2 Sum Monthly Consumption value_mwh
    // let dataThree = useMemo(() => [], []); // 3 Sum Monthly Consumption value_raw

    // dataOne = generateStaticConsumption({ from: '2023-01-01' }, 'heizung');

    // dataTwo = generateStaticConsumption({ from: '2023-01-01' }, 'strom');

    // dataThree = generateStaticConsumption({ from: '2023-01-01' }, 'wasser');

    // const { min, max } = getMinMax([dataOne, dataTwo]);
    // const { min1, max1 } = getMinMax([dataThree]);

    // const area = 1000; // Building.heated_area editable in modal building settings

    // //32,5
    // console.log("RAW Data: ", dataOne)

    // let data = useMemo(() => [{
    //     unit: 'MWh',
    //     dimension: 'Mega Watt',
    //     name: ['Heizung', 'Strom'],
    //     data: [dataOne, dataTwo],
    //     type: ['Column', 'Column'],
    //     min: min,
    //     max: max
    // }, {
    //     unit: 'm³',
    //     dimension: 'm³ Wasser',
    //     name: ['Wasser'],
    //     data: [dataThree],
    //     type: ['Column'],
    //     min: min1,
    //     max: max1
    // }], [dataOne, dataTwo, dataThree, min, max, min1, max1]);

    const heatingSum = dataRaw.heating?.reduce((total, item) => (total + item.heating), 0) || 0
    const electricitySum = dataRaw.current?.reduce((total, item) => (total + item.current), 0) || 0
    const waterSum = dataRaw.water?.reduce((total, item) => (total + item.water), 0) || 0

    const heatingSumConsumption = `${(heatingSum * 1000 / dataRaw.heated_area)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} kWh/m²`
    const electricitySumConsumption = `${(electricitySum * 1000 / dataRaw.heated_area)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} kWh/m²`
    const waterSumConsumption = `${(waterSum / dataRaw.heated_area)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} Liter/m²`

    const heatingSumPrice = `${(heatingSum)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${actualType === 'price' ? '€' : 'kg'}`
    const electricitySumPrice = `${(electricitySum)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${actualType === 'price' ? '€' : 'kg'}`
    const waterSumPrice = `${(waterSum)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${actualType === 'price' ? '€' : 'kg'}`

    useEffect(() => {
        fetchConsumptionData();
    }, [])

    useEffect(() => {
        console.log(data)
    }, [data])

    return <Card {...{ heading: t("consumption"), className: orgDashbord ? 'consumption__gridspan' : ''}}>
        <div>
            <SelectYear {...{ onYearChange: setYear, selectedYear: year, className: "mb-3", disabled: false }} />

            <div className="d-flex justify-content-between gap-3 mb-3">
                <ButtonGroup className="w-100">
                    <Button variant="outline-primary" className={type === 'MWh' && 'active'} onClick={(e) => setType('MWh')}>{t('consumption')}</Button>
                    <Button variant="outline-primary" className={type === 'price' && 'active'} onClick={(e) => setType('price')}>{t("price")}</Button>
                    <Button variant="outline-primary" className={type === 'CO2' && 'active'} onClick={(e) => setType('CO2')}>CO2e</Button>
                </ButtonGroup>

                <Button variant="outline-primary" className="p-10" onClick={() => fetchConsumptionData()}>
                    <FontAwesomeIcon icon={faRefresh} />
                </Button>
            </div>

            <p className="mb-3">KPIs:</p>
            <div className="consumption__grid mb-3">
                <div>
                    <p className="m-0">{heatingSum === 0 ? t("noDataAvailable") : actualType === 'MWh' ? heatingSumConsumption : heatingSumPrice}</p>
                    <span><small>{t('heating')}</small></span>
                </div>
                <div>
                    <p className="m-0">{electricitySum === 0 ? t("noDataAvailable") : actualType === 'MWh' ? electricitySumConsumption : electricitySumPrice}</p>
                    <span><small>{t('current')}</small></span>
                </div>
                <div>
                    <p className="m-0">{waterSum === 0 ? t("noDataAvailable") : actualType === 'MWh' ? waterSumConsumption : waterSumPrice}</p>
                    <span><small>{t('din276Main.short.water')}</small></span>
                </div>
            </div>
            <div className="chart__wrapper">
                {actualType === 'MWh' && <ColumnChart {...{ theme: defaultTheme === 'light' ? 'dark' : 'light', data, colors: ['#f33333', '#fff700', '#0ec7ff'], load: true, firstLoad: true, dataToShow: true, interval: { count: 1 , timeUnit: 'month'}}} />}
                {actualType === 'price' && <ColumnChart {...{ theme: defaultTheme === 'light' ? 'dark' : 'light', data, colors: ['#f33333', '#fff700', '#0ec7ff'], load: true, firstLoad: true, dataToShow: true, interval: { count: 1 , timeUnit: 'month'}}} />}
                {actualType === 'CO2' && <ColumnChart {...{ theme: defaultTheme === 'light' ? 'dark' : 'light', data, colors: ['#f33333', '#fff700', '#0ec7ff'], load: true, firstLoad: true, dataToShow: true, interval: { count: 1 , timeUnit: 'month'}}} />}
            </div>
        </div>
    </Card>
}

export default Consumption;