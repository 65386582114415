import React, { useState, useCallback, useEffect, useRef } from "react"
import { useAuth } from "../Context";
import { useToast } from '../NotificationsContent';
import { useTranslation, Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Card from "../components/Card";
import { Button, Col, Form, Row } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie } from "../util/helpers";
import { useSwagger } from "../context/SwaggerContext";

export const Register = () => {
    const { doSetLogin, defaultTheme } = useAuth();
    const { addToast } = useToast();
    const { t, i18n } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [showSecondPassword, setShowSecondPassword] = useState(false);

    const [tnc, setTNC] = useState(false);
    const [pp, setPP] = useState(false);
    const [mt, setMT] = useState(false);
    const client = useSwagger();

    const nameRef = useRef();
    const lastNameRef = useRef();
    // const emailRef = useRef();
    const otpRef = useRef();
    const passwordRef = useRef();
    const password2ndRef = useRef();
    const signUPBTN = useRef();

    const navigate = useNavigate()

    const regexLevels = (value) => {
        var strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=/\\()%ยง!@#$%^&*]).{8,}$/;
        var mediumRegex = /^(?:(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[0-9]))|(?:(?=.*[A-Z])(?=.*[0-9])).{6,}$/;
    
        return strongRegex.test(value) ? "green" : mediumRegex.test(value) ? "orange" : "red";
    }    

    const styleStrengthLine = (color, value) => {
        var bars = document.getElementsByClassName("bar");

        [...bars].forEach(bar => {
            bar.classList.remove("bg__red", "bg__orange", "bg__green");
            bar.classList.add("bg__default");
        });

        if (value === "" || value === null || value === undefined) return

        [...bars].forEach(bar => {
            bar.classList.remove("bg__default");
            bar.classList.add(color === "red" ? "bg__red" : color === "orange" ? "bg__orange" : "bg__green");
        });
    }

    const errorHandler = useCallback((message, elem) => {
        addToast(t("createAccount"), message, "error")
        elem.focus()
        signUPBTN.current.disabled = false;
    }, [addToast, t, signUPBTN.current])

    const submit = useCallback(async(e) => {
        e.preventDefault();
        signUPBTN.current.disabled = true;

        const prename = nameRef.current;
        const name = lastNameRef.current;
        // const mail = emailRef.current;
        const otp = otpRef.current;
        const password = passwordRef.current;
        const password2nd = password2ndRef.current;

        if (prename.value === "" || prename.value === null || prename.value === undefined) {
            errorHandler(t("registration.prename"), prename)
            return
        }

        if (name.value === "" || name.value === null || name.value === undefined) {
            errorHandler(t("registration.name"), name)
            return
        }

        // if (mail.value === "" || mail.value === null || mail.value === undefined) {
        //     errorHandler(t("registration.mail"), mail)
        //     return
        // }

        if (password.value === "" || password.value === null || password.value === undefined) {
            errorHandler(t("registration.password"), password)
            return
        }

        if (password2nd.value === "" || password2nd.value === null || password2nd.value === undefined) {
            errorHandler(t("registration.password2nd"), password2nd)
            return
        }

        if (password.value !== password2nd.value) {
            errorHandler(t("passwordNoMatch"), password)
            return
        }

        if (otp.value === "" || otp.value === null || otp.value === undefined) {
            errorHandler(t("registration.otp"), otp)
            return
        }

        const obj = {
            name: name.value,
            prename: prename.value,
            // mail: mail.value,
            password: password.value,
            token: localStorage.getItem('sh-jwttoken'),
            otp: otp.value,
            terms: tnc && pp && mt
        }

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Cookie"] = getCookie("sessionid");
                req.credentials = 'include';
                req.body = JSON.stringify(obj);
                return req;
            };

            const response = await client.apis["User"].postRegistration();

            if (response.status === 406) throw new Error(t("passwordComplexity"));

            if (response.status >= 200 && response.status < 300) {
                localStorage.removeItem('sh-jwttoken');
                doSetLogin(response?.obj);
                navigate(response?.obj?.["TwoFA"] ? '/2fa' : '/')
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("createAccount"), error.message, "error");
            signUPBTN.current.disabled = false;
            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [addToast, i18n, addToast, t, tnc, pp, mt])

    const keyUPEvent = useCallback(() => {
        let pw = passwordRef.current.value;
        let color = regexLevels(pw);
        styleStrengthLine(color, pw);
    }, [passwordRef.current])

    useEffect(() => {
        window.addEventListener("keyup", keyUPEvent);
        return () => window.removeEventListener("keyup", keyUPEvent);
    }, [keyUPEvent])

    useEffect(() => {
        const token = localStorage.getItem('sh-jwttoken');
        const regex = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.[A-Za-z0-9-_.+/=]*$/;

        let redirect = true;

        if (regex.test(token)) {
            const parts = token.split('.');
            const header = JSON.parse(atob(parts[0]));
            if (header && header.alg === 'HS512') {
                redirect = false
            }
        }

        if (redirect) navigate('/signup')
    }, [])

    return <div style={{ width: '100%', maxWidth: '512px' }}>
        <Card {...{ heading: t("createAccount"), className: 'w-100' }}>
            <Form className='d-block' onSubmit={(e) => submit(e) }>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>{t("name")}</Form.Label>
                            <Form.Control ref={nameRef} type="text" placeholder="Max" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formLastName">
                            <Form.Label>{t("lastName")}</Form.Label>
                            <Form.Control ref={lastNameRef} type="text" placeholder="Mustermann" />
                        </Form.Group>
                    </Col>
                </Row>

                {/* <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>{t("emailAddress")}</Form.Label>
                    <Form.Control ref={emailRef} type="email" placeholder="beispiel@sigmaheat.de" />
                </Form.Group> */}

                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>{t('password')}</Form.Label>
                    <div className='d-flex justify-content-between'>
                        <Form.Control ref={passwordRef} type={showPassword ? 'text' : 'password'} placeholder={t('password')} />
                        
                        <Button variant="outline-primary" className={`ms-3${showPassword ? ' active' : ''}`} onClick={() => setShowPassword(!showPassword)}>
                            <FontAwesomeIcon icon={faEye} size='sm' />
                        </Button>
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNewPassword">
                    <Form.Label>{t('newPasswordAgain')}</Form.Label>
                    <div className='d-flex justify-content-between'>
                        <Form.Control ref={password2ndRef} type={showSecondPassword ? 'text' : 'password'} placeholder={t('newPasswordAgain')} />
                        
                        <Button variant="outline-primary" className={`ms-3${showSecondPassword ? ' active' : ''}`} onClick={() => setShowSecondPassword(!showSecondPassword)}>
                            <FontAwesomeIcon icon={faEye} size='sm' />
                        </Button>
                    </div>
                </Form.Group>

                <Form.Group className="my-3" controlId="formPassword">
                    <Form.Label>{t("passwordStrength")}</Form.Label>
                    <div className="strength__indicator">
                        <div className="bar bg__default" />
                        <div className="bar bg__default" />
                        <div className="bar bg__default" />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formOtp">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control ref={otpRef} type="text" placeholder="xxxxxx" />
                </Form.Group>

                <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="termsandconditions"
                    checked={tnc}
                    required
                    onChange={(e) => setTNC(e.target.checked)}
                    label={
                        <Trans i18nKey='registrationTermsnConditions'>
                            <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                        </Trans>
                    }
                />
                
                <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="privacypolicy"
                    required
                    checked={pp}
                    onChange={(e) => setPP(e.target.checked)}
                    label={
                        <Trans i18nKey='registrationPP'>
                            <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                        </Trans>
                    }
                />

                <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="marketingterms"
                    checked={mt}
                    onChange={(e) => setMT(e.target.checked)}
                    label={
                        <Trans i18nKey='registrationMarketingTerms'>
                            <a href="https://sigmaheat.de/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-decoration-underline" style={{ color: "inherit" }} />
                        </Trans>
                    }
                />

                <Button variant="outline-primary" className='w-100 mb-3' type='submit' ref={signUPBTN}>{t('createAccount')}</Button>
                <span className='d-inline-block'>{t('alreadyMember')} <Link to='/login' className={`d-inline-block text-decoration-underline ${defaultTheme === undefined && localStorage.getItem('SH_Theme') === undefined ? "text-black" : defaultTheme === undefined ? localStorage.getItem('SH_Theme') === 'light' ? "text-black" : "text-light" : defaultTheme === 'light' ? 'text-black' : 'text-light'}`}>{t('login')}</Link></span>
            </Form>
        </Card>
    </div>
}

export default Register;