import React, { useState, useEffect, useRef, useCallback } from "react";
import { useToast } from "../../NotificationsContent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleCheck, faPaperPlane, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import WebSocketWorker from '../../websocket/WebsocketWorker';
import { useAuth } from '../../Context';
import { useParams } from 'react-router-dom';
import { useSwagger } from "../../context/SwaggerContext";
import { useTranslation } from 'react-i18next';
import SecurityActionModal from "../SecurityActionModal";
const { v4: uuidv4 } = require('uuid');

const Chat = ({users}) => {
    const client = useSwagger();
    const {addToast} = useToast();
    const {login,updateActiveUsers} = useAuth();
    const _buildingGuid = useParams()['buildingGuid'];
    const chatBoxRef = useRef(null);
    const { t } = useTranslation();
    const [show, setShow] = useState(undefined)
    const [showDeleteMessage, setShowDeleteMessage] = useState(false)

    const [sentMessages, setSentMessages] = useState([]);
    // const [receivedMessages, setReceivedMessages] = useState({"start":{date: "2024-10-18T08:28:24.533959Z" , message: "Schön, dass du hier bist. Hier kannst du nicht nur Nachrichten austauschen, sondern auch wichtige Informationen zum Gebäude erhalten. Viel Spaß!"}});
    const [receivedMessages, setReceivedMessages] = useState({});
    
    const [pendingMessage, setPendingMessage] = useState({})

    // sort
    const allMessages = [...Object.keys(sentMessages)?.map(msg_id => ({ ...sentMessages[msg_id], type: 'sent' })), ...Object.keys(receivedMessages)?.map(msg_id => ({ ...receivedMessages[msg_id], type: 'received' }))];
    const sortedMessages = allMessages.sort((a, b) => new Date(a.date) - new Date(b.date));

    const [newMessage, setNewMessage] = useState('');

    const formatDateWithoutSeconds = (dateString) => {
        return new Date(dateString).toLocaleString(undefined, { 
            hour: '2-digit', 
            minute: '2-digit', 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit' 
        });
    };

    // long press click
    const [isLongPressed, setIsLongPressed] = useState(false);
    const pressTimer = useRef(null); // Timer-Referenz

    const loadChat = useCallback(async() => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_chat_list({
                building_uuid: _buildingGuid,
            });

            if (response.status === 204) {
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                let rMsg = {};
                let sMsg = {};

                response.obj?.map(entry => {
                    if(entry.sender==login.id){
                        sMsg[entry.id]={...entry,date:entry.created_at,sent:true};
                    }
                    else{
                        rMsg[entry.id]={...entry,date:entry.created_at};
                    }
                })

                setReceivedMessages({...rMsg});
                setSentMessages({...sMsg});
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            // addToast(t("error"), t("networkError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, t])

    useEffect(()=>{
        loadChat()
    },[])

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            let newsentMessages = {...sentMessages};
            let message = {
                date: new Date().toISOString(),
                message: newMessage, 
                sent: false,
            }
            let id = uuidv4();
            newsentMessages[id]=message;
            setSentMessages({...newsentMessages});
            setPendingMessage(prevMsg => {
                let newmsg = {...prevMsg};
                newmsg[id]=message;
                return newmsg;
            });
            setNewMessage('');
            setShowDeleteMessage(null);
        }
    };

    const handleDeleteMessage  = useCallback(async(messageId) => {
        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Content-Type"] = "application/json";
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const response = await client.apis["building"].building_message_destroy({
                building_uuid: _buildingGuid,
                message_uuid: messageId
            });

            if (response.status >= 200 && response.status < 300) {
                addToast(t("success"), t("deleteMessageSuccessfully"), "success");
                loadChat()
                setShow(undefined)
                setShowDeleteMessage(null);
            }

            client.http.requestInterceptor = originalRequestInterceptor;
        } catch (error) {
            addToast(t("error"), t("networkError"), "error");

            client.http.requestInterceptor = originalRequestInterceptor;
        }
    }, [client, _buildingGuid, login.Authorization, addToast, t, loadChat])

    // wird nach langem Drücken ausgeführt
    const handleLongPress = (id, type) => {
        setIsLongPressed(true);
        if (type === "sent") setShowDeleteMessage(id)
    };

    // Start Timer (mousedown)
    const startPressTimer = (id, type) => {
        pressTimer.current = setTimeout(() => handleLongPress(id, type), 800);
    };

    // cancal Timer (onMouseUp oder onMouseLeave)
    const cancelPressTimer = () => {
        clearTimeout(pressTimer.current);
        setIsLongPressed(false);
    };

    useEffect(() => {
        const wsWorker = new WebSocketWorker(login, _buildingGuid, (title, msg, type) => {}, updateActiveUsers,setReceivedMessages);
        
        Object.keys(pendingMessage)?.map(msg_id => {
            let latestMessage = pendingMessage[msg_id];
            if(latestMessage)wsWorker.sendMessage(latestMessage, () => {
                // setSentMessages((prevMessages) => {
                //     let msgs = {...prevMessages};
                //     msgs[msg_id].sent=true;
                //     return msgs;
                // });
                loadChat()
                setPendingMessage(prevMsg => {
                    delete prevMsg[msg_id];
                    return prevMsg;
                })

            });
        });
        
        return () => {
            wsWorker.disconnect();
        };
    }, [pendingMessage]);

    useEffect(() => {
        // Scrollt nach unten, wenn man eine Nachricht geschickt hat
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [sentMessages, receivedMessages]); 

    return (
        <>
            <div className="chat_container">
                {(showDeleteMessage && (
                    <div className="delete_overlay_container">
                        <div className="delete_overlay_header">
                            <div>
                                <span>
                                    <FontAwesomeIcon style={{fontSize:"15px"}} icon={faTrash} />
                                </span> 
                                <span>{t('deleteMessage')}?</span>
                                <button className="delete_button" onClick={() => setShow({content: "deleteMessage", messageID: showDeleteMessage})}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </button>

                                <button className="close_button" onClick={(e) => {
                                    e.stopPropagation(); // verhindert, dass der Klick auf das "X" das Overlay erneut öffnet
                                    setShowDeleteMessage(null);
                                }}>

                                    <FontAwesomeIcon icon={faXmark} /> 
                                </button>
                            </div>
                        </div>
                    </div>
                ))}

                <div className={users ? 'chat_box' : 'chat_box chat_box_no_active_users'} ref={chatBoxRef}>
                    {sortedMessages.map((msg, index) => (
                        // <div key={index} className={`chat_message ${msg.type === 'sent' ? 'sent' : 'received'}`} onClick={() => setShowDeleteMessage(msg.id)}>
                        <div key={index} className={`chat_message ${msg.type === 'sent' ? 'sent' : 'received'}`} onMouseDown={() => startPressTimer(msg.id, msg.type)} onMouseUp={cancelPressTimer} onMouseLeave={cancelPressTimer} onTouchStart={() => startPressTimer(msg.id, msg.type)} onTouchEnd={cancelPressTimer}>
                            {/* {msg.type == "received" && msg.sender && <small>Sender: {msg.sender}</small>} */}
                            <span>{msg.message}</span>
                            <small>{formatDateWithoutSeconds(msg.date)} {msg.sent ? <FontAwesomeIcon icon={faCircleCheck} /> : ""}</small>
                            {((msg.id && showDeleteMessage === msg.id && msg.type === "sent") && (
                                <div className="delete_overlay">
                                    {/* <div >
                                        <button className="close_button" onClick={(e) => {
                                            e.stopPropagation(); // verhindert, dass der Klick auf das "X" das Overlay erneut öffnet
                                            setShowDeleteMessage(null);
                                        }}>

                                            <FontAwesomeIcon icon={faXmark} /> 
                                        </button>
                                    </div>

                                    <button className="delete_button" onClick={() => setShow({content: "deleteMessage", messageID: msg.id})}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button> */}
                                </div>
                            ))}
                        </div>            
                    ))}
                </div>
                <form className="chat_input" onSubmit={handleSendMessage}>
                    <input 
                        type="text" 
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder={t('messagePlaceholder')}
                        className={users ? 'input_with_active_users' : ''}
                    />
                    <button type="submit"><FontAwesomeIcon className='chat_input_icon' icon={faPaperPlane} /></button>
                </form>
            </div>
            <SecurityActionModal {...{ show: show?.content === "deleteMessage", onHide: () => setShow(undefined), title: t("deleteMessage"), question: t("deleteMessageQuestion"), action: () => handleDeleteMessage(show.messageID) }} />
        </>
      );
    };

export default Chat