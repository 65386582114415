import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../Context';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import Logo from '../assets/SigmaHeatLogo_lang.svg'
import ToggleIcon from '../assets/Einstellungen_Account_Icon.svg'
import cloneDeep from 'lodash/cloneDeep';
import Collapse from '../components/Collapse'
import { deleteCookie, extractInitials, specificColorGenerator } from "../util/helpers";
import { useToast } from '../NotificationsContent';
import { useSwagger } from '../context/SwaggerContext';

const CollapsableItem = ({ item, menuToggle, contentHeight }) => {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        contentHeight(true);
    }, [display, contentHeight])

    return (
        <>
            <li className='navigation__submenu__item'>
                <Collapse {...{boolean: display, onClick: () => setDisplay(!display)}} />
                <span>{item.label}</span>
            </li>
            <li className={`${display ? 'd-block' : 'd-none'}`}>
                <ul className='navigation__submenu__list'>
                    {item.array.map(it => {
                        return (
                            <li key={`collapsableitem-${Math.random()}`}>
                                <Link className='navigation__submenu__item' to={it.to} onClick={menuToggle}><FontAwesomeIcon icon={it.icon} /><span>{it.label}</span></Link>
                            </li>
                        )
                    })}
                </ul>
            </li>
        </>
    )
}

function Accordion({ link, menuToggle }) {
    const [toggle, setToggle] = useState(false);
    const listRef = useRef(null);
    const listWrapperRef = useRef(null);

    const contentHeight = useCallback((toggle) => {
        let margin = parseInt(window.getComputedStyle(listWrapperRef.current).marginTop);
        let height = `${listRef.current.clientHeight + margin}px`;
        listWrapperRef.current.style.setProperty('--height', height);
        if (toggle === undefined) setToggle(window.screen.width > 1024)
        if (window.screen.width < 1024) setToggle(true)
    }, [])

    const customEvent = useCallback(() => {
        const event = new CustomEvent('footerResize', { detail: { message: 'Hello!' } });
        window.dispatchEvent(event);
    }, []);

    useEffect(() => {
        const refListWrapper = listWrapperRef.current;
        contentHeight()
        window.addEventListener('resize', contentHeight)

        refListWrapper.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'min-height') customEvent();
        });

        return () => {
            window.removeEventListener('resize', contentHeight)
            refListWrapper.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'min-height') customEvent();
            });
        }
    }, [customEvent, contentHeight, link])

    return (
        <li>
            <div className='list__extend__wrapper'>
                <NavLink className='navigation__menu__item' to={link.to} onClick={() => menuToggle(false)}>
                    <FontAwesomeIcon icon={link.icon} />
                    <span>{link.label}</span>
                </NavLink>
                {Boolean(link.array.length) && <FontAwesomeIcon className={`${toggle ? ' open' : ''}`} icon={faArrowDown} onClick={() => setToggle(!toggle)} />}
            </div>
            <div className={`navigation__submenu__wrapper${toggle ? ' open' : ''}`} ref={listWrapperRef}>
                <ul className='navigation__submenu__list' ref={listRef}>
                    {
                        // eslint-disable-next-line array-callback-return
                        link.array.map((sublink, index) => {
                            if (sublink.type === 'Link') return <li key={`subnavlink-link-${sublink.to}`}><Link className='navigation__submenu__item' to={sublink.to} onClick={() => menuToggle(false)}><FontAwesomeIcon icon={sublink.icon} /><span>{sublink.label}</span></Link></li>
                            if (sublink.type === 'Category') return <React.Fragment key={`subnavlink-category-${index}`}><li key={`subnavlink-categorylink-${index}`}><Link className='navigation__submenu__item'>{sublink.label}</Link></li> {sublink.array.map(subitem => <li key={`subsubnavlink-key-${subitem.to}`}><Link className='navigation__submenu__item' to={subitem.to} onClick={() => menuToggle(false)}><FontAwesomeIcon icon={subitem.icon} /><span>{subitem.label}</span></Link></li>)}</React.Fragment>
                            if (sublink.type === 'Divider') return <div key={`subnavlink-divider-${index}`} className='sidebar__divider' />
                            if (sublink.type === 'Collapsable') return <CollapsableItem key={`collapsable-category-${index}`} {...{item: sublink, menuToggle: () => menuToggle(false), contentHeight}} />
                        })
                    }
                </ul>
            </div>
        </li>
    )
}

function Navbar({ toggle, setToggle, linksArray }) {
    const { addToast } = useToast();
    const {login, doSetLogin, image48, updateProfileImage} = useAuth();
    const _orgGuid = useParams()['orgGuid'];
    const _buildingGuid = useParams()["buildingGuid"];
    const { t } = useTranslation();
    const navigate = useNavigate();
    const client = useSwagger();
    const location = useLocation();

    const logout = async() => {
        doSetLogin(null);
        navigate('/login');
        deleteCookie('sigmaheat_user', undefined, '.sigmaheat.de');
        localStorage.setItem("logout", true);
    }

    const organisations = login.organisations;

    const changeOrganisation = useCallback(async(e) => {
        e.preventDefault();
       
        if (location.pathname === '/') {
            navigate(0);
        } else {
            let loginCopy = cloneDeep(login)
            delete loginCopy.currentOrganisation
            await doSetLogin(loginCopy)
            navigate('/', { replace: true });
        }
    }, [login, doSetLogin])

    const loadProfileImage = async(size) => {
        const sizes = [48];
        if (login === null || !sizes.includes(size)) return
        // fetch(`https://tech.sigmaheat.de/user/${login.id}/profilepicture?size=${size}`, {
        //     headers: {
        //         'Authorization': login.Authorization,
        //     },
        //     method: "GET"
        // })
        // .then((response) => {
        //     if (!response.ok) {
        //         const error = {
        //             message: t("profileImageLoadingError"),
        //             status: response.status
        //         }
        //         throw error;
        //     }
        //     return response.blob().then(content => {
        //         if(content.size>0)updateProfileImage(size, URL.createObjectURL(content));
        //     })
        // })
        // .catch(error => {
        //     updateProfileImage(size, false);
        //     if (error.status !== 204) addToast(t("error"), error.message, "error")
        // });

        if (!client) return;

        const originalRequestInterceptor = client.http.requestInterceptor;

        try {
            client.requestInterceptor = (req) => {
                req.headers["Authorization"] = login.Authorization;
                return req;
            };

            const params = { user_uuid_path: login.id};

            if (size) {
                params.size = size;
            }

            const response = await client.apis["User"].getUserProfilePicture(params);

            if (response.status >= 200 && response.status < 300) {
                if(response.data.size>0)updateProfileImage(size, URL.createObjectURL(response.data))
            }

            client.http.requestInterceptor = originalRequestInterceptor;

        } catch (error) {
            addToast(t('error'), t("profileImageLoadingError"), "error");
        }
    }

    useEffect(() => {
        if (image48 === false) loadProfileImage(48)
    }, [image48])

    useEffect(() => {
        function waitUntilFontsLoadedAndExecute() {
            document.fonts.ready.then(() => {
                const getAverageCharWidth = () => {
                    const tempElement = document.createElement('span');
                    tempElement.style.position = 'absolute';
                    tempElement.style.visibility = 'hidden';
                    tempElement.innerText = '\u00A0';
                    document.body.appendChild(tempElement);
                    const averageCharWidth = tempElement.offsetWidth;
                    document.body.removeChild(tempElement);
                    return averageCharWidth;
                }

                const autoGap = () => {
                    const marquee = document.querySelector('.marquee').clientWidth;
                    const span = document.querySelector('.marquee span');
                    span.innerText = login?.currentOrganisation?.name || login.mail;

                    return marquee > span.clientWidth ? marquee - span.clientWidth : 10;
                }

                const backspacesForWidth = () => {
                    const averageCharWidth = getAverageCharWidth();
                    const backspaces = Math.ceil(autoGap() / averageCharWidth);
                    return backspaces;
                }

                const addHTMLBackspacesToInnerText = (element, count) => {
                    const htmlBackspaces = Array(count).fill('\u00A0').join('');
                    element.innerText = (login?.currentOrganisation?.name || login.mail) + htmlBackspaces;
                }

                const bs = backspacesForWidth();
                Array.from(document.querySelectorAll('.marquee span')).forEach(sp => {
                    addHTMLBackspacesToInnerText(sp, bs);
                })
            });
        }

        // Marquee
        const findLeftMostVisibleItem = () => {
            const marqueeContainer = document.querySelector('.marquee');
            const marqueeTexts = Array.from(document.querySelectorAll('.marquee span'));
            const containerLeft = marqueeContainer.getBoundingClientRect().left;
            let leftMostVisibleItem = null;

            for (const textItem of marqueeTexts) {
                const textItemRect = textItem.getBoundingClientRect();
                if (textItemRect.right >= containerLeft) {
                    leftMostVisibleItem = textItem;
                    break;
                }
            }

            return leftMostVisibleItem;
        }

        const findVisibleWidth = (element) => {
            const marqueeContainer = document.querySelector('.marquee');
            const containerRect = marqueeContainer.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();

            if (elementRect.left >= containerRect.left && elementRect.right <= containerRect.right) {
                return elementRect.width;
            } else if (elementRect.left < containerRect.left && elementRect.right > containerRect.left) {
                return elementRect.right - containerRect.left;
            } else if (elementRect.left < containerRect.right && elementRect.right > containerRect.right) {
                return containerRect.right - elementRect.left;
            }

            return 0;
        }

        const marqueeContainer = document.querySelector('.marquee');
        const marqueeText = [...document.querySelectorAll('.marquee span')];

        let animationSpeed = 2;

        const startMarqueeAnimation = () => {
            marqueeText.forEach(text => text.style.animation = `marquee ${animationSpeed}s linear infinite running`)
        }

        const stopMarqueeAnimation = () => {
            const leftElem = findLeftMostVisibleItem();
            const vpLeftElem = findVisibleWidth(leftElem);
            const itemWidth = marqueeText[0].offsetWidth;
            const timeToTravel = ((vpLeftElem * animationSpeed) / itemWidth).toFixed(2);

            setTimeout(function () {
                marqueeText.forEach(text => text.style.animation = `marquee ${animationSpeed}s linear infinite paused`)
            }, timeToTravel * 1000);
        }

        window.addEventListener('resize', waitUntilFontsLoadedAndExecute());
        marqueeContainer.addEventListener('mouseover', startMarqueeAnimation);
        marqueeContainer.addEventListener('mouseleave', stopMarqueeAnimation);

        // Navigation Event
        var prevScrollpos = window.pageYOffset;
        window.addEventListener('scroll', function() {
            var currentScrollPos = window.pageYOffset;
            // if (window.pageYOffset <= 0) currentScrollPos = 0
            // document.querySelector('.navigation').style.transform = `translateY(${prevScrollpos >= currentScrollPos ? '0' : '-60'}px)`;
            // if (window.innerWidth <= 1024) document.querySelector('.navigation__mobile').style.minHeight = prevScrollpos >= currentScrollPos ? 'calc(100vh - 60px)' : '100vh';
            // else document.querySelector('.navigation__mobile').style.minHeight = null;
            if (window.pageYOffset <= 0) currentScrollPos = 0;
            const navigationElement = document.querySelector('.navigation');
            const mobileNavigationElement = document.querySelector('.navigation__mobile');

            if (navigationElement) {
                navigationElement.style.transform = `translateY(${prevScrollpos >= currentScrollPos ? '0' : '-60'}px)`;
                if (prevScrollpos >= currentScrollPos) {
                    if (window.pageYOffset >= 60) navigationElement.classList.add('hovered');
                    if (window.pageYOffset <  60) navigationElement.classList.remove('hovered');
                }
            }

            if (mobileNavigationElement) {
                if (window.innerWidth <= 1024) {
                    mobileNavigationElement.style.minHeight = prevScrollpos >= currentScrollPos ? 'calc(100vh - 60px)' : '100vh';
                } else {
                    mobileNavigationElement.style.minHeight = null;
                }
            }
            prevScrollpos = currentScrollPos;
        })

        return () => {
            marqueeContainer.removeEventListener('mouseover', startMarqueeAnimation);
            marqueeContainer.removeEventListener('mouseleave', stopMarqueeAnimation);
        }
    }, [login?.currentOrganisation?.name])

    return (
        <nav className={`navigation${toggle ? ' hovered': ''}`}>
            <section className='navigation__main__menu'>
                <div className='menu__button__logo__wrapper'>
                    {/* dont show burger menu in change organisation */}
                    {(_orgGuid || _buildingGuid) &&
                        <button className={`navigation__trigger ${toggle ? ' open' : ''}`} onClick={() => setToggle(!toggle)}>
                            <span className='hamburger__line' />
                            <span className='hamburger__line' />
                            <span className='hamburger__line' />
                        </button>
                    }
                    <Link to='/'>
                        <img className='img__logo' src={Logo} alt='' />
                    </Link>
                </div>

                <div className={`navigation__mobile ${toggle ? ' open' : ''}`}>
                    <ul className='navigation__menu__list'>
                        {linksArray.map(link => link.array ? <Accordion key={`navlink-keys-${link.to}`} {...{link, menuToggle: setToggle}} /> : <li key={`navlink-key-${link.to}`}><NavLink className='navigation__menu__item' to={link.to} onClick={() => setToggle(false)}><FontAwesomeIcon icon={link.icon} /><span>{link.label}</span></NavLink></li>)}
                    </ul>
                </div>

                <Dropdown>
                    <button className='dropdown__main__button dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'>
                        <div className='dropdown__column'>
                            <div className='profile__img__wrapper author__img' style={{ backgroundColor: specificColorGenerator(login?.prename + ' ' + login?.name, true) }}>
                                {image48 && <img className="author__img" src={image48} />}
                                {image48 === false && <span className="initials" style={{ color: specificColorGenerator(login?.prename + ' ' + login?.name, false) }}>{extractInitials(login?.prename + ' ' + login?.name)}</span>}
                            </div>

                            <div className='profile__info__wrapper'>
                                <p>{`${login.prename} ${login.name}`}</p>

                                <div className='marquee'>
                                    {login.hasOwnProperty('currentOrganisation') ? (
                                        <>
                                            <span>{login?.currentOrganisation?.name}</span>
                                            <span>{login?.currentOrganisation?.name}</span>
                                            <span>{login?.currentOrganisation?.name}</span>
                                            <span>{login?.currentOrganisation?.name}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>{login?.mail}</span>
                                            <span>{login?.mail}</span>
                                            <span>{login?.mail}</span>
                                            <span>{login?.mail}</span>
                                        </>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className='dropdown__toggle'>
                            <img src={ToggleIcon} alt='' />
                        </div>
                    </button>

                    <ul className='dropdown-menu dropdown__content'>
                        {Boolean(organisations.length) && <>
                            <Dropdown.Header>{t('organisations')}</Dropdown.Header>
                            <Dropdown.Item href='/#' onClick={(e) => changeOrganisation(e)}>{t('changeOrganisation')}</Dropdown.Item>
                            {(_orgGuid || login?.currentOrganisation?.id && login?.currentOrganisation?.in_onboarding === 3) &&<Link className='dropdown-item' to={`/${_orgGuid || login?.currentOrganisation?.id}/orgprofile`}>{t('organisationProfile')}</Link>}
                            <Dropdown.Divider />
                        </>}
                        <Link className='dropdown-item' to='/settings'>{t('settings')}</Link>
                        <Dropdown.Item onClick={logout}>{t('logout')}</Dropdown.Item>
                    </ul>
                </Dropdown>
            </section>
        </nav>
    )
}

export default Navbar