import React from "react"
import { useTranslation } from "react-i18next"

export const Price = ({ price, recurring }) => {
    const { t } = useTranslation();

    return <div className="price__tiers__wrapper">
        <div className="price_tier">
            <input type="radio" className="price__radio" checked={true} />
            <label className="radio__label">
                <h3>{t("price")}</h3>
                <p>{price}€ / {recurring} {t("month")}</p>
            </label>
        </div>
    </div>
}