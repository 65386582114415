import { useState, useCallback, useEffect } from "react";
import { useAuth } from "../Context";
import { useTranslation } from "react-i18next";
import { useToast } from "../NotificationsContent";
import { useParams } from "react-router-dom";
import {
  Accordion,
  Button,
  Col,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Card from "../components/Card";
import RoofForm from "./RoofForm";
import { useSwagger } from "../context/SwaggerContext";
import { faEraser, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { evaluateMathExpression, getChangedFields, isEmpty } from "../util/helpers";

const INDIVIDUAL_CATALOG = "c70146ec-d8f6-48e4-b359-b686a1da7fa0";
const ROOF_TYPE_ID = 5;
const VIRTUAL_GROUP_ID = 100;
const GROUND_ID = 0;
const WINDOW_TYPE_ID = 3;
const GROUND_SOIL_ID = 10;
const OUTER_WALL_SOIL_ID = 11;

export const AddEditSimpleBuildingPart = ({
  onClose,
  room,
  editBP,
  parentBP,
  building
}) => {
  const { login } = useAuth();
  const { addToast } = useToast();
  const { t } = useTranslation();
  const _buildingGuid = useParams()["buildingGuid"];
  const client = useSwagger();
  const [roof, setRoof] = useState(editBP?.more_attributes);

  let bpType = 0;
  if (editBP !== undefined)
    bpType = "type" in editBP?.more_attributes ? 5 : editBP.type;
  
  if (editBP === undefined && parentBP)
    bpType = 3

  const calculateAdjacentTemperature = (f) => {
    return Math.round((room.room_temperature - Number(f) * (room.room_temperature - building?.design_temperature)) * 100) / 100
  };

  const calculateF = (adjacentTemperature) => {
    return Math.round((room.room_temperature - Number(adjacentTemperature)) / (room.room_temperature - building?.design_temperature) * 100) / 100
  };

  let initialF = parentBP ? 1 : 0.5

  const initialSBP = editBP
    ? {
      id: editBP.id,
      name: editBP.name,
      area: editBP.area,
      floor: editBP.floor?.id,
      room: editBP.room?.id,
      type: bpType,
      adjacent_temperature: calculateAdjacentTemperature(editBP?.f),
      individual: {
        U_typical: editBP.individual?.U_typical,
        U_thermal_bridge: editBP.individual?.U_thermal_bridge,
        standard_reference: editBP.individual?.standard_reference?.id,
        construction_year: editBP.individual?.construction_year,
        comment: editBP?.individual?.comment
      },
      f: editBP?.f,
      additional_volume: editBP?.additional_volume,
      filter_1: editBP.individual?.standard_reference?.filter_1,
      filter_2: editBP.individual?.standard_reference?.filter_2,
      valid_from: editBP.individual?.standard_reference?.valid_from,
      valid_to: editBP.individual?.standard_reference?.valid_to,
      ...( (editBP?.subtract_from_surface) && { subtract_from_surface: editBP?.subtract_from_surface} ),
    }
    : {
      room: room.id,
      floor: room.floor.id,
      individual: { U_thermal_bridge: undefined },
      type: "0",
      f: initialF,
      adjacent_temperature: calculateAdjacentTemperature(initialF),
    };
  const [sbp, setSBP] = useState(initialSBP);
  const [type, setType] = useState(undefined);
  const [individual, setIndividual] = useState(
    editBP && (editBP?.individual?.standard_reference ||
      editBP?.individual?.U_typical)
      ? true
      : false
  );

  const [deduction, setDeduction] = useState(editBP?.parent ? true : false);
  // const [insulation, setInsulation] = useState(false);
  const initialAttributes = editBP
    ? {
      width: editBP?.more_attributes?.width,
      height: editBP?.more_attributes?.height,
      distance_from_floor: editBP?.more_attributes?.distance_from_floor,
      direction: editBP?.more_attributes?.direction,
      maxDepthGround: editBP?.more_attributes?.maxDepthGround,
    }
    : {};
  const [attributes, setAttributes] = useState(initialAttributes);

  const [bpc, setBPC] = useState(undefined);
  const [bpt, setBPT] = useState([]);

  const [thickness, setThickness] = useState(0);
  const [lambda, setLambda] = useState(0);

  const [isNameChangedByUser, setIsNameChangedByUser] = useState(false);

  let minVolume = room?.height * room?.area;
  let maxVolume = 2 * room?.height * room?.area;

  const [roomHeight, setRoomHeight] = useState(roof?.room_height || room.height);


  const changeHandler = useCallback(
    (e) => {
      const { id, value } = e.target;

      if (id === "name") setIsNameChangedByUser(true);


      setSBP((prevState) => {
        const updatedState = { ...prevState };

        if (id === "standard_reference") {
          updatedState.individual = {
            ...prevState.individual,
            standard_reference: value,
            U_typical: undefined,
          };
        } else if (id === "construction_year") {
          updatedState.individual = {
            ...prevState.individual,
            construction_year: value,
          };
        } else if (id === "U_thermal_bridge") {
          const updatedValue = value.replace(",", ".");
          updatedState.individual = {
            ...prevState.individual,
            U_thermal_bridge: updatedValue,
          };
        } else if (id === "U_typical") {
          const updatedValue = value.replace(",", ".");
          updatedState.individual = {
            ...prevState.individual,
            U_typical: updatedValue,
            standard_reference: undefined,
          };
        } else if (id === "comment") {
          updatedState.individual = {
            ...prevState.individual,
            comment: value,
            standard_reference: undefined,
          };
        } else {
          updatedState[id] = value;
        }

        return updatedState;
      });

      if (id === "type") {
        setAttributes({});
        setSBP((prevState) => ({
          ...prevState,
          f: 1,
          adjacent_temperature: calculateAdjacentTemperature(1),
        }));
      }

      if (id === "type" && (parseInt(value) === GROUND_SOIL_ID || parseInt(value) === OUTER_WALL_SOIL_ID)) {
        setSBP((prevState) => ({
          ...prevState,
          f: 0.3,
          adjacent_temperature: calculateAdjacentTemperature(0.3),
        }));
      } else if (id === "type" && parseInt(value) === GROUND_ID) {
        setSBP((prevState) => ({
          ...prevState,
          f: 0.5,
          adjacent_temperature: calculateAdjacentTemperature(0.5),
        }));
      } 

      if (id === "type" && value in [0, 2, 8] && !sbp.hasOwnProperty("area")) {
        setSBP((prevState) => ({
          ...prevState,
          area: room.area,
        }));
      }

      if (id === "f") {
        setSBP((prevState) => ({
          ...prevState,
          adjacent_temperature: calculateAdjacentTemperature(value),
        }));
      }

      if (id === "adjacent_temperature") {
        setSBP((prevState) => ({
          ...prevState,
          f: calculateF(value)
        }));
      }

    },
    [sbp]
  );

  const changeCheckboxHandler = useCallback(
    (e) => {
      const { id, checked } = e.target;
      setSBP((prevState) => ({ ...prevState, [id]: checked }));
    },
    [sbp]
  );

  const attributesHandler = useCallback(
    (e) => {
      const { id, value } = e.target;
      setAttributes((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    },
    [attributes]
  );

  const getBPTypology = useCallback(async () => {
    if (!client || sbp["bpc"] === INDIVIDUAL_CATALOG)
      return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis[
        "buildingpartcatalog"
      ].getBuildingpartCatalogTypology({
        org_uuid: login.currentOrganisation.id,
        buildingpart_catalog_uuid: sbp.bpc,
      });

      if (response.status === 204) {
        setBPT([]);
        return;
      }

      if (response.status >= 200 && response.status < 300) {
        setBPT(response.obj);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");
      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, onClose, sbp.bpc, editBP]);

  const getBPC = useCallback(async () => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis["building"].getBuildingPartCatalog({
        org_uuid: login.currentOrganisation.id,
      });

      if (response.status === 204) {
        setBPC([]);
        return;
      }

      if (response.status >= 200 && response.status < 300) {
        setSBP((prevState) => ({
          ...prevState,
          bpc:
            editBP?.individual?.U_typical &&
              editBP?.individual?.U_thermal_bridge
              ? INDIVIDUAL_CATALOG
              : (editBP?.individual?.standard_reference?.catalog || response.obj[0]?.id),
        }));

        setBPC([
          ...response.obj,
          {
            created_at: new Date(),
            description: "",
            id: INDIVIDUAL_CATALOG,
            name: "Individually",
            organisation: null,
            updated_at: new Date(),
          },
        ]);
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");

      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization, bpc, onClose]);

  const getTypes = useCallback(async () => {
    if (!client) return;

    const originalRequestInterceptor = client.http.requestInterceptor;

    try {
      client.requestInterceptor = (req) => {
        req.headers["Content-Type"] = "application/json";
        req.headers["Authorization"] = login.Authorization;
        return req;
      };

      const response = await client.apis[
        "buildingparttypes"
      ].buildingparttypes_list();

      if (response.status === 204) {
        setType([]);
        return;
      }

      if (response.status >= 200 && response.status < 300) {
        if (editBP) {
          const typeItem = response.obj.find(
            (item) => item.label === editBP.type
          );
          if (typeItem) {
            editBP.type = typeItem.value;
          }
        }

        const labelMap = {
          Floor: "floor",
          OuterWall: "outerwall",
          Ceiling: "ceiling",
          Window: "window",
          OuterDoor: "outerdoor",
          Roof: "roof",
          InnerWall: "innerwall",
          InnerDoor: "innerdoor",
          InnerCeiling: "innerceiling",
          Shutterbox: "rollershutterbox",
          "Virtual Group": "virtualGroup",
          "Ground Soil" : "groundSoil",
          "OuterWall Soil": "outerWallSoil",
        };

        const updatedItems = response.obj.map((item) => {
          const newLabel = labelMap[item.label];
          return newLabel ? { ...item, label: newLabel } : item;
        });
        let slopingRoofGenerator = {value: ROOF_TYPE_ID, label: "slopingRoofGenerator"}
        updatedItems.push(slopingRoofGenerator)

        if (editBP) {
          let filterUpdatedItems = updatedItems.filter((item) => item.value !== 5)
          setType(filterUpdatedItems);
        } else {
          setType(updatedItems);
        }

        setSBP((prevState) => ({
          ...prevState,
          type: editBP || parentBP ? bpType : response.obj?.[0]?.value,
          area: editBP ? editBP.area : room.area,
        }));
      }

      client.http.requestInterceptor = originalRequestInterceptor;
    } catch (error) {
      addToast(t("error"), t("networkError"), "error");

      client.http.requestInterceptor = originalRequestInterceptor;
    }
  }, [client, login.Authorization]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let bp = { ...sbp };

      if (editBP && individual && bp?.bpc !== INDIVIDUAL_CATALOG && filtered_4.length) {
        if (bp?.individual?.standard_reference === editBP.individual?.standard_reference?.id) {
          bp.individual.standard_reference = filtered_4[0].id
        }
      }

      if (sbp?.bpc !== INDIVIDUAL_CATALOG) {
        delete sbp?.individual?.comment
      }

      if (sbp?.individual?.standard_reference === undefined && filtered_4.length && individual) {
        bp.individual.standard_reference = filtered_4[0].id
      }

      if (parentBP) {
        bp.parent_id = parentBP.id;
        if (!bp.hasOwnProperty("subtract_from_surface")) {
          bp["subtract_from_surface"] = false;
        }
      }

      bp.more_attributes = attributes;

      bp["area"] = evaluateMathExpression(bp["area"])

      if (parseInt(sbp?.type) >= VIRTUAL_GROUP_ID) {
        delete bp?.area;
      }

      if (parseInt(sbp?.type) < VIRTUAL_GROUP_ID) {
        delete bp?.additional_volume;
      }

      if (!individual && bp?.individual?.U_thermal_bridge === undefined) {
        delete bp?.individual;
      }

      if (!individual && bp?.individual?.U_thermal_bridge !== undefined) {
        delete bp?.individual?.U_typical;
        delete bp?.individual?.standard_reference;
        if (parseFloat(bp?.individual?.U_thermal_bridge) === 0) delete bp?.individual?.U_thermal_bridge;
      }

      if (typeof bp?.individual?.standard_reference == 'object') {
        bp.individual.standard_reference = bp?.individual?.standard_reference?.id;
      }

      if (bp?.bpc === INDIVIDUAL_CATALOG) {
        delete bp.individual.standard_reference
      }

      if (bp?.bpc !== INDIVIDUAL_CATALOG) {
        delete bp.individual?.U_typical
      }

      const changedFields = getChangedFields(bp, initialSBP);
      if (editBP !== undefined && isEmpty(changedFields)) onClose()

      // return

      if (!client || (editBP !== undefined && isEmpty(changedFields))) return;

      const originalRequestInterceptor = client.http.requestInterceptor;

      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;

          req.body = JSON.stringify(editBP ? changedFields : bp);
          return req;
        };

        const response = editBP
          ? await client.apis[
            "building"
          ].building_buildingpart_simple_partial_update({
            building_uuid: _buildingGuid,
            buildingpart_uuid: editBP?.id,
          })
          : await client.apis["building"].building_buildingpart_simple_create({
            building_uuid: _buildingGuid,
          });

        // console.log("RERSPOSNE: ", response)

        if (response.status >= 200 && response.status < 300) {
          onClose();
        }

        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        addToast(t("error"), t("responseError"), "error");

        client.http.requestInterceptor = originalRequestInterceptor;
      }
    },
    [
      client,
      login.Authorization,
      t,
      addToast,
      sbp,
      individual,
      attributes,
      deduction,
    ]
  );

  const onSubmitRoof = useCallback(
    async (e) => {
      e.preventDefault();

      let obj = { ...roof, name: sbp.name, room_height: roomHeight };

      if (editBP !== undefined) obj = { ...obj, id: editBP.id };

      if (!client) return;

      const originalRequestInterceptor = client.http.requestInterceptor;

      try {
        client.requestInterceptor = (req) => {
          req.headers["Content-Type"] = "application/json";
          req.headers["Authorization"] = login.Authorization;

          req.body = JSON.stringify(obj);
          return req;
        };

        const response = await client.apis[
          "building"
        ].building_buildingpart_simple_roof_create({
          building_uuid: _buildingGuid,
        });

        if (response.status >= 200 && response.status < 300) {
          onClose();
          addToast(
            t("success"),
            "Das Dach wurde erfolgreich hinzugefügt!",
            "success"
          );
        }

        client.http.requestInterceptor = originalRequestInterceptor;
      } catch (error) {
        addToast(t("error"), t("responseError"), "error");

        client.http.requestInterceptor = originalRequestInterceptor;
      }
    },
    [client, login.Authorization, t, addToast, roof, onClose, sbp, roomHeight]
  );


  const calculateThickness = useCallback(() => {
    if (!sbp?.individual.hasOwnProperty("U_typical")) {
      addToast(
        t("error"),
        "Bevor Sie die Dämmung berechnen, tragen Sie bitte ein U Wert",
        "error"
      );
      return;
    }
    if (lambda === 0) {
      addToast(t("error"), "Lambda darf nicht null sein", "error");
      return;
    }
    if (thickness === 0) {
      addToast(t("error"), "Dicke darf nicht null sein", "error");
      return;
    }
    let value = Math.pow(
      1 / sbp?.individual?.U_typical + (thickness * 10) / lambda,
      -1
    );
    setSBP((prevState) => ({
      ...prevState,
      individual: {
        ...prevState.individual,
        U_typical: String(value),
      },
    }));
  }, [thickness, lambda, sbp, addToast]);

  useEffect(() => {
    getTypes();
    if (parentBP) {
      setSBP((prevState) => ({
        ...prevState,
        subtract_from_surface: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (individual) getBPC();
  }, [individual]);

  useEffect(() => {
    if (bpc !== undefined) getBPTypology();
  }, [bpc, sbp?.bpc]);

  let filtered =
    sbp.type != undefined ? bpt.filter((b) => b.type == sbp.type) : bpt;
  //   console.log("filtered t", filtered);
  let filtered_1 = sbp.filter_1
    ? filtered.filter((b) => b.filter_1 == sbp.filter_1)
    : filtered;
  //   console.log("filtered 1", filtered_1);
  let filtered_2 = sbp.filter_2
    ? filtered_1.filter((b) => b.filter_2 == sbp.filter_2)
    : filtered_1;
  //   console.log("filtered 2", filtered_2);

  let filtered_3 =
    Number(sbp.valid_from) > 0
      ? filtered_2.filter((b) => b.valid_from == sbp.valid_from)
      : filtered_2;
  //   console.log("filtered 3", filtered_3);

  let filtered_4 =
    Number(sbp.valid_to) > 0
      ? filtered_3.filter((b) => b.valid_to == sbp.valid_to)
      : filtered_3;
  //   console.log("filtered 4", filtered_4);

  let filter_1_options = [
    ...new Set(filtered.filter((e) => e.filter_1 != "").map((e) => e.filter_1)),
  ];
  let filter_2_options = [
    ...new Set(
      filtered_1.filter((e) => e.filter_2 != "").map((e) => e.filter_2)
    ),
  ];
  let filter_3_options = [
    ...new Set(
      filtered_2.filter((e) => e.valid_from != "").map((e) => e.valid_from)
    ),
  ];
  let filter_4_options = [
    ...new Set(
      filtered_3.filter((e) => e.valid_to != "").map((e) => e.valid_to)
    ),
  ];

  useEffect(() => {
    if (sbp.filter_1 && editBP?.individual?.standard_reference?.filter_1 != sbp?.filter_1) {
      setSBP((prevState) => ({
        ...prevState,
        filter_2: filter_2_options[0],
        valid_from: filter_3_options[0],
        valid_to: filter_4_options[0],
      }));
    }
  }, [sbp.filter_1]);

  useEffect(() => {
    if (sbp.filter_2) {
      setSBP((prevState) => ({
        ...prevState,
        valid_from: filter_3_options[0],
        valid_to: filter_4_options[0],
      }));
    }
  }, [sbp.filter_2]);

  useEffect(() => {
    if (sbp.valid_from) {
      setSBP((prevState) => ({
        ...prevState,
        valid_to: filter_4_options[0],
      }));
    }
  }, [sbp.valid_from]);

  useEffect(() => {
    if (!isNameChangedByUser) {
      // wenn der Name vom user nicht geändert wurde, soll der Name wie beim type erscheinen beim adden
      if (!editBP && type) {
        const typeObj = type.find((type) => type.value === parseInt(sbp.type));
        const newName = typeObj ? `${t(typeObj.label)}` : "";
        setSBP((prevShp) => ({
          ...prevShp,
          name: newName,
        }));
      }
    }
  }, [sbp.type, isNameChangedByUser, type]);

  useEffect(() => {
    if (sbp.type) {
      setSBP((prevShp) => ({
        ...prevShp,
        filter_1: "",
        filter_2: "",
        valid_from: "",
        valid_to: "",
        individual: { ...prevShp.individual, standard_reference: undefined },
      }));
    }
  }, [sbp.type]);

  const get_filter_1_and_2 = (type) => {
    let label_filter_1 = type == 9 ? t("insulation") : t("material");
    let label_filter_2 = type == 3 ? t("glazing") : t("structure");

    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>{label_filter_1}</Form.Label>

          <Form.Select
            id="filter_1"
            value={sbp["filter_1"] || editBP?.individual?.standard_reference?.filter_1 || ""}
            onChange={(e) => changeHandler(e)}
            disabled={filter_1_options.length < 1}
          >
            {filter_1_options.map((filter_1) => (
              <option key={`bpt-key-${filter_1}`} value={filter_1}>
                {filter_1}
              </option>
            ))}
            <option key={`bpt-key-none`} value={""}>
              {"keine Auswahl"}
            </option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>{label_filter_2}</Form.Label>
          <Form.Select
            id="filter_2"
            value={sbp["filter_2"] || editBP?.individual?.standard_reference?.filter_2 || ""}
            onChange={(e) => changeHandler(e)}
            disabled={filter_2_options.length < 1}
          >
            {filter_2_options.map((filter_2) => (
              <option key={`bpt-key-${filter_2}`} value={filter_2}>
                {filter_2}
              </option>
            ))}
            <option key={`bpt-key-none`} value={""}>
              {"keine Auswahl"}
            </option>
          </Form.Select>
        </Form.Group>
      </>
    );
  };

  const get_individual_submenu = () => {
    return (
      <>
        {sbp["bpc"] !== INDIVIDUAL_CATALOG && (
          <>
            {get_filter_1_and_2(sbp?.type)}

            <Form.Group className="mb-3">
              <Form.Label>{t("valid_from")}</Form.Label>
              <Form.Select
                id="valid_from"
                value={sbp["valid_from"] || editBP?.individual?.standard_reference?.valid_from || ""}
                onChange={(e) => changeHandler(e)}
                disabled={filter_3_options.length < 1}
              >
                {filter_3_options.map((valid_from) => (
                  <option key={`bpt-key-${valid_from}`} value={valid_from}>
                    {valid_from}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t("valid_to")}</Form.Label>
              <Form.Select
                id="valid_to"
                value={sbp["valid_to"] || editBP?.individual?.standard_reference?.valid_to || ""}
                onChange={(e) => changeHandler(e)}
                disabled={filter_4_options.length < 1}
              >
                {filter_4_options.map((valid_to) => (
                  <option key={`bpt-key-${valid_to}`} value={valid_to}>
                    {valid_to}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t("buildingPart")}</Form.Label>
              <Form.Select
                id="standard_reference"
                value={sbp?.individual?.standard_reference}
                onChange={(e) => changeHandler(e)}
                disabled={filtered_4.length < 1}
              >
                {filtered_4?.map((b) => (
                  <option key={`bpt-key-${b.id}`} value={b.id}>
                    {b.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </>
        )}
      </>
    );
  };

  const get_main_informations = () => {
    return <>
      {parentBP && (
        <Form.Group className="mb-3">
          <Form.Label>{t("buildingPart")} Name</Form.Label>
          <Form.Control type="text" value={parentBP["name"] || ""} disabled />
        </Form.Group>
      )}

      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          id="name"
          required
          type="text"
          value={sbp["name"] || ""}
          onChange={(e) => changeHandler(e)}
        />
      </Form.Group>

      {parseInt(sbp?.type) === VIRTUAL_GROUP_ID && !parentBP && !editBP?.parent && (
        <Form.Group className="mb-3">
          <Form.Label>{t("volume")}</Form.Label>
          <Form.Control
            id="additional_volume"
            required
            type="number"
            step="any"
            min={-minVolume}
            max={maxVolume}
            value={sbp["additional_volume"] || ""}
            onChange={(e) => changeHandler(e)}
          />
        </Form.Group>
      )}

      <Form.Group className="mb-3">
        <Form.Label>Type</Form.Label>
        <Form.Select
          id="type"
          value={sbp["type"] || ""}
          onChange={(e) => changeHandler(e)}
        >
          {type?.filter((b) => !(individual && b.value == ROOF_TYPE_ID))?.map((b) => (
            <option key={`type-key-${b.value}`} value={b.value}>
              {t(b.label)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {parseInt(sbp?.type) !== ROOF_TYPE_ID && parseInt(sbp?.type) !== VIRTUAL_GROUP_ID && (
        <Form.Group className="mb-3">
          <Form.Label>
            {t("area")}
            {sbp["area"] > 5 * room.area && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={
                  <Tooltip>{t("WarningAreaSize", { factor: 5 })}</Tooltip>
                }
              >
                <span>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    color="yellow"
                    style={{ paddingLeft: "5px" }}
                  />
                </span>
              </OverlayTrigger>
            )}
          </Form.Label>
          <Form.Control
            id="area"
            required
            type="text"
            step="any"
            min="0.1"
            value={sbp["area"] || ""}
            onChange={(e) => changeHandler(e)}
          />
        </Form.Group>
      )}

      {(parseInt(sbp?.type) === OUTER_WALL_SOIL_ID || parseInt(sbp?.type) === GROUND_SOIL_ID) &&
        <Form.Group className="mb-3">
          <Form.Label>{t("maxDepthGround")}</Form.Label>
          <Form.Control
            id="maxDepthGround"
            type="number"
            placeholder=""
            min="0"
            required
            value={attributes["maxDepthGround"] || ""}
            onChange={(e) => attributesHandler(e)}
          />
        </Form.Group>
      }

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{t("temperatureCorrection")}</Form.Label>
            <Form.Control
              id="f"
              required
              type="number"
              value={sbp["f"] || ``}
              step={0.01}
              max={1.5}
              min={0}
              onChange={(e) => changeHandler(e)}
              disabled={Number(sbp.type) >= VIRTUAL_GROUP_ID}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{t("adjacentTemperature") + " (°C)"}</Form.Label>
            <Form.Control
              id="adjacent_temperature"
              required
              type="number"
              value={sbp["adjacent_temperature"] || ``}
              onChange={(e) => changeHandler(e)}
              disabled={Number(sbp.type) >= VIRTUAL_GROUP_ID}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  }

  const get_window_informations = () => {
    return <>
      {parseInt(sbp?.type) === WINDOW_TYPE_ID && (
        <>
          <Accordion defaultActiveKey={null} className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("additionalInformations")}</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label>{t("distanceFromFloor")}</Form.Label>
                  <Form.Control
                    id="distance_from_floor"
                    type="text"
                    value={attributes["distance_from_floor"] || ""}
                    onChange={(e) => attributesHandler(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("width")}</Form.Label>
                  <Form.Control
                    id="width"
                    type="text"
                    value={attributes["width"] || ""}
                    onChange={(e) => attributesHandler(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("height")}</Form.Label>
                  <Form.Control
                    id="height"
                    type="text"
                    value={attributes["height"] || ""}
                    onChange={(e) => attributesHandler(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("direction")}</Form.Label>
                  <Form.Control
                    id="direction"
                    type="text"
                    value={attributes["direction"] || ""}
                    onChange={(e) => attributesHandler(e)}
                  />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </>
  }

  const get_insulation_menu = () => {
    return <>
      {sbp?.bpc === INDIVIDUAL_CATALOG && (
        <>
          <Accordion defaultActiveKey={null} className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("additionalInformations")}</Accordion.Header>
              <Accordion.Body>
              <Form.Group className="mb-3">
                <Form.Label>{t("thickness")} (cm)</Form.Label>
                <Form.Control
                  id="thickness"
                  type="number"
                  placeholder=""
                  value={thickness}
                  onChange={(e) => setThickness(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Lambda (WLG) (milli W/mK)</Form.Label>
                <Form.Control
                  id="Lambda"
                  type="number"
                  placeholder=""
                  value={lambda}
                  onChange={(e) => setLambda(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="outline-primary"
                className="w-100 mb-3"
                onClick={() => calculateThickness()}
              >
                {t("calc")}
              </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </>
  }

  // const get_insulation_menu = () => {
  //   return <>
  //     {insulation && (
  //       <>
  //         <Form.Group className="mb-3">
  //           <Form.Label>{t("thickness")} (cm)</Form.Label>
  //           <Form.Control
  //             id="thickness"
  //             type="number"
  //             placeholder=""
  //             value={thickness}
  //             onChange={(e) => setThickness(e.target.value)}
  //           />
  //         </Form.Group>

  //         <Form.Group className="mb-3">
  //           <Form.Label>Lambda (WLG) (milli W/mK)</Form.Label>
  //           <Form.Control
  //             id="Lambda"
  //             type="number"
  //             placeholder=""
  //             value={lambda}
  //             onChange={(e) => setLambda(e.target.value)}
  //           />
  //         </Form.Group>

  //         <Button
  //           variant="outline-primary"
  //           className="w-100 mb-3"
  //           onClick={() => calculateThickness()}
  //         >
  //           {t("calc")}
  //         </Button>
  //       </>
  //     )}
  //   </>
  // }

  const get_individual_menu = () => {

    let current_selected = undefined;

    // bpt.map(b => {
    //   if (b.id == sbp?.individual?.standard_reference) current_selected = b;
    // })

    filtered_4?.map(b => {
      if (b.id == sbp?.individual?.standard_reference) current_selected = b;
    })

  return <>
      {parseInt(sbp?.type) !== ROOF_TYPE_ID && parseInt(sbp?.type) !== VIRTUAL_GROUP_ID && (
        <Nav variant="pills" justify>
          <Nav.Item className="me-2">
            <Nav.Link
              className={
                individual ? "table-nav-link" : "table-nav-link-active"
              }
              onClick={() => {
                setIndividual(false);
              }}
            >
              {t("fromVariant")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="ms-2">
            <Nav.Link
              className={
                !individual ? "table-nav-link" : "table-nav-link-active"
              }
              onClick={() => {
                setIndividual(true);
              }}
            >
              {t("individually")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      )}

      {individual && parseInt(sbp?.type) !== VIRTUAL_GROUP_ID && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>{t("Buildingpartcataloges")}</Form.Label>
            <Form.Select
              id="bpc"
              value={sbp["bpc"] || ""}
              onChange={(e) => changeHandler(e)}
            >
              {bpc?.map((b) => (
                <option key={`bpc-key-${b.id}`} value={b.id}>
                  {b.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {get_individual_submenu()}

          {(sbp?.bpc !== INDIVIDUAL_CATALOG) &&
            <Card {...{ heading: t('dataset'), className: "mb-3" }}>
              {
                <>
                  <p>{t("description") + " : "}</p>
                  <p>{current_selected?.name || filtered_4[0]?.name}</p>{" "}
                  <p>{current_selected?.description || filtered_4[0]?.description}</p>{" "}
                  {/* <p>{t("morInfo") + " : "}</p>
                  <span>{current_selected?.filter_1 || filtered_4[0]?.filter_1}</span>{", "}
                  <span>{current_selected?.filter_2 || filtered_4[0]?.filter_2}</span>{", "}
                  <span>{current_selected?.valid_from || filtered_4[0]?.valid_from}</span>{" bis "}
                  <span>{current_selected?.valid_to || filtered_4[0]?.valid_to}</span>{" "} */}
                </>
              }

              <p>
                {t("uValue") +
                  " : " +
                  (current_selected?.u_value_m2 || filtered_4[0]?.u_value_m2 || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                W/m²/K
              </p>
            </Card>
          }
        </>
      )}

      {sbp["bpc"] === INDIVIDUAL_CATALOG && individual && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>{t("uTypical")} (W/m²K)</Form.Label>
            <Form.Control
              id="U_typical"
              type="number"
              required
              placeholder=""
              min="0"
              step="0.01"
              value={sbp.individual?.U_typical || ""}
              onChange={(e) => changeHandler(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>U {t("thermalBridge")}</Form.Label>
            <Form.Control
              id="U_thermal_bridge"
              type="number"
              required={individual ? true : false}
              step="any"
              min="0"
              placeholder=""
              value={sbp?.individual?.U_thermal_bridge || ""}
              onChange={(e) => changeHandler(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("comment")}</Form.Label>
            <Form.Control
              as="textarea"
              id="comment"
              placeholder=""
              value={sbp?.individual?.comment || ""}
              onChange={(e) => changeHandler(e)}
              rows={2} 
            />
          </Form.Group>
{/* 
          <Form.Group className="mb-3">
            <Form.Check
              id="insulation"
              type="checkbox"
              checked={insulation}
              onChange={(e) => setInsulation(e.target.checked)}
              label="Insulation?"
            />
          </Form.Group> */}

          {get_insulation_menu()}
        </>
      )}

      {parseInt(sbp?.type) !== VIRTUAL_GROUP_ID &&
        parseInt(sbp?.type) !== ROOF_TYPE_ID &&
        sbp["bpc"] !== INDIVIDUAL_CATALOG && (
          <>
            {individual ? (
              <Form.Group className="mb-3">
                <Form.Label>U {t("thermalBridge")}</Form.Label>
                <Form.Control
                  id="U_thermal_bridge"
                  type="number"
                  required={individual ? true : false}
                  value={sbp?.individual?.U_thermal_bridge || ""}
                  step="0.01"
                  min="0"
                  placeholder=""
                  onChange={(e) => changeHandler(e)}
                />
              </Form.Group>
            ) : (
              <Accordion defaultActiveKey={null} className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {t("additionalInformations")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        U {t("thermalBridge")} ({t("deviatingFromVariant")})
                      </Form.Label>
                      <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                        <Form.Control
                          id="U_thermal_bridge"
                          type="number"
                          required={individual ? true : false}
                          step="0.01"
                          min="0"
                          placeholder=""
                          value={sbp?.individual?.U_thermal_bridge || ""}
                          onChange={(e) => changeHandler(e)}
                        />
                        <Button
                          className="p-10"
                          variant="outline-primary"
                          onClick={() => { let a = { ...sbp }; delete a?.individual?.U_thermal_bridge; setSBP({ ...a }); }}
                        >
                          <FontAwesomeIcon icon={faEraser} />
                        </Button>
                      </div>

                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("constructionYear")}</Form.Label>
                      <Form.Control
                        id="construction_year"
                        type="number"
                        min="1900"
                        placeholder=""
                        value={sbp?.individual?.construction_year || ""}
                        onChange={(e) => changeHandler(e)}
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </>
        )}
    </>
  }

  return (
    <Form
      onSubmit={(e) =>
        parseInt(sbp?.type) !== ROOF_TYPE_ID ? onSubmit(e) : onSubmitRoof(e)
      }
    >
      {get_main_informations()}

      {get_window_informations()}

      {get_individual_menu()}

      {(parentBP || editBP?.parent) && (
        <Form.Group className="mb-3">
          <Form.Check
            id="subtract_from_surface"
            type="checkbox"
            checked={sbp["subtract_from_surface"] || false}
            onChange={(e) => changeCheckboxHandler(e)}
            label={t("subtractFromSurface")}
          />
        </Form.Group>
      )}

      {parseInt(sbp?.type) === ROOF_TYPE_ID && (
        <RoofForm {...{ room: room?.id, setRoof, roof, roomHeight, setRoomHeight }} />
      )}

      <Button variant="outline-primary" className="w-100" type="submit">
        {editBP ? t("save") : t("add")}
      </Button>
    </Form>
  );
};

export default AddEditSimpleBuildingPart;
